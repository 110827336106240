// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/sync_training_camp/icon_close_white.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_install_app_modal_container{position:relative;top:15%;width:100%;left:0}.comp_install_app_modal_container .modal_close{position:absolute;right:25px;top:-32px;width:34px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:32.5px 32px;background-position:0 0;background-repeat:no-repeat}.comp_install_app_modal_container .modal_content{position:relative;margin:0 auto;width:350px;height:433px}.comp_install_app_modal_container .modal_content img{max-width:100%}.comp_install_app_modal_container .modal_content .continue_study{position:absolute;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:168px;border:1px solid #49c114;padding:3px 0;text-align:center;font-size:14px;color:#49c114;background:#fff;border-radius:200px;bottom:99px}@media screen and (min-width:1024px){.comp_install_app_modal_container{max-width:500px;margin:0 auto}}@media screen and (max-width:320px){.comp_install_app_modal_container .modal_content{width:100%}.comp_install_app_modal_container .modal_content .continue_study{font-size:12px;width:145px;bottom:136px}}", ""]);
// Exports
module.exports = exports;
