import React from "react";
import PropTypes from "prop-types";

const ParentGuideVideo = ({ videoSrc, videoRef }) => {
  return (
    <video
      ref={videoRef}
      id="pre_video"
      autoPlay={false}
      controls="controls"
      // poster={extra.poster}
      x5-video-player-type="h5"
      x5-video-player-fullscreen="false"
      x5-video-orientation="landscape|portrait"
    >
      <source src={videoSrc} type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
  );
};

const ParentGuideVideoWithRef = React.forwardRef((props, ref) => (
  <ParentGuideVideo {...props} videoRef={ref} />
));

ParentGuideVideo.propTypes = {
  videoSrc: PropTypes.string,
  videoRef: PropTypes.object,
};
export default ParentGuideVideoWithRef;
