import React from "react";
import "./Banner.scss";

export const TopTitle = ({ text }) => {
  return (
    <div className="banner_top_title">
      <div className="name">{text}</div>
    </div>
  );
};

// 单课程
export const SingleCourseBanner = ({ ...props }) => {
  const {
    grade,
    recommend_title,
    recommend_brief,
    url,
    photo_url,
    course_type,
    is_spell,
    course_day_count,
    media_lessons_count,
    media_courses_count,
    ecoupon,
  } = props.recommendCourse;
  const more_course_url = `https://xinya.me/?ref=recommend_report_single`;

  const bannerRecommendCoupon = () => {
    return (
      <div className="banner_recommend_coupon">
        <div className="achieve_coupon">
          <div className="achive_left">{`送您一张${ecoupon.price}元专属福利券`}</div>
          <div className="achive_right">领券报名</div>
        </div>
      </div>
    );
  };

  return (
    <div className="single_course_banner">
      <TopTitle text={`${grade}孩子都在学`} />
      <a className="banner_container_entrance" href={`${url}`}>
        <div
          className="banner_container"
          style={{
            backgroundImage: photo_url ? `url(${photo_url})` : null,
            borderRadius: ecoupon ? null : "6px",
          }}
        >
          <h2>{recommend_title}</h2>
          <p>{recommend_brief}</p>
          {/* <p>
            {`${course_day_count}天 · `}
            {course_type === "poetry" ? (
              is_spell ? (
                <span>{media_courses_count}节课</span>
              ) : (
                <span>{media_courses_count}首诗</span>
              )
            ) : (
              <span>{media_lessons_count}节课</span>
            )}
          </p> */}
        </div>
        {ecoupon && bannerRecommendCoupon()}
      </a>
      <a href={more_course_url} className="more_course">
        查看其它课程
      </a>
    </div>
  );
};

// 多课程
export const MultCourseBanner = ({ ...props }) => {
  const { name, first_course, courses } = props.recommendTheme;

  // 副入口
  const sideBannerBox = () => {
    return (
      <div className="side_banner_box">
        <ul
          style={{
            display: courses.length == 1 ? "initial" : null,
          }}
        >
          {courses.map((list, index) => (
            <li key={index}>
              <a href={`${list.url}`}>
                <img src={`${list.recommend_thumb_photo_url}`} alt="" />
                <div className="title">{list.recommend_title}</div>
                <div className="description">{list.recommend_brief}</div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // 主入口
  const mainBannerBox = () => {
    return (
      <a
        className={`main_banner_box ${courses ? "" : "remove_border_bottom"}`}
        href={`${first_course.url}`}
      >
        <img src={first_course.recommend_main_photo_url} alt="" />
        <div className="mult_course_content">
          <div className="left_content">
            <div className="left_content_title">
              {first_course.recommend_title}
            </div>
            <p>{first_course.recommend_brief}</p>
          </div>
          <div className="right_content">查看详情</div>
        </div>
      </a>
    );
  };

  return (
    <div className="mult_course_banner">
      <div className="mult_container">
        <div className="banner_title">{name}</div>
        {first_course && mainBannerBox()}
        {courses && sideBannerBox()}
      </div>
    </div>
  );
};
