import React from "react";
import PropTypes from "prop-types";

import { request, csrfHeaders } from "../utils/request";
import { formatDate } from "../utils/formatAudioTime";

import "./PracticeReport.scss";

class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);
    var date = formatDate(new Date());
    this.state = {
      get_day_study_url: this.props.get_day_study_url,
      date: date,
      i: 0,
      media_lessons: this.props.media_lessons,
      practice_report: this.props.practice_report,
      date: this.props.date,
      day_study_exam: this.props.day_study_exam,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const get_day_study_url = this.state.get_day_study_url;
    const media_lessons = this.state.media_lessons;
    const practice_report = this.state.practice_report;
    const date = this.state.date;
    const i = this.state.i;

    console.log("后端测试报告传递过来的时间：" + date);

    return (
      <div>
        {this.state.day_study_exam == true ? (
          ""
        ) : (
          <input
            type="date"
            name="date"
            className="btnDate"
            onChange={this.handleChange}
            value={this.state.date}
          />
        )}
        <div>
          {this.state.practice_report == true ? (
            media_lessons.map((media_lesson, k) => (
              <PracticeList
                media_lesson={media_lesson}
                key={media_lesson.id}
                selected={i == k}
              />
            ))
          ) : (
            <div className="practice_no_finish">
              你选的日期还没有完成练习，完成练习后，才可以查看报告哦 ～
            </div>
          )}
        </div>
      </div>
    );
  }

  handleChange(e) {
    console.log(e.target.value);
    var date = e.target.value;
    this.setState({
      date: date,
    });
    this.ajaxRequest(date);
  }

  ajaxRequest(date) {
    var url = this.props.get_day_study_url + "&date=" + date;
    request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((resp) => {
        const data = resp.data;
        console.log("正确返回");
        this.setState({
          media_lessons: data.media_lessons,
          practice_report: data.practice_report,
        });
      })
      .catch((err) => {
        console.log("错误返回");
        console.log(err);
      });
  }
}

class QuizList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const quiz = this.props.quiz;
    const index = this.props.index;

    return (
      <div className="practice_report_content">
        <div className="practice_report_info">
          <div className="option_title">
            <i>{index + 1}. </i>
            <span>{quiz.question}</span>
          </div>
          {quiz.question_photo_url != null ? (
            <div className="question_pic">
              <img src={quiz.question_photo_url} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="report_answer">
          <div className="respond_answer">
            <p>回答：</p>
            {quiz.error_url != "" && quiz.error_url != null ? (
              <img src={quiz.error_url} />
            ) : (
              quiz.error_answer
            )}
          </div>
          <div className="right_answer">
            <p>正确答案：</p>
            {quiz.correct_url != "" && quiz.correct_url != null ? (
              <img src={quiz.correct_url} />
            ) : (
              quiz.correct_answer
            )}
          </div>
        </div>
      </div>
    );
  }
}

class PracticeList extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      isDisplay: "block",
      selected: this.props.selected,
    };
  }

  componentDidMount() {
    if (this.props.selected) {
      this.setState({
        isDisplay: "block",
      });
    }
  }

  onClickShow = (e) => {
    if (this.state.isDisplay == "none") {
      this.setState({
        isDisplay: "block",
      });
    }
  };

  render() {
    const media_lesson = this.props.media_lesson;
    const icon_stars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

    return (
      <div>
        <div className="practice_report_list">
          <div className="practice_report_name" onClick={this.onClickShow}>
            <img src={icon_stars[media_lesson.rank]} />
            <p>
              {media_lesson.media_course_name}：{media_lesson.name}
            </p>
          </div>
          {media_lesson.failed_quizzes.length > 0 ? (
            <div
              className="practice_report_box"
              style={{ display: this.state.isDisplay }}
            >
              <h3>错题</h3>
              {media_lesson.failed_quizzes.map((quiz, index) => (
                <QuizList quiz={quiz} index={index} key={quiz.id} />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default class PracticeReport extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    const media_lessons = this.props.media_lessons;
    const get_day_study_url = this.props.get_day_study_url;
    const practice_report = this.props.practice_report;
    const date = this.props.date;
    const day_study_exam = this.props.day_study_exam;

    return (
      <div className="comp-practice-report">
        <div className="practice_calendar_date">
          <div className="example_date">
            {this.props.score ? (
              <div className="practice_score">{this.props.score}分</div>
            ) : (
              ""
            )}
            <DatePickerInput
              media_lessons={media_lessons}
              get_day_study_url={get_day_study_url}
              practice_report={practice_report}
              date={date}
              day_study_exam={day_study_exam}
            />
          </div>
        </div>
      </div>
    );
  }
}

PracticeReport.propTypes = {
  media_lessons: PropTypes.array,
};
