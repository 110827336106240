import React, { useEffect } from "react";
import Raven from "raven-js";
export const defaultFn = () => {};
const WechatShare = (props) => {
  useEffect(() => {
    const {
      shareOptions,
      onShareTimelineSuccess,
      onShareAppMessage,
      debug,
    } = props;
    if (debug) {
      console.warn("WechatShare debug:", debug);
      window.wx.ready = (fn) => {
        fn();
      };
    }
    // Raven.captureMessage(`user share before wx.ready ${typeof wx?.ready}`, {
    //   level: "info",
    // });

    try {
      wx.ready(() => {
        // Raven.captureMessage("user share in wx.ready", { level: "info" });
        // Raven.captureBreadcrumb({
        //   message: "user checkin",
        //   category: "share options",
        //   data: {
        //     ...shareOptions,
        //   },
        // });
        // Raven.captureMessage("user share options", { level: "info" });

        const shareTimelineOptions = {
          ...shareOptions,
          success: onShareTimelineSuccess || defaultFn,
        };

        const shareAppMessage = {
          ...shareOptions,
          success: onShareAppMessage || defaultFn,
        };
        wx.onMenuShareTimeline(shareTimelineOptions);
        wx.onMenuShareAppMessage(shareAppMessage);
      });
    } catch (e) {
      Raven.captureException(e);
    }

    // Raven.captureMessage(`user share after wx.ready ${typeof wx?.ready}`, {
    //   level: "info",
    // });
  }, []);

  return null;
};

export default WechatShare;
