import React from "react";
import icon_pass_back from "training_camp_activity/item_three.png";
import { days, unit_setting } from "../reading_index/ReadingIndex";
import icon_go_study from "reading/icon_go_study.png";
import icon_go_test from "reading/icon_go_test.png";
import ExcellentExerciseIcon from "excellent_exercise.png";
import PraiseIcon from "praise-filled.png";

import StarComponent from "./StarComponent";
// const
const dayItems = [];
export default class SpecialItem extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "SpecialItem");
  }
  getDayName(i, times) {
    return (
      <div className="day-name">
        <div className="day-name-title">{days[i]}</div>
        <div className="day-name-time">{`约${Math.ceil(times / 60)}分钟`}</div>
      </div>
    );
  }
  getDayStatus(type, unitIndex, isCurrent, status) {
    return (
      <>
        <div
          className="study-status-img"
          style={{
            backgroundColor: isCurrent
              ? "#fff"
              : unit_setting[unitIndex].minColor,
          }}
        >
          <img
            src={
              type === 0
                ? isCurrent
                  ? unit_setting[unitIndex].iconStudy
                  : icon_go_study
                : isCurrent
                ? unit_setting[unitIndex].iconTest
                : icon_go_test
            }
            style={{ width: type === 0 ? (isCurrent ? 8 : "") : "" }}
            className={type === 0 ? "icon_go_study" : "icon_go_test"}
          ></img>
        </div>
        <div
          className="study-status-font"
          style={{
            color: isCurrent ? "#fff" : unit_setting[unitIndex].minColor,
          }}
        >
          {status ? "复习" : "开始学习"}
        </div>
      </>
    );
  }
  getDayCourse(i, type, status, limtTime, likesCount) {
    return (
      <div
        className="unit-day-0-bottom-list"
        style={{ paddingLeft: type == 999 ? 8 : "" }}
      >
        {i && (
          <div className={type == 999 ? "course-dote1" : "course-dote"}>
            {i}
          </div>
        )}
        {status ? (
          <div
            className={`work-status ${
              // status == "pending_upload" || status == "pending_comment"
              //   ? "work-status2"
              status == "commented" || status == "pending_upload"
                ? "work-status1"
                : "work-status2"
              // : "work-status2"
            }   `}
          >
            {status == "pending_upload" ? (
              null
            ) : status == "commented" ? (
              "已点评"
            ) : (
              <>
                <div className="item1">
                  {status == "pending_comment" ? "已上传" : "已超时"}
                </div>
                <div className="item2">
                  {status == "pending_comment" ? "待点评" : "无点评"}
                </div>
              </>
            )}
            {/* {limtTime || ""} */}
          </div>
        ) : (
          ""
        )}
        {likesCount > 0 && (
          <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#FFF9E7', padding: '3px 9px', borderRadius: '12px', marginLeft: '5px'}}>
            <img src={PraiseIcon} style={{width: '12px', height: '12px'}} />
            <div style={{marginLeft: '4px', color: '#FF973C', fontSize: '11px', fontFamily: 'San Francisco'}}>{likesCount}</div>
          </div>
        )}
      </div>
    );
  }

  courseNameInspect() {
    return `《${this.props.poetry_articles[0]?.name}》${
      this.props.poetry_articles.length > 1
        ? "等" + this.props.poetry_articles.length + "篇文章"
        : ""
    }`
  }

  isThirdDayOfR3() {
    return this.props.study_mode === 'reading_v2' && this.props.study_mode_version === 3 && this.props.dayIndex === 2
  }
  
  render() {
    return (
      <div
        className="unit-item-content-0"
        style={{ paddingTop: this.props.dayIndex ? 30 : 0 }}
        id={this.props.date}
        // id={this.props.date}
      >
        <div className="unit-day-0">
          <div className="unit-day-0-top">
            <div className="unit-day-0-top-left">
              {/* {this.getDayName(0, 13)} 0第几天，13多长时间 */}
              {this.getDayName(
                this.props.study_type == 4
                  ? // this.props.study_type == 5 ||

                    3
                  : this.props.study_type == 6
                  ? this.props.totalLength > 1
                    ? 4
                    : 5
                  : this.props.dayIndex,
                this.props.total_avg_time
              )}
            </div>
            <div className="unit-day-0-top-right">
              {/* {this.getDayStatr(pass, rank)} */}
              {/* {this.getDayStatr(true, 2)} */}
              {this.props.rank === 0 || this.props.rank ? (
                <StarComponent
                  pass={this.props.state}
                  rank={this.props.rank}
                ></StarComponent>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="unit-day-0-bottom"
            style={{position: 'relative'}}
            onClick={() => {
              // this.props.showModel();
              if (this.props.day_lock) {
                this.props.showModel(new Date() < new Date(this.props.date));
                return;
              } else {
                window.location.href = this.props.url;
              }
            }}
          >
            <div
              className={
                this.props.day_lock
                  ? "unit-day-0-bottom-left unit-day-0-bottom-left-lock"
                  : "unit-day-0-bottom-left"
              }
            >
              <img src={this.props.photo_url}></img>
              {this.props.day_lock ? <div className="lock"></div> : ""}
              {this.props.excellent_exercise && <img src={ExcellentExerciseIcon} style={{width: '70px', height: '62px', position: 'absolute', bottom: 0, right: 0}} />}
            </div>
            <div className="unit-day-0-bottom-right">
              <div className="unit-day-0-bottom-lists">
                {this.props.study_type !== 4 &&
                this.props.study_type !== 5 &&
                this.props.study_type !== 6 &&
                this.props.dayIndex < 2
                  ? this.getDayCourse(
                      this.props.dayIndex == 0 ? "新课学习" : "纸质练习",
                      this.props.dayIndex
                    )
                  : this.props.study_mode === 'reading_v2' && this.props.study_mode_version === 3 && 
                    this.props.study_type !== 4 && this.props.study_type !== 5 && this.props.study_type !== 6
                  ? this.getDayCourse(
                      "纸质练习",
                      this.props.dayIndex,
                      null,
                      null,
                      this.props.likes_count
                    )
                  : ""}
                {/* {this.props.dayIndex == 1
                  ? 
                  : ""} */}
                {this.props.study_type == 4 ||
                (this.props.study_type == 6 && this.props.dayIndex === 0)
                  ? this.getDayCourse(this.props.sub_topic)
                  : this.props.dayIndex == 1
                  ? this.getDayCourse(
                      this.props.dayIndex == 1 ? "老师点评" : "",
                      this.props.dayIndex,
                      this.props.reading_comment_state,
                      `需${
                        this.props.upload_end_date
                          ? this.props.upload_end_date.replace(/-/g, ".")
                          : ""
                      }前上传 `
                    )
                  : this.isThirdDayOfR3()
                  ? this.getDayCourse(
                    this.courseNameInspect(),
                    999,
                  )
                  : this.getDayCourse(
                      this.props.dayIndex == 0 ? "在线练习" : "阅读闯关",
                      this.props.dayIndex
                    )}
                {this.props.study_type !== 4 &&
                (this.props.study_type !== 6 || this.props.dayIndex !== 0) &&
                this.props.poetry_articles.length > 0 && !this.isThirdDayOfR3()
                  ? this.getDayCourse(
                      this.courseNameInspect(),
                      999
                    )
                  : ""}
              </div>
              <div
                className="unit-day-0-bottom-study-status"
                style={{
                  backgroundColor:
                    this.props.current_study_day == this.props.day
                      ? unit_setting[this.props.unitIndex].minColor
                      : "#fff",
                  border: `1px solid ${
                    unit_setting[this.props.unitIndex].minColor
                  }`,
                }}
              >
                {this.getDayStatus(
                  this.props.totalLength == 1 ? 1 : 0,
                  this.props.unitIndex,
                  this.props.current_study_day == this.props.day,
                  this.props.state == "pass" ? true : false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
