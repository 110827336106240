/* eslint-disable react/prop-types */
import React from "react";
import { Dialog, DialogManager } from "../utils/Dialog";
import { onNavigate } from "../utils/helpers";
import CountDownTime from "../utils/CountDown";
import { format as formatTime } from "../utils/formatAudioTime.js";

import "./CourseAuditionCoupon.scss";

export default ({ ...props }) => {
  const {
    background,
    ecoupons,
    ecouponsStudyRequired,
    systemTime,
    batchEcouponsEndTime,
    flagTrail,
  } = props;
  console.log("CourseAuditionCoupon_props: ", props);

  const availableCourses = (ecoupon) =>
    ecoupon?.can_buy_names &&
    ecoupon?.can_buy_names.reduce((pre, cur, index) => {
      if (index === ecoupon.can_buy_names.length - 1) {
        return pre + `${cur}`;
      }
      return pre + `${cur}、`;
    }, "");

  const handleCountdownEnd = (e) => {};

  const handleCick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const url = ecoupons[index].url;
    if (ecouponsStudyRequired) {
      soleCouponDialog();
      return;
    }
    onNavigate(e, url);
  };

  const soleCouponDialog = () => {
    const buttons = [
      {
        label: "确定",
        onClick: () => {
          confirmDialog.close();
        },
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>完成任意一天课程的学习，即可领取</Dialog>
    );
  };

  const trialBoxStyles = {
    background: "#fff",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 0px 4px -1px rgba(0,0,0,.2)",
  };

  const auditionCouponStyles = {
    background: `${background}`,
  };

  return (
    <div
      className="course_audition_couppon"
      style={flagTrail ? trialBoxStyles : auditionCouponStyles}
    >
      {!flagTrail &&
        (batchEcouponsEndTime ? (
          <div className="text_describe">
            <div className="time_text_warn">
              <span>
                {formatTime(batchEcouponsEndTime, "YYYY.MM.DD HH:mm")}
              </span>
              <span>前完成任意一天课程的学习</span>
            </div>
            <p>即可领取以下课程优惠券</p>
            <div className="coupon_time_warn">
              （优惠券有效期6小时，限领1次）
            </div>
          </div>
        ) : (
          <div className="text_describe">
            <p>完成任意一天课程的学习，即可领取</p>
            <div className="coupon_time_warn">（有效期6小时，限领1次）</div>
          </div>
        ))}
      {flagTrail && (
        <div className="trial_coupon_text">
          您有{ecoupons.length}张优惠券可领取，领取后<span>7天有效</span>
        </div>
      )}
      <ul style={flagTrail ? { justifyContent: "center" } : {}}>
        {ecoupons.map((ecoupon, index) => {
          const invalidConpon =
            new Date(systemTime) >= new Date(ecoupon.end_time) ||
            ecoupon?.state == "已使用";
          const couponContent = availableCourses(ecoupon);
          return (
            <li
              key={ecoupon.id}
              onClick={invalidConpon ? null : handleCick}
              data-index={index}
              className={`${invalidConpon ? "li_invalid" : ""}`}
            >
              <a href="javascript:void(0)">
                <div className="top_section">
                  <div className="price">
                    <div>
                      <span>￥</span>
                      <strong>{ecoupon.price}</strong>
                    </div>
                    {ecoupon.min_product_price > 0 && (
                      <div className="use_condition">{`满${ecoupon.min_product_price}元可用`}</div>
                    )}
                  </div>
                  <div className="content">可用于{couponContent}</div>
                </div>
                <div
                  className="bottom_section"
                  style={{ background: `${flagTrail ? "#fff" : background}` }}
                >
                  {ecoupon.end_time ? (
                    <div className="now_get_coupon">
                      {new Date(systemTime) >= new Date(ecoupon.end_time) ? (
                        <span className="go_use">已过期</span>
                      ) : ecoupon.state == "已使用" ? (
                        <span className="go_use">已使用</span>
                      ) : (
                        <>
                          {!flagTrail && (
                            <CountDownTime
                              endTime={new Date(ecoupon.end_time)}
                              overText=""
                              onEnd={handleCountdownEnd}
                            >
                              {({ h, m, s }) => {
                                return (
                                  <span>
                                    <span className="expired_warn_text">
                                      距过期
                                    </span>
                                    <span className="hour">{h}</span>:
                                    <span className="minus">{m}</span>:
                                    <span className="seconds">{s}</span>
                                  </span>
                                );
                              }}
                            </CountDownTime>
                          )}
                          <span className="go_use">去使用</span>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="now_get_coupon">立即领券</div>
                  )}
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
