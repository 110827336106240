import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import "../ReactMarkDown.scss";

class LazyloadContent extends Component {
  imageRenderer = ({ src, alt }) => {
    return this.props.noneLazy ? (
      <img src={src} />
    ) : (
      <img className="lazyload" data-src={src} alt={alt || ""} />
    );
  };
  rendererMarkdown = (content, hint_photo_url) => {
    console.log(content, "content", hint_photo_url);
    if (content || hint_photo_url) {
      const renderers = { image: this.imageRenderer };
      const md = (
        <>
          {content ? (
            <ReactMarkdown
              className="md"
              source={content}
              renderers={renderers}
              escapeHtml={false}
              skipHtml={false}
            />
          ) : (
            ""
          )}
          {hint_photo_url ? <img src={hint_photo_url}></img> : ""}
        </>
      );
      return md;
    } else {
      return "";
    }
  };
  render() {
    const {
      content,
      noneLazy,
      selectIndex,
      position,
      hint_photo_url,
    } = this.props;
    return (
      <div
        className="markdown_content"
        style={{
          padding: noneLazy && "0 0 90px",
          display: selectIndex == position ? "block" : "none",
          minHeight: noneLazy && "1000px",
        }}
      >
        {this.rendererMarkdown(content, hint_photo_url)}
      </div>
    );
  }
}

export default LazyloadContent;
