// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/study_package/icon_clock.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_learning_reminder{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center}.comp_learning_reminder .current_remind_time{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:16px;background-repeat:no-repeat;background-position:0;padding-left:22px;font-size:14px;color:#666}.comp_learning_reminder .change_reminder_time{background-color:#fff;border-radius:15px;border:1px solid #49c114;padding:5px 10px;color:#49c114;font-size:12px;line-height:1em}", ""]);
// Exports
module.exports = exports;
