import React from "react";
import CloseIcon from "icon_cancle_upper.png";
import PropTypes from "prop-types";

const CloseButton = (props) => {
  let { width, height, className, onClick } = props;
  if (width && width === "number") {
    width = `${width}px`;
  }
  if (height && height === "number") {
    height = `${height}px`;
  }

  if (!width && height) {
    width = height;
  }
  if (!height && width) {
    height = width;
  }
  const iconStyle = {
    width: width,
    height: height,
  };
  return <img src={CloseIcon} className={className} style={iconStyle} onClick={onClick} />;
};

CloseButton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default CloseButton;
