import {request, csrfHeaders} from "../utils/request";

const doExchangeReward = ({ requestData }) => {
  return request({
    url: '/api/exchange_rewards',
    headers: csrfHeaders,
    method: 'POST',
    data: requestData
  })
}

export default doExchangeReward