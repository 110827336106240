import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import zoomInIcon from "zoom_in.png"
import removeButtonIcon from "remove-button.png"
import arrowWithCircleIcon from "arrow-up-with-circle.png"

const UploadExerciseSingle = (props) => {
  console.log('single props is: ', props)
  useEffect(() => {
    const width = $('.single-block').parent().parent().width()
    const margin = ((width - 3 * 100) / 6) + 'px'
    $('.single-block').css({marginLeft: margin, marginRight: margin})
    console.log("margin is: ", margin)
  }, [])
  const pageStyle = {
    width: '96px',
    height: '130px',
    borderRadius: '10px',
    position: 'relative',
    backgroundImage: `url(${props.answer ?? props.template.photo_url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }

  const pageLabelStyle = {
    borderTopLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    backgroundColor: '#5AC2F9',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: '12px',
    width: '38px',
    height: '18px',
    textAlign: 'center',
    lineHeight: '18px',
  }

  const zoomInStyle = {
    color: 'white',
    width: '24px',
    position: 'absolute',
    right: 0,
    top: 0,
  }

  const uploadButtonStyle = {
    width: '52px',
    position: 'absolute',
    top: '-20px',
    left: '50%',
    transform: "translateX(-50%)",
  }

  const removeAnswerButtonStyle = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderBottomRightRadius: '10px',
    width: '18px',
    height: '18px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const handleClickUpload = (e) => {
    console.log('click upload!')
    $(e.target).prev().click()
  }

  const handleImageChange = (e) => {
    const newFile = e.target.files[0];
    console.log(newFile);
    props.prepareUploadAnswer(newFile, props.index)
  }

  const PageLabel = () => {
    return (
      <div style={pageLabelStyle}>{props.template.page}</div>
    )
  }

  const CancelAnswerButton = () => {
    if (!props.answer || !props.canDelete) return null
    return (
      <div style={removeAnswerButtonStyle}>
        <img src={removeButtonIcon} style={{width: '10px'}} onClick={() => {props.removeAnswer(props.index)}} />
      </div>
      
    )
  }

  const UploadBlock = () => {
    if (props.answer || props.noUploadButton) return null
    return (
      <div style={{backgroundColor: '#FFEFD2', position: 'absolute', bottom: -1, left: 0, width: '100%', height: '36px', borderRadius: '6px 6px 10px 10px'}}>
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png"
          style={{ display: "none" }}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={handleImageChange}
        />
        <img src={arrowWithCircleIcon} style={uploadButtonStyle} onClick={handleClickUpload} />
      </div>
    )
  }

  return (
    <div className="single-block" style={{padding: '0 2px',  position: 'relative', marginBottom: '15px'}}>
      <div style={pageStyle}>
        <PageLabel />
        <img src={zoomInIcon} style={zoomInStyle} onClick={() => {props.handleZoomIn(props.index, props.setZoomInFunc)}} />
        <CancelAnswerButton />
      </div>
      <UploadBlock />
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  if(prevProps.answer === nextProps.answer) {
    return true
  }
  return false
}

export default React.memo(UploadExerciseSingle, areEqual)

UploadExerciseSingle.propTypes = {
  answer: PropTypes.string,
  template: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  prepareUploadAnswer: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  setZoomInFunc: PropTypes.func.isRequired,
}
