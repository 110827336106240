/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { DirectBuy } from "../../../../shared/DirectBuy.jsx";
import unlockTips from "pinyin/UnlockTips.png";
import "./UnlockedFullVersion.scss";

export default ({ ...props }) => {
  const { upgradePackage } = props;
  return (
    <div className="unlock_full_version_course">
      <UnlockedPayment upgradePackage={upgradePackage} />
    </div>
  );
};

class UnlockedPayment extends DirectBuy {
  render() {
    const {
      study_package_activity_buy_url,
      id,
      price,
      target_type,
    } = this.props.upgradePackage;
    return (
      <div
        className="unlocked_payment_container"
        onClick={this.buy.bind(this, study_package_activity_buy_url, {
          target_id: id,
          price: price,
          target_type: target_type,
        })}
      >
        <img src={unlockTips} alt="" />
        <div className="button_payment">{`升级（￥${price}）`}</div>
      </div>
    );
  }
}

UnlockedPayment.propTypes = {
  upgradePackage: PropTypes.object,
};
