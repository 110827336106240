import React from "react";
import "./QuickBack.scss";

export default class QuickBack extends React.Component {
  constructor(props) {
    super(props);
  }
  quickBackClick = (e) => {
    window.location.href = this.props.courseListUrl;
    sessionStorage.clear();
  };

  render() {
    return (
      <div className="quick_back" onClick={this.quickBackClick}>
        返回课程页
      </div>
    );
  }
}
