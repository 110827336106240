/* eslint-disable react/prop-types */
import React from "react";
import "./UnlockedAllCourseReminder.scss";

const UnlockedAllCourseReminder = ({ bottom, url }) => {
  return (
    <div className="comp_unlocked_all_course_reminder">
      <div className="all_course_reminder" style={{ bottom: bottom || 0 }}>
        <div className="flex_wrapper">
          <dl>
            <dt>解锁全部课程继续学习</dt>
          </dl>
          <a className="now_btn" href={url}>
            立即报名
          </a>
        </div>
      </div>
    </div>
  );
};

export default UnlockedAllCourseReminder;
