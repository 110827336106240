import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";

import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";
import { KbItem } from "./PracticeClass";
export default (props) => {
  return (
    <InfoBroadTitle isImg={false} titles={"阅读闯关"}>
      <div>
        {props.poetry_articles.map((articlesItem, index) => {
          return (
            <KbItem
              {...articlesItem}
              key={"articlesItem" + index}
              isBook={true}
            ></KbItem>
          );
        })}
      </div>
    </InfoBroadTitle>
  );
};
