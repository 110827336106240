import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";
import LazyloadContent from "../../utils/lazyload/LazyloadContent";
import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";

export default ({ media_lessons }) => {
  return (
    <InfoBroadTitle isImg={false} titles={"讲义"}>
      <div className="knowledge_desc_content">
        {media_lessons &&
          media_lessons.map((it, ix) => {
            return it.media_conclusion ? (
              <LazyloadContent content={it.media_conclusion} key={"it" + ix} />
            ) : (
              ""
            );
          })}
      </div>
    </InfoBroadTitle>
  );
};
