import React from "react";
// import styled from "styled-components";
import arrow_right_white from "../../../../assets/images/arrow-right-white.png";
// import "./ReadingWord.scss";
// const Index = styled.div`
import { QuizItem } from "../components/PracticeClass";
import icon_R2_down from "R2/icon_R2_down.png";
import "./Word.scss";
// `;
const WordName = ({ name, count, material_index }) => {
  return (
    <div
      className={material_index == 0 ? "word-name word-name-top" : "word-name"}
    >
      <div className="word-name-title-icon">
        <img src={arrow_right_white}></img>
      </div>

      <div className="word-name-title">{`${name}（${count}个）`}</div>
      {/* <div className="word-name-"></div> */}
    </div>
  );
};
const WordContent = ({ name, materials, material_index }) => {
  return (
    <div className="word-content">
      {materials &&
        materials.map((materials_item, index) => {
          return (
            <div
              className="word-content-item"
              key={materials_item + index + "item" + material_index}
              style={{ fontSize: 16 }}
            >
              {materials_item}
            </div>
          );
        })}
      {/* <div className="word-content-item">一个</div>
      <div className="word-content-item">一个</div>
      <div className="word-content-item">一个</div>
      <div className="word-content-item">一个</div> */}
    </div>
  );
};
const WordsContent = ({ name, materials, material_index }) => {
  return (
    <div className="words-content">
      {materials &&
        materials.map((materials_item, index) => {
          return (
            <div
              className="words-content-item"
              key={"item" + index + "words" + material_index}
            >
              <div className="words-content-item-index">{index + 1}</div>
              <div style={{ marginTop: -3, fontSize: 16 }}>
                {materials_item}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default class word extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWrongs: false,
    };
  }
  render() {
    return (
      <div className="reading-index2-word">
        <WordName
          name={this.props.name}
          count={this.props.materials.length}
          material_index={this.props.material_index}
        ></WordName>
        <div
          style={{
            border: this.props.isReport ? "1px solid #FFBA44" : 0,
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          {this.props.type == 0 ? (
            <WordContent {...this.props}></WordContent>
          ) : (
            <WordsContent {...this.props}></WordsContent>
          )}
          {this.props.isReport &&
          (this.props.correct_count || 0) + (this.props.wrong_count || 0) ? (
            <div
              className="show-wrongs"
              onClick={() => {
                let showWrongs = this.state.showWrongs;
                this.setState({ showWrongs: !showWrongs });
              }}
            >
              <div
                style={{
                  color: this.props.wrong_count ? "#FF6868" : "#55C656",
                }}
              >{`共${
                (this.props.correct_count || 0) + (this.props.wrong_count || 0)
              }题，${
                this.props.wrong_count
                  ? "错" + this.props.wrong_count + "题"
                  : "全对"
              }`}</div>
              <img
                src={icon_R2_down}
                className={
                  this.state.showWrongs
                    ? "icon_R2_down icon_R2_down_tran"
                    : "icon_R2_down"
                }
              ></img>
            </div>
          ) : (
            ""
          )}
          {this.state.showWrongs && this.props.quizzes ? (
            <div>
              <div>
                {this.props.quizzes.length > 0 &&
                  this.props.quizzes.map((itm, inx) => {
                    return (
                      <QuizItem
                        itm={itm}
                        inx={inx}
                        key={inx + "==" + "ques"}
                      ></QuizItem>
                    );
                  })}
              </div>
              <div
                className="show-wrongs"
                onClick={() => {
                  let showWrongs = this.state.showWrongs;
                  this.setState({ showWrongs: !showWrongs });
                }}
              >
                <div style={{ color: "#999" }}>收起</div>
                <img
                  src={icon_R2_down}
                  className={"icon_R2_down icon_R2_down_tran"}
                ></img>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
