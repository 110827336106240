import React from "react";

import icon_special from "reading/icon_special.png";
import {
  unit_setting,
  UnitSpecial,
  UnitSpecialIndex,
} from "../reading_index/ReadingIndex";
import SpecialItem from "./SpecialItem";

export default class Special extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "topic");
  }

  dividerLine(color) {
    return (
      <div
        style={{
          margin: "0px 15px",
          // padding: "0 15px",
          borderBottom: `2px dotted ${color}`,
        }}
      ></div>
    );
  }

  contiuneStudy(bottom, isFirst) {
    return bottom ? (
      <div
        className="contiune-study"
        id="currect_id"
        // id={this.props.current_study_date}
        style={{
          marginBottom: bottom ? bottom : "",
          marginTop: isFirst ? -36 : "",
        }}
      >
        上次学到这里，继续
      </div>
    ) : (
      <div
        id="currect_id"
        style={{
          backgroundColor: "#F0F0F0",
          padding: "21px 0 23px",
        }}
      >
        <div
          className="contiune-study"
          // style={{ marginBottom: bottom ? bottom : "" }}
        >
          上次学到这里，继续
        </div>
      </div>
    );
  }
  render() {
    return (
      <UnitSpecial
        className="unit-item"
        index={this.props.unitIndex}
        isLast={this.props.isLast}
      >
        <div className="unit-item-info">
          <div className="unit-item-info-top">
            <div
              style={{
                backgroundColor:
                  this.props.unitIndex == 0
                    ? unit_setting[0].specialBorder
                    : unit_setting[this.props.unitIndex % 4].specialBorder,
                padding: 2,
                borderRadius: 8,
                marginRight: 7,
              }}
            >
              <UnitSpecialIndex
                className="unit-item-info-index"
                index={this.props.unitIndex}
              >
                {this.props.day_studies[0] &&
                this.props.day_studies[0][0]?.study_type == 4
                  ? // this.props.day_studies[0][0]?.study_type == 5 ||
                    "单元练习"
                  : this.props.day_studies[0][0]?.study_type == 6
                  ? "综合测试"
                  : `专题${this.props.topicIndex}`}
              </UnitSpecialIndex>
            </div>
            <div className="unit-item-info-name">
              {this.props.name && this.props.name.substr(0, 9)}
            </div>
          </div>
          <div className="unit-item-info-bottom">{`${this.props.start_date.replace?.(
            /-/g,
            "."
          )} - ${this.props.end_date.replace?.(/-/g, ".")}`}</div>
        </div>
        <div className="unit-item-content">
          {/* {this.dividerLine(unit_setting[1].minColor)} */}
          {this.props.day_studies &&
            this.props.day_studies[0] &&
            this.props.day_studies[0].map((dayItem, dayIndex) => {
              return (
                <React.Fragment key={dayItem.id + "day"}>
                  {this.props.current_study_date == dayItem.date && dayIndex
                    ? this.contiuneStudy(0)
                    : dayIndex
                    ? this.dividerLine(
                        unit_setting[this.props.unitIndex].minColor
                      )
                    : ""}

                  <SpecialItem
                    {...dayItem}
                    dayIndex={dayIndex}
                    totalLength={this.props.day_studies[0].length}
                    current_study_date={this.props.current_study_date}
                    current_study_day={this.props.current_study_day}
                    unitIndex={this.props.unitIndex}
                    showModel={this.props.showModel}
                    study_mode_version={this.props.study_mode_version}
                    study_mode={this.props.study_mode}
                  ></SpecialItem>
                </React.Fragment>
              );
            })}

          {/* {this.dividerLine(unit_setting[1].minColor)} */}

          {this.props.day_studies[0] &&
            this.props.day_studies[0][0]?.study_type !== 4 &&
            this.props.day_studies[0][0]?.study_type !== 5 &&
            this.props.day_studies[0][0]?.study_type !== 6 && (
              <div
                className="day-study-report"
                style={{
                  backgroundColor: this.props.report_url
                    ? unit_setting[this.props.unitIndex].minColor
                    : "",
                }}
                onClick={() => {
                  if (this.props.report_url) {
                    window.location.href = this.props.report_url;
                  }
                }}
              >
                <img src={icon_special}></img>
                <div>查看专题掌握情况</div>
              </div>
            )}
        </div>
      </UnitSpecial>
    );
  }
}
