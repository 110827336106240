// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/sync_training_camp/icon_close_white.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#portal_root .modal_container_buy_dialog{position:relative;top:15%;width:100%;left:0}#portal_root .modal_container_buy_dialog .modal_close{position:absolute;right:25px;top:-32px;width:34px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:32.5px 32px;background-position:0 0;background-repeat:no-repeat}#portal_root .modal_container_buy_dialog .modal_content{position:relative;margin:0 auto;width:350px;height:433px}#portal_root .modal_container_buy_dialog .modal_content img{max-width:100%}#portal_root .modal_container_buy_dialog .modal_content .continue_study{position:absolute;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:168px;background-color:#49c114;box-shadow:0 0 2px 0 rgba(0,0,0,.5);color:#fff;text-align:center;font-size:15px;border-radius:200px;bottom:99px;height:45px;line-height:45px}.study_packages-details #portal_root .modal_container_buy_dialog{max-width:500px;margin:0 auto}", ""]);
// Exports
module.exports = exports;
