import React, { Component } from "react";
import dialogPass from "dialog-xy-pass.png";
import Raven from "raven-js";
import {
  request as axios,
  csrfHeaders,
  getAxiosErrorText,
} from "../utils/request";
import BWDetect from "../utils/BWDetect.js";
import {
  promisifiedLoadImage,
  detectedTypeOf,
  showDialog,
  retryFetch,
} from "../utils/helpers";
import { ToastManager } from "../utils/Toast";
import { throttledRequest } from "../utils/helpers";
import { SOE, SoeLog } from "../utils/soe";
import CrystalReward from "../point_reward/CrystalReward";
import FullScreenCrystalReward from "../point_reward/FullScreenCrystalReward";
import AllCorrectAnswer from "point_rewards/all-right-reward-10.png";
import StudyOnTimeIcon from "point_rewards/study-ontime-reward-5.png";
import FinishWholeDayStudyIcon from "point_rewards/finish-current-day-reward-5.png";
// import { Object } from 'core-js';
const REQUEST_LIMITION = 3;

const request = axios.create({
  timeout: 15000,
});
// examType 不能用于提交请求的exam_type
export const BasicQuizGeneration = (ExtendedComponent, examType, options) => {
  console.log(
    "================== options is: ",
    options,
    "-----------------------------"
  );
  options = options ?? {};
  return class extends ExtendedComponent {
    setCanScrollIcon = () => {
      const generateQuizCon = document.getElementsByClassName(
        "generate_quiz_box"
      )[0];
      if (generateQuizCon) {
        const isScrolledToBottom = () =>
          $(document).scrollTop() + document.body.offsetHeight >=
          generateQuizCon.offsetHeight + 60;
        const isContainerHigherThanViewPort = () =>
          generateQuizCon.offsetHeight > document.body.offsetHeight;
        // console.log('hhahah eval', generateQuizCon.offsetHeight, document.body.offsetHeight, $(document).scrollTop())
        if (isContainerHigherThanViewPort() && !isScrolledToBottom()) {
          this.setState({
            setCanScrollIcon: true,
          });
        }
        const winScroll = () => {
          if (isScrolledToBottom()) {
            this.setState({
              setCanScrollIcon: false,
            });
            window.removeEventListener("scroll", winScroll);
          }
        };
        window.addEventListener("scroll", winScroll);
      }
    };

    calculationContinueWrong = (() => {
      let continueWrongCount = [];
      return {
        add: function () {
          return continueWrongCount.push(1);
        },
        reset: function () {
          return (continueWrongCount.length = 0);
        },
        total: function () {
          return continueWrongCount.length;
        },
      };
    })();

    componentDidMount() {
      window.addEventListener("load", () => {
        BWDetect();
      });
      this.setCanScrollIcon();
      if (options) {
        this.setState(
          {
            totalPoint: options.totalPoint || 0,
            showRewardCrystal: false,
            allCorrect: this.props.should_reward,
            showAllCorrect: false,
            isOldVersion: true,
            showStudyOnTime: options.showStudyOnTime,
          },
          () => {
            console.log(this.state.allCorrect, "初始化的 allCorrect");
          }
        );
      }
    }

    componentDidUpdate(prevProps, prevState) {
      setTimeout(() => {
        if (!this.state.setCanScrollIcon) {
          this.setCanScrollIcon();
        }
      }, 500);

      // 处理填空题, 回车键提交选项
      $("input").keydown(function (e) {
        if (e.which == 13) {
          try {
            $(".fill-btn-submit").trigger("click");
          } catch (e) {}

          return;
        }
      });
    }

    componentWillMount() {
      this.doSubmittionAndNextQuiz = throttledRequest(
        this.doSubmittionAndNextQuiz.bind(this),
        7000,
        () => {
          this.initAnswerState();
          this.removeCurrentQuizAnswer();
          this.startAnswerTime = new Date();
          this.requestSubmitSingleQuiz = 0;
        }
      );

      this.requestSubmitSingleQuiz = 0;

      let loadFirstQuizImages = function (quiz) {
        return this.loadingImageWithTimeout(this.loadingQuizImages)(quiz, true);
      };

      this.initQuizImagesState();

      // PRACTICE_LEARNING 模式下忽略首张quiz图片加载的耗时
      if (examType === "PRACTICE_LEARNING") {
        this.startAnswerTime = new Date();
      }

      this.startAnswerTime = new Date();
      // 如果第一题是有图片，显示toast，去加载
      loadFirstQuizImages
        .call(this, this.state.quiz)
        .then(() => {
          // 第一题开始答题的时间
          if (!(examType === "PRACTICE_LEARNING")) {
            this.startAnswerTime = new Date();
          }
        })
        .catch((err) => {
          // 处理图片加载出错的情况
          Raven.captureException(err);
          this.setState(() => ({
            isSuccDisplayQuizImages: false,
          }));
        });
    }

    // componentDidMount() {
    //   // 处理iOS 微信客户端6.7.4 键盘收起页面未下移bug
    //   (/iphone|ipod|ipad/i.test(navigator.appVersion)) && document.addEventListener('blur', (e) => {
    //     document.body.scrollIntoView(false)
    //   }, true)
    // }

    //加载图片出错的重新加载点击按钮
    reloadQuizImages = () => {
      this.loadingImageWithTimeout(this.loadingQuizImages)
        .call(this, this.state.quiz, true)
        .then(() => {
          this.setState(() => ({
            isSuccDisplayQuizImages: true,
          }));
          // 初始化开始答题的时间
          this.startAnswerTime = new Date();
        })
        .catch((err) => {
          // 初始化开始答题的时间
          this.startAnswerTime = new Date();
          // 处理图片加载出错的情况
          Raven.captureException(err);
          console.log(err);
        });
    };

    // 答题开始，弹框提示
    initRecoverRecord(quizzes, clickCallBack) {
      let todoQuiz = quizzes[0];
      this.answers = [];
      this.costTimeGroup = [];
      console.log(examType, "-----");
      if (
        examType == "POETRY_PRACTICE" ||
        examType == "POETRY_PRACTICE_UNIT" ||
        examType == "TERM_REVIEW" ||
        examType == "READING" ||
        examType == "WRONG_QUIZ_REVISAL"
      ) {
        this.popr_quiz_ids = [];
        this.popr_correct_count = 0;
        this.subjective_count = 0;
        this.popr_times = 0;
        for (let quiz of quizzes) {
          console.log(
            "-----------------------popr_quiz_ids",
            this.popr_quiz_ids
          );
          this.popr_quiz_ids.push(quiz.id);
          if ("user_answer" in quiz) {
            if (quiz.subjective) {
              this.subjective_count++;
            }
            if (quiz.user_answer.input_correct) {
              // if (!quiz.subjective) {
              this.popr_correct_count++;
              // }
              this.popr_times =
                this.popr_times +
                (new Date(quiz.user_answer.end_answer_time) -
                  new Date(quiz.user_answer.start_answer_time));
            }
          } else {
            continue;
          }
        }
      }
      let findToDoQuiz = function () {
        // 找到最后一个回答过的quiz
        console.log("=================", quizzes);
        let answeredQuiz = [...quizzes].reverse().find((quiz, index) => {
          if ("user_answer" in quiz && quiz.user_answer.id) {
            return quiz;
          }
        });
        // 找到最后一个回答过的quizid
        let index = quizzes.findIndex(function (element) {
          return element.id == answeredQuiz.id;
        });

        if (index + 1 <= quizzes.length - 1) {
          todoQuiz = quizzes[index + 1];
        }
        return todoQuiz;
      };
      let pushFinishedAnswers = function () {
        for (const [index, quiz] of Object.entries(quizzes)) {
          if ("user_answer" in quiz) {
            this.costTimeGroup.push({
              quizId: quiz.id,
              startTime: new Date(quiz.user_answer.start_answer_time),
              endTime: new Date(quiz.user_answer.end_answer_time),
            });
          } else {
            break;
          }
        }
        if (examType == "TODAY_QUIZ" || examType == "DAY_EXAM") {
          for (let quiz of quizzes) {
            if ("user_answer" in quiz) {
              let json = {
                media_lesson: {
                  id: quiz.current_lesson.id,
                  tag: {
                    id: quiz.tag.id,
                    quiz: { ...quiz.user_answer },
                  },
                },
              };
              this.answers.push(json);
            } else {
              break;
            }
          }
        }
        // if (examType == "POETRY_PRACTICE") {
        //   this.popr_quiz_ids = [];
        //   this.popr_correct_count = 0;
        //   this.popr_times = 0;
        //   for (let quiz of quizzes) {
        //     console.log("-----------------------", this.popr_quiz_ids);
        //     this.popr_quiz_ids.push(quiz.id);
        //     if ("user_answer" in quiz) {
        //       if (quiz.user_answer.input_correct) {
        //         this.popr_correct_count++;
        //         this.popr_times +=
        //           new Date(quiz.user_answer.end_answer_time) -
        //           new Date(quiz.user_answer.start_answer_time);
        //       }
        //     } else {
        //       break;
        //     }
        //   }
        // }
        if (examType === "PRACTICE_LEARNING") {
          for (let quiz of quizzes) {
            if ("user_answer" in quiz) {
              for (const mediaLesson of this.mediaLessons) {
                if (mediaLesson.id == quiz.media_lesson.id) {
                  mediaLesson.quizzes.push(quiz.user_answer);
                }
              }
            } else {
              break;
            }
          }
        }

        if (
          examType == "GRADE_EXAM" ||
          examType == "INTERVIEW_EXAM" ||
          examType == "INTERVIEW" ||
          examType == "POETRY_PRACTICE" ||
          examType == "POETRY_PRACTICE_UNIT"
        ) {
          for (let quiz of quizzes) {
            if ("user_answer" in quiz) {
              this.answers.push(quiz.user_answer);
            } else {
              break;
            }
          }
        }
        console.log(examType, "last answers", this.answers);
        console.log(examType, "last answers", this.mediaLessons);
      };
      console.log(examType, "=================", quizzes);
      if (quizzes.find((quiz) => "user_answer" in quiz)) {
        let dialogOptions = {
          text: "接着上次的进度继续做吗？",
          mainLabel: "继续",
          subLabel: "重新开始",
          mainCallback: () => {
            // console.log("=================", quizzes);
            pushFinishedAnswers.call(this);
            this.setState(
              () => ({
                quiz: findToDoQuiz(),
                // startAnswerTimer: true
              }),
              () => {
                clickCallBack &&
                  examType !== "POETRY_PRACTICE" &&
                  examType !== "POETRY_PRACTICE_UNIT" &&
                  clickCallBack();
              }
            );
            // 加载图片
            this.loadingImageWithTimeout(this.loadingQuizImages)
              .call(this, findToDoQuiz(), true)
              .then(() => {
                // 初始化开始答题时间
                this.startAnswerTime = new Date();
              })
              .catch((err) => {
                this.setState({
                  isSuccDisplayQuizImages: false,
                });
              });
          },
          subCallback: () => {
            // 初始化开始答题时间
            this.subjective_count = 0;
            this.popr_correct_count = 0;
            this.popr_times = 0;
            this.isRedo = true;
            this.startAnswerTime = new Date();
            clickCallBack && clickCallBack();
            this.setState(() => ({
              startAnswerTimer: true,
            }));
          },
        };
        showDialog.call(this, dialogOptions);
      } else {
        clickCallBack && clickCallBack();
      }
      // 初始化开始答题时间
      this.startAnswerTime = new Date();
      return todoQuiz;
    }

    isLastQuiz() {
      let lastQuiz = this.props.quizzes[this.props.quizzes.length - 1];
      console.log(
        lastQuiz,
        "islait",
        this.state.quiz,
        lastQuiz.id == this.state.quiz.id
      );
      return lastQuiz && lastQuiz.id == this.state.quiz.id;
    }

    isFirstQuiz() {
      let firstQuiz = this.props.quizzes[0];
      return firstQuiz && firstQuiz.id == this.state.quiz.id;
    }
    isRedoFun() {
      return this.isRedo;
    }
    // 初始化答题界面状态
    initAnswerState() {
      this.setState(() => ({
        selectedIndex: false,
        isCorrect: "",
        selectedOption: "",
        answerText: "",
        setCanScrollIcon: false,
        isSelectedOrFilled: false,
        showRewardCrystal: false,
        hasSingleReward: false,
      }));
    }

    // 一个quiz的image集合
    collectQuizImages = function (quiz) {
      return new Promise((resolve, reject) => {
        if (detectedTypeOf(quiz) !== "object") {
          reject(new Error("please pass a quiz object for collectQuizImage"));
        }
        let preLoadImage = [];
        if (quiz.question_photo_url) {
          preLoadImage.push(quiz.question_photo_url);
        }
        if (examType === "PRACTICE_LEARNING") {
          quiz.subquestions.forEach((subquestion) => {
            if (subquestion.question_photo_url || subquestion.photo_url) {
              preLoadImage.push(subquestion.question_photo_url);
            }
          });
          if (quiz.subquestions.length === 1) {
            const subQuiz = quiz.subquestions[0];
            if (subQuiz.options) {
              subQuiz.options.map((option) => {
                if (option.photo) {
                  preLoadImage.push(option.photo.url);
                }
              });
            }
          }
          resolve(preLoadImage);
          return;
        }
        if (examType === "SUBQUESTIONS") {
          if (quiz.options) {
            quiz.options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
          resolve(preLoadImage);
          return;
        }
        if (examType === "INTERVIEW_WRONG_QUIZ") {
          if (quiz && quiz.subquestions && "options" in quiz.subquestions[0]) {
            quiz.subquestions[0].options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
          resolve(preLoadImage);
          return;
        }

        if (
          examType === "GRADE_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          if ("options" in quiz.subquestion) {
            quiz.subquestion.options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
        } else {
          if (quiz.options) {
            quiz.options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
        }
        resolve(preLoadImage);
      });
    };

    loadingImageWithTimeout = (fn) => {
      let toast = "";
      let timeout = null;
      let timeoutSec = 15000;
      const withLoading = async (quiz, isShowToast = true) => {
        if (quiz && "isImageLoaded" in quiz && !quiz.isImageLoaded) {
          if (isShowToast) {
            timeout = setTimeout(() => {
              timeout = null;
              toast && toast.cancel();
              const dialogOptions = {
                text: "图片加载缓慢，请检查您的网络后重试",
                mainLabel: "确定",
                mainCallback: async () => {
                  try {
                    await withLoading(quiz, isShowToast);
                  } catch (e) {
                    console.log(e);
                    Raven.captureException(e);
                  }
                },
              };
              showDialog.call(this, dialogOptions);
              Raven.captureException(
                `load quiz image timeout ${quiz.id}. onLine: ${navigator.onLine}`
              );
            }, timeoutSec);
            timeoutSec = timeoutSec + 10000;
            toast = ToastManager.showLoading("加载中...");
          }
        }
        return fn
          .call(this, quiz, isShowToast)
          .then((res) => {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            toast && toast.cancel();
            return res;
          })
          .catch((e) => {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            toast && toast.cancel();
            Raven.captureException(e);
            return Promise.reject(e);
          });
      };
      return withLoading;
    };

    // 加载单个quiz下图片
    loadingQuizImages(quiz) {
      if (!quiz) {
        // 没有可以加载的图片直接resolve
        return Promise.resolve();
      }
      if (quiz && "isImageLoaded" in quiz && !quiz.isImageLoaded) {
        return this.collectQuizImages
          .call(this, quiz)
          .then((res) => {
            if (res.length == 0) {
              return [];
            }
            return promisifiedLoadImage(res);
          })
          .then((loadedImages) => {
            if (loadedImages.length > 0) {
              quiz["isImageLoaded"] = true;
            }
          })
          .catch((err) => {
            console.log(err);
            return Promise.reject(err);
          });
      } else {
        return Promise.resolve();
      }
    }

    // 初始化quiz下图片加载状态
    initQuizImagesState() {
      if (this.props.quizzes) {
        this.props.quizzes.forEach((quiz) => {
          if (quiz.question_photo_url) {
            quiz["isImageLoaded"] = false;
          }
          if (examType === "SUBQUESTIONS") {
            if (quiz.options) {
              quiz.options.map((option) => {
                if (option.photo) {
                  quiz["isImageLoaded"] = false;
                }
              });
            }
            return;
          }
          if (examType === "PRACTICE_LEARNING") {
            quiz.subquestions.forEach((subquestion) => {
              if (subquestion.photo_url || subquestion.question_photo_url) {
                quiz["isImageLoaded"] = false;
              }
            });
            if (quiz.subquestions.length === 1) {
              const subQuiz = quiz.subquestions[0];
              if (subQuiz.options) {
                subQuiz.options.map((option) => {
                  if (option.photo) {
                    quiz["isImageLoaded"] = false;
                  }
                });
              }
            }
            return;
          }
          if (
            examType == "INTERVIEW_WRONG_QUIZ" ||
            examType == "WRONG_QUIZ_REVISAL"
          ) {
            if (
              quiz &&
              quiz.subquestions &&
              "options" in quiz.subquestions[0]
            ) {
              quiz.subquestions[0].options.map((option) => {
                if (option.photo) {
                  quiz["isImageLoaded"] = false;
                }
              });
            }
            return;
          }
          if (
            examType === "GRADE_EXAM" ||
            examType === "INTERVIEW_EXAM" ||
            examType == "INTERVIEW"
          ) {
            if ("options" in quiz.subquestion) {
              quiz.subquestion.options.map((option) => {
                if (option.photo) {
                  quiz["isImageLoaded"] = false;
                }
              });
            }
          } else {
            if (quiz.options) {
              quiz.options.map((option) => {
                if (option.photo) {
                  quiz["isImageLoaded"] = false;
                }
              });
            }
          }
        });
      }
    }
    submitGradeExamContinusWrongAnswer = () => {
      const currentAnswers = this.answers;
      const uncompletedQuiz = [...this.props.quizzes].splice(
        currentAnswers.length,
        this.props.quizzes.length - 1
      );
      let json;
      uncompletedQuiz.forEach((item) => {
        if (item.question_type == 0) {
          json = {
            id: item.id,
            subquestions: [
              {
                id: item.subquestion.id,
                start_answer_time: "",
                end_answer_time: "",
              },
            ],
            answer: "",
            correct: "",
            product_code: item.product_code,
          };
        } else {
          json = {
            id: item.id,
            subquestions: [
              {
                id: item.subquestion.id,
                option: [],
                start_answer_time: "",
                end_answer_time: "",
              },
            ],
            correct: "",
            product_code: item.product_code,
          };
        }
        this.uncompletedAnswers.push(json);
      });
      let notAllCompletedQuizzes = [
        ...currentAnswers,
        ...this.uncompletedAnswers,
      ];
      this.totalCostTime = this.costTimeGroup.reduce((init, next) => {
        return (init =
          init + (new Date(next.endTime) - new Date(next.startTime)));
      }, 0);

      this.totalCostTime = Math.ceil(this.totalCostTime / 1000);
      console.log("this.totalCostTime: ", this.totalCostTime);

      var quizzes = {
        quizzes: notAllCompletedQuizzes,
        total_time: this.totalCostTime,
        ...this.calculateContinusWrongCorrectness.call(this, this.answers),
      };
      console.log("quizzes参数：", quizzes);
      return retryFetch()(async () => {
        let self = this;
        try {
          const resp = await request({
            url: self.props.save_result_url,
            method: "POST",
            headers: csrfHeaders,
            data: quizzes,
          });
          const result = resp.data;
          const url = result.redirect_url;
          this.setState(() => ({
            testResultRedirectUrl: url,
          }));
        } catch (err) {
          console.log(err);
        }
      });
    };

    // 模拟题连错逻辑
    continusWrongCount() {
      const { isCorrect, quiz, answerText } = this.state;
      const { add, reset, total } = this.calculationContinueWrong;
      const isFillCorrect = answerText.trim() === quiz.subquestion.answer;
      let index = this.props.quizzes.findIndex(function (element) {
        return element.id == quiz.id;
      });
      let prevQuiz = index > 0 && this.props.quizzes[index - 1];
      console.log("已答题quiz: ", quiz, "前一题prevQuiz: ", prevQuiz);

      const quizStageType =
        prevQuiz && quiz.product_code != prevQuiz.product_code;

      // 如果当前答题是最后一题，则不计数弹框
      if (this.isLastQuiz()) {
        return;
      }
      if (isCorrect === "correct" || isFillCorrect) {
        if (quizStageType) {
          reset();
        }
      } else {
        // 如果答错题并且已答题和前一题的题目所处阶段类型不一致，直接重置
        if (quizStageType) {
          reset();
        }
        add();
        if (total() == 5) {
          this.submitGradeExamContinusWrongAnswer.call(this);
          this.setState(() => ({
            continueWrongCountArrive: true,
          }));
          return;
        }
      }
      console.warn(`已连错：${total()}题`);
    }

    // 取下一题
    nextQuiz() {
      // thinking_exam_type 为1的时候是新的结果页
      // if (
      //   examType == "GRADE_EXAM" &&
      //   this.state.quiz?.study_package_category_id &&
      //   this.props.thinking_exam_type == 1
      // ) {
      //   this.continusWrongCount();
      // }

      // 如果下一题是最后一题，就提交全部答案，然后显示结果页
      if (this.isLastQuiz()) {
        this.isLastQuizSelect = true;
        if (examType === "SUBQUESTIONS") return;
        // 是最后一题就提交报告显示结果页, 之前已经提交过最后一题的答案
        // return this.submitAnswersAndshowResult.call(this);
        if (this.state.allCorrect && this.props.should_reward) {
          this.createRewardRecord(
            options.allCorrectRewardPoint,
            "all_correct_reward"
          );
          this.setState({
            showAllCorrect: true,
            showRewardCrystal: false,
            totalPoint:
              this.state.totalPoint + this.props.all_correct_reward_point,
          });
        } else {
          return this.submitAnswersAndshowResult.call(this);
        }
        return;
      }

      let preloadNextQuizImages = function (index) {
        let nextSecondQuiz = this.props.quizzes[index + 2];
        if (nextSecondQuiz) {
          return this.loadingQuizImages(nextSecondQuiz, false);
        } else {
          return Promise.resolve();
        }
      };
      let loadCurrentQuizImages = function (quiz) {
        return this.loadingImageWithTimeout(this.loadingQuizImages)(quiz, true);
      };

      let setNextQuiz = function () {
        let quiz = this.state.quiz;
        let index = this.props.quizzes.findIndex(function (element) {
          return element.id == quiz.id;
        });
        let nextQuiz = this.props.quizzes[index + 1];
        this.setState(() => ({
          quiz: nextQuiz,
          answerText: "",
        }));
        // 除第一题外每题开始答题的时间
        this.startAnswerTime = new Date();
        return {
          nextQuiz,
          index,
        };
      };

      // 初始化答题界面状态
      this.initAnswerState.call(this);
      //设置下一题
      let nextQuizAndId = setNextQuiz.call(this);

      // 如果下一题是有图片 并且没有完成预加载就显示toast, 去加载
      loadCurrentQuizImages
        .call(this, nextQuizAndId.nextQuiz)
        .then(() => {
          // 除第一题外每题开始答题的时间
          this.startAnswerTime = new Date();
        })
        .catch((err) => {
          // 处理图片加载出错的情况
          this.setState(() => ({
            isSuccDisplayQuizImages: false,
          }));
          Raven.captureException(err);
        });

      // 对下下题图片预加载
      preloadNextQuizImages.call(this, nextQuizAndId.index).catch((err) => {
        console.log(err);
        Raven.captureException(err);
      });

      // 下一题在顶部显示
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    // 提交单题答案和去取下一题
    doSubmittionAndNextQuiz(times, limition) {
      if (examType === "SUBQUESTIONS") {
        this.nextQuiz.call(this);
        return;
      }

      // ToDo do we need async submitAnswerRecord? now,yes
      const toast = ToastManager.showLoading("提交中...");
      this.submitAnswerRecord
        .call(this)
        .then((rsp) => {
          if (this.props.practise === false) {
            window.location.href = this.props.get_wrong_quiz_sets_url;
            return;
          }
          this.requestSubmitSingleQuiz = 0;
          this.isSubmitting = false;
          toast.cancel();
        })
        .catch((err) => {
          this.isSubmitting = false;
          toast.cancel();

          let dialogOptions = {
            text: getAxiosErrorText(err),
            mainLabel: "确定",
            mainCallback: () => {
              this.requestSubmitSingleQuiz = this.requestSubmitSingleQuiz + 1;
              this.doSubmittionAndNextQuiz.call(
                this,
                this.requestSubmitSingleQuiz,
                REQUEST_LIMITION
              );
            },
          };
          showDialog.call(this, dialogOptions);
          console.log("提交答案 错误返回", err.response);
          return Promise.reject(err); // 提交有错，初始化答题界面，跳过取下一题
        })
        .then(() => {
          if (this.props.practise && this.state.showResult) {
          } else {
            return this.nextQuiz.call(this);
          }
        })
        .catch((err) => {
          console.log(err);
          Raven.captureException(err);
          this.initAnswerState.call(this);
        });
    }

    // R2 课课练 期末复习 暂时使用该函数提交最后一题
    submitLiatQuiz() {
      let data = {
        day_study_id: this.props.day_study_id,
        quiz_ids: [...this.popr_quiz_ids],
        day_study_exam_id: this.props.day_study_exam_id || "",
        correct_count: this.popr_correct_count,
        error_count: this.props.quizzes.length - this.popr_correct_count,
        exam_type: this.props.exam_type,
        poetry_article_id: this.props.poetry_article_id,
        study_package_activity_id: this.props.study_package_activity_id,
        score: 100 * (this.popr_correct_count / this.props.quizzes.length),
        total_time: this.popr_times / 1000,
      };
      return request({
        url: this.props.save_result_url,
        method: "POST",
        data: data,
        headers: csrfHeaders,
      }).then((res) => {
        // return;
        //期末复习
        // if (this.props.exam_type == "READING") {
        //   window.location.href = res.data.redirect_url;
        // }
        if (
          this.isLastQuiz() &&
          (this.props.exam_type == "TERM_REVIEW" ||
            this.props.exam_type == "READING") &&
          res.data.redirect_url
        ) {
          if (this.state.showResult) {
            this.setState({
              data_res: { ...res },
            });
          } else {
            if (this.state.allCorrect && this.props.should_reward) {
              this.showAllCorrectRewardForSpecialStudy(res);
            } else {
              window.location.href = res.data.redirect_url;
            }
          }

          return;
        }
        //课课练 最后一题 不是录音题 并且有结果
        if (
          this.isLastQuiz() &&
          this.state.quiz.question_type !== 4 &&
          res &&
          this.state.showResult
        ) {
          this.setState({
            data_res: { ...res },
          });
          return;
        }
        if (this.state.allCorrect && this.props.should_reward) {
          this.showAllCorrectRewardForSpecialStudy(res);
        } else {
          this.handelData(res);
        }
      });
    }

    // 显示全对全屏提示 => R2 课课练 期末复习
    showAllCorrectRewardForSpecialStudy = (res) => {
      setTimeout(() => {
        this.setState({
          showAllCorrect: true,
          showRewardCrystal: false,
          data_res: { ...res },
          shouldHandleData: true,
          totalPoint: this.state.totalPoint + options.allCorrectRewardPoint,
        });
      }, 1000);
    };

    // 显示全对奖励和完成全天学习奖励 => 其它的练习题（不包括R2 课课练 期末复习)
    handleFinishWholeDayStudyReward = (res, callback) => {
      setTimeout(() => {
        if (res.data.finish_whole_day_study_reward) {
          console.log("jinru");
          this.setState({
            showFinishWholeDayStudyReward: true,
            showRewardCrystal: false,
            data_res: { ...res },
            totalPoint:
              this.state.totalPoint + options.finishWholeDayStudyRewardPoint,
            callback: callback,
          });
        } else {
          callback.call(this, res);
        }
      }, 1000);
    };

    // 播放答题音效
    promisifiedPlayEffectAudio = (ElementId, isCorrect) => {
      // console.log("------------", ElementId);
      console.log("testing => ", isCorrect, options?.shouldReward, !this.state.hasSingleReward)
      if (
        typeof isCorrect !== "undefined" &&
        isCorrect &&
        options?.shouldReward &&
        !this.state.hasSingleReward
      ) {
        this.setState({
          showRewardCrystal: true,
          quizScore: 1,
          totalPoint: this.state.totalPoint + 1,
          hasSingleReward: true,
        });
        this.createRewardRecord(1);

        setTimeout(() => {
          this.setState({
            showRewardCrystal: false,
          });
        }, 2000);
      } else {
        this.setState({
          allCorrect: false,
        });
      }
      return new Promise((resolve, reject) => {
        let audio = document.getElementById(ElementId);
        console.log("------------", audio);
        audio.play();
        setTimeout(() => {
          resolve();
        }, 500);
      });
    };

    // 处理答案选择操作
    handleSelect = (e) => {
      // 如果是最后一题，返回
      if (this.isLastQuizSelect || this.isSubmitting) {
        return;
      }
      let selectTarget = e.currentTarget;
      let selectedIndex = selectTarget.getAttribute("data-index");
      if (
        examType == "INTERVIEW_WRONG_QUIZ" ||
        examType == "WRONG_QUIZ_SET" ||
        examType == "PRACTICE_LEARNING" ||
        examType == "WRONG_QUIZ_REVISAL"
      ) {
        this.setState((preState) => ({
          isSelectedOrFilled: true,
          selectedIndex: selectedIndex,
          isCorrect:
            selectTarget.getAttribute("data-option") === "true"
              ? "correct"
              : "error",
          selectedOption: preState.quiz.subquestions[0].options[selectedIndex],
        }));
        return;
      }

      if (
        examType === "GRADE_EXAM" ||
        examType === "INTERVIEW_EXAM" ||
        examType == "INTERVIEW"
      ) {
        this.setState((preState) => ({
          isSelectedOrFilled: true,
          selectedIndex: selectedIndex,
          isCorrect:
            selectTarget.getAttribute("data-option") === "true"
              ? "correct"
              : "error",
          selectedOption: preState.quiz.subquestion.options[selectedIndex],
        }));
        return;
      }
      this.setState((preState) => ({
        isSelectedOrFilled: true,
        selectedIndex: selectedIndex,
        isCorrect:
          selectTarget.getAttribute("data-option") === "true"
            ? "correct"
            : "error",
        selectedOption: preState.quiz.options[selectedIndex],
      }));
    };

    // 填空题输入答案操作
    handleInputChange = (e) => {
      const target = e.target;
      const value = target.value;
      this.setState(() => ({
        isSelectedOrFilled: true,
        answerText: value?.trimStart(),
      }));
    };

    // 处理【分步练习】弹框按钮 ”下一题 or 确定“ 操作
    deliverNextQuestionStatus = () => {
      const isAnswerCorrect =
        this.state.answerText.trim() === this.state.quiz.answer;
      const question_type = this.state.quiz.question_type;

      if (question_type === 0) {
        this.setState(() => ({
          fillButtonDialog: false,
        }));
        // 处理填空题后数据操作，提交和取下一题
        this.handleAfterUserFill.call(this, isAnswerCorrect);
      } else if (question_type === 1) {
        let correctOption = "";
        correctOption = this.state.quiz.options.find((option) => {
          if (option.correct) {
            return option;
          }
        });
        this.setState(() => ({
          optionButtonDialog: false,
        }));
        // 处理选择题后数据操作，提交和取下一题
        this.handleAfterUserSelection.call(
          this,
          correctOption,
          this.state.selectedOption
        );
      } else if (question_type === 3) {
      }
    };

    // 点击图片弹框显示
    handleImgDialog = (boolState) => {
      this.setState(() => ({
        imgDialogStatus: boolState,
      }));
    };

    // 处理用户手写题
    confirmWrite = async (quizID, base64Img, onClearBoard) => {
      const toast = ToastManager.showLoading("提交中...");
      try {
        const resPutImg = await this.submitWrittenImg(quizID, base64Img);
        onClearBoard();
        console.warn("confirmWrite", resPutImg);
        toast.cancel();
        this.handleAfterUserWritten.call(this, {
          id: resPutImg.id,
          url: resPutImg.url,
        });
      } catch (e) {
        toast.cancel();
        console.warn("submitWrittenImg error", e);
        let dialogOptions = {
          text: getAxiosErrorText(e),
          mainLabel: "确定",
          mainCallback: async () => {
            try {
              await this.confirmWrite(quizID, base64Img, onClearBoard);
            } catch (e) {}
          },
        };
        showDialog.call(this, dialogOptions);
        Raven.captureException(e);
      }
    };

    // 处理选择题确定
    confirmSelect = () => {
      console.log(
        "让我们看看变化之前allcorrect的值：",
        this.state.allCorrect,
        "============"
      );
      if (examType === "GRADE_EXAM") {
        this.initTimeOut();
      }
      if (this.state.isSelectedOrFilled) {
        if (!this.props.is_answer_time_limited && !this.state.selectedOption) {
          let dialogOptions = {
            text: "请先选择选项哦～",
            mainLabel: "确定",
            mainCallback: () => {
              this.initAnswerState.call(this);
            },
          };
          showDialog.call(this, dialogOptions);
          return;
        }
        this.setState(() => ({
          isSelectedOrFilled: false,
          scrollLeftStatus: true,
        }));
        let correctOption = "";

        if (
          examType == "INTERVIEW_WRONG_QUIZ" ||
          examType == "WRONG_QUIZ_SET" ||
          examType == "PRACTICE_LEARNING" ||
          examType == "WRONG_QUIZ_REVISAL"
        ) {
          correctOption = this.state.quiz.subquestions[0].options.find(
            (option) => {
              if (option.correct) {
                return option;
              }
            }
          );
          this.promisifiedPlayEffectAudio(
            this.state.isCorrect === "correct"
              ? "effect_pass_sound"
              : "effect_fail_sound",
            this.state.isCorrect === "correct"
          )
            .then(() => {
              this.handleAfterUserSelection.call(
                this,
                correctOption,
                this.state.selectedOption,
                this.state.quiz.media_lesson.id
              );
              console.log(
                "让我们看看allcorrect的值：",
                this.state.allCorrect,
                "============"
              );
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }

        if (
          examType === "GRADE_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          correctOption = this.state.quiz.subquestion.options.find((option) => {
            if (option.correct) {
              return option;
            }
          });
        } else {
          correctOption = this.state.quiz.options.find((option) => {
            if (option.correct) {
              return option;
            }
          });
        }

        // 选择题·模拟考不显示音效
        if (
          // examType == "GRADE_EXAM" ||
          // examType === "DAY_EXAM" ||
          examType == "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          this.handleAfterUserSelection.call(
            this,
            correctOption,
            this.state.selectedOption
          );
          return;
        }
        this.promisifiedPlayEffectAudio(
          this.state.isCorrect === "correct"
            ? "effect_pass_sound"
            : "effect_fail_sound",
          this.state.isCorrect === "correct"
        )
          .then(() => {
            // SUBQUESTIONS条件下，选中提交选项是否相等，如相等，则继续下一题，否则继续做
            if (examType === "SUBQUESTIONS") {
              if (correctOption.correct != this.state.selectedOption.correct) {
                return;
              } else {
                if (this.isLastQuiz()) {
                  this.setState(() => ({
                    optionButtonDialog: true, // 答对选择题,设置状态为true
                  }));
                  return;
                }
              }
            }

            // 处理选择题后数据操作，提交和取下一题
            this.handleAfterUserSelection.call(
              this,
              correctOption,
              this.state.selectedOption
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    // 处理选择题确定
    confirmSelectNew = (flag) => {
      if (examType === "GRADE_EXAM") {
        this.initTimeOut();
      }
      if (this.state.isSelectedOrFilled || flag) {
        if (!this.props.is_answer_time_limited && !this.state.selectedOption) {
          let dialogOptions = {
            text: "请先选择选项哦～",
            mainLabel: "确定",
            mainCallback: () => {
              this.initAnswerState.call(this);
            },
          };
          showDialog.call(this, dialogOptions);
          return;
        }
        this.setState(() => ({
          isSelectedOrFilled: false,
          scrollLeftStatus: true,
        }));
        let correctOption = "";

        if (
          examType == "INTERVIEW_WRONG_QUIZ" ||
          examType == "WRONG_QUIZ_SET" ||
          examType == "PRACTICE_LEARNING" ||
          examType == "WRONG_QUIZ_REVISAL"
        ) {
          if (flag) {
            correctOption = {
              answer: this.state.quiz.answer,
            };
          } else {
            correctOption = this.state.quiz.subquestions[0].options.find(
              (option) => {
                if (option.correct) {
                  return option;
                }
              }
            );
          }

          // this.promisifiedPlayEffectAudio(
          //   this.state.isCorrect === "correct"
          //     ? "effect_pass_sound"
          //     : "effect_fail_sound"
          // )
          //   .then(() => {
          this.handleAfterUserSelection.call(
            this,
            correctOption,
            this.state.selectedOption,
            this.state.quiz.media_lesson.id
          );
          // })
          // .catch((err) => {
          //   console.log(err);
          // });
          return;
        }

        if (
          examType === "GRADE_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          correctOption = this.state.quiz.subquestion.options.find((option) => {
            if (option.correct) {
              return option;
            }
          });
        } else {
          correctOption = this.state.quiz.options.find((option) => {
            if (option.correct) {
              return option;
            }
          });
        }

        // 选择题·模拟考不显示音效
        if (
          // examType == "GRADE_EXAM" ||
          // examType === "DAY_EXAM" ||
          examType == "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          this.handleAfterUserSelection.call(
            this,
            correctOption,
            this.state.selectedOption
          );
          return;
        }
        // this.promisifiedPlayEffectAudio(
        //   this.state.isCorrect === "correct"
        //     ? "effect_pass_sound"
        //     : "effect_fail_sound"
        // )
        //   .then(() => {
        // SUBQUESTIONS条件下，选中提交选项是否相等，如相等，则继续下一题，否则继续做
        if (examType === "SUBQUESTIONS") {
          if (correctOption.correct != this.state.selectedOption.correct) {
            return;
          } else {
            if (this.isLastQuiz()) {
              this.setState(() => ({
                optionButtonDialog: true, // 答对选择题,设置状态为true
              }));
              return;
            }
          }
        }

        // 处理选择题后数据操作，提交和取下一题
        this.handleAfterUserSelection.call(
          this,
          correctOption,
          this.state.selectedOption
        );
        // })
        // .catch((err) => {
        //   console.log(err);
        // });
      }
    };

    // 处理语音题目
    btnSubmitOralAnswer = (audioData) => {
      let { is_not_support_recorder } = this.props;
      // TODO check this
      if (is_not_support_recorder) {
        this.isAnswerCorrect(false);
        return false;
      }

      this.setState({
        isSelectedOrFilled: true,
      });

      let quiz = this.state.quiz;
      let text = quiz.subquestions[0].answer;

      // This request may cost 2s.
      SOE(audioData, text, (r) => {
        SoeLog("Quiz", quiz.id, text, r);

        let resp = r.Response;
        if (resp.Status !== "Finished") {
          resp.SuggestedScore = 0;
        }

        this.setState({ answerScore: resp.SuggestedScore });
        this.isAnswerCorrect(resp.SuggestedScore >= 60);
      });
    };

    isAnswerCorrect = (b) => {
      this.promisifiedPlayEffectAudio(
        b ? "effect_pass_sound" : "effect_fail_sound", b
      ).then(() => {
        return new Promise((resolve, reject) => {
          if (this.isLastQuiz()) {
            // TODO check this
            this.setState(() => ({
              fillButtonDialog: true, // 答对填空题,设置状态为true
            }));
            return;
          }
        });
      });

      this.handleAfterUserFill(b);
    };

    // 处理填空题确定
    btnSubmitFillAnwer = () => {
      if (examType === "GRADE_EXAM") {
        this.initTimeOut();
      }
      console.log("======isLastQuizSelect=================");
      // 如果是最后一题，返回
      if (
        this.isLastQuizSelect ||
        this.isSubmitting ||
        !this.state.isSelectedOrFilled
      ) {
        return;
      }
      if (
        !this.props.is_answer_time_limited &&
        (this.state.answerText.trim() === "" || !this.state.answerText)
      ) {
        alert("请输入答案");
        this.setState(() => ({
          isSelectedOrFilled: false,
        }));
        return;
      }

      if (this.state.isSelectedOrFilled) {
        this.setState(() => ({
          isSelectedOrFilled: false,
          scrollLeftStatus: true,
        }));
        if (examType == "SUBQUESTIONS") {
          this.promisifiedPlayEffectAudio(
            this.state.answerText.trim() === this.state.quiz.answer
              ? "effect_pass_sound"
              : "effect_fail_sound",
              this.state.answerText.trim() === this.state.quiz.answer
          )
            .then(() => {
              return new Promise((resolve, reject) => {
                const isAnswerCorrect =
                  this.state.answerText.trim() === this.state.quiz.answer;

                if (!isAnswerCorrect) {
                  this.setState({
                    answerText: "",
                  });
                  return;
                } else {
                  if (this.isLastQuiz()) {
                    this.setState(() => ({
                      fillButtonDialog: true, // 答对填空题,设置状态为true
                    }));
                    return;
                  }
                }

                // 处理填空题后数据操作，提交和取下一题
                this.handleAfterUserFill.call(this, isAnswerCorrect);
              });
            })
            .then(() => {
              this.setState({
                answerText: "",
              });
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
        if (
          examType == "INTERVIEW_WRONG_QUIZ" ||
          examType == "WRONG_QUIZ_SET" ||
          examType == "PRACTICE_LEARNING" ||
          examType == "WRONG_QUIZ_REVISAL"
        ) {
          const isAnswerCorrect =
            this.state.answerText.trim() ===
            this.state.quiz.subquestions[0].answer;

          this.promisifiedPlayEffectAudio(
            isAnswerCorrect ? "effect_pass_sound" : "effect_fail_sound", isAnswerCorrect
          )
            .then(() => {
              return new Promise((resolve, reject) => {
                this.handleAfterUserFill.call(
                  this,
                  isAnswerCorrect,
                  this.state.quiz.media_lesson.id
                );
              });
            })
            .then(() => {
              this.setState({
                answerText: "",
              });
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
        // 填空题·模拟考不显示音效
        if (
          // examType == "GRADE_EXAM" ||
          // examType === "DAY_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          this.handleAfterUserFill.call(this);

          return;
        }
        const isCorrect = this.state.answerText.trim() === (examType === "GRADE_EXAM" ? this.state.quiz.subquestion.answer : this.state.quiz.answer)
        this.promisifiedPlayEffectAudio(
          isCorrect ? "effect_pass_sound" : "effect_fail_sound",
          isCorrect
        )
          .then(() => {
            return new Promise((resolve, reject) => {
              this.handleAfterUserFill.call(this);
            });
          })
          .then(() => {
            this.setState({
              answerText: "",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    btnSubmitFillAnwerNew = () => {
      if (examType === "GRADE_EXAM") {
        this.initTimeOut();
      }

      // 如果是最后一题，返回
      if (
        this.isLastQuizSelect ||
        this.isSubmitting ||
        !this.state.isSelectedOrFilled
      ) {
        return;
      }
      if (
        !this.props.is_answer_time_limited &&
        (this.state.answerText.trim() === "" || !this.state.answerText)
      ) {
        alert("请输入答案");
        this.setState(() => ({
          isSelectedOrFilled: false,
        }));
        return;
      }

      if (this.state.isSelectedOrFilled) {
        this.setState(() => ({
          isSelectedOrFilled: false,
          scrollLeftStatus: true,
        }));
        if (examType == "SUBQUESTIONS") {
          this.promisifiedPlayEffectAudio(
            this.state.answerText.trim() === this.state.quiz.answer
              ? "effect_pass_sound"
              : "effect_fail_sound",
            this.state.answerText.trim() === this.state.quiz.answer
          )
            .then(() => {
              return new Promise((resolve, reject) => {
                const isAnswerCorrect =
                  this.state.answerText.trim() === this.state.quiz.answer;

                if (!isAnswerCorrect) {
                  this.setState({
                    answerText: "",
                  });
                  return;
                } else {
                  if (this.isLastQuiz()) {
                    this.setState(() => ({
                      fillButtonDialog: true, // 答对填空题,设置状态为true
                    }));
                    return;
                  }
                }

                // 处理填空题后数据操作，提交和取下一题
                this.handleAfterUserFill.call(this, isAnswerCorrect);
              });
            })
            .then(() => {
              this.setState({
                answerText: "",
              });
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
        if (
          examType == "INTERVIEW_WRONG_QUIZ" ||
          examType == "WRONG_QUIZ_SET" ||
          examType == "PRACTICE_LEARNING" ||
          examType == "WRONG_QUIZ_REVISAL"
        ) {
          const isAnswerCorrect =
            this.state.answerText.trim() ===
            this.state.quiz.subquestions[0].answer;

          this.promisifiedPlayEffectAudio(
            isAnswerCorrect ? "effect_pass_sound" : "effect_fail_sound",
            isAnswerCorrect
          )
            .then(() => {
              return new Promise((resolve, reject) => {
                this.handleAfterUserFill.call(
                  this,
                  isAnswerCorrect,
                  this.state.quiz.media_lesson.id
                );
              });
            })
            .then(() => {
              this.setState({
                answerText: "",
              });
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
        // 填空题·模拟考不显示音效
        if (
          // examType == "GRADE_EXAM" ||
          // examType === "DAY_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          this.handleAfterUserFill.call(this);
          this.setState({
            answerText: "",
          });
          return;
        }
        const isCorrect = this.state.answerText.trim() === (examType == "GRADE_EXAM" ? this.state.quiz.subquestion.answer : this.state.quiz.answer)
        this.promisifiedPlayEffectAudio(
          isCorrect ? "effect_pass_sound" : "effect_fail_sound", isCorrect
        )
          .then(() => {
            return new Promise((resolve, reject) => {
              this.handleAfterUserFill.call(this);
            });
          })
          .then(() => {
            this.setState({
              answerText: "",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    handleAfterUserWritten(resPutImg) {
      // 每题结束答题的时间
      this.endAnswerTime = new Date();
      if (examType !== "SUBQUESTIONS") {
        this.costTimeGroup.push({
          quizId: this.state.quiz.id,
          startTime: new Date(this.startAnswerTime),
          endTime: new Date(this.endAnswerTime),
        });
      }

      this.handleCrystalReward(true).then(() => {
        this.handleWrithenAnswerData.call(this, resPutImg);
        this.doSubmittionAndNextQuiz.call(
          this,
          this.requestSubmitSingleQuiz,
          REQUEST_LIMITION
        );
      });
    }

    // 处理选择题后数据操作，提交和取下一题
    handleAfterUserSelection(correctOption, option, mediaLessonId) {
      // 每题结束答题的时间
      this.endAnswerTime = new Date();
      if (examType !== "SUBQUESTIONS") {
        this.costTimeGroup.push({
          quizId: this.state.quiz.id,
          startTime: new Date(this.startAnswerTime),
          endTime: new Date(this.endAnswerTime),
        });
      }
      // attention！ this data handle step is sync，do not make it async ,or you need promise ！
      this.handleCrystalReward(correctOption.id === option.id).then(() => {
        this.handleSelectedAnswerData.call(
          this,
          correctOption,
          option,
          mediaLessonId
        );
        this.doSubmittionAndNextQuiz.call(
          this,
          this.requestSubmitSingleQuiz,
          REQUEST_LIMITION
        );
      });
    }

    // 处理填空题后数据操作，提交和取下一题
    handleAfterUserFill(isAnswerCorrect, mediaLessonId) {
      this.handleCrystalReward(isAnswerCorrect).then(() => {
        // 每题结束答题的时间
        this.endAnswerTime = new Date();
        if (examType !== "SUBQUESTIONS") {
          this.costTimeGroup.push({
            quizId: this.state.quiz.id,
            startTime: this.startAnswerTime,
            endTime: this.endAnswerTime,
          });
        }

        // attention！ this data handle step is sync，do not make it async, or you need promise ！
        this.handleFilledAnswerData.call(this, isAnswerCorrect, mediaLessonId);
        if (
          // examType == "GRADE_EXAM" ||
          // examType === "DAY_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          this.setState({
            answerText: "",
          });
        }
        this.doSubmittionAndNextQuiz.call(
          this,
          this.requestSubmitSingleQuiz,
          REQUEST_LIMITION
        );
      });
    }

    // 提交全部答案和显示结果页
    submitAnswersAndshowResult() {
      const toast = ToastManager.showLoading("加载中...");
      this.totalCostTime = this.costTimeGroup.reduce((init, next) => {
        return (init =
          init + (new Date(next.endTime) - new Date(next.startTime)));
      }, 0);

      this.totalCostTime = Math.ceil(this.totalCostTime / 1000);
      console.log("totalCostTime", this.totalCostTime, examType);
      let submitAnswers = function () {
        if (examType == "GRADE_EXAM") {
          console.log("最后一题结束, 提交结果中....");
          // const kb_sign = location.search.split("&");
          // const kb_dimension_el = kb_sign[kb_sign.length -1]
          // if (kb_dimension_el == "kb_dimension") {
          //   this.setState({
          //     kbDimensionEleStatus: true
          //   })
          // }
          return this.submitGradeExamAnswers.call(this);
        }
        if (examType === "INTERVIEW_EXAM") {
          return this.submitInterviewExamAnswers.call(this);
        }
        if (examType === "INTERVIEW") {
          return this.submitInterviewAnswers.call(this);
        }
        if (
          examType == "DAY_EXAM" ||
          examType == "TODAY_QUIZ" ||
          examType == "EXERCISE_QUIZ"
        ) {
          return this.submitDayExamOrTodayQuizAnswers.call(this);
        }
        if (examType === "INTERVIEW_WRONG_QUIZ") {
          return this.submitInterviewWrongQuizAnswers.call(this);
        }
        if (examType === "WRONG_QUIZ_SET") {
          return this.submitInterviewWrongQuizAnswers.call(this);
        }
        if (examType === "WRONG_QUIZ_REVISAL") {
          return this.submitInterviewWrongQuizAnswers.call(this);
        }
        if (examType === "PRACTICE_LEARNING") {
          return this.submitPracticeLearningAnswers.call(this);
        }
      };

      promisifiedLoadImage([dialogPass]).catch(console.log);

      return (
        submitAnswers
          .call(this)
          // .then((res) => this.showResultPage().call(this, res))
          .then((res) => {
            // console.log("==========让我们看看返回数据吧。。。", res, '================')
            this.handleFinishWholeDayStudyReward(
              res,
              this.showResultPage(this)
            );
          })
          .then(() => toast.cancel())
          .catch((err) => {
            toast.cancel();
            let dialogOptions = {
              text: getAxiosErrorText(err),
              mainLabel: "确定",
              mainCallback: () => {
                this.submitAnswersAndshowResult.call(this);
              },
            };
            showDialog.call(this, dialogOptions);
            console.log("提交答案 错误返回");
            Raven.captureException(err);
            console.log(err);
          })
      );
    }

    // 提交单个答案记录
    submitAnswerRecord = () => {
      console.log("提交单个答案");
      let isLast = false;
      // let isFirst = false
      let isRedo = false;

      if (this.isLastQuiz()) {
        isLast = true;
      }
      // if (this.isFirstQuiz()) {
      //   isFirst = true
      // }
      if (this.isRedo) {
        isRedo = true;
        this.isRedo = false;
      }
      this.answer["start_answer_time"] = this.startAnswerTime;
      this.answer["end_answer_time"] = this.endAnswerTime;
      if (
        examType === "PRACTICE_LEARNING" &&
        this.state.quiz &&
        this.state.quiz.subquestions.length !== 1
      ) {
        console.warn(" PRACTICE_LEARNING this.costTimeGroup");
        this.costTimeGroup.push({
          quizId: this.state.quiz.id,
          startTime: new Date(this.startAnswerTime),
          endTime: new Date(this.endAnswerTime),
        });
      }

      console.log(this.costTimeGroup);
      console.warn("cost time", this.endAnswerTime - this.startAnswerTime);
      let answer = {
        answer: this.answer,
        is_last: isLast,
        is_redo: isRedo,
      };
      console.log(answer);
      this.isSubmitting = true;
      try {
        Raven.captureBreadcrumb({
          message: "user quiz answer",
          category: "submit quiz answer",
          data: answer,
        });
      } catch (e) {}
      return retryFetch()(() => {
        let self = this;
        try {
          return request({
            url: self.props.exam_progress_save_url,
            method: "POST",
            headers: csrfHeaders,
            data: answer,
          });
        } catch (err) {
          console.log(err);
          Raven.captureException(err);
        }
      });
    };

    // 计算连错，提交数据
    calculateContinusWrongCorrectness = (answersGroup) => {
      const calculation = (data) => {
        let corrects = [];
        let totalLength = data.length;
        let totalQuizzesLength = this.props.quizzes.length;
        console.log("1491_totalQuizzesLength: ", totalQuizzesLength);
        data.forEach((answer) => {
          if (answer.correct) {
            corrects.push({
              quizId: answer.quizId,
            });
          }
        });
        const correctLength = corrects.length;
        return {
          correct_count: correctLength,
          error_count: totalLength - correctLength,
          score: parseInt((correctLength / totalQuizzesLength) * 100),
        };
      };

      if (examType == "GRADE_EXAM") {
        const rawAnswerGroup = answersGroup.map((answer) => {
          return {
            quizId: answer.id,
            correct: answer.correct,
          };
        });
        return calculation(rawAnswerGroup);
      }
    };

    calculateCorrectness = (answersGroup) => {
      const calculation = (data) => {
        let corrects = [];
        let totalLength = data.length;
        data.forEach((answer) => {
          if (answer.correct) {
            corrects.push({
              quizId: answer.quizId,
            });
          }
        });
        const correctLength = corrects.length;
        return {
          correct_count: correctLength,
          error_count: totalLength - correctLength,
          score: parseInt((correctLength / totalLength) * 100),
        };
      };

      if (examType == "GRADE_EXAM") {
        const rawAnswerGroup = answersGroup.map((answer) => {
          return {
            quizId: answer.id,
            correct: answer.correct,
          };
        });
        return calculation(rawAnswerGroup);
      }

      if (examType === "INTERVIEW_EXAM") {
        const rawAnswerGroup = answersGroup.map((answer) => {
          return {
            quizId: answer.media_lesson.tag.quiz.id,
            correct: answer.media_lesson.tag.quiz.input_correct,
          };
        });
        return calculation(rawAnswerGroup);
      }

      if (examType === "INTERVIEW") {
      }

      if (
        examType == "DAY_EXAM" ||
        examType == "TODAY_QUIZ" ||
        examType == "EXERCISE_QUIZ"
      ) {
        const rawAnswerGroup = answersGroup.map((answer) => {
          return {
            quizId: answer.media_lesson.tag.quiz.id,
            correct: answer.media_lesson.tag.quiz.input_correct,
          };
        });
        return calculation(rawAnswerGroup);
      }

      if (examType === "INTERVIEW_WRONG_QUIZ") {
        let rawAnswerGroup = [];
        answersGroup.forEach((answer) => {
          answer.quizzes.forEach((quiz) => {
            rawAnswerGroup.push({
              quizId: quiz.id,
              correct: quiz.subquestions[0].correct,
            });
          });
        });
        return calculation(rawAnswerGroup);
      }
      if (examType === "PRACTICE_LEARNING") {
        let rawAnswerGroup = [];
        answersGroup.forEach((answer) => {
          answer.quizzes.forEach((quiz) => {
            rawAnswerGroup.push({
              quizId: quiz.id,
              correct: true,
            });
            for (const subquestion of quiz.subquestions) {
              if (!subquestion.correct) {
                const id = rawAnswerGroup.findIndex((answer) => {
                  answer.quizId === quiz.id;
                });
                rawAnswerGroup.splice(id, 1);
                rawAnswerGroup.push({
                  quizId: quiz.id,
                  correct: false,
                });
                break;
              }
            }
          });
        });
        return calculation(rawAnswerGroup);
      }
    };
    // 最后提交答案
    // submitGradeExamAnswers 在GradeExam jsx中
    submitDayExamOrTodayQuizAnswers = () => {
      console.log("提交答案");
      console.warn(this.totalCostTime);
      var answers = {
        answers: this.answers,
        id: this.state.day_study_id,
        study_package_activity_id: this.props.study_package_activity_id,
        exam_type: this.state.exam_type,
        total_time: this.totalCostTime,
        ...this.calculateCorrectness.call(this, this.answers),
      };
      if (examType == "DAY_EXAM") {
        answers = Object.assign(
          {
            day_study_exam_id: this.props.day_exam.id,
          },
          answers
        );
      }
      return retryFetch()(() => {
        let self = this;
        return request({
          url: self.props.save_result_url,
          method: "POST",
          headers: csrfHeaders,
          data: answers,
        });
      });
    };

    handleCrystalReward = (isCorrect) => {
      return new Promise((resolve, reject) => {
        if (this.props.should_reward && !this.state.hasSingleReward) {
          const score = isCorrect ? 1 : 0;
          this.setState({
            quizScore: score,
            showRewardCrystal: isCorrect,
            totalPoint: this.state.totalPoint + score,
            hasSingleReward: true,
          });
          this.createRewardRecord(score);
        } else {
          // const score = isCorrect ? 1 : 0;
          // this.setState({
          //   quizScore: score,
          //   showRewardCrystal: false,
          //   totalPoint: this.state.totalPoint + score,
          // });
        }

        if (isCorrect) {
          setTimeout(() => {
            resolve();
          }, 2000);
        } else {
          this.setState({
            allCorrect: false,
          });
          resolve();
        }
      });
    };

    createRewardRecord = (amount, source) => {
      if (amount <= 0) {
        return;
      }
      source = source ?? "quiz";
      request({
        url: options.createRewardPath,
        method: "POST",
        headers: csrfHeaders,
        data: {
          study_package_activity_id: options.studyPackageActivityId,
          source: source,
          target_id: this.state.quiz.id,
          target_type: "Quiz",
          amount: amount,
        },
      }).then(() => {
        this.setState({
          hasSingleReward: false,
        })
      }).catch((e) => {
        console.log("create reward record error: ", e)
        this.setState({
          hasSingleReward: false,
        })
      });
    };

    handleAllCorrectConfirmClick = () => {
      this.setState({
        showAllCorrect: false,
      });

      // R2 的特殊逻辑
      console.log(this.state.shouldHandleData, "-----------ddd--------");
      if (this.state.shouldHandleData) {
        this.handelData(this.state.data_res);
      } else {
        this.submitAnswersAndshowResult();
      }
    };

    handleStudyOnTimeConfirmClick = () => {
      this.setState({
        showStudyOnTime: false,
      });
    };

    handleFinishWholeDayConfirmClick = () => {
      this.setState({
        showFinishWholeDayStudyReward: false,
      });
      console.log("------------", this.state, "===============查看所有参数");

      // 如果有特定的处理函数，则执行该函数
      if (this.state.callback && this.state.data_res) {
        this.state.callback.call(this, this.state.data_res);
        return;
      }

      // R2 的特殊逻辑
      console.log(this.state.shouldHandleData, "-----------ddd--------");
      if (this.state.shouldHandleData) {
        this.handelData(this.state.data_res);
      } else {
        this.submitAnswersAndshowResult();
      }
    };

    render() {
      return (
        <>
          {this.state.showRewardCrystal && options.shouldReward && (
            <CrystalReward
              point={this.state.quizScore}
              totalPoint={this.state.totalPoint}
              showBabyPoint={true}
            />
          )}
          {this.state.showAllCorrect &&
          this.state.allCorrect &&
          options.shouldReward ? (
            <FullScreenCrystalReward
              point={options.allCorrectRewardPoint}
              totalPoint={this.state.totalPoint}
              textUrl={AllCorrectAnswer}
              handleClick={this.handleAllCorrectConfirmClick}
            />
          ) : null}
          {this.state.showStudyOnTime && options.shouldReward && (
            <FullScreenCrystalReward
              point={this.props.study_on_time_reward_point}
              totalPoint={this.state.totalPoint}
              textUrl={StudyOnTimeIcon}
              handleClick={this.handleStudyOnTimeConfirmClick}
            />
          )}
          {this.state.showFinishWholeDayStudyReward && options.shouldReward && (
            <FullScreenCrystalReward
              point={options.finishWholeDayStudyRewardPoint}
              totalPoint={this.state.totalPoint}
              textUrl={FinishWholeDayStudyIcon}
              handleClick={this.handleFinishWholeDayConfirmClick}
            />
          )}
          {super.render()}
        </>
      );
    }
  };
};
