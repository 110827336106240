import React, { useState, useCallback } from "react";
import InnerModal from "../../../../utils/Modal/InnerModal.jsx";
import ModalMask from "../../../../utils/Modal/ModalMask.jsx";
import RemindInstallAppModal from "./RemindInstallAppModal.jsx";

const usePoetryDetailInstallApp = (isOpenModal) => {
  const [isShowReminderModal, setIsShowReminderModal] = useState(false);

  const onSetRemindInstallApp = useCallback(
    (bool) => {
      if (isOpenModal) {
        setIsShowReminderModal(bool);
        return bool;
      }
    },
    [isOpenModal]
  );

  const onModalCloseClick = useCallback((e) => {
    setIsShowReminderModal(false);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const innerModal = useCallback(() => {
    return isShowReminderModal ? (
      <InnerModal>
        <ModalMask>
          <RemindInstallAppModal onModalCloseClick={onModalCloseClick} />
        </ModalMask>
      </InnerModal>
    ) : null;
  }, [isShowReminderModal, onModalCloseClick]);

  return { onSetRemindInstallApp, reminderInstallAppModal: innerModal };
};

export default usePoetryDetailInstallApp;
