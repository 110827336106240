import React, { PureComponent } from "react";
import "./StudyProgressCircle.scss";
// 学习进度环
/**
 *   <StudyProgressCircle
            studiedCourseDayCount={studied_course_day_count}  //已学天数
            courseDayCount={course_day_count}  // 总天数
            totalStudiedMediaLessonsCount={studied_media_courses_count} // 已学课程数
            mediaLessonsCount={media_courses_count}  // 总课程数
            leftCircleName={'已学天数'} // 左边环title     
            rightCircleName={'已学古诗'}// 右边环title 
            scale={0.7} // 设置大小
          />
 */
export default class StudyProgressCircle extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      studiedCourseDayCount,
      courseDayCount,
      totalStudiedMediaLessonsCount,
      mediaLessonsCount,
      leftCircleName,
      rightCircleName,
      scale,
      isHiddenRightCircle,
    } = this.props;
    // if (!scale) {
    //   scale = 1
    // }
    let scoreProgress_left = {},
      scoreProgress_right = {};
    let scoreProgress_left_2 = {},
      scoreProgress_right_2 = {};
    let left_day_progress = Math.round(
      (studiedCourseDayCount / courseDayCount) * 100
    );
    let right_period_progress = Math.round(
      (totalStudiedMediaLessonsCount / mediaLessonsCount) * 100
    );
    if (left_day_progress >= 0 && left_day_progress <= 50) {
      const rotate = -135 + (18 / 5) * left_day_progress;
      scoreProgress_left = {
        transform: `rotate(${rotate}deg)`,
      };
      scoreProgress_right = {
        transform: `rotate(-135deg)`,
      };
    } else {
      const rotate = -135 + (18 / 5) * (left_day_progress - 50);
      scoreProgress_left = {
        transform: `rotate(45deg)`,
      };
      scoreProgress_right = {
        transform: `rotate(${rotate}deg)`,
      };
    }

    if (right_period_progress >= 0 && right_period_progress <= 50) {
      const rotate = -135 + (18 / 5) * right_period_progress;
      scoreProgress_left_2 = {
        transform: `rotate(${rotate}deg)`,
      };
      scoreProgress_right_2 = {
        transform: `rotate(-135deg)`,
      };
    } else {
      const rotate = -135 + (18 / 5) * (right_period_progress - 50);
      scoreProgress_left_2 = {
        transform: `rotate(45deg)`,
      };
      scoreProgress_right_2 = {
        transform: `rotate(${rotate}deg)`,
      };
    }

    const setExtraCssStyle = () => {
      const style = {};
      if (scale !== undefined) {
        style.transform = "scale(" + scale + ")";
      }
      if (isHiddenRightCircle) {
        style.justifyContent = "flex-end";
        style.marginRight = 8;
      }
      return style;
    };
    return (
      <div className="circle_box" style={setExtraCssStyle()}>
        {/* left 已学天数*/}
        <div className="interview_score">
          <div className="circleProgress_wrapper">
            <div className="circleProgress_wrapper_inside"></div>
            <span className="correct_score">
              <span>{studiedCourseDayCount}</span>
              <span>/{courseDayCount}</span>
            </span>
            <div className="wrapper left">
              <div
                className="circleProgress leftcircle"
                style={scoreProgress_left}
              ></div>
            </div>
            <div className="wrapper right">
              <div
                className="circleProgress rightcircle"
                style={scoreProgress_right}
              ></div>
            </div>
          </div>
          <div className="circle_title">{leftCircleName}</div>
        </div>

        {/* right  已学课时*/}
        {!isHiddenRightCircle && (
          <div className="interview_score">
            <div className="circleProgress_wrapper">
              <div className="circleProgress_wrapper_inside"></div>
              <span className="correct_score">
                <span>{totalStudiedMediaLessonsCount}</span>
                <span>/{mediaLessonsCount}</span>
              </span>
              <div className="wrapper left">
                <div
                  className="circleProgress leftcircle"
                  style={scoreProgress_left_2}
                ></div>
              </div>
              <div className="wrapper right">
                <div
                  className="circleProgress rightcircle"
                  style={scoreProgress_right_2}
                ></div>
              </div>
            </div>
            <div className="circle_title">{rightCircleName}</div>
          </div>
        )}
      </div>
    );
  }
}
