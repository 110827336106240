import React from "react";
import ReactDOM from "react-dom";

import ComponentProxy from "../core/ComponentProxy";

import "./AuthenticationDialog.scss";

class AuthenticationDialog extends React.Component {
  onLoginClick = (e) => {
    window.location = this.props.url;
  };

  render() {
    return (
      <div className="comp-authentication-dialog">
        <div className="content">
          <p>请先登录</p>
          <a
            className="btn_login"
            href="javascript:void(0);"
            onClick={this.onLoginClick}
          >
            立即登录
          </a>
          <div className="close_btn" onClick={this.props.onClose}>
            &times;
          </div>
        </div>
      </div>
    );
  }
}

export default class AuthenticationDialogProxy extends ComponentProxy {
  constructor(url) {
    super();

    this.url = url;
  }

  createComponent() {
    return (
      <AuthenticationDialog url={this.url} onClose={() => this.cancel()} />
    );
  }
}
