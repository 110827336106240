import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";
import "./Word.scss";
import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";

export default (props) => {
  //带答案解析的，详情页的
  return (
    <InfoBroadTitle isImg={false} titles={"好词好句积累"}>
      {props.content && (
        <div>
          <Titles text={"答案解析"}></Titles>
          <ReactMarkdown
            source={props.content}
            className="markdown_content markdown_content_back_eee"
            escapeHtml={false}
          ></ReactMarkdown>
        </div>
      )}
      {props.content && props.sub_subjects && props.sub_subjects.length > 0 ? (
        <div style={{ height: 40, width: "100%" }}></div>
      ) : (
        ""
      )}
      <div>
        <Titles text={"本次积累"}></Titles>
        {props.sub_subjects && props.sub_subjects.length > 0
          ? props.sub_subjects.map((itm, idx) => {
              return (
                <Word
                  key={"materials" + idx}
                  type={itm.material_type == 3 ? 1 : 0}
                  name={itm.name}
                  materials={[...itm.materials]}
                  material_index={idx}
                  isReport={false}
                  correct_count={itm.correct_count}
                  wrong_count={itm.wrong_count}
                  quizzes={itm.quizzes}
                  // name={material_item.name}
                  // materials={material_item.materials}
                  // material_index={material_index}
                ></Word>
              );
            })
          : ""}
      </div>
    </InfoBroadTitle>
  );
};
