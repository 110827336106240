import React, {useState, useEffect} from "react";
import crystal from "point_rewards/crystal.png";
import progressBarImage from "point_rewards/progress-bar.png";
import Capsule from "../shared/Capsule";
import ProgressBar from "../shared/ProgressBar";
import ConfirmExchange from "./ConfirmExchange";
import DispatchNotify from "./DispatchNotify";
import RewardCommodityList from "./RewardCommodityList";
import './RewardExchangeProgress.scss';
import "../shared/useful_css.scss";
import doExchangeReward from "../shared/request_exchange_reward";

const imgStyle = {
  width: '22px',
  height: '22px',
  fontSize: '14px',
}

const changeRewardStyle = {
  color:"#FFC000",
  border:"1px solid #FFC000",
  fontSize: '14px',
}

const exchangeRewardStyle = {
  backgroundColor: "#FFC000",
  color: "#FFF",
  fontSize: '14px',
}

const RewardExchangeProgress = (props) => {
  const [currentCommodity, setCurrentCommodity] = useState(props.commodity)
  const [showConfirm, setShowConfirm] = useState(!!props.showConfirm);
  const [hasExchanged, setHasExchanged] = useState(props.commodity.exchanged)
  const [showNotify, setShowNotify] = useState(false)
  const [notifyText, setNotifyText] = useState('')
  const [showRewardList, setShowRewardList] = useState(false)
  const [currentActiveIndex, setCurrentActiveIndex] = useState(null)
  const queryParams = Object.fromEntries([...new URLSearchParams(location.search)])
  const progressBarStyle = {
    outterStyle: {
      width: '100%',
      borderRadius: '8px',
    },
    innerStyle: {
      backgroundImage: `url(${progressBarImage})`,
    },
    totalLength: currentCommodity.point_cost,
    currentLength: props.totalPoint,
  };

  useEffect(() => {
    if (currentCommodity.chould_exchange_reward) {
      startToExchange()
    }
  }, []);

  useEffect(() => {
    setCurrentActiveIndex(findActiveIndex())
  }, [])

  const startToExchange = () => {
    const url = props.create_reward_exchange_url;
    const data = {
      amount: currentCommodity.point_cost,
      study_package_activity_id: props.study_package_activity_id,
      commodity_id: currentCommodity.id,
      shipment_address_id: queryParams.shipment_address_id,
    }
    doExchangeReward({requestData: data}).then((res)=>{
      if (res.data.code === 200) {
        console.log(res.data)
        setShowConfirm(false)
        setHasExchanged(true)
        setNotifyText(res.data.data.text)
        setShowNotify(true)
      }
    })
  }

  const considerAgain = () => {
    setShowConfirm(false)
  }

  const switchReward = () => {
    setShowRewardList(true)
  }


  const confirmExchangeOption = (res) => {
    if (res) {
      setCurrentCommodity(res.data.data.reward_commodity)
      const index = findActiveIndex(res.data.data.reward_commodity.id)
      setCurrentActiveIndex(index)
    }
    setShowRewardList(false)
  }

  const buttonList = () => {
    if (hasExchanged) {
      return (
        <Capsule text="兑换成功" style={exchangeRewardStyle} />
      )
    } else {
      return (
        <>
          <Capsule text="更换奖品" style={changeRewardStyle} onClick={switchReward} />
          {exchangeButton(currentCommodity.point_cost, props.totalPoint, ()=>{setShowConfirm(true)})}
        </>
      )
    }
  }

  const findActiveIndex = (id) => {
    id = id ?? currentCommodity.id
    for (let i = 0; i < props.list.length; i++) {
      if (id === props.list[i].commodity.id) {
        return i
      }
    }
    return -1
  }

  return (
    <div className={`reward-exchange-progress flex flex-col ${props.className}`} style={props.style}>
      {showRewardList &&
        <RewardCommodityList
          confirmExchangeOption={confirmExchangeOption}
          chooseLater={()=>{setShowRewardList(false)}}
          list={props.list}
          selectRewardPath={props.selectRewardPath}
          studyPackageActivityId={props.study_package_activity_id}
          activeIndex={currentActiveIndex}
          commoditiesPath={props.commodities_path}
          maxRewardPoints={props.maxRewardPoints}
        /> }
      <div className="flex justify-between">
        <div className="title font-bold">奖品兑换进度</div>
        <div className="flex flex-row items-center">
          <img src={crystal} style={imgStyle}/>
          <div className="progress-num">{pointProgress(currentCommodity.point_cost, props.totalPoint)}</div>
        </div>
      </div>

      <div className="flex flex-row mt-4">
        <div className="commodity-img" style={{backgroundImage: `url(${currentCommodity.photo})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}></div>
        <div className="flex flex-col flex-grow justify-between pl-3">
          <div>
            <div className="text-left font-bold" style={{color: "#333333", fontSize: '16px'}}>{currentCommodity.name}</div>
            <div className="mt-1">
              <ProgressBar {...progressBarStyle} />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            {buttonList()}
          </div>
          {showConfirm ? <ConfirmExchange
            photo={currentCommodity.photo}
            pointCost={currentCommodity.point_cost}
            name={currentCommodity.name}
            considerAgain={considerAgain}
            showWindow={setShowConfirm}
            commodityId={currentCommodity.id}
            studyPackageActivityId={props.study_package_activity_id}
            /> : null}

          {showNotify && <DispatchNotify shouldShowWindow={showNotify} notifyText={notifyText} />}
        </div>
      </div>
    </div>
  )
};

const pointProgress = (commodityPointCost, totalPoint) => {
  let currentPoint = totalPoint
  currentPoint = Math.min(currentPoint, commodityPointCost)
  return `${currentPoint}/${commodityPointCost}`
}

const exchangeButton = (commodityPointCost, totalPoint, setShowConfirm) => {

  if (commodityPointCost <= totalPoint ) {
    return <Capsule text="兑换奖品" style={exchangeRewardStyle}  onClick={setShowConfirm}/>
  }
}

export default RewardExchangeProgress;