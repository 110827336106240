import React, { useState } from "react";
import Capsule from "../shared/Capsule";
import crystalWithCircle from "point_rewards/crystal-with-circle.png";
import { request, csrfHeaders } from "../utils/request";
import crystal from "point_rewards/crystal.png";
import arrow from "point_rewards/arrow-to-right";
import PropTypes from "prop-types";
import "./ConfirmExchange.scss";
import CloseButton from "../utils/CloseButton";
import "../shared/useful_css.scss";


const ConfirmExchange = ({photo, name, pointCost, considerAgain, confirmToExchange, ...others}) => {
  const mainPhotoStyle = {
    width: "90px",
    height: "90px",
  }
  const arrowStyle = {
    width: "30px",
    height: "30px",
  }
  const smallCrystalStyle = {
    width: "22px",
    height: "22px",
  }

  const confirmStyle = {
    color:"#FFC000",
    border:"1px solid #FFC000",
    fontSize: '16px',
    padding: 0,
    width: '130px',
    height: '44px',
    lineHeight: '44px',
  }
  
  const exchangeRewardStyle = {
    backgroundColor: "#FFC000",
    color: "#FFF",
    fontSize: '16px',
    padding: 0,
    width: '130px',
    height: '44px',
    lineHeight: '44px',
  }

  const defaultChooseShipmentAddressPath = `/shipment_addresses/detail?commodity_id=${others.commodityId}&redirect_url=${encodeURIComponent(location.href)}`

  const handleConfirm = () => {
    const data = {
      commodity_id: others.commodityId,
      study_package_activity_id: others.studyPackageActivityId,
    }
    request({
      url: '/api/exchange_rewards/prepare',
      method: 'POST',
      headers: csrfHeaders,
      data: data,
    }).then((res) => {
      const redirectUrl = others.redirectUrl ?? defaultChooseShipmentAddressPath
      location.href = redirectUrl
    })
  }

  return (
    <div className="confirm-exchange-component-container">
      <div className="background-mask"></div>
      <div className="confirm-exchange-container flex flex-col items-center">
        <div className="confirm-exchange bg-white flex flex-col items-center">
          <div className="flex flex-row items-center">
            <img src={crystalWithCircle} style={mainPhotoStyle} />
            <img src={arrow} style={arrowStyle} className="mx-5" />
            <img src={photo} style={mainPhotoStyle} className="commodity-photo" />
          </div>

          <div className="info flex flex-row mt-6 items-center flex-wrap">
            <div>将使用</div>
            <div className="point-cost">{pointCost}</div>
            <img src={crystal} style={smallCrystalStyle} className="mx-2" />
            <div>兑换“{name}”</div>
          </div>

          <div className="flex flex-row justify-between mt-6 w-100">
            <Capsule text="再想想" style={confirmStyle} onClick={considerAgain} />
            <Capsule text="确认" style={exchangeRewardStyle} onClick={handleConfirm} />
          </div>
        </div>
        <CloseButton width={48} className="mt-6" onClick={considerAgain} />
      </div>
    </div>
  )
}

ConfirmExchange.propTypes = {
  photo: PropTypes.string.isRequired,
  pointCost: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}

export default ConfirmExchange;