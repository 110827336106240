/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import "./PoetryRewards.scss";

const PoetryRewards = ({
  rewardsTimeList,
  rewardPhoto,
  babyName,
  isSpell,
  isSanZiJing,
}) => {
  return (
    <div
      className={`comp_poetry_rewards ${
        isSpell
          ? "comp_spell_poetry_rewards"
          : isSanZiJing
          ? "comp_san_zi_jing_poetry_rewards"
          : ""
      }`}
    >
      <p className="title">{`精美奖牌、奖状，只给按时完课的${babyName}！`}</p>
      {rewardsTimeList.map((time, index) => {
        if (index === 0 && time) {
          return (
            <p className="reward_one reward" key={index}>{`${time}，${
              rewardsTimeList.length === 2
                ? isSanZiJing
                  ? "完成『三字经·上』所有内容学习，可获得一次完课奖励：“学富五车”奖牌+“持之以恒小明星”奖状；"
                  : "完成『一级』所有内容学习，可获得一次完课奖励：“诗词小秀才”奖牌+奖状；"
                : isSanZiJing
                ? "完成『三字经·上』所有内容学习，可获得一次完课奖励：“学富五车”奖牌+“持之以恒小明星”奖状；"
                : `完成所有内容学习，可获得完课奖励：奖牌、奖状${
                    isSpell ? "" : "、贴纸"
                  }；`
            }`}</p>
          );
        }
        if (index === 1 && time) {
          return (
            <p className="reward_two reward" key={index}>{`${time}，${
              isSanZiJing
                ? "完成所有内容学习，可以获得第二次完课奖励：“才高八斗”奖牌+“传统文化小明星”奖状"
                : "完成所有内容学习，可以获得第二次完课奖励：“诗词小举人”奖牌+奖状"
            }`}</p>
          );
        }
      })}
      <img src={rewardPhoto} alt="" className="rewards_img" />
    </div>
  );
};

PoetryRewards.displayName = "PoetryRewards";

PoetryRewards.propTypes = {
  rewardsTimeList: PropTypes.array,
  rewardPhoto: PropTypes.string,
  babyName: PropTypes.string,
};

export default PoetryRewards;
