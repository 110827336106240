import React, { Component } from "react";
import LazyloadContent from "../../utils/lazyload/LazyloadContent";
import "../../utils/ReactMarkDown.scss";
import "./SolveIdea.scss";

class SolveIdea extends Component {
  render() {
    const { hint } = this.props;
    return (
      <div className="solve_idea">
        <div className="text">解题思路:</div>
        <LazyloadContent content={hint} />
      </div>
    );
  }
}

export default SolveIdea;
