import React, { useState, memo } from "react"

import "./UploadExerciseList.scss"

import TeacherComments from "../components/TeacherComments";
import ExamRoom from "../components/ExamRoom";
import AnswerAnalysis from "../components/AnswerAnalysis";
import ExcellentExerciseList from "./ExcellentExerciseList";
import UploadExerciseList from "./UploadExerciseList";

const UploadExerciseContent = (props) => {
  const others = props.others
  const SidebarLabel = (text) => {
    return (
      <div style={{marginLeft: '-20px', width: '112px', textAlign: 'center'}}>
        <div style={{backgroundColor: '#FFBA44', height: '32px', lineHeight: '32px', color: 'white', fontSize: '18px', fontWeight: 'bold', borderRadius: '10px 50px 50px 0'}}>{text}</div>
        <div style={{width: '5px', height: '5px', backgroundColor: '#F7831D', borderRadius: '0 0 0 4px'}}></div>
      </div>
    )
  }

  const isCommented = () => {
    return others.reading_comment_state === 'commented'
  }

  const isPendingComment = () => {
    return others.reading_comment_state === 'pending_comment'
  }

  const isUploaded = () => {
    return others.reading_comment_state === 'uploaded'
  }


  const isThirdDayOfReadingV3 = () => {
    return others.study_mode === 'reading_v2' && others.study_mode_version === 3 && others.study_topic_day === 3
  }
  
  return (
    <>
      <UploadExerciseList {...props} SidebarLabel={SidebarLabel} isThirdDayOfReadingV3={isThirdDayOfReadingV3} />
      {isThirdDayOfReadingV3() && <ExcellentExerciseList
        excellentExercises={others.excellent_exercises ?? []}
        SidebarLabel={SidebarLabel}
        readingCommentState={others.reading_comment_state}
      />}

      {!isThirdDayOfReadingV3() && 
      (isPendingComment() || isCommented()) ? 
      (
        <TeacherComments
          titles={"老师评语"}
          reading_comment_state={others.reading_comment_state}
          course_consultant={others.course_consultant}
          comment_text={others.comment_text}
          comment_audio_url={others.comment_audio_url}
          comment_audio_info_url={others.comment_audio_info_url}
        ></TeacherComments>
      ) : null }

      {(isUploaded() || isPendingComment() || isCommented()) &&
        !isThirdDayOfReadingV3() &&
        others.rehearsal_analysis ? (
          <ExamRoom content={others.rehearsal_analysis}></ExamRoom>
        ) : (
          ""
      )}

      {(isUploaded() || isPendingComment() || isCommented()) &&
        !isThirdDayOfReadingV3() &&
        others.sub_subjects &&  others.sub_subjects.length > 0 ? (
          <AnswerAnalysis
            content={others.material_analysis}
            sub_subjects={others.sub_subjects}
          ></AnswerAnalysis>
        ) : (
          ""
      )}
    </>
  )
}

export default memo(UploadExerciseContent)