import React, { useMemo } from "react";
import PropTypes from "prop-types";
// import { useSelector } from "../../../utils/react-global-state";
import { DirectBuy } from "../../../shared/DirectBuy.jsx";
import ModalManage from "../../../utils/Modal/ModalManage.jsx";
import ModalMask from "../../../utils/Modal/ModalMask.jsx";

class PaymentModal extends DirectBuy {
  render() {
    const {
      onModalClose,
      packageId,
      packageBuyUrl,
      packagePrice,
      packageTargetType,
      isSpell,
    } = this.props;
    return (
      <div
        className={`payment_container ${
          isSpell ? "payment_container_pinyin" : ""
        }`}
      >
        <div className="modal_close" onClick={onModalClose}></div>
        <div
          className="payment_click"
          onClick={this.buy.bind(this, packageBuyUrl, {
            target_id: packageId,
            price: packagePrice,
            target_type: packageTargetType,
          })}
        >
          {`解锁（￥${packagePrice}）`}
        </div>
      </div>
    );
  }
}

PaymentModal.propTypes = {
  onModalClose: PropTypes.func,
  money: PropTypes.number,
};

const useCoursePayment = ({ ...props }) => {
  // console.log("useCoursePayment_props: ", props);
  const {
    study_package_activity_buy_url,
    target_type,
    id,
    price,
    isSpell,
  } = props;
  // const packageBuyUrl = useSelector(
  //   state => state.upgrade_package.study_package_activity_buy_url
  // );
  // const packageTargetType = useSelector(
  //   state => state.upgrade_package.target_type
  // );
  // const packageId = useSelector(state => state.upgrade_package.id);
  // const packagePrice = useSelector(state => state.upgrade_package.price);
  const payModal = useMemo(
    () =>
      new ModalManage({
        render: (_, onModalClose) => {
          return (
            <ModalMask>
              <PaymentModal
                onModalClose={onModalClose}
                packageTargetType={target_type}
                packageId={id}
                packagePrice={price}
                packageBuyUrl={study_package_activity_buy_url}
                isSpell={isSpell}
              />
            </ModalMask>
          );
        },
      }),
    [id, price, study_package_activity_buy_url, target_type, isSpell]
  );
  return payModal.open;
};

export default useCoursePayment;
