import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";

import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";

export default (props) => {
  //每日报告，没有答案解析，有下拉错题（isReport==true）
  return (
    <InfoBroadTitle isImg={false} titles={"好词好句积累"}>
      <div>
        {props.sub_subjects && props.sub_subjects.length > 0
          ? props.sub_subjects.map((itm, idx) => {
              return (
                <Word
                  key={"materials" + idx}
                  type={itm.material_type == 3 ? 1 : 0}
                  name={itm.name}
                  materials={[...itm.materials]}
                  material_index={idx}
                  isReport={true}
                  correct_count={itm.correct_count}
                  wrong_count={itm.wrong_count}
                  quizzes={itm.quizzes}
                  // name={material_item.name}
                  // materials={material_item.materials}
                  // material_index={material_index}
                ></Word>
              );
            })
          : ""}
      </div>
    </InfoBroadTitle>
  );
};
