import React, { Component } from "react";
import ReportHeaderDay from "../utils/study_report_info/ReportHeaderDay";
import QuickBack from "../utils/quick_back/QuickBack";
import UserAgent from "../utils/UserAgent";

import "./ParentClassReport.scss";

const vko_one_day_activities = {
  title: "教育路线的选择",
  day: 1,
  vko_activities: [
    {
      name: "择校倾向测评",
      description: "体制内or体制外or双轨？快速测出你的教育理念及择校倾向",
      url: "https://xinya.me/mini_exams?exam_type=school_route&ref=parentclass",
      button_name: "立即测评",
      is_type: "route",
    },
    {
      name: "上海公办&民办小学大全",
      description: "168所公办/民办/国际小学，课程教材/办学特色等一览无余",
      url:
        "https://xinya.me/wap/schools?city=%E4%B8%8A%E6%B5%B7&school_route_prefer=100&ref=parentclass",
      button_name: "立即查看",
    },
    {
      name: "查我家对口小学",
      description: "2019年公办小学对口范围、办学基本情况公示表",
      url:
        "http://act.shanghaicity.openservice.kankanews.com/iloveshanghai/2015/zhaosheng/indexnew.html",
      button_name: "立即查看",
    },
  ],
};
const vko_two_day_activities = {
  title: "报考录取政策",
  day: 2,
  vko_activities: [
    {
      name: "上海实验学校(上实)报考流程",
      description: "报名、网筛、机考、面试时间表，机考与面试细节内容",
      photo_url: "https://upload.xinya.me/2019ysxvideo/2_1.png",
      button_name: "立即查看",
    },
    {
      name: "2018年公办小学统筹情况",
      description: "报考民办未录取，能否回对口公办？",
      photo_url: "https://upload.xinya.me/2019ysxvideo/2_2.png",
      button_name: "立即查看",
    },
    {
      name: "2019年幼升小关键时间点",
      description: "每个关键时间点不错过",
      photo_url: "https://upload.xinya.me/2019ysxvideo/2_3.png",
      button_name: "立即查看",
    },
    {
      name: "人户分离常见问题",
      description: "如何办理？哪些情况要办理？",
      photo_url: "https://upload.xinya.me/2019ysxvideo/2_4.png",
      button_name: "立即查看",
    },
    {
      name: "人户一致与入户年限常见问题",
      description: "人户一致的定义、人户一致的4种情况",
      photo_url: "https://upload.xinya.me/2019ysxvideo/2_5.jpg",
      button_name: "立即查看",
    },
  ],
};
const vko_three_day_activities = {
  title: "录取比例",
  day: 3,
  vko_activities: [
    {
      name: "上实近3年的录取率",
      description: "报名人数、录取人数、录取率",
      photo_url: "https://upload.xinya.me/2019ysxvideo/3_1.png",
      button_name: "立即查看",
    },
    {
      name: "上岸牛蛙家长经验【免费听】",
      description: "小月龄慢热孩子面试上实，如何脱颖而出？",
      url: "https://xinya.me/experiences/56?ref=parentclass",
      button_name: "立即查看",
    },
    {
      name: "各民办小学的录取率（2018年VS2017年）",
      description: "2018年录取率变高的学校、录取率依然低的学校",
      photo_url: "https://upload.xinya.me/2019ysxvideo/3_3.png",
      button_name: "立即查看",
    },
    {
      name: "2019年全市招生、接受二志愿的民办小学",
      description: "全市招生的学校，以及本区/外区招生人数",
      photo_url: "https://upload.xinya.me/2019ysxvideo/3_4.png",
      button_name: "立即查看",
    },
    {
      name: "2019年接受二志愿的民办小学",
      description: "接受二志愿的学校，以及是否全市招生",
      photo_url: "https://upload.xinya.me/2019ysxvideo/3_5.png",
      button_name: "立即查看",
    },
  ],
};
const vko_four_day_activities = {
  title: "面试偏重点",
  day: 4,
  vko_activities: [
    {
      name: "学校考点偏重list",
      description: "面试时偏重计算、思维、语言、外语的学校有哪些",
      photo_url: "https://upload.xinya.me/2019ysxvideo/4_1.png",
      button_name: "立即查看",
    },
    {
      name: "常见英文面谈问答句型",
      description: "姓名年龄、兴趣爱好、天气事件、家人幼儿园等",
      photo_url: "https://upload.xinya.me/2019ysxvideo/4_2.png",
      button_name: "立即查看",
    },
  ],
  vko_ysx_activities: {
    title: "相关幼升小课程",
    ysx_activities: [
      {
        name: "《幼升小面试真题汇编(数学思维篇)》",
        description: "汇集2011~2018年上海数学思维真题与练习",
        photo_url:
          "https://upload.fireflybaby.cn/bargain_activity/2b84e3f111e791105f8a0ec9ca19391e.png?imageView2/1/w/350/h/155",
        url:
          "https://xinya.me/study_package_activity_details?id=250&ref=parentclass",
        is_type: "math",
      },
      {
        name: "《分级学古诗》",
        description: "42首精讲古诗、64首泛读古诗",
        photo_url:
          "https://upload.fireflybaby.cn/study_package_activity/d76f41782c669fe866dc1cc3fa594e9e.jpg?imageView2/1/w/350/h/155",
        url:
          "https://xinya.me/study_package_activity_details?id=266&ref=parentclass",
        is_type: "poetry",
      },
      {
        name: "《数学思维训练课(大班&冲刺班)》",
        description: "数学思维训练课大班、幼升小冲刺班",
        photo_url:
          "https://upload.fireflybaby.cn/study_package_activity/0beb2819fe0c266b3969f0cd4ca16b98.png?imageView2/1/w/350/h/155",
        url:
          "https://xinya.me/study_package_activity_details?id=263&ref=parentclass",
        is_type: "math",
      },
      {
        name: "基础计算课",
        description: "20以内进退位加减法、100以内加减及速度闯关练习",
        photo_url:
          "https://upload.fireflybaby.cn/study_package_activity/0c60502e8353c543a87167e2a57da00a.png?imageView2/1/w/350/h/155",
        url:
          "https://xinya.me/study_package_activity_details?id=269&ref=parentclass",
        is_type: "math",
      },
    ],
  },
};

const vko_five_day_activities = {
  title: "面试备考经验",
  day: 5,
  vko_activities: [
    {
      name: "幼升小名校加油站",
      description: "向51位名校家长取经，提升孩子上岸几率",
      url:
        "https://xinya.me/study_package_activity_details?id=262&ref=parentclass",
      button_name: "立即查看",
    },
    {
      name: "经验list",
      description: "心芽经验分享嘉宾备考面试小技巧",
      photo_url: "https://upload.xinya.me/2019ysxvideo/5_2.png",
      button_name: "立即查看",
    },
  ],
};

const YsxActivitySection = ({ ysx_activity }) => {
  return (
    <div className="entrance_course">
      <div className="box_shadow">
        <div className="number_course_list">
          <a
            href={ysx_activity.url}
            style={{ backgroundImage: "url(" + ysx_activity.photo_url + ")" }}
          >
            <h2>{ysx_activity.name}</h2>
            <p>{ysx_activity.description}</p>
            <div className="activity_status">
              <span>查看详情</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

const VkoActivitySection = ({ vko_activity, handleVkoUrlClick }) => {
  return (
    <div
      className="enterance_path"
      onClick={handleVkoUrlClick}
      data-photo-url={vko_activity.photo_url}
      data-url={vko_activity.url}
    >
      <div className="entrance_path_desc">
        <h3>{vko_activity.name}</h3>
        <div className="entrance_desc">{vko_activity.description}</div>
      </div>
      <div className="entrance_sign">
        <span>{vko_activity.button_name}</span>
      </div>
    </div>
  );
};

class RelativeCourse extends Component {
  render() {
    let { math_interested_status, poetry_interested_status } = this.props;
    return math_interested_status != 0 && poetry_interested_status != 0 ? (
      ""
    ) : (
      <div className="relative_tool_container">
        <div className="text">相关课程</div>
        <div className="entrance_course_container">
          {this.props.vko_ysx_activities.ysx_activities.map(
            (ysx_activity, index) => {
              if (math_interested_status === 0) {
                return (
                  ysx_activity.is_type === "math" && (
                    <YsxActivitySection
                      key={index}
                      ysx_activity={ysx_activity}
                    />
                  )
                );
              }

              if (poetry_interested_status === 0) {
                return (
                  ysx_activity.is_type === "poetry" && (
                    <YsxActivitySection
                      key={index}
                      ysx_activity={ysx_activity}
                    />
                  )
                );
              }
            }
          )}
        </div>
      </div>
    );
  }
}

class CourseVkoReport extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      isAlertQR: false,
    };
  }

  handleVkoUrlClick = (e) => {
    e.preventDefault();
    let photo_url = e.currentTarget.getAttribute("data-photo-url");
    let url = e.currentTarget.getAttribute("data-url");
    console.log("photo_url", photo_url);
    console.log("url", url);

    if (photo_url) {
      this.onAlertPhoto(event, true);
      this.setState({
        photoUrl: photo_url,
      });
    }

    if (url) {
      if (UserAgent.isMiniProgram()) {
        onMiniProgram(e, url, {});
      } else if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({ href: url });
      } else {
        window.location.href = url;
      }
    }
  };

  //点击弹图片
  onAlertPhoto = (e, isAlert) => {
    e.preventDefault();
    this.setState({
      isAlertQR: isAlert,
    });
  };

  selectAct = (index) => {
    let obj = {
      one: vko_one_day_activities,
      two: vko_two_day_activities,
      three: vko_three_day_activities,
      four: vko_four_day_activities,
      five: vko_five_day_activities,
    };
    return obj[index];
  };

  render() {
    const {
      day,
      prev_day_url,
      next_day_url,
      study_report,
      study_package_url,
      school_route_needed,
      math_interested_status,
      poetry_interested_status,
    } = this.props;
    const day_index = ["one", "two", "three", "four", "five"][day - 1];

    return (
      <div className="vko_report_container">
        <ReportHeaderDay
          prevDayUrl={prev_day_url}
          day={day}
          nextDayUrl={next_day_url}
        />
        <QuickBack courseListUrl={study_package_url} />
        {study_report === false ? (
          <div className="study_no_finish">
            <p>完成当天课程的学习</p>
            <p>之后才可以查看学习报告哦 ～</p>
          </div>
        ) : (
          <div>
            <div className="vko_report_box">
              <div className="enterance_path_box">
                {day &&
                  this.selectAct(day_index).vko_activities.map(
                    (vko_activity, index) => {
                      if (school_route_needed) {
                        return [
                          vko_activity.is_type === "route" && (
                            <VkoActivitySection
                              key={index}
                              vko_activity={vko_activity}
                              handleVkoUrlClick={this.handleVkoUrlClick}
                            />
                          ),
                          vko_activity.is_type != "route" && (
                            <VkoActivitySection
                              key={vko_activity.name}
                              vko_activity={vko_activity}
                              handleVkoUrlClick={this.handleVkoUrlClick}
                            />
                          ),
                        ];
                      } else {
                        return (
                          vko_activity.is_type != "route" && (
                            <VkoActivitySection
                              key={index}
                              vko_activity={vko_activity}
                              handleVkoUrlClick={this.handleVkoUrlClick}
                            />
                          )
                        );
                      }
                    }
                  )}
              </div>
            </div>

            {this.selectAct(day_index).vko_ysx_activities && (
              <RelativeCourse
                vko_ysx_activities={
                  this.selectAct(day_index).vko_ysx_activities
                }
                math_interested_status={math_interested_status}
                poetry_interested_status={poetry_interested_status}
              />
            )}

            {this.state.isAlertQR && (
              <div className="alert_qr_code">
                <div className="qr_photo_prompt">
                  <h3>长按可保存图片</h3>
                  <div className="prompt_box">
                    <img src={this.state.photoUrl} />
                  </div>
                  <div
                    className="close_dialog"
                    onClick={(e) => this.onAlertPhoto(e, false)}
                  >
                    &times;
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default CourseVkoReport;
