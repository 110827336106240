import React from "react";
import PropTypes from "prop-types";
import Raven from "raven-js";
import ReactMarkdown from "react-markdown";

import { request, csrfHeaders } from "../utils/request";
import {
  reloadPage,
  judgeStringType,
  clipboard,
  awaitWrapper,
} from "../utils/helpers";
import { Dialog, DialogManager } from "../utils/Dialog";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import { StudyPackageCommentModule } from "../study_packages/UnitModeStudyPackage.jsx";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { ToastManager } from "../utils/Toast";
import { isDevelopment, isProduction, GA, GA_new } from "../utils/Env.js";
import UserAgent from "../utils/UserAgent";
import StudyReportInfo from "../utils/study_report_info/StudyReportBar";
import RewardExchangeProgress from "../point_reward/RewardExchangeProgress";
import ContinuousLearningFlag from "./ContinuousLearningFlag.jsx";
import ReportHeaderDay from "../utils/study_report_info/ReportHeaderDay";
import DayStudyExam from "../utils/study_report_info/DayStudyExam";
import { ReportPracticeLearnItem } from "../utils/report_quiz_item/ReportPracticeLearnItem";
import SolveIdea from "../utils/study_report_info/SolveIdea.jsx";
import AudioPlayer from "../utils/audio_player/AudioPlayer";
import QuickBack from "../utils/quick_back/QuickBack";
import {
  SingleCourseBanner,
  MultCourseBanner,
} from "../../components/banner/Banner.jsx";
import WechatShare from "../utils/WechatShare.jsx";

import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_fail_course from "study_package/icon_fail_course.png";

import icon_go_card from "study_package/icon_go_card.png";
import icon_card_fail from "study_package/icon_card_fail.png";
// import icon_card_success from "study_package/icon_card_success.png";
import icon_card_success from "icon_quiz_pass.png";

import alert_dialog_share from "study_package/tips_share.png";

import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";
import arrowRight from "arrow-right.png";
import icon_rule from "study_package/icon_rule.png";

import "./StudyReport.scss";
import "./UserStudyReportQuizList.scss";
import "./StepByStepExercise.scss";

export const PrinterPracticeNote = ({ handlePdfPractice, isPoetry }) => {
  return (
    <div
      className="printer_practice_note"
      onClick={handlePdfPractice.bind(this, true)}
    >
      {isPoetry ? "讲义打印" : " 课后练习题打印"}
    </div>
  );
};

export const PdfPracticeNoteCopy = ({
  media_lessons,
  handlePdfPractice,
  copyUrl,
  unitPdfUrl,
  unitName,
  activityCode,
  isPoetry,
}) => {
  const CopyPdfUrlList = ({ title }) => {
    return (
      <div className="pdf_quiz_lists">
        <div className="left_title">{title}</div>
        {media_lessons.map((media_lesson, index) => {
          const {
            media_course_name,
            name,
            media_lesson_pdf_url,
          } = media_lesson;
          return !media_lesson.media_lesson_pdf_url ? (
            ""
          ) : (
            <div className="quiz_list" key={index}>
              <div className="left_section">
                <div className="practice_title">
                  {isPoetry
                    ? media_course_name
                    : !!media_course_name
                    ? `${activityCode}-${media_course_name}-${name}`
                    : `${activityCode}-${name}`}
                </div>
                {/* <div className="practice_url">{media_lesson_pdf_url}</div> */}
              </div>
              <div
                className="right_section"
                onClick={copyUrl}
                data-index={index}
              >
                复制网址
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const UnitPdfUrlList = ({ title }) => {
    return (
      <div className="pdf_quiz_lists">
        <div className="left_title">{title}</div>
        <div className="quiz_list">
          <div className="left_section">
            <div className="practice_title">
              {isPoetry
                ? `${unitName}单元全部讲义`
                : `${activityCode}-${unitName}单元全部练习题`}
            </div>
            {/* <div className="practice_url">{unitPdfUrl}</div> */}
          </div>
          <div className="right_section" onClick={copyUrl} data-index={`-1`}>
            复制网址
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="pdf_practice_copy">
      <div className="overlay" />
      <div className="dialog_container">
        <div className="title">建议使用电脑浏览器访问以下网址打印</div>
        <div
          className="close_button_right"
          onClick={handlePdfPractice.bind(this, false)}
        >
          &times;
        </div>
        <div className="pdf_quiz_lists_container">
          <CopyPdfUrlList title={`今日${isPoetry ? "讲义" : "练习题"}`} />
          {unitPdfUrl && (
            <UnitPdfUrlList
              title={`《${unitName}》单元全部${
                isPoetry ? "讲义" : "练习题"
              }（一次性打印）`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SuggestAgainStudy = ({ overall_rank, icon_fail_course }) => {
  if (overall_rank == 0 || overall_rank == 1) {
    return (
      <div className="suggest_again_study">
        <img src={icon_fail_course} />
      </div>
    );
  } else {
    return "";
  }
};

const StudyReportName = ({ icon_stars, media_lesson }) => {
  return (
    <div className="study_report_name">
      {"overall_rank" in media_lesson && (
        <img src={icon_stars[media_lesson.overall_rank]} />
      )}
      <div className="report_lesson_names">
        <div className="names">
          {media_lesson.nickname && (
            <div className="nickname">{media_lesson.nickname}</div>
          )}
          <p>
            {media_lesson.media_course_name}：{media_lesson.name}
            {media_lesson.extension ? "【延伸课】" : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

const PracticeLearningSection = ({
  media_lessons,
  practiceLearningClick,
  list_p,
  dayStudyExam,
  idex,
}) => {
  return (
    <div>
      {media_lessons ? (
        media_lessons.map((media_lesson, inx) => (
          <div className="practice_learning_box" key={inx}>
            <div className="practice_head">
              {media_lesson.media_course_name}：{media_lesson.name}
            </div>
            {media_lesson.quizzes.map((quiz, iddx) => (
              <div className="practice_learning" key={iddx}>
                <div
                  className="flex_practice_learning"
                  onClick={practiceLearningClick}
                  data-id={quiz.id}
                >
                  <div className="title_question">
                    <h3>分步练习</h3>
                    <p>{quiz.question}</p>
                  </div>
                  <img src={list_p[quiz.id] ? arrowDown : arrowUp} />
                </div>
                {list_p[quiz.id] ? (
                  ""
                ) : (
                  <div className="practice_learning_content">
                    <div className="quiz_media_lesson">
                      <div className="parent_quizzes">
                        {quiz.question_photo_url && (
                          <div className="parent_question_photo">
                            <img src={quiz.question_photo_url} alt="" />
                          </div>
                        )}
                      </div>
                      {quiz.subquestions.map((subquestion, index) => (
                        <ReportPracticeLearnItem
                          key={index}
                          subquestion={subquestion}
                          iddx={index}
                          totalCount={quiz.subquestions.length}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))
      ) : (
        <DayStudyExam
          key={dayStudyExam.id}
          index={idex}
          dayStudyExam={dayStudyExam}
          key={idex}
        />
      )}
    </div>
  );
};

const CourseReportStateResult = ({
  icon_stars,
  media_lesson,
  baby_name,
  today_quiz_recommend,
}) => {
  return (
    <div className="course_info">
      <div className="report_state">
        <div className="report_state_result">
          {media_lesson.rank >= 0 ? (
            <img src={icon_stars[media_lesson.rank]} />
          ) : media_lesson.media_lesson_url ? (
            <a href={media_lesson.media_lesson_url} className="go_practise">
              去学习
            </a>
          ) : (
            ""
          )}
          <h3>上课状态</h3>
        </div>
        {media_lesson.rank != null ? (
          media_lesson.quiz_answer_count - media_lesson.quiz_count > 0 ? (
            <p className="result_describe">
              上课过程中共{media_lesson.quiz_count}次提问，答错
              {media_lesson.quiz_answer_count - media_lesson.quiz_count}次
            </p>
          ) : (
            /**
             * 这里共N次提问，使用quiz_answer_count的原因是：
             * 如果是拖动进度条跳过部分题目节点，这里是实时获取题目的次数。
             * 例如：如果是直接拖动进度条到结尾，没有一次提问，就是0次提问，否则如果是正常的学习，就是正常的回答题目次数
             */
            <p className="result_describe">
              上课过程中共{media_lesson.quiz_answer_count}
              次提问，全部答对，真棒！
            </p>
          )
        ) : (
          <p className="result_describe">{baby_name}还未完成今日学习</p>
        )}
      </div>
      {!media_lesson.today_quiz_media_lesson_skip && (
        <div className="report_practise">
          <div className="report_state_result">
            {media_lesson.today_quiz_media_lesson_rank == null ? (
              media_lesson.today_quiz_url ? (
                <a href={media_lesson.today_quiz_url} className="go_practise">
                  去测试
                </a>
              ) : (
                ""
              )
            ) : (
              <img
                src={icon_stars[media_lesson.today_quiz_media_lesson_rank]}
              />
            )}
            <h3>随堂测试</h3>
          </div>
          <p className="result_describe">{today_quiz_recommend}</p>
        </div>
      )}
    </div>
  );
};

const ReportKnowledgeDescribe = ({
  knowledgeDescClick,
  index,
  list_k,
  media_lesson,
  mediaLessonLength,
}) => {
  let fold = mediaLessonLength <= 1 ? list_k : !list_k;
  return (
    <div className="report_knowledge_describe">
      <div
        className="knowledge_title"
        onClick={knowledgeDescClick}
        data-index={index}
      >
        <img src={fold ? arrowDown : arrowUp} />
        <h3>知识点讲解</h3>
      </div>
      {fold ? (
        ""
      ) : (
        <div className="knowledge_desc_content">
          <ReactMarkdown
            source={media_lesson.media_conclusion}
            escapeHtml={false}
          />
          {media_lesson.recommend_media_lesson && (
            <AdMediaLessonRecommend
              recommendMediaLesson={media_lesson.recommend_media_lesson}
            />
          )}
        </div>
      )}
    </div>
  );
};

const AdMediaLessonRecommend = ({ recommendMediaLesson }) => {
  const {
    photo_url,
    recommend_study_package_name,
    url,
    bought,
  } = recommendMediaLesson;
  return (
    <div className="ad_media_lesson_recommed">
      <div className="relative_knowledge_title">相关知识点讲解：</div>
      <a className="lesson_recommed_top" href={url}>
        <img src={photo_url} alt="" />
        <div className="lesson_time_guide">
          <div className="play_flag">
            <span>5分钟视频精讲</span>{" "}
          </div>
        </div>
      </a>
      {
        <div className="lesson_knowledge_description">
          <p>{`知识点内容属于《${recommend_study_package_name}》`}</p>
          {!bought && <p>需要报名课程后才能观看</p>}
        </div>
      }
    </div>
  );
};

const PracticeResult = ({
  media_lesson,
  list_f,
  practiceResultClick,
  index,
  practiceQuizzesLength,
  practiceFaultQuizCount,
  mediaLessonLength,
}) => {
  let fold = mediaLessonLength <= 1 ? list_f : !list_f;
  if (media_lesson.quizzes.length > 0) {
    return (
      <div className="practice_result">
        <div
          className="practice_title"
          onClick={practiceResultClick}
          data-index={index}
        >
          <img src={fold ? arrowDown : arrowUp} />
          <h3>
            随堂测试
            {practiceQuizzesLength && (
              <span>
                {`（共${practiceQuizzesLength}题/${
                  practiceFaultQuizCount.length != 0
                    ? `错${practiceFaultQuizCount.length}题，已同步至错题本`
                    : "全对"
                }）`}
              </span>
            )}
          </h3>
        </div>
        {fold ? (
          ""
        ) : (
          <div className="practice_result_content">
            <div className="practice_report_box">
              {media_lesson.quizzes.map((quiz, index) => (
                <UserStudyReportQuizList
                  key={quiz.id}
                  quiz={quiz}
                  index={index}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return "";
  }
};

const ExerciseQuizzesPractice = ({
  media_lesson,
  externalExerciseClick,
  index,
  list_e,
  exerciseQuizzesLength,
  exerciseFaultQuizCount,
  mediaLessonLength,
}) => {
  let fold = mediaLessonLength <= 1 ? list_e : !list_e;
  return !media_lesson.exercise_quizzes ? (
    !media_lesson.exercise_quiz_url ? (
      ""
    ) : (
      <div className="practice_result">
        <div className="practice_title">
          {media_lesson.exercise_quiz_url ? (
            <a href={media_lesson.exercise_quiz_url} className="go_practise">
              去练习
            </a>
          ) : (
            ""
          )}
          <h3>
            课外练习
            {exerciseQuizzesLength && (
              <span>
                {`（共${exerciseQuizzesLength}题/${
                  exerciseFaultQuizCount.length != 0
                    ? `错${exerciseFaultQuizCount.length}题，已同步至错题本`
                    : "全对"
                }）`}
              </span>
            )}
          </h3>
        </div>
      </div>
    )
  ) : (
    <div className="practice_result">
      <div
        className="practice_title"
        onClick={externalExerciseClick}
        data-index={index}
      >
        <img src={fold ? arrowDown : arrowUp} />
        <h3>
          课外练习
          {exerciseQuizzesLength && (
            <span>
              {`（共${exerciseQuizzesLength}题/${
                exerciseFaultQuizCount.length != 0
                  ? `错${exerciseFaultQuizCount.length}题，已同步至错题本`
                  : "全对"
              }）`}
            </span>
          )}
        </h3>
      </div>
      {fold ? (
        ""
      ) : (
        <div className="practice_result_content">
          <div className="practice_report_box">
            {media_lesson.exercise_quizzes.map((quiz, index) => (
              <UserStudyReportQuizList
                key={quiz.id}
                quiz={quiz}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const YourSelfAnswer = ({ quiz, correct_url_judge }) => {
  return (
    <p>
      你的答案：
      {correct_url_judge ? (
        <img src={quiz.correct_url} />
      ) : (
        judgeStringType(quiz.correct_answer)
      )}
      （正确）
    </p>
  );
};
export const CorrectAnswer = ({ quiz, correct_url_judge }) => {
  return (
    <p>
      正确答案：
      {correct_url_judge ? (
        <img src={quiz.correct_url} />
      ) : (
        judgeStringType(quiz.correct_answer)
      )}
    </p>
  );
};

export const CompareAnswer = ({ quiz, correct_url_judge, error_url_judge }) => {
  const imgLabel = <img src={quiz.error_url} />;
  const textLable = `${quiz.error_answer}`;
  const singleAnswerCompare = (error_param, labelParam) => {
    return (
      <React.Fragment>
        {error_param == "" ? (
          <p>答题超时</p>
        ) : (
          <p className="first_p_label">
            你的答案：
            {labelParam}
            <span>（错误）</span>
          </p>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {error_url_judge
        ? singleAnswerCompare(quiz.error_url, imgLabel)
        : singleAnswerCompare(quiz.error_answer, textLable)}
      <CorrectAnswer quiz={quiz} correct_url_judge={correct_url_judge} />
    </React.Fragment>
  );
};

export const QuestionAndPhoto = ({ quiz }) => {
  return (
    <div className="practice_report_info">
      <div
        className="option_title"
        style={{
          padding: Array.isArray(quiz.subquestions) ? "0" : null,
        }}
      >
        <span>{quiz.question}</span>
      </div>
      {quiz.question_photo_url != null && (
        <div className="question_pic">
          <img src={quiz.question_photo_url} />
        </div>
      )}
    </div>
  );
};

export const AnswerCorrectOrFailText = ({ answer_status }) => {
  return answer_status ? (
    <div className="answer_correct">回答正确</div>
  ) : (
    <div className="answer_error">回答错误</div>
  );
};

// 拼音语音课·正确语音答案
export const CorrectPinyinVoice = ({ quiz, correct_audio_url_judge, text }) => {
  return (
    <div
      className="pinyin_voice"
      style={{
        padding: "0",
        margin: "0",
      }}
    >
      {correct_audio_url_judge ? (
        <AudioPlayer
          type="pinyin"
          name={text}
          entireRecordUrl={quiz.correct_audio_url}
        />
      ) : (
        quiz.error_answer?.includes("mp3") && (
          <AudioPlayer
            type="pinyin"
            name={text}
            entireRecordUrl={quiz.error_answer}
          />
        )
      )}
    </div>
  );
};

// 拼音语音课·错误语音答案
export const SinglePinyinAudioAnswerCompare = ({
  quiz,
  text,
  err_audio_status,
  error_audio_url_judge,
}) => {
  return (
    <React.Fragment>
      {quiz.error_audio_url == "" ? (
        <p>答题超时</p>
      ) : (
        <div
          className="pinyin_voice"
          style={{
            padding: "0",
            margin: "0",
          }}
        >
          {error_audio_url_judge && (
            <AudioPlayer
              errAudioStatus={err_audio_status}
              type="pinyin"
              name={text}
              entireRecordUrl={quiz.error_audio_url}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

// 拼音语音课·错误/正确语音答案对比
export const ComparePinyinVoice = ({
  quiz,
  correct_audio_url_judge,
  error_audio_url_judge,
}) => {
  // 有错误和正确音频比较
  return (
    <React.Fragment>
      <SinglePinyinAudioAnswerCompare
        quiz={quiz}
        text="你的答案"
        err_audio_status="err_audio_status"
        error_audio_url_judge={error_audio_url_judge}
      />
      <CorrectPinyinVoice
        quiz={quiz}
        correct_audio_url_judge={correct_audio_url_judge}
        text="正确答案"
      />
    </React.Fragment>
  );
};

// 答对/答错显示
export const UserStudyReportQuizList = ({ quiz, quizType }) => {
  const correct_url_judge = quiz.correct_url != "" && quiz.correct_url != null;
  const error_url_judge = quiz.error_url != "" && quiz.error_url != null;

  const correct_audio_url_judge =
    quiz.correct_audio_url != "" && quiz.correct_audio_url != null;
  const error_audio_url_judge =
    quiz.error_audio_url != "" && quiz.error_audio_url != null;

  const answer_status =
    judgeStringType(quiz.correct_answer) ==
      judgeStringType(quiz.error_answer) &&
    quiz.correct_url == quiz.error_url &&
    quiz.correct_audio_url == quiz.error_audio_url;

  let answerBorderClass;
  if (correct_audio_url_judge || quiz.error_answer?.includes("mp3")) {
    answerBorderClass = "";
  } else {
    if (answer_status) {
      answerBorderClass = "correct_answer";
    } else {
      answerBorderClass = "fail_answer";
    }
  }
  return (
    <div className="practice_study_report_content">
      {Array.isArray(quiz.subquestions) ||
      quiz.error_answer?.includes("mp3") ? (
        ""
      ) : (
        <AnswerCorrectOrFailText answer_status={answer_status} />
      )}
      {quizType != "pinyin" && <QuestionAndPhoto quiz={quiz} />}
      {Array.isArray(quiz.subquestions) ? (
        // 分步练习
        <StepSubquestionsExercise
          subquestions={quiz.subquestions}
          subquestionsLength={quiz.subquestions.length}
        />
      ) : (
        <div
          className={`${answerBorderClass} quiz_answer`}
          style={{
            padding:
              correct_audio_url_judge || quiz.error_answer?.includes("mp3")
                ? "10px 0"
                : null,
          }}
        >
          {error_audio_url_judge ||
          correct_audio_url_judge ||
          quiz.error_answer?.includes("mp3") ? (
            // 有音频
            <React.Fragment>
              {answer_status || quiz.error_answer?.includes("mp3") ? (
                <CorrectPinyinVoice
                  quiz={quiz}
                  correct_audio_url_judge={correct_audio_url_judge}
                  text="你的答案"
                />
              ) : (
                <ComparePinyinVoice
                  quiz={quiz}
                  correct_audio_url_judge={correct_audio_url_judge}
                  error_audio_url_judge={error_audio_url_judge}
                />
              )}
            </React.Fragment>
          ) : (
            // 无音频（图片和文字）
            <React.Fragment>
              {answer_status ? (
                <YourSelfAnswer
                  quiz={quiz}
                  correct_url_judge={correct_url_judge}
                />
              ) : (
                <CompareAnswer
                  quiz={quiz}
                  correct_url_judge={correct_url_judge}
                  error_url_judge={error_url_judge}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
      {quiz.hint && <SolveIdea hint={quiz.hint} />}
    </div>
  );
};

// 分步练习中的答对/答错显示
export const StepSubquestionsExercise = ({
  subquestions,
  subquestionsLength,
}) => {
  const option_wrapper = (options) => {
    return (
      <div className="pinyin_option_wrapper">
        {options.map((option, ix) => (
          <div className="options" key={ix}>
            {option.photo ? <img src={option.photo.url} /> : option.content}
          </div>
        ))}
      </div>
    );
  };

  // 答题·分步音频答案
  const stepCorrectAudioVoice = (audio) => {
    return (
      <div
        className="pinyin_voice"
        style={{
          padding: "0",
          margin: "0",
        }}
      >
        <AudioPlayer
          type="pinyin"
          name={`你的答案`}
          entireRecordUrl={audio.url}
        />
      </div>
    );
  };

  // 答题·文字/图片答案
  const stepCorrectAnswer = (sub_question) => {
    // 选择题 · 图片、文字
    const chooseOptionPhoto = (choose_option) => {
      return choose_option.photo ? (
        <img src={choose_option.photo.url} />
      ) : (
        choose_option.content
      );
    };
    // 填空题
    const inputAnswer = (sub_question) => {
      return sub_question.input_answer ? sub_question.input_answer : "";
    };

    return sub_question.choose_option ? (
      // 选择题和音频题
      sub_question.choose_option.audio ? (
        stepCorrectAudioVoice(sub_question.choose_option.audio)
      ) : (
        <div className="sub_question_answer">
          <p>
            你的答案：
            {chooseOptionPhoto(sub_question.choose_option)}
            （正确）
          </p>
        </div>
      )
    ) : (
      // 填空题
      <div className="sub_question_answer">
        <p>
          你的答案：
          {inputAnswer(sub_question)}
          （正确）
        </p>
      </div>
    );
  };
  return subquestions.map((sub_question, index) => (
    <div className="step_subquestions_exercise" key={index}>
      <div className="left_step_index">{`${
        index + 1
      }/${subquestionsLength}`}</div>
      <div className="right_pinyin_content">
        {sub_question.question && (
          <div className="sub_question_title">{sub_question.question}</div>
        )}
        {sub_question.question_photo_url && (
          <img
            className="step_photo_url"
            src={sub_question.question_photo_url}
            alt=""
          />
        )}
        {/* {option_wrapper(xxx.options)} */}
        {stepCorrectAnswer(sub_question)}
      </div>
    </div>
  ));
};

const ReleteSchool = ({
  media_lesson,
  schoolsDescClick,
  index,
  list_s,
  mediaLessonLength,
}) => {
  let fold = mediaLessonLength <= 1 ? list_s : !list_s;
  if (media_lesson.schools.length > 0) {
    return (
      <div className="report_relete_school">
        <div
          className="celete_school_title"
          onClick={schoolsDescClick}
          data-index={index}
        >
          <img src={fold ? arrowDown : arrowUp} />
          <h3>在面试中考过的学校</h3>
        </div>
        {fold ? (
          ""
        ) : (
          <ul>
            {media_lesson.schools.map((school) => (
              <li key={school.id}>
                <a href={school.url}>
                  <span>{school.name}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  } else {
    return "";
  }
};

const CheckinSection = ({
  poster_url,
  checkin_note_url,
  items,
  checkin_days,
  rewards,
  receiveAwardRequest,
  shrinkClick,
  shrink,
}) => {
  return (
    <div className="study_card_box">
      <div
        className="card_head_list"
        style={{ borderRadius: poster_url ? "5px" : null }}
      >
        <div className="card_status_day">
          {poster_url ? (
            <p>连续打卡33天，奖励现金330元！</p>
          ) : (
            <p>
              已打卡<i>{checkin_days}</i>天
            </p>
          )}
          {items}
        </div>
      </div>
      {poster_url
        ? !shrink && (
            <div className="detail_rule">
              <img src={icon_rule} alt="" />
              <a href={checkin_note_url}>详细规则</a>
            </div>
          )
        : !shrink && (
            <div className="award_option_list">
              <ul>
                {!rewards
                  ? ""
                  : rewards.map((reward) => (
                      <li key={reward.id}>
                        <h3>{reward.name}</h3>
                        <div className="achieve_award">
                          {reward.status == 3 ? (
                            <span className="already_receive">已拥有</span>
                          ) : (
                            ""
                          )}
                          {reward.status == 2 ? (
                            <span className="already_receive">已领取</span>
                          ) : (
                            ""
                          )}
                          {reward.status == 1 ? (
                            <span
                              className="go_award"
                              onClick={receiveAwardRequest.bind(this, reward)}
                            >
                              领取
                            </span>
                          ) : (
                            ""
                          )}
                          {reward.status == 0 ? (
                            <span className="no_award_text">
                              打卡{reward.days}天可领取
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    ))}
              </ul>
              <div className="detail_rule">
                <img src={icon_rule} alt="" />
                <a href={checkin_note_url}>详细规则</a>
              </div>
            </div>
          )}
      <div className="accordion" onClick={shrinkClick.bind(this)}>
        {shrink ? "奖励领取 & 规则说明" : "收起"}
        <img src={shrink ? arrowDown : arrowUp} alt="" />
      </div>
    </div>
  );
};

const StudyNoFinish = ({ study_inprogress }) => {
  if (study_inprogress) {
    return "";
  } else {
    return (
      <div className="study_no_finish">
        完成当天课程的学习后，才可以查看学习报告哦 ～
      </div>
    );
  }
};

class StudyReportList extends React.Component {
  constructor(props) {
    super(props);
    const { media_lesson } = this.props;

    var today_quiz_media_lesson_rank =
      media_lesson.today_quiz_media_lesson_rank;

    // "课外练习" 总题数
    const exerciseQuizzesLength =
      media_lesson.exercise_quizzes && media_lesson.exercise_quizzes.length;
    // 计算: "课外练习" 所有的答题对错情况，true： 正确， false：错误
    const exerciseQuizzesTotalCount = this.calcQuizzesTotalAnswer(
      media_lesson.exercise_quizzes || []
    );
    // 计算 "课外练习" 所有答错的题目数量
    const exerciseFaultQuizCount = this.filterFaultCount(
      exerciseQuizzesTotalCount
    );

    // "随堂测试" 总题数
    const practiceQuizzesLength =
      media_lesson.quizzes && media_lesson.quizzes.length;
    // 计算: "随堂测试" 所有的答题对错情况，true： 正确， false：错误
    const practiceQuizzesTotalCount = this.calcQuizzesTotalAnswer(
      media_lesson.quizzes || []
    );
    // 计算 "随堂测试" 所有答错的题目数量
    const practiceFaultQuizCount = this.filterFaultCount(
      practiceQuizzesTotalCount
    );

    // console.log("课外练习：", exerciseQuizzesLength, exerciseQuizzesTotalCount, exerciseFaultQuizCount);
    // console.log("随堂测试====：", practiceQuizzesLength, practiceQuizzesTotalCount, practiceFaultQuizCount);

    this.state = {
      today_quiz_media_lesson_rank,
      today_quiz_url: this.props.today_quiz_url,
      exerciseQuizzesLength,
      exerciseFaultQuizCount,
      practiceQuizzesLength,
      practiceFaultQuizCount,
    };
  }

  componentDidMount() {
    var today_quiz_recommend;
    let { media_lesson, baby_name } = this.props;

    if (media_lesson.today_quiz_media_lesson_rank == null) {
      this.setState({
        today_quiz_recommend: baby_name + "还未完成随堂测试",
      });
    } else if (media_lesson.today_quiz_media_lesson_rank == 3) {
      this.setState({
        today_quiz_recommend: "1次就能通过随堂测试，真棒！",
      });
    } else if (media_lesson.today_quiz_media_lesson_rank == 0) {
      let text =
        media_lesson.exercise_quizzes_count > 0 ? "，建议做“课外练习”" : "";
      this.setState({
        today_quiz_recommend: "未能通过随堂测试" + text,
      });
    } else {
      let text =
        media_lesson.exercise_quizzes_count > 0 ? "，建议做“课外练习”" : "";
      this.setState({
        today_quiz_recommend: "还差一点点，继续努力" + text,
      });
    }
  }

  calcQuizzesTotalAnswer = (quizzes) =>
    quizzes.map(
      (quiz) =>
        judgeStringType(quiz.correct_answer) ==
          judgeStringType(quiz.error_answer) &&
        quiz.correct_url == quiz.error_url
    );

  filterFaultCount = (total_quizzes) =>
    total_quizzes.filter((error_quiz) => error_quiz == false);

  render() {
    const { media_lesson, media_lesson_length } = this.props;
    const icon_stars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

    const {
      today_quiz_recommend,
      today_quiz_url,
      today_quiz_media_lesson_rank,
      exerciseQuizzesLength,
      exerciseFaultQuizCount,
      practiceQuizzesLength,
      practiceFaultQuizCount,
    } = this.state;

    return (
      <div className="study_report_list">
        <SuggestAgainStudy
          icon_fail_course={icon_fail_course}
          overall_rank={media_lesson.overall_rank}
        />
        <StudyReportName icon_stars={icon_stars} media_lesson={media_lesson} />
        <div className="study_report_content">
          {/* 学习状况 */}
          <CourseReportStateResult
            icon_stars={icon_stars}
            media_lesson={media_lesson}
            baby_name={this.props.baby_name}
            today_quiz_recommend={today_quiz_recommend}
          />

          {/* 知识点讲解 */}
          <ReportKnowledgeDescribe
            knowledgeDescClick={this.props.knowledgeDescClick}
            index={this.props.index}
            list_k={this.props.list_k}
            media_lesson={media_lesson}
            mediaLessonLength={media_lesson_length}
          />
          {
            // 随堂测试
            !media_lesson.quizzes ? (
              ""
            ) : (
              <PracticeResult
                media_lesson={media_lesson}
                list_f={this.props.list_f}
                practiceResultClick={this.props.practiceResultClick}
                index={this.props.index}
                practiceQuizzesLength={practiceQuizzesLength}
                practiceFaultQuizCount={practiceFaultQuizCount}
                mediaLessonLength={media_lesson_length}
              />
            )
          }

          {/* 课外练习 */}
          <ExerciseQuizzesPractice
            media_lesson={media_lesson}
            externalExerciseClick={this.props.externalExerciseClick}
            index={this.props.index}
            list_e={this.props.list_e}
            exerciseQuizzesLength={exerciseQuizzesLength}
            exerciseFaultQuizCount={exerciseFaultQuizCount}
            mediaLessonLength={media_lesson_length}
          />

          {/* 在面试中考过的学校 */}
          <ReleteSchool
            media_lesson={media_lesson}
            schoolsDescClick={this.props.schoolsDescClick}
            index={this.props.index}
            list_s={this.props.list_s}
            mediaLessonLength={media_lesson_length}
          />
        </div>
      </div>
    );
  }
}

const AdCourseRecommendModule = ({ babyName }) => {
  const unit_arrays = [
    {
      photo_url:
        "https://upload.fireflybaby.cn/study_package_activity/0beb2819fe0c266b3969f0cd4ca16b98.png",
      title: "数学思维训练课T3(大班）",
      brief: "适合：已掌握10以内加减法的孩子",
      date: "45天·86节课",
      description: "考点全覆盖，难点全吃透",
      url: `https://xinya.me/study_package_activity_details?id=364`,
    },
    {
      photo_url:
        "https://upload.fireflybaby.cn/study_package_activity/8264e9d4657561e49c0be7950708e508.png",
      title: "基础计算课C2(20以内加减法）",
      brief: "适合：已掌握10以内加减法的孩子",
      date: "18天·14节课",
      description: "让孩子理解计算背后的原理法则",
      url: `https://xinya.me/study_package_activity_details?id=347`,
    },
  ];
  return (
    <div className="ad_course_recommend_module">
      <div className="top_title_flag">
        <div className="name">与{babyName}同龄的孩子都在学</div>
      </div>
      <ul>
        {unit_arrays.map((unit, index) => (
          <li key={index}>
            <a
              href={unit.url}
              style={{
                backgroundImage: `url(${unit.photo_url})`,
              }}
            >
              <h2>{unit.title}</h2>
              <p>{unit.brief}</p>
              <p>{unit.date}</p>
              <p>{unit.description}</p>
            </a>
          </li>
        ))}
      </ul>
      <a href="https://xinya.me/?ref=report" className="find_more_course">
        查看更多课程
      </a>
    </div>
  );
};
class StudyReport extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props, "===================");

    let objR = new Object();
    !this.props.day_study_exams
      ? ""
      : this.props.day_study_exams.forEach(function (item) {
          !item.quizzes
            ? ""
            : item.quizzes.forEach(function (im) {
                objR[im.id] = true;
              });
        });

    let objP = new Object();
    // 问卷：本地调试隐藏
    this.props.day_study_exams.forEach(function (item) {
      !item.media_lessons
        ? ""
        : item.media_lessons.forEach(function (im) {
            im.quizzes.forEach(function (m) {
              objP[m.id] = true;
            });
          });
    });

    this.state = {
      createRecommendRequestUrl: this.props.create_recommend_request_url,
      study_report: this.props.study_report,
      day_study_exams: this.props.day_study_exams,
      checkin_days: this.props.checkin_days,
      day: this.props.day,
      get_reward_url: this.props.get_reward_url,
      do_checkin_url: this.props.do_checkin_url,
      prev_day_url: this.props.prev_day_url,
      next_day_url: this.props.next_day_url,
      objK: this.props.media_lessons
        ? Array(this.props.media_lessons.length).fill(false)
        : "",
      objF: this.props.media_lessons
        ? Array(this.props.media_lessons.length).fill(false)
        : "",
      objS: this.props.media_lessons
        ? Array(this.props.media_lessons.length).fill(false)
        : "",
      objE: this.props.media_lessons
        ? Array(this.props.media_lessons.length).fill(false)
        : "",
      isAlertQR: false,
      list: objR,
      list_p: objP,
      pdfPracticeUrlCopy: false,
      shrink: true,
    };
  }

  // 以练代学
  practiceLearningClick = (e) => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("data-id");
    this.state.list_p[id] = !this.state.list_p[id];
    this.setState({
      list_p: this.state.list_p,
    });
  };

  // 知识点讲解
  knowledgeDescClick = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    this.state.objK[idx] = !this.state.objK[idx];
    this.setState({
      objK: this.state.objK,
    });
  };

  //随堂测试
  practiceResultClick = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    this.state.objF[idx] = !this.state.objF[idx];
    this.setState({
      objF: this.state.objF,
    });
  };

  //课外练习
  externalExerciseClick = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    this.state.objE[idx] = !this.state.objE[idx];
    this.setState({
      objE: this.state.objE,
    });
  };

  //习题面试考过的学校
  schoolsDescClick = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    this.state.objS[idx] = !this.state.objS[idx];
    this.setState({
      objS: this.state.objS,
    });
  };

  showDialogWeChatCheckIn(text) {
    const buttons = [
      {
        label: "确定",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>{text}</Dialog>
    );
  }

  posterDialog = () => {
    // 打卡海报生成
    if (this.props.poster_url) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          free: "invite_reward_money",
          reportCardRuleUrl: this.props.checkin_note_url,
        });
      }
      this.sharePosterProxy.open();
    }
  };

  // 点击去打卡
  onAlert = (isAlert) => {
    if (isProduction()) {
      if (typeof ga === "function") {
        ga(
          "send",
          "event",
          "Courses",
          "checkIn",
          this.props.study_package_activity_id
        );
      }
    }
    // 浏览器和 Android 2.1.6 版本之前的 App 不能打卡
    if (UserAgent.isWebBrowser()) {
      this.showDialogWeChatCheckIn.call(
        this,
        "请在『心芽学堂』公众号的学习报告通知里打开进行打卡"
      );
      return;
    }

    if (UserAgent.isAndroid() && this.props.is_old_android) {
      this.showDialogWeChatCheckIn.call(
        this,
        "当前版本暂不支持打卡，请前往应用市场升级到最新版App"
      );
      return;
    }

    if (UserAgent.isNativeClient()) {
      const share_url = this.props.share.share_url;
      const share_image_url = this.props.share.share_image_url;
      const share_title = this.props.share.share_title;
      const share_desc = this.props.share.share_desc;

      const checkin_status = this.props.checkin_status;
      const do_checkin_url = this.state.do_checkin_url;
      const day = this.state.day;

      XinYaNativeBridge.share(
        {
          url: share_url,
          title: share_title,
          description: share_desc,
          image: share_image_url,
          channels: ["wechatTimeline"],
        },
        function (success) {
          if (checkin_status == "checkin") {
            request({
              url: do_checkin_url,
              method: "POST",
              headers: csrfHeaders,
              data: {
                day: day,
              },
            })
              .then((rsp) => {
                // alert("分享成功")
                // reloadPage()
                this.toast = ToastManager.showSuccess("分享成功");
                setTimeout(() => {
                  reloadPage();
                }, 2000);
              })
              .catch((err) => {
                request({
                  url: do_checkin_url,
                  method: "POST",
                  headers: csrfHeaders,
                  data: {
                    day: day,
                  },
                }).then((rsp) => {
                  // alert("分享成功")
                  // reloadPage()
                  this.toast = ToastManager.showSuccess("分享成功");
                  setTimeout(() => {
                    reloadPage();
                  }, 2000);
                });
              });
          }
        }
      );
    }

    // 仅在微信中显示分享提示
    if (UserAgent.isWechat()) {
      // 如果是线上 小程序环境也会进入这个条件
      // 线上小程序会判断UserAgent.isWechat() true

      if (isProduction()) {
        // console.log("isProduction");
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            // console.log("miniProgram true getEnv");
            this.showDialogWeChatCheckIn.call(this);
          } else {
            // console.log("miniProgram false getEnv");
            this.props.poster_url
              ? this.posterDialog.call(this)
              : this.setState({
                  isAlertQR: isAlert,
                });
          }
        });
      } else {
        this.props.poster_url
          ? this.posterDialog.call(this)
          : this.setState({
              isAlertQR: isAlert,
            });
      }
      return;
    }

    wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        // console.log("miniProgram getEnv");
        this.showDialogWeChatCheckIn.call(this);
      }
    });
  };

  componentDidMount() {
    if (this.props.popup_poster === true) {
      this.posterDialog();
    }
  }

  receiveAwardRequest = (reward) => {
    request({
      url: this.state.get_reward_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        checkin_reward_id: reward.id,
      },
    })
      .then((rsp) => {
        const data = rsp.data;
        if (data.error_code) {
          alert(data.error_msg);
          return;
        }
        this.setState(
          {
            status: data.status,
          },
          () => {
            // console.log(data);
          }
        );
        this.GetSuccessReward(reward, data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  GetSuccessReward = (reward, data) => {
    const buttons = [
      {
        label: data.url ? "填写地址" : "确定",
        onClick: () => {
          if (data.url) {
            window.location.href = data.url;
          } else {
            this.confirmDialog.close();
            reloadPage();
          }
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        您已成功领取{reward.name}，
        {data.url ? "请前往填写快递地址" : "请注意『心芽学堂』公众号通知"}
      </Dialog>
    );
  };

  handlePdfPractice = (boolState) => {
    this.setState({
      pdfPracticeUrlCopy: boolState,
    });
  };

  handleCopyUrl = (e) => {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    // console.log('parentIndex: ', parentIndex);

    let currentClickUrl =
      parentIndex == -1
        ? this.props.unit_pdf_url
        : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  };

  shrinkClick = () => {
    this.setState({ shrink: !this.state.shrink });
  };

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });

    GA("shortcut", "reportComment", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "report_comment",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
  };

  // 错题本
  // 错题本
  handleWrongBookClick = () => {
    // console.log(
    //   "GA_new("shortcut", 26, this.props.study_package_activity_id, () => {",
    //   this.props
    // );
    GA("shortcut", "report_wrong_quiz", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "report_wrong_quiz",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = this.props.get_wrong_quiz_sets_url;
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  onBackIconClick = () => {
    GA("shortcut", "reportback", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "report_back",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = this.props.study_package_url;
    sessionStorage.clear();
  };

  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(({ bonus, had_joined_groupon, remaining_shopping_group_count }) => {
        new SharePosterProxy({
          // posterUrl: this.props.poster_url,
          // posterImgSrc: this.props.poster_img_src,
          posterTemplate,
          posterCreation,
          mode: "front_end",
          free:
            had_joined_groupon && remaining_shopping_group_count > 0
              ? "in_or_open_shopping_group"
              : "none_shopping_group",
          bonus: bonus,
          sale_mode: "shopping_group",
          remaining_shopping_group_count,
        })
          .open({
            isShowToast: false,
          })
          .then(() => {
            toast.cancel();
          });
      })
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
    GA("shortcut", "reportComment", this.props.study_package_activity_id);

    GA_new(
      "shortcut",
      "report_comment",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    // GA("shortcut", "reportPoster", study_package_activity_id);
  };

  render() {
    const {
      trial_unlock_mode,
      questionnaire_tip,
      questionnaire_url,
      media_lessons,
      baby_name,
      study_package_url,
      trial,
      unit_pdf_url,
      unit_name,
      study_package_activity_code,
      continue_study_reward,
      course_type,
      is_user_study_package,
      get_wrong_quiz_sets_url,
      reward_commodity,
      total_point,
      create_reward_exchange_url,
      study_package_activity_id,
    } = this.props;
    const {
      list,
      list_p,
      day,
      study_report,
      day_study_exams,
      prev_day_url,
      next_day_url,
      pdfPracticeUrlCopy,
      shrink,
      writeEvaluateDialogState,
      createRecommendRequestUrl,
    } = this.state;

    let items = "";
    if (study_report == false) {
      items = <span>请先完成今日学习</span>;
    } else {
      switch (this.props.checkin_status) {
        case "fail": // 本期活动打卡结束
          items = <span>本期打卡活动结束</span>;
          break;
        case "checkin": // 去打卡
          items = (
            <span className="go_checkin" onClick={() => this.onAlert(true)}>
              去打卡
            </span>
          );
          break;
        case "already_checkin": //今天打了以前一天的卡
          items = <span>今日已打卡</span>;
          break;
        case "success": //今日打卡成功
          items = <img src={icon_card_success} />;
          break;
      }
    }

    let mediaLessonExit = media_lessons && media_lessons.length > 0;

    // 判断 media_lesson 中是否有至少一个 pdf_url (状态: true/false)
    let lessonPdfUrlBool =
      mediaLessonExit &&
      media_lessons
        .map((lesson) => lesson.media_lesson_pdf_url)
        .some((url) => typeof url == "string");

    // console.log("lessonPdfUrlBool: ", lessonPdfUrlBool);
    return (
      <div className="comp-study-report">
        <div className="practice_calendar_date">
          <div className="example_date">
            {!trial && (
              <ReportHeaderDay
                prevDayUrl={prev_day_url}
                day={day}
                nextDayUrl={next_day_url}
                isUserStudyPackage={is_user_study_package}
              />
            )}

            {
              UserAgent.isNativeClient() ? (
                <RightFloatIcons
                  isShowWrongBook={!!get_wrong_quiz_sets_url}
                  onWongBookClick={this.handleWrongBookClick}
                  isShowMakeComment={
                    this.props.comment_state === "uncommented" &&
                    !!createRecommendRequestUrl
                  }
                  onMakeCommentClick={this.getWriteEvaluateStatus}
                  // isShowRedMoney={!!this.props.bonus}
                  // onRedMoneyClick={this.onInvitationIconClick}
                  isShowBack={true}
                  onBackClick={this.onBackIconClick}
                />
              ) : (
                <RightFloatIcons
                  isShowWrongBook={!!get_wrong_quiz_sets_url}
                  onWongBookClick={this.handleWrongBookClick}
                  isShowMakeComment={
                    this.props.comment_state === "uncommented" &&
                    !!createRecommendRequestUrl
                  }
                  onMakeCommentClick={this.getWriteEvaluateStatus}
                  isShowRedMoney={!!this.props.bonus}
                  onRedMoneyClick={this.onInvitationIconClick}
                  isShowBack={true}
                  onBackClick={this.onBackIconClick}
                />
              )
              // // 这里组件内做了判断, 只允许在app(客户端)才显示“返回课程页”
              // <QuickBack courseListUrl={study_package_url} />
            }
            {!!writeEvaluateDialogState && (
              <StudyPackageCommentModule
                create_recommend_request_url={createRecommendRequestUrl}
                comment_target={this.props.comment_target}
                current_user_comment={this.props.current_user_comment}
                bought_phase={this.props.bought_phase}
                promotion={this.props.promotion}
                rate={this.props.rate}
                name={this.props.study_package_name}
                hideDialog={this.hideWriteEvaluateState}
                getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
              />
            )}
            {trial_unlock_mode
              ? questionnaire_url && (
                  <div className="new_questionnaire_tips">
                    <div className="new_text">课程体验满意度小调研</div>
                    <div className="new_btn">
                      <a href={questionnaire_url}>开始</a>
                    </div>
                  </div>
                )
              : questionnaire_tip && (
                  <div className="questionnaire-tips">
                    <div className="q-text">活跃用户小调研</div>
                    <div className="q-btn">
                      <a href={questionnaire_url}>开始</a>
                    </div>
                  </div>
                )}

            {
              // 练习题pdf打印 (入口)
              (lessonPdfUrlBool || unit_pdf_url) && (
                <PrinterPracticeNote
                  handlePdfPractice={this.handlePdfPractice}
                />
              )
            }

            {pdfPracticeUrlCopy && (
              <PdfPracticeNoteCopy
                media_lessons={media_lessons}
                handlePdfPractice={this.handlePdfPractice}
                copyUrl={this.handleCopyUrl}
                unitPdfUrl={unit_pdf_url}
                unitName={unit_name}
                activityCode={study_package_activity_code}
              />
            )}

            {!trial && (
              <StudyReportInfo
                studiedCourseDayCount={this.props.studied_course_day_count}
                courseDayCount={this.props.course_day_count}
                totalStudiedMediaLessonsCount={
                  this.props.total_studied_media_lessons_count
                }
                mediaLessonsCount={this.props.media_lessons_count}
                leftSectionName={
                  is_user_study_package ? "已学次数" : "已学天数"
                }
                rightSectionName={"已学课时"}
                reward={this.props.reward}
                studied_tags_count={this.props.studied_tags_count || 0}
                total_tags_count={this.props.total_tags_count || 0}
                currentStudyModule={this.props.current_study_module || 0}
                course_type={this.props.course_type}
              />
            )}

            {!trial &&
              reward_commodity &&
              this.props.exchange_commodities.commodities.length > 0 && (
                <RewardExchangeProgress
                  commodity={reward_commodity}
                  totalPoint={total_point}
                  create_reward_exchange_url={create_reward_exchange_url}
                  study_package_activity_id={study_package_activity_id}
                  list={this.props.exchange_commodities.commodities}
                  selected={this.props.exchange_commodities.selected}
                  selectRewardPath={this.props.select_reward_commodity_path}
                  commoditiesPath={this.props.commodities_path}
                  maxRewardPoints={this.props.max_reward_points}
                />
              )}

            {continue_study_reward && (
              <ContinuousLearningFlag
                course_type={course_type}
                baby_name={baby_name}
                study_day={continue_study_reward.continue_studied_days}
                url={continue_study_reward.continue_study_reward_url}
              />
            )}

            {
              // 打卡机制
              !this.props.checkin ? (
                ""
              ) : (
                <CheckinSection
                  poster_url={this.props.poster_url}
                  checkin_note_url={this.props.checkin_note_url}
                  items={items}
                  checkin_days={this.state.checkin_days}
                  rewards={this.props.rewards}
                  receiveAwardRequest={this.receiveAwardRequest}
                  shrinkClick={this.shrinkClick}
                  shrink={shrink}
                />
              )
            }

            {
              // 弹框分享
              this.state.isAlertQR ? (
                <div
                  className="alert_dialog_share"
                  onClick={() => this.onAlert(false)}
                >
                  <img src={alert_dialog_share} />
                </div>
              ) : (
                ""
              )
            }

            {
              // 唤醒·单元考·小考试
              // 1 · 单元考
              day_study_exams &&
                day_study_exams.length > 0 &&
                day_study_exams.map(
                  (day_study_exam, idex) =>
                    day_study_exam.day_exam_type !== 2 && (
                      // "score" in day_study_exam && (
                      <DayStudyExam
                        key={day_study_exam.id}
                        list={list}
                        index={idex}
                        dayStudyExam={day_study_exam}
                        key={idex}
                      />
                    )
                  // )
                )
            }

            {
              // 以练代学
              day_study_exams &&
                day_study_exams.length > 0 &&
                day_study_exams.map(
                  (day_study_exam, index) =>
                    day_study_exam.day_exam_type === 2 && (
                      // day_study_exam.media_lessons && (
                      <PracticeLearningSection
                        media_lessons={day_study_exam.media_lessons}
                        practiceLearningClick={this.practiceLearningClick}
                        key={index}
                        list_p={list_p}
                        dayStudyExam={day_study_exam}
                        idex={index}
                      />
                    )
                  // )
                )
            }

            {
              // (study_report == true)
              // ?
              // (
              media_lessons &&
                media_lessons.length > 0 &&
                media_lessons.map((media_lesson, index) => (
                  <StudyReportList
                    media_lesson_length={media_lessons.length}
                    media_lesson={media_lesson}
                    key={media_lesson.id}
                    baby_name={baby_name}
                    index={index}
                    practiceResultClick={this.practiceResultClick}
                    list_f={this.state.objF[index]}
                    schoolsDescClick={this.schoolsDescClick}
                    list_s={this.state.objS[index]}
                    knowledgeDescClick={this.knowledgeDescClick}
                    list_k={this.state.objK[index]}
                    externalExerciseClick={this.externalExerciseClick}
                    list_e={this.state.objE[index]}
                  />
                ))
              // )
              // :
              // (
              //   this.props.checkin ? '' :
              //   <StudyNoFinish
              //     study_inprogress={this.props.study_inprogress == true}
              //   />
              // )
            }
          </div>
          {this.props.recommend_course && (
            <SingleCourseBanner recommendCourse={this.props.recommend_course} />
          )}
          {this.props.recommend_theme && (
            <MultCourseBanner recommendTheme={this.props.recommend_theme} />
          )}
          {/* <AdCourseRecommendModule babyName={baby_name} /> */}
        </div>
      </div>
    );
  }
}

export default (props) => {
  const {
    checkin,
    checkin_status: checkinStatus,
    share,
    do_checkin_url,
    day,
  } = props;
  const {
    share_url: link,
    share_image_url: imgUrl,
    share_title: title,
    share_desc: desc,
  } = share;

  const onShareTimelineSuccess = async () => {
    Raven.captureBreadcrumb({
      message: "user checkin success",
      category: "after wx.onMenuShareTimeline",
      data: {
        checkinStatus,
      },
    });
    Raven.captureMessage("user checkin success message", {
      level: "info",
    });
    if (checkinStatus == "checkin") {
      const [error] = await awaitWrapper(request)({
        url: do_checkin_url,
        method: "POST",
        headers: csrfHeaders,
        data: {
          day,
        },
      });

      if (error) {
        await request({
          url: do_checkin_url,
          method: "POST",
          headers: csrfHeaders,
          data: {
            day,
          },
        });
        ToastManager.showSuccess("分享成功");
        setTimeout(() => {
          reloadPage();
        }, 2000);
      } else {
        ToastManager.showSuccess("分享成功");
        setTimeout(() => {
          reloadPage();
        }, 2000);
      }
    }
  };
  const onShareAppMessage = () => {
    alert("分享到朋友圈才可以打卡成功哦～");
  };

  return (
    <>
      <WechatShare
        shareOptions={{
          title,
          link,
          desc,
          imgUrl,
        }}
        onShareTimelineSuccess={checkin && onShareTimelineSuccess}
        onShareAppMessage={checkin && onShareAppMessage}
      />
      <StudyReport {...props} />
    </>
  );
};

StudyReport.propTypes = {
  media_lessons: PropTypes.array,
};
