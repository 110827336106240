/* eslint-disable react/prop-types */
/* eslint-disable lines-between-class-members */
import React, { Component, useEffect, useCallback, useState } from "react";
// import { onNavigate } from '../utils/helpers.js'
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import Picker from "better-picker";
import UserAgent from "../utils/UserAgent";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import { ToptipsManager } from "../utils/Toptips";
import { ToastManager } from "../utils/Toast.jsx";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import { formatDotDate, formatAudioTime } from "../utils/formatAudioTime";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { GA, GA_new } from "../utils/Env";
import { debounce, showDialog, onNavigate } from "../utils/helpers";
import CourseAuditionCoupon from "../study_packages/CourseAuditionCoupon.jsx";
import CourseSignInReminder from "../study_packages/CourseSignInReminder.jsx";
import ReactMarkdown from "react-markdown";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_warning from "study_package/icon_warning.png";
import icon_quiz_pass from "icon_quiz_pass.png";
import icon_once_img from "c4/icon_once_img.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";
import icon_card_play from "study_package/icon_card_play.png";
import icon_pen from "new_study_unit/icon_pen.png";
import icon_package_placeholder from "study_package/icon_package_placeholder.png";
import icon_accomplish from "new_study_unit/icon_accomplish.png";
import icon_test_accomplish from "new_study_unit/icon_test_accomplish.png";
import default_photo from "new_study_unit/thumb_placeholder.png";
import default_exam_photo from "new_study_unit/thumb_examinations.png";
import iconGift from "icon-gift.png";
// import iconQuestionBook from "new_study_unit/Note.png";
import "./UnitModeStudyPackage.scss";

/***
 * 传参说明【四步走】：鉴于此页面涉及的传参复杂性，前端请求拿到后端给过来的原始接口数据之后是如何处理的，说明如下：
 * 1. withUnitStudySubscription.jsx页是处理原始请求拿到的数据，处理的方法名：getUnitStudy
 * 2. UniModeStudyPackage.jsx页对上层HOC处理的数据做处理，处理的方法名：setDayStudies
 * 3. 处理后的state数据经过render props传递给展示页，通过 renderTabPaneContent 传递到<CoursesListSection />组件中
 * 4. CoursesListSection 组件拿到上层传递过来的数据，进行相应的数据及页面展示处理，这时候就拿到了最开始请求中需要的数据
 */

class SliderMenu extends Component {
  constructor(props) {
    super(props);
    // console.log("SliderMenu_props: ", props);
    this.state = {
      activeTabId: this.props.activeTabId,
      dayStudies: [],
    };
    console.log(props.units_title);
  }

  setDayStudies = (unitId) => {
    const toast = ToastManager.showLoading("加载中");
    return this.props
      .getUnitStudy(unitId)
      .then((res) => {
        // console.log("65_setDayStudies_res: ", res);
        toast.cancel();
        this.setState(
          {
            dayStudies: res.day_studies || [],
            main_photo_url: res.main_photo_url,
            description: res.description,
            ecoupons_study_required: res.ecoupons_study_required,
            ecoupons: res.ecoupons,
            upgradePackageRequired: res.upgrade_package_required,
            batchEcouponsEndTime: res.batch_ecoupons_end_time,
          },
          () => {
            if (res.upgrade_package_required) {
              this.props.getUpgradePackageRequiredStaus(true);
            } else {
              this.props.getUpgradePackageRequiredStaus(false);
            }
            toast.cancel();
          }
        );
      })
      .catch((err) => {
        this.setState({
          dayStudies: [],
        });
        toast.cancel();
        console.log(err);
      });
  };

  tabClick = (e, activeTabId) => {
    const { units_title } = this.props;
    // let activeTabId = activeTabId >= units_title.length ? 0 : activeTabId;

    let tabs =
      units_title[activeTabId]?.handbook ||
      units_title[activeTabId].name == "家长须知" ||
      units_title[activeTabId].name == "学前体验"
        ? "parent_guide"
        : units_title[activeTabId].name == "错题本"
        ? "wrong_quiz"
        : units_title[0].name == "家长须知" ||
          units_title[0].name == "学前体验" ||
          units_title[0].handbook
        ? "tab" + activeTabId
        : "tab" + (1 + window.parseInt(activeTabId));

    GA("study_package_details", tabs, this.props.study_package_activity_id);
    GA_new(
      "study_package_details",
      tabs,
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    this.setState({
      dayStudies: [],
    });
    // units_title
    this.setDayStudies(units_title[activeTabId].id);

    this.positionTabMenuTheme(activeTabId);
  };

  // 处理tab_menu_li 导航的颜色
  positionTabMenuTheme = (activeTabId) => {
    const { themeColor } = this.props;
    const tab_light = document.getElementsByClassName("head_menu_li")[
      activeTabId
    ];
    const head_menu_li = document.getElementsByClassName("head_menu_li");
    const headMenuLis = [].slice.call(head_menu_li);

    headMenuLis.forEach((li) => {
      li.style.cssText = `background-color: none;`;
    });
    if (tab_light) {
      const preActiveTabId = activeTabId == 0 ? 0 : activeTabId - 1;
      tab_light.style.cssText = `background-color: ${themeColor[activeTabId].background};color: ${themeColor[activeTabId].font}`;
      if (activeTabId != 0) {
        head_menu_li[preActiveTabId].style.cssText = `background-image: none;`;
      }
    }
  };

  componentDidMount() {
    const { activeTabId } = this.state;
    const { units_title } = this.props;

    // this.setDayStudies(units_title[activeTabId].id);

    // this.positionTabMenuTheme(activeTabId);
    this.tabClick(null, activeTabId);
  }

  render() {
    console.log("------135---------props-----------------", this.props);
    // console.log("---------------state------------------", this.state);
    const {
      units_title,
      renderTabPaneContent,
      study_package_system_time,
      study_package_start_time,
      is_parent_class,
      lock,
      themeColor,
      getCourseDetailStatus,
      upgradePackage,
      thinkingExam,
      currentStudyDate,
    } = this.props;

    const {
      dayStudies,
      activeTabId,
      main_photo_url,
      description,
      ecoupons_study_required,
      ecoupons,
      upgradePackageRequired,
      batchEcouponsEndTime,
    } = this.state;

    return (
      <Tabs
        defaultAction={this.tabClick}
        styles="head_menu_ul"
        defaultActiveIndex={activeTabId}
        animatedTabTitle={true}
      >
        {units_title.map((unit, index) => (
          <TabPane
            tab={
              <div className="tab_wrapper">
                {unit.photoUrl && <img src={unit.photoUrl} alt="" />}
                <p>{unit.name}</p>
              </div>
            }
            styles="head_menu_li"
            activeStyle="tab_light"
            key={index}
          >
            {renderTabPaneContent({
              getCourseDetailStatus,
              dayStudies,
              study_package_system_time,
              study_package_start_time,
              is_parent_class,
              lock,
              target_type: this.props.target_type,
              target_id: this.props.target_id,
              study_package_activity_id: this.props.study_package_activity_id,
              themeColor: themeColor[index % (themeColor.length - 1)],
              main_photo_url,
              description,
              ecoupons_study_required,
              ecoupons,
              upgradePackageRequired,
              upgradePackage,
              batchEcouponsEndTime,
              thinkingExam,
              currentStudyDate,
            })}
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

export const DateHourData = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

export const UserBabyInfo = ({
  baby,
  reminderTimeHour,
  configReminderTimeUrl,
}) => {
  const setRemainderPicker = useCallback(
    (dom) => {
      const picker = new Picker({
        data: [DateHourData],
        selectedIndex: [reminderTimeHour + 1],
        title: "",
      });
      picker.on("picker.select", function (selectedVal, selectedIndex) {
        dom.innerText = DateHourData[selectedIndex[0]].text;

        if (selectedIndex[0] - 1 != reminderTimeHour) {
          const params = {
            reminder_hour: selectedIndex[0] - 1,
          };
          var url = configReminderTimeUrl;
          request({
            url: url,
            method: "POST",
            headers: csrfHeaders,
            data: params,
          })
            .then((resp) => {
              console.log("正确返回");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });

      if (reminderTimeHour >= -1) {
        const time_setup_click = document.getElementById("time_setup_click");

        if (time_setup_click) {
          time_setup_click.addEventListener("click", function () {
            picker.show();
            if (UserAgent.isNativeClient()) {
              XinYaNativeBridge.requestAuthorization({
                authType: "notification",
              });
            }
          });
        }
      }
    },
    [configReminderTimeUrl, reminderTimeHour]
  );

  useEffect(() => {
    const pickerDom = document.getElementById("reminder_picker");
    setRemainderPicker(pickerDom);
  }, [setRemainderPicker]);

  return (
    <div className="user_baby_info">
      <a className="baby_info" href={baby.edit_baby_path} target="_blank">
        <div className="avatar_wrapper">
          <img
            src={
              baby.photo_url ||
              (baby.gender === "男" ? iconBoyUser : iconGirlUser)
            }
            alt=""
          />
          {!baby.photo_url && <div className="to_add_avatar"></div>}
        </div>

        <div className="baby_num">
          <span>{baby.nickname}</span>
          <span>{`学号：${baby.sno}`}</span>
        </div>
      </a>

      <div className="alert_time" id="time_setup_click">
        <div className="icon_alert">上课提醒</div>
        <div className="current_time" id="reminder_picker">
          {reminderTimeHour === -1 ? "不提醒" : `当前${reminderTimeHour}点`}
        </div>
      </div>
    </div>
  );
};

class CoursesListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseDetailStatus: false,
      courseContentIndex: "",
      lesson_times: [],
    };
    this.scrolledAnchor = false;
  }

  handleCourseList = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const state = e.currentTarget.getAttribute("data-state");

    GA(
      "study_package_details",
      state === "pass" ? "review" : "start_day_study",
      this.props.study_package_activity_id
    );
    GA_new(
      "study_package_details",
      state === "pass" ? "review" : "start_day_study",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    const singleDayStudies = this.props.dayStudies[index];
    const mediaLessons = singleDayStudies.media_lessons;

    this.setState(() => ({
      courseContentIndex: index,
      courseDetailStatus: true,
    }));

    // 向上层组件传递详情页弹框显示的状态
    this.props.getCourseDetailStatus(true);
  };

  // 锁定的课时，点击时弹提示
  handleLockedDialog = (day_study) => {
    let text;
    if (day_study.week) {
      text = `课程将于${formatDotDate(
        day_study.week_start
      )}日解锁，请到时再来学习`;
    } else {
      if (this.props.lock == "unlock_by_day") {
        text = `课程将于${formatDotDate(day_study.date)}解锁，请到时再来学习`;
      } else {
        text = `请先学完前一天的内容，或等日期到了解锁`;
      }
    }
    const buttons = [
      {
        label: "我知道了",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        {text}
        {/* {day_study.week
          ? `课程将于${day_study.week_start}解锁，请到时再来学习`
          : this.props.lock == "unlock_by_day"
          ? `课程将于${date}解锁，请到时再来学习`
          : `请先学完前一天的内容，或等日期到了解锁`} */}
      </Dialog>
    );
  };

  handleDetailCloseDialog = () => {
    this.setState(() => ({
      courseDetailStatus: false,
    }));

    // 向上层组件传递详情页关闭的状态
    this.props.getCourseDetailStatus(false);
  };

  // “学前体验” article模块请求
  handleArticleRequest = (e) => {
    const record_url = e.currentTarget.getAttribute("data-url");
    request({
      url: record_url,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  // 速度闯关练习类型的弹框提醒
  speedPracticeClick = () => {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        请使用手机版微信扫描练习卷上的二维码，完成训练
      </Dialog>
    );
  };

  handleTestEntrance = (e) => {
    const url = e.currentTarget.getAttribute("data-url");
    console.log("试听入口: ", url);
    onNavigate(e, url);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.scrolledAnchor) {
      const anchorCurrentProgress = document.getElementById("current_progress");
      if (anchorCurrentProgress) {
        let scrollVal = 0;
        const courseListContainers = document.getElementsByClassName(
          "course_list_container_box"
        );
        // 试听、优惠券单元
        const trialCouponContainers = document.getElementsByClassName(
          "trial_coupon_container"
        );
        const trialCouponContainersVal = trialCouponContainers[0]
          ? trialCouponContainers[0].offsetHeight
          : 0;

        const courseListContainersArr = [].slice.call(courseListContainers);
        courseListContainersArr.some((container) => {
          if (container.getAttribute("data-id") === "current_progress") {
            return true;
          }
          scrollVal += container.offsetHeight + 15;
        });

        // 试听模块，菜单栏下面不需要空出10px距离，滑动定位也要去掉10px
        const trialContailScrollDistance = trialCouponContainers[0] ? 15 : 25;

        const totalScrollHeight =
          scrollVal === 0
            ? 0
            : Number(scrollVal) +
              Number(trialCouponContainersVal) -
              trialContailScrollDistance;

        console.log(
          "scrollVal: ",
          scrollVal,
          "totalScrollHeight: ",
          totalScrollHeight
        );

        $("#_scroll_list").animate({ scrollTop: totalScrollHeight }, 500);
        this.scrolledAnchor = true;
      }
      // window.location.replace(window.location.pathname + window.location.search + "#current_progress")
    }
  }

  renderActiveTagPrompt = (isActive, day_study) => {
    const includeFirstDay = (day_study) => {
      return day_study.handbook ? "请先从这里开始" : "开始第1天学习";
    };

    const excludeFirstDay = (day_study) => {
      return day_study.handbook
        ? "上次学到这里，继续学习"
        : day_study.start_rest_day
        ? "今日休息"
        : "上次学到这里，继续学习";
    };
    return (
      isActive && (
        <div className="current_progress" id="current_progress">
          {day_study.day == 1
            ? includeFirstDay(day_study)
            : excludeFirstDay(day_study)}
        </div>
      )
    );
  };

  componentDidMount() {
    const portal = document.getElementById("portal_root");
    if (portal && portal.firstChild) {
      portal.removeChild(portal.firstChild);
    }
  }
  render() {
    console.log("CoursesListSection----props-UnitModeStudyPackage", this.props);
    console.log("CoursesListSection----state-UnitModeStudyPackage", this.state);
    const {
      study_package_system_time,
      dayStudies,
      themeColor,
      baby,
      reminderTimeHour,
      configReminderTimeUrl,
      system_time: systemTime,
      is_parent_class: isParentClass,
      main_photo_url,
      description,
      ecoupons_study_required,
      ecoupons,
      upgradePackageRequired,
      upgradePackage,
      batchEcouponsEndTime,
      thinkingExam,
      currentStudyDate,
    } = this.props;
    const { courseDetailStatus, courseContentIndex } = this.state;
    const singleDayStudies = dayStudies[courseContentIndex];
    const iconStars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

    const lineGradient = {
      backgroundImage: `linear-gradient(
        180deg,
        hsla(0,0%,100%,0),
        hsla(0,0%,100%,0) 80%,
        ${themeColor.background} 100%,
        ${themeColor.background}
      )`,
    };

    return dayStudies && dayStudies.length > 0 ? (
      <div>
        {courseDetailStatus ? (
          <InnerModal>
            <CourseDetailSection
              singleDayStudies={singleDayStudies}
              handleCloseDetail={this.handleDetailCloseDialog}
              speedPracticeClick={this.speedPracticeClick}
              icon_stars={iconStars}
              system_time={systemTime}
              is_parent_class={isParentClass}
              handleArticleRequest={this.handleArticleRequest}
            />
          </InnerModal>
        ) : null}
        {!upgradePackageRequired && (
          <div
            className="add_scroll_distance"
            style={{ background: themeColor.background }}
          ></div>
        )}
        <div
          className="course_list_container_section"
          style={{
            maxHeight: document.body.offsetHeight - 60,
            background: themeColor.background,
          }}
          id="_scroll_list"
        >
          {upgradePackageRequired && (
            // dayStudies 存在, 并且是试听课才显示
            <div className="trial_coupon_container">
              <div
                className="trial_course_unit"
                style={{
                  backgroundImage: `url(${main_photo_url})`,
                }}
              >
                <span style={lineGradient}></span>
              </div>
              {description && (
                <div className="trial_course_describe">
                  <img src={icon_warning} alt="" />
                  {description}
                </div>
              )}
              {ecoupons && ecoupons.length > 0 && (
                <CourseAuditionCoupon
                  batchEcouponsEndTime={batchEcouponsEndTime}
                  ecoupons={ecoupons}
                  ecouponsStudyRequired={ecoupons_study_required}
                  background={themeColor.background}
                  systemTime={study_package_system_time}
                />
              )}
            </div>
          )}
          {
            // TODO: 思维课试听测评入口
            thinkingExam && (
              <div
                className="course_list_container_box trial_course_list_container_box"
                data-id={currentStudyDate == null ? "current_progress" : ""}
              >
                {currentStudyDate == null &&
                  this.renderActiveTagPrompt(true, {
                    day: 1,
                    handbook: true,
                  })}
                <div
                  className="course_list_container"
                  onClick={this.handleTestEntrance}
                  data-url={
                    thinkingExam.report_url
                      ? thinkingExam.report_url
                      : thinkingExam.examination_url
                  }
                  style={{
                    boxShadow:
                      currentStudyDate == null
                        ? `0 0 0 0 ${themeColor.titleBorder} inset, 0 0 6px rgba(128,74,64,0.5)`
                        : "",
                    border:
                      currentStudyDate == null
                        ? `1px solid ${themeColor.titleBorder}`
                        : "",
                  }}
                >
                  {thinkingExam.report_url && (
                    <div className="already_finish_package">
                      <img src={icon_test_accomplish} alt="" />
                    </div>
                  )}

                  <div className="media_course_list_content">
                    <img src={default_exam_photo} alt="" />
                    <div className="course_list_box">
                      <div>
                        <div className="day_exam_name">
                          心芽数学思维分级测评
                        </div>
                        <div className="day_exam_desc">
                          快速测试{baby?.nickname}适合哪个阶段的课程
                        </div>
                      </div>
                      <div className="now_entrance">
                        {thinkingExam.report_url ? "查看结果" : "立即测评"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {dayStudies.map((day_study, index) => {
            // console.log("UnitModeStudyPackage_dayStudy: ", day_study);
            const day_lock = day_study.day_lock;
            const activeCurrentProgress =
              day_study.current_progress_date === true;
            const goToClassJudge = day_lock == false;
            const pass = day_study.state === "pass";

            // 找到当前单元数组中的第一项, 并判断当前学习的进度是否为true
            const dayStudyFirstCurrentProgress =
              activeCurrentProgress && index === 0;

            const activePlayStudy = activeCurrentProgress;
            const activeGreenButton = goToClassJudge && !activeCurrentProgress;

            const day_study_exams_value =
              day_study.day_study_exams && day_study.day_study_exams.length > 0;
            const media_courses_value =
              day_study.media_courses && day_study.media_courses.length > 0;
            const onlyDayStudyExams =
              day_study_exams_value && !media_courses_value;

            const thumbnailImg = (day_study) => {
              if (day_study.handbook && day_study.day === 1) {
                return day_study.photo_url;
              }
              if (day_study.photo_url) {
                return day_study.photo_url;
              }
              if (
                day_study.media_courses &&
                day_study.media_courses.length > 0
              ) {
                return day_study.media_courses[0].photo_url
                  ? day_study.media_courses[0].photo_url
                  : default_photo;
              }
              return default_exam_photo;
            };

            // 学前体验 第一天 未学状态
            let handBookFirstDayState;
            if (
              day_study.day == 1 &&
              day_study.handbook &&
              day_study.state == "blank"
            ) {
              handBookFirstDayState = true;
            }

            const begainStudy = (
              activeCurrentProgress,
              pass,
              handbook,
              onlyDayStudyExams
            ) => {
              return activeCurrentProgress ? (
                <div className="button_begain_study">
                  <div
                    className="progress_icon_study"
                    style={{
                      background: activePlayStudy ? themeColor.titleBorder : "",
                    }}
                  ></div>
                  <p style={{ color: activePlayStudy ? themeColor.font : "" }}>
                    {`${pass ? "复习" : "开始学习"}`}
                  </p>
                </div>
              ) : pass ? (
                <div className="begain_study_active">
                  <img src={icon_card_play} alt="" />
                  <p>复习</p>
                </div>
              ) : (
                <div className="begain_study_active">
                  {handbook && onlyDayStudyExams ? (
                    <React.Fragment>
                      <img
                        src={icon_pen}
                        alt=""
                        style={{
                          width: "16px",
                          height: "16px",
                          minWidth: "16px",
                        }}
                      />
                      <p>开始测试</p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img src={icon_card_play} alt="" />
                      <p>开始学习</p>
                    </React.Fragment>
                  )}
                </div>
              );
            };

            // 解锁形式·天或周
            const DayOrWeekly = (day_study) => {
              if (day_study.handbook) {
                return day_study.name;
              } else if (day_study.week) {
                return `第${day_study.week}周`;
              } else if (day_study.day) {
                return `第${day_study.day}天`;
              }
            };

            return day_study.day ? (
              <React.Fragment key={index}>
                {day_study.handbook && index === 0 && (
                  <UserBabyInfo
                    baby={baby}
                    reminderTimeHour={reminderTimeHour}
                    configReminderTimeUrl={configReminderTimeUrl}
                  />
                )}
                <div
                  className="course_list_container_box"
                  style={
                    dayStudyFirstCurrentProgress ? { paddingTop: "10px" } : {}
                  }
                  data-id={activeCurrentProgress ? "current_progress" : ""}
                  data-state={day_study.state}
                >
                  {currentStudyDate != null &&
                    this.renderActiveTagPrompt(
                      activeCurrentProgress,
                      day_study
                    )}
                  <div
                    className={`course_list_container ${
                      goToClassJudge ? "" : "course_list_container_lock"
                    } ${
                      activeCurrentProgress
                        ? "current_progress_course_list_container"
                        : ""
                    } `}
                    data-index={index}
                    data-state={day_study.state}
                    onClick={
                      goToClassJudge
                        ? this.handleCourseList
                        : this.handleLockedDialog.bind(this, day_study)
                    }
                    style={{
                      boxShadow: activeCurrentProgress
                        ? `0 10px 2px 1px ${themeColor.titleBorder} inset, 2px 2px 6px rgba(128,74,64,0.5)`
                        : "",
                      border: activeCurrentProgress
                        ? `1px solid ${themeColor.titleBorder}`
                        : "",
                      marginTop:
                        activeCurrentProgress || handBookFirstDayState
                          ? "15px"
                          : "",
                    }}
                  >
                    <div
                      className={`course_list_section ${
                        goToClassJudge ? "" : "course_list_section_lock"
                      } ${activePlayStudy ? "course_list_section_bg" : ""}`}
                      style={{
                        borderBottom: activeGreenButton
                          ? ".5px solid #ddd"
                          : "",
                        background: activePlayStudy
                          ? themeColor.titleBorder
                          : "",
                      }}
                    >
                      <div className="day_and_study_report_entrance">
                        <div
                          className={`day_or_date ${
                            day_study.state === "pass"
                              ? "finish_padding_left"
                              : ""
                          }`}
                        >
                          {/* 按周解锁 */}
                          <div className="correspond_course">
                            {day_study.customize_name || DayOrWeekly(day_study)}
                            {/* {day_study.handbook
                                ? day_study.name
                                : `第${day_study.day}天`} */}
                          </div>
                          {!day_study.handbook && !day_study.customize_name && (
                            <div className="date">
                              {day_study.week
                                ? `${formatDotDate(
                                    day_study.week_start
                                  )} - ${formatDotDate(day_study.week_end)}`
                                : formatDotDate(day_study.date)}
                            </div>
                          )}
                        </div>
                        {goToClassJudge ? (
                          begainStudy(
                            activeCurrentProgress,
                            pass,
                            day_study.handbook,
                            onlyDayStudyExams
                          )
                        ) : (
                          <div className="button_begain_lock">
                            <img src={icon_lock_gray} alt="" />
                          </div>
                        )}
                      </div>
                      {day_study.study_report && (
                        <div
                          className="already_finish_package"
                          style={{
                            top: activeCurrentProgress && "-1px",
                            left: activeCurrentProgress && "-1px",
                          }}
                        >
                          <img src={icon_accomplish} alt="" />
                        </div>
                      )}
                    </div>
                    <div className="media_course_list_content">
                      <img src={`${thumbnailImg(day_study)}`} alt="" />
                      <div className="course_list_box">
                        {day_study.articles && (
                          <div className="course_list_content">
                            {day_study.name && (
                              <div
                                className="media_course_title"
                                style={{
                                  color: goToClassJudge
                                    ? themeColor.font
                                    : "#aaa",
                                  backgroundColor: goToClassJudge
                                    ? themeColor.background
                                    : "#eee",
                                }}
                              >
                                {day_study.name}
                              </div>
                            )}
                            <ul>
                              {day_study.articles.map((article, index) => (
                                <li key={article.id}>
                                  <span>{index + 1}. </span>
                                  <p
                                    className={`${
                                      goToClassJudge ? "" : "p_lock"
                                    }`}
                                  >
                                    {article.name}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {day_study.day_study_exams.map((exam) => (
                          <div
                            className={`day_exam_name ${
                              goToClassJudge ? "" : "day_exam_name_lock"
                            }`}
                            key={exam.id}
                          >
                            {exam.name}
                          </div>
                        ))}
                        {day_study.media_courses.map((media_course, idx) => (
                          <div className="course_list_content" key={idx}>
                            {media_course.name && (
                              <div
                                className="media_course_title"
                                style={{
                                  color: goToClassJudge
                                    ? themeColor.font
                                    : "#aaa",
                                  backgroundColor: goToClassJudge
                                    ? themeColor.background
                                    : "#eee",
                                }}
                              >
                                {media_course.name}
                              </div>
                            )}
                            <ul>
                              {media_course.media_lessons.map(
                                (media_lesson, index) => (
                                  <li key={media_lesson.id}>
                                    {media_lesson.position && (
                                      <span>{media_lesson.position}. </span>
                                    )}
                                    <p
                                      className={`${
                                        goToClassJudge ? "" : "p_lock"
                                      }`}
                                    >
                                      {media_lesson.nickname
                                        ? `${media_lesson.nickname} - `
                                        : ""}
                                      {media_lesson.original_name}
                                      {media_lesson.extension
                                        ? "【延伸课】"
                                        : ""}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <HolidayRestPrompt
                key={index}
                renderActiveTagPrompt={this.renderActiveTagPrompt}
                activeCurrentProgress={activeCurrentProgress}
                day_study={day_study}
                goToClassJudge={goToClassJudge}
                // handleLockedDialog={this.handleLockedDialog}
              />
            );
          })}
          <CurrentUnitEnd themeColor={themeColor} />
        </div>
        {/* 试听体验课才显示底部 */}
        {upgradePackageRequired && upgradePackage && (
          <CourseSignInReminder
            url={upgradePackage.study_package_activity_buy_url}
            text="每天20分钟夯实思维基础，领跑同龄孩子"
          />
        )}
      </div>
    ) : (
      ""
    );
  }
}

const CurrentUnitEnd = ({ themeColor }) => {
  return (
    <div className="current_unit_end" style={{ color: themeColor.font }}>
      本单元结束
    </div>
  );
};

const HolidayRestPrompt = ({
  goToClassJudge,
  renderActiveTagPrompt,
  activeCurrentProgress,
  day_study,
  handleLockedDialog,
}) => {
  const isSameDay =
    formatDotDate(day_study.start_rest_day) ===
    formatDotDate(day_study.end_rest_day);
  let showDate;
  if (day_study.week) {
    showDate = `${formatDotDate(day_study.week_start)} - ${formatDotDate(
      day_study.week_end
    )}`;
  } else if (isSameDay) {
    showDate = formatDotDate(day_study.start_rest_day);
  } else {
    showDate = `${formatDotDate(day_study.start_rest_day)} - ${formatDotDate(
      day_study.end_rest_day
    )}`;
  }
  return (
    <div
      className="course_list_container_box"
      data-id={activeCurrentProgress ? "current_progress" : ""}
    >
      {renderActiveTagPrompt(activeCurrentProgress, day_study)}
      <div
        className={`course_list_container ${
          goToClassJudge ? "" : "course_list_container_lock"
        }`}
        // onClick={goToClassJudge ? null : handleLockedDialog}
      >
        <div
          className={`course_list_section ${
            goToClassJudge ? "" : "course_list_section_lock"
          }`}
          style={{
            borderBottom: goToClassJudge ? ".5px solid #ddd" : null,
          }}
        >
          <div className="day_and_study_report_entrance">
            <div className="day_or_date">
              <div className="date">{showDate}</div>
            </div>
          </div>
        </div>
        <div className="media_course_list_holiday">
          <img src={icon_package_placeholder} alt="" />
          <div className="rest_content">
            <p>休息日</p>
            <p>补课或复习之前的内容吧</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const getAudioResult = (infoUrl) => {
  return request({
    url: infoUrl,
    method: "get",
  }).then((resp) => {
    const totalTime = parseInt(resp.data.format.duration);
    return formatAudioTime(totalTime);
  });
};

const CourseDetailSection = ({
  singleDayStudies,
  handleCloseDetail,
  icon_stars,
  system_time,
  is_parent_class,
  handleArticleRequest,
}) => {
  const [audioTimeList, setAudioTimeList] = useState([]);
  const day_study = singleDayStudies;
  // console.log('day_study: ', day_study);
  const now_date = new Date(system_time);
  const articlesArray =
    day_study && day_study.articles && day_study.articles.length > 0;
  const mediaLessons =
    day_study.media_lessons && day_study.media_lessons.length > 0;
  const dayStudyExams =
    day_study.day_study_exams && day_study.day_study_exams.length > 0;
  const calculateTrainingCamps =
    day_study.calculate_training_camps &&
    day_study.calculate_training_camps.length > 0;

  const study_report = day_study.study_report;
  const studyReportUrl = day_study.study_report_url;

  useEffect(() => {
    const { media_lessons } = singleDayStudies;
    if (media_lessons && media_lessons.length > 0) {
      const mediaLessonsAudioInfoUrls = media_lessons.map(
        (mediaLesson) => mediaLesson.audio_info_url
      );
      Promise.all(mediaLessonsAudioInfoUrls.map((url) => getAudioResult(url)))
        .then((audioTimesList) =>
          setAudioTimeList(() => audioTimesList.map((time) => ({ time })))
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [singleDayStudies, singleDayStudies.media_lessons]);

  return (
    <div className="course_detail_container">
      <div className="course_detail_section">
        <div className="course_detail_box">
          <div className="head_title">
            <p>学习内容</p>
            <div className="close_dialog" onClick={handleCloseDetail}>
              &times;
            </div>
          </div>
          <div className="calendar_course_list">
            {study_report && (
              <div className="button_study_report">
                <a href={`${studyReportUrl}&ref=daily_course`}>今日学习报告</a>
              </div>
            )}
            {
              // 学前体验 “家长须知”, 单天模块
              articlesArray &&
                day_study.articles.map((article, index) => (
                  <ArticlesSection
                    key={article.id}
                    article={article}
                    index={index}
                    handleArticleRequest={handleArticleRequest}
                  />
                ))
            }
            {
              // * day_study_exams 唤醒模块/单元/期末
              dayStudyExams &&
                day_study.day_study_exams.map(
                  (day_study_exam) =>
                    day_study_exam.quizzes_count > 0 && (
                      <AwakeSection
                        key={day_study_exam.id}
                        day_study_exam={day_study_exam}
                        day_lock={day_study.day_lock}
                        icon_stars={icon_stars}
                      />
                    )
                )
            }
            {
              //  * media_lessons 课程学习模块
              mediaLessons &&
                day_study.media_lessons.map((media_lesson, idx) => {
                  return (
                    <MediaLessonsSection
                      key={media_lesson.id}
                      media_lesson={media_lesson}
                      day_lock={day_study.day_lock}
                      icon_stars={icon_stars}
                      lesson_time={audioTimeList[idx]}
                      is_parent_class={is_parent_class}
                    />
                  );
                })
            }
            {
              // * 新增速度闯关练习类型
              calculateTrainingCamps && (
                <CalcalateTrainingCamps
                  calculate_training_camps={day_study.calculate_training_camps}
                  lock={day_study.lock}
                  day_lock={day_study.day_lock}
                  speedPracticeClick={this.speedPracticeClick}
                  day_study_start_time={
                    new Date(day_study.day_study_start_time)
                  }
                  now_date={now_date}
                />
              )
            }
            {
              // * 随堂测试（原今日练习）模块
              day_study.today_quiz_url && day_study.quizzes_count > 0 && (
                <TodayQuizSection
                  today_quiz_url={day_study.today_quiz_url}
                  day_lock={day_study.day_lock}
                  today_quiz_status={day_study.today_quiz_status}
                  quizzes_count={day_study.quizzes_count}
                />
              )
            }

            {
              // * 课外练习模块 (随堂测试)
              day_study.exercise_quiz_url &&
                day_study.exercise_quizzes_count > 0 && (
                  <ExamineSection
                    day_lock={day_study.day_lock}
                    exercise_quiz_url={day_study.exercise_quiz_url}
                    exercise_quiz_status={day_study.exercise_quiz_status}
                    exercise_quizzes_count={day_study.exercise_quizzes_count}
                  />
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

// const FloatingIcons = ({
//   baby,
//   toUserSetting,
//   toWrongQuizSets,
//   toStudyReport,
//   courseDetailStatus,
//   getWriteEvaluateStatus,
//   createRecommendRequestUrl
// }) => {
//   return (
//     <div
//       className="course_floating_icons"
//       style={{ zIndex: courseDetailStatus ? "-1" : "10" }}
//     >
//       <div className="icons_container">
//         <a className="setting user" href={toUserSetting} target="_blank">
//           <img
//             src={
//               baby.photo_url ||
//               (baby.gender === "男" ? iconBoyUser : iconGirlUser)
//             }
//             alt=""
//           />
//           <span>设置</span>
//         </a>
//         {/* <a className="setting question_book" href={toWrongQuizSets}  target="_blank">
//           <img src={iconQuestionBook}></img>
//           <span>错题本</span>
//         </a> */}
//         {toStudyReport && (
//           <a
//             className="setting study_report"
//             href={`${toStudyReport}&ref=shortcut`}
//             target="_blank"
//           >
//             <div className="icon_message">
//               <img src={iconStudyReport}></img>
//               {
//                 // TODO: 暂无字段提供
//                 // <div className="message">
//                 //   <span>2</span>
//                 // </div>
//               }
//             </div>
//             <span>学习报告</span>
//           </a>
//         )}
//         {createRecommendRequestUrl && (
//           <a
//             className="setting write_evaluate"
//             href="javascript: void(0)"
//             onClick={() => getWriteEvaluateStatus()}
//           >
//             <div className="icon_message">
//               <img src={iconComment}></img>
//             </div>
//             <span>写评价</span>
//           </a>
//         )}
//       </div>
//     </div>
//   );
// };

export default class UnitModeStudyPackage extends Component {
  constructor(props) {
    super(props);
    console.log("UnitModeStudyPackage-props:", props);
    this.state = {
      createRecommendRequestUrl: props.create_recommend_request_url,
      courseDetailStatus: false,
      writeEvaluateDialogState: false,
    };
  }

  // 点击”唤醒课程详情“ 和 ”关闭课程详情“ 设置的状态值, 用来动态控制 FloatingIcons 组件的浮层层级 z-index
  getCourseDetailStatus = (boolStateValue) => {
    this.setState({
      courseDetailStatus: boolStateValue,
    });
  };

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
    GA("shortcut", "packageComment", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "package_comment",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  // 请求接口数据成功之后, 链接置空
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(({ bonus, had_joined_groupon, remaining_shopping_group_count }) => {
        new SharePosterProxy({
          // posterUrl: this.props.poster_url,
          // posterImgSrc: this.props.poster_img_src,
          posterTemplate,
          posterCreation,
          mode: "front_end",
          free:
            had_joined_groupon && remaining_shopping_group_count > 0
              ? "in_or_open_shopping_group"
              : "none_shopping_group",
          bonus: bonus,
          sale_mode: "shopping_group",
          remaining_shopping_group_count,
        })
          .open({
            isShowToast: false,
          })
          .then(() => {
            toast.cancel();
          });
      })
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });

    GA("shortcut", "packagePoster", study_package_activity_id);
    GA_new(
      "shortcut",
      "package_poster",
      study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
  };

  onStudyReportIconClick = () => {
    GA("shortcut", "study_reports", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "study_reports",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = `${this.props.study_report_url}&ref=shortcut_course`;
  };

  // 每隔30分钟，当前页面从隐藏到显示，就强制刷新页面
  listenVisibilityEventChange() {
    let lastReloadTime = new Date();
    document.addEventListener(
      "visibilitychange",
      function () {
        const nowTime = new Date();
        const intervel = (nowTime - lastReloadTime) / 1000;

        // console.log("nowTime: ", nowTime);
        // console.log("lastReloadTime: ", lastReloadTime);

        // console.log(
        //   "intervel_visibilityState: ",
        //   intervel + "秒",
        //   document.visibilityState
        // );

        if (document.visibilityState === "visible" && intervel >= 30 * 60) {
          window.location.reload(true);
          lastReloadTime = new Date();
        }
      },
      false
    );
  }
  componentDidMount() {
    this.listenVisibilityEventChange();
  }
  componentWillUnmount() {
    window.removeEventListener(
      "visibilitychange",
      this.listenVisibilityEventChange
    );
  }

  // 回调函数获取是否是试听课
  getUpgradePackageRequiredStaus = (boolState) => {
    this.setState({
      upgradePackageRequired: boolState,
    });
  };

  // 错题本
  handleWrongBookClick = () => {
    // console.log("==================");
    GA("shortcut", "package_wrong_quiz", this.props.study_package_activity_id);

    GA_new(
      "shortcut",
      "package_wrong_quiz",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = this.props.question_book_url;
  };

  render() {
    // console.log("--------this.props111111111111------", this.props);
    // console.log("--------this.state------", this.state);
    const {
      study_package_units,
      getUnitStudy,
      get_unit_study_url,
      course_user_setting_url,
      // wrong_quiz_url,
      // study_report_url,
      getDefaultActiveUnit,
      is_parent_class,
      study_package_system_time,
      study_package_start_time,
      lock,
      themeColor,
      baby,
      ios_review,
      reminder_time_hour,
      config_reminder_time_url,
      upgrade_package,
      thinking_exam,
      current_study_date,
    } = this.props;

    const {
      // courseDetailStatus,
      writeEvaluateDialogState,
      createRecommendRequestUrl,
      upgradePackageRequired,
    } = this.state;

    const units_title = study_package_units.map((unit) => ({
      name: unit.name,
      id: unit.id,
      photoUrl: unit.photo_url,
    }));

    const { index } = getDefaultActiveUnit();
    return (
      <div
        className={`comp_unit_mode_study_package ${
          upgradePackageRequired ? "comp_trial_unit_mode_study_package" : ""
        }`}
      >
        <SliderMenu
          getUnitStudy={getUnitStudy.bind(null, get_unit_study_url)}
          units_title={units_title}
          activeTabId={index >= units_title.length ? 0 : index}
          is_parent_class={is_parent_class}
          study_package_system_time={study_package_system_time}
          study_package_start_time={study_package_start_time}
          lock={lock}
          target_id={this.props.target_id}
          target_type={this.props.target_type}
          themeColor={themeColor}
          getCourseDetailStatus={this.getCourseDetailStatus}
          getUpgradePackageRequiredStaus={this.getUpgradePackageRequiredStaus}
          upgradePackage={upgrade_package}
          thinkingExam={thinking_exam}
          study_package_activity_id={this.props.study_package_activity_id}
          currentStudyDate={current_study_date}
          renderTabPaneContent={(extraProps) => (
            <CoursesListSection
              {...extraProps}
              baby={baby}
              reminderTimeHour={reminder_time_hour}
              configReminderTimeUrl={config_reminder_time_url}
            />
          )}
        />
        {/* <FloatingIcons
          toUserSetting={course_user_setting_url}
          toWrongQuizSets={wrong_quiz_url}
          toStudyReport={study_report_url}
          baby={baby}
          courseDetailStatus={courseDetailStatus}
          getWriteEvaluateStatus={this.getWriteEvaluateStatus}
          createRecommendRequestUrl={createRecommendRequestUrl}
        /> */}
        <RightFloatIcons
          isShowMakeComment={
            this.props.comment_state === "uncommented" &&
            !!createRecommendRequestUrl &&
            !ios_review
          }
          isShowRedMoney={!!this.props.bonus && !ios_review}
          onRedMoneyClick={this.onInvitationIconClick}
          onMakeCommentClick={this.getWriteEvaluateStatus}
          isShowReport
          onReportClick={this.onStudyReportIconClick}
          isShowWrongBook={true}
          onWongBookClick={this.handleWrongBookClick}
        />
        {writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
        {this.props.has_new_study_mode &&
          !this.state.showUpdate &&
          !window.localStorage.showUpdate_old_c4 && (
            <div className="old-to-new-back">
              <div className="old-to-new-center">
                <img src={icon_once_img} className="center-img"></img>
                <div className="old-to-new-decs">
                  <div>{`您拥有《${this.props.study_package_name}》新版的自适应课表，是否进入新课表学习？`}</div>
                  {/* <div></div> */}
                </div>
                <div className="old-to-new-selected">
                  <div
                    className="old-cancle"
                    onClick={() => {
                      if (this.state.noTips) {
                        request({
                          url: this.props
                            .do_not_reminder_for_new_study_mode_url,
                          // data: {},
                          headers: csrfHeaders,
                          method: "POST",
                        }).then((res) => {
                          window.localStorage.showUpdate_old_c4 = true;
                        });
                      } else {
                        this.setState({ showUpdate: true });
                      }
                    }}
                  >
                    取消
                  </div>
                  <div
                    className="new-go"
                    onClick={() => {
                      //进入新课标
                      if (this.state.noTips) {
                        request({
                          url: this.props
                            .do_not_reminder_for_new_study_mode_url,
                          // data: {},
                          headers: csrfHeaders,
                          method: "POST",
                        }).then((res) => {
                          window.localStorage.showUpdate_old_c4 = true;
                        });
                      } else {
                        window.location.href = this.props.study_url;
                      }
                    }}
                  >
                    进入
                  </div>
                </div>
                <div
                  className="old-to-new-no-tips"
                  onClick={() => {
                    this.setState({ noTips: true });
                  }}
                >
                  <div className="no-tips">{/* <img src={}></img> */}</div>
                  不再提示
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export class StudyPackageCommentModule extends Component {
  constructor(props) {
    super(props);
    const { rate, current_user_comment } = props;
    this.state = {
      showRuleDialog: false,
      selectIndex: rate ? rate - 1 : -1,
      rate: rate || "",
      recommendText: current_user_comment || "",
    };
  }

  btnCloseRecommendListClick = () => {
    this.props.hideDialog();
  };

  onSelectLimitClick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const value = e.currentTarget.getAttribute("data-value");
    this.setState({
      selectIndex: index,
      rate: value,
    });
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      recommendText: value,
    });
  };

  handleSubmit = () => {
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请您给课程打个分");
      return;
    } else if (this.state.recommendText === "") {
      ToptipsManager.warn("请先填写您的推荐语");
      return;
    } else {
      if (this.state.recommendText.length >= 20) {
        this.setState({
          recommendText: this.state.recommendText,
        });
        this.toast = ToastManager.showLoading("提交中...");
        const params = {
          text: this.state.recommendText,
          rate: this.state.rate,
          commentable_id: this.props.comment_target.commentable_id,
          commentable_type: this.props.comment_target.commentable_type,
          reply_to_id: this.props.comment_target.reply_to_id,
          note: this.props.bought_phase,
        };
        if (this.props.promotion && this.props.promotion.reward) {
          params.reward_id = this.props.promotion.reward.id;
        }
        var url = this.props.create_recommend_request_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            this.toast.cancel();
            const data = resp.data;
            console.log(data);
            this.props.hideDialog();
            this.props.getCreateRecommendRequestUrl();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ToptipsManager.warn("填写推荐语字数应大于20个字");
        return;
      }
    }
  };

  // 活动规则显示
  activityRuleClick = () => {
    this.setState({
      showRuleDialog: true,
    });
  };

  // 活动规则隐藏
  hideActivityRule = () => {
    this.setState({
      showRuleDialog: false,
    });
  };
  render() {
    const { promotion, name } = this.props;
    const activityCommentRule = () => {
      return promotion && promotion.brief ? (
        <div className="activity_rule_text">
          <img src={iconGift} alt="" />
          {promotion.brief}，
          <span onClick={this.activityRuleClick}>活动规则</span>
        </div>
      ) : (
        ""
      );
    };

    return (
      <React.Fragment>
        <div className="study_packag_comment_module">
          <div className="overlay"></div>
          <div className="comment_list_container">
            <div className="course_name">
              评价
              {name && `《${name}》`}
            </div>
            <div
              className="close_btn_right"
              style={{ top: -4 }}
              onClick={this.btnCloseRecommendListClick}
            >
              &times;
            </div>
            {activityCommentRule()}
            <div className="recommend_score">
              <div className="text">
                您是否愿意把课程推荐给朋友？（1-10分请打分）
              </div>
              <div className="number_list">
                <ul>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                    <li
                      key={index}
                      data-index={index}
                      data-value={item}
                      onClick={this.onSelectLimitClick.bind(this)}
                      className={
                        this.state.selectIndex == index ? "highLight" : ""
                      }
                    >
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="degree">
                  <span>
                    非常
                    <br />
                    不愿意
                  </span>
                  <span>一般</span>
                  <span>
                    非常
                    <br />
                    愿意
                  </span>
                </div>
              </div>
              <div className="fill_recommend">
                <p>请填写评价</p>
                <div className="field_textarea">
                  <textarea
                    type="text"
                    placeholder="说说你对课程的感受或者收获吧，给别人一个参考。(请不少于20字)"
                    value={this.state.recommendText}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                <button onClick={this.handleSubmit.bind(this)}>确定</button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showRuleDialog && (
          <ActivityRuleDialog
            promotion={this.props.promotion}
            hideActivityRule={this.hideActivityRule}
          />
        )}
      </React.Fragment>
    );
  }
}

// 活动评论规则
export const ActivityRuleDialog = ({ promotion, hideActivityRule }) => {
  return (
    <div className="activity_rule">
      <div className="activity_rule_content">
        <div className="rule_content">
          <h3>活动规则</h3>
          <div className="react_markdown">
            {promotion && <ReactMarkdown source={promotion.rule} />}
          </div>
          <div
            className="close_btn_right"
            style={{ top: -4 }}
            onClick={() => hideActivityRule()}
          >
            &times;
          </div>
        </div>
      </div>
    </div>
  );
};

const AwakeSection = ({ day_study_exam, day_lock }) => {
  const score = day_study_exam.score >= 0;
  const goPracticeStyle = day_lock == false && !score;

  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : day_study_exam.url}
      className="awake_style"
    >
      <div
        className={`calendar_examine ${
          goPracticeStyle ? "go_practice_style" : ""
        }`}
      >
        {day_lock == false ? (
          score ? (
            <div className="score">
              <i>{day_study_exam.score}</i>分
            </div>
          ) : (
            <div className="go_practice awake_practice">去测试</div>
          )
        ) : (
          <img className="course_star" src={icon_lock_gray} />
        )}

        <div>
          <div className="knowledge_content">
            <h2 style={{ color: day_lock == true && "#999" }}>
              {day_study_exam.name}
            </h2>
          </div>

          <div className="course_subscrible_practice">
            <div className="course_amount_time">
              {<i>{day_study_exam.quizzes_count}道题</i>}
              {day_study_exam.count_down_mins && (
                <span>限时：{day_study_exam.count_down_mins}分钟</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const ArticlesSection = ({ article, index, handleArticleRequest }) => {
  return (
    <a
      target="_blank"
      href={article.url}
      className="media_lesson_style"
      data-url={article.create_study_record}
      onClick={debounce(handleArticleRequest)}
    >
      <div className="calendar_course go_class_style">
        <div className="go_class lesson_practice">查看</div>
        <div className="knowledge_content">
          <div className="media_lesson_names">
            <span>{index + 1}. </span>
            <h2>{article.name}</h2>
          </div>
        </div>
      </div>
    </a>
  );
};

const MediaLessonsSection = ({
  media_lesson,
  day_lock,
  icon_stars,
  lesson_time,
  is_parent_class,
}) => {
  let courseEverydayTotalStatus;
  const goClassStyle = day_lock == false && !icon_stars[media_lesson.rank];

  if (day_lock == true) {
    courseEverydayTotalStatus = (
      <img className="course_star" src={icon_lock_gray} />
    );
  } else {
    if (icon_stars[media_lesson.rank]) {
      courseEverydayTotalStatus = (
        <img className="course_star" src={icon_stars[media_lesson.rank]} />
      );
    } else {
      courseEverydayTotalStatus = (
        <div className="go_class lesson_practice">去上课</div>
      );
    }
  }

  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : media_lesson.url}
      data-url={day_lock == true ? "" : media_lesson.url}
      className="media_lesson_style"
    >
      <div
        className={`calendar_course ${goClassStyle ? "go_class_style" : ""}`}
      >
        {courseEverydayTotalStatus}
        <div className="knowledge_content">
          {!is_parent_class && (
            <div className="knowledge_small_title">
              {media_lesson.media_course_name}
            </div>
          )}
          <div className="media_lesson_names">
            {media_lesson.position && <span>{media_lesson.position}. </span>}
            <h2 style={{ color: day_lock == true && "#999" }}>
              {media_lesson.nickname ? `${media_lesson.nickname} - ` : ""}
              {media_lesson.original_name}
              {media_lesson.extension ? "【延伸课】" : ""}
            </h2>
          </div>
          {
            // media_lesson.objective && <p>{media_lesson.objective}</p>
          }
          <div className="course_subscrible_practice">
            <div className="course_time_difficulty">
              {lesson_time && <i>{lesson_time.time}</i>}
              {"resource_quiz_count" in media_lesson && (
                <span>{media_lesson.resource_quiz_count}次互动</span>
              )}
              {
                // “难度”字段 暂不做显示
                // (media_lesson.difficulty != null) && <span>{media_lesson.difficulty}</span>
              }
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CalcalateTrainingCamps = ({
  calculate_training_camps,
  day_lock,
  speedPracticeClick,
  day_study_start_time,
  now_date,
}) => {
  let handleClickSpeedPractice;
  let imgStar;

  if (day_lock === false) {
    handleClickSpeedPractice = day_lock === true ? null : speedPracticeClick;
    imgStar = "";
  } else {
    handleClickSpeedPractice =
      day_study_start_time < now_date ? speedPracticeClick : null;
    if (day_study_start_time > now_date) {
      imgStar = <img className="course_star" src={icon_lock_gray} />;
    }
  }

  return calculate_training_camps.map((camp, position) => (
    <a
      key={position}
      href="javascript:void(0)"
      onClick={handleClickSpeedPractice}
    >
      <div className="calendar_examine">
        {imgStar}
        <div className="knowledge_content">
          <h2
            style={{
              color:
                day_lock == true && day_study_start_time > now_date && "#999",
            }}
          >
            {camp.training_camp_name}（第{camp.position}关）：
            {camp.calculate_training_name}
          </h2>
        </div>
      </div>
    </a>
  ));
};

const TodayQuizSection = ({
  today_quiz_url,
  day_lock,
  today_quiz_status,
  quizzes_count,
}) => {
  let todayQuizStatus;
  const goPracticeStyle = day_lock === false && today_quiz_status === false;

  if (day_lock == true) {
    todayQuizStatus = <img className="course_star" src={icon_lock_gray} />;
  } else {
    if (today_quiz_status === false) {
      todayQuizStatus = (
        <div className="go_practice now_quiz_practice">去测试</div>
      );
    } else {
      todayQuizStatus = (
        <img className="course_star course_quiz_pass" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : today_quiz_url}
      className="today_quiz_style"
    >
      <div
        className={`calendar_examine ${
          goPracticeStyle ? "go_practice_style" : ""
        }`}
      >
        {todayQuizStatus}
        <div>
          <div className="knowledge_content">
            <h2 style={{ color: day_lock == true && "#999" }}>随堂测试</h2>
          </div>

          <div className="course_subscrible_practice">
            <div className="course_amount_time">
              <i>{quizzes_count}道题</i>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExamineSection = ({
  day_lock,
  exercise_quiz_url,
  exercise_quiz_status,
  exercise_quizzes_count,
}) => {
  let examineStatus;
  const goPracticeStyle = day_lock === false && exercise_quiz_status === false;

  if (day_lock == true) {
    examineStatus = <img className="course_star" src={icon_lock_gray} />;
  } else {
    if (exercise_quiz_status === false) {
      examineStatus = (
        <div className="go_practice now_quiz_practice">去练习</div>
      );
    } else {
      examineStatus = (
        <img className="course_star course_quiz_pass" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : exercise_quiz_url}
      className="examine_style"
    >
      <div
        className={`calendar_examine ${
          goPracticeStyle ? "go_practice_style" : ""
        }`}
      >
        {examineStatus}
        <div>
          <div className="knowledge_content">
            <h2 style={{ color: day_lock == true && "#999" }}>
              课外练习（选做）
            </h2>
          </div>

          <div className="course_subscrible_practice">
            <div className="course_amount_time">
              <i>{exercise_quizzes_count}道题</i>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
