import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import add_icon from "../../../../assets/images/icon-add-white.png";
import icon_uploader from "../../../../assets/images/reading/icon_uploader.png";
import icon_tips from "../../../../assets/images/reading/icon_tips.png";
import icon_reading_cancle from "../../../../assets/images/reading/icon_reading_cancle.png";
import { ImgDialog } from "../../utils/quizzes_practice_template/practiceTemplates";
import icon_title_bottom from "R2/icon_title_bottom.png";
import icon_reading_name from "reading/icon_reading_name.png";
import AnswerAnalysis from "../components/AnswerAnalysis";
// quizzes_practice_template/practiceTemplates
import UserAgent from "@/components/utils/UserAgent";
import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";
import cancle_green from "reading/cancle_green.png";
import { debounce, showDialog } from "../../utils/helpers";
import ReactMarkdown from "react-markdown";
import { ToastManager } from "../../utils/Toast";
import { request, csrfHeaders } from "../../utils/request";
import Raven from "raven-js";
import ExamRoom from "../components/ExamRoom";
import Word from "../components/Word";
import MyAnswer from "../components/MyAnswer";
import TeacherComments from "../components/TeacherComments";
import { data } from "jquery";
import Crystal from "point_rewards/crystal.png";

export const InfoBroadTitle = styled.div`
  position: relative;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  margin: 20px 15px 0px;
  padding: 80px 15px 30px;
  padding-bottom:${({ isImg }) => (isImg ? "20px" : "30px")};
  &::before {
    content: "${({ titles }) => titles}";
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    padding: 4px 20px;
    position: absolute;
    line-height: 22px;
    left: -5px;
    white-space: nowrap;
    top: 20px;
    background-color: #FFBA44;
    border-radius: 10px 50px 50px 0px;
  }
  &::after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    left: -5px;
    top: 49px;
    background: url(${icon_title_bottom})0 0/100% 100% no-repeat ;

  }
`;
const FileDelete = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 10px 0px 5px;
  right: 0px;
  top: 0;
  z-index: 100;
  // margin: -9px;
`;

const FileWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const StyledPhoto = styled.div`
  position: relative;
  width: calc((100% - 24px) / 3);
  // width: 100px;
  min-width: 98px;
  min-height: 76px;
  max-height: 76px;
  box-sizing: border-box;
  margin: 0 4px 10px;
  background: #eee;
  border-radius: 10px;
  display: -webkit-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  color: #999;
  .file {
    width: 100%;
  }
`;
export const Titles = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
      }}
    >
      <img src={icon_reading_name} style={{ width: 15, height: 9 }}></img>
      <div
        style={{
          margin: "0px 8px",
          fontSize: 18,
          fontFamily: " PingFang SC",
          fontWeight: "bold",
          color: "#FF973C",
        }}
      >
        {text}
      </div>
      <img src={icon_reading_name} style={{ width: 15, height: 9 }}></img>
    </div>
  );
};

export const Photo = ({
  file,
  index,
  setAnswerPhotos,
  isUploaded,
  isReviewed,
}) => {
  const [isOpenImgDialog, setOpenImgDialog] = useState(false);
  if (!file) return null;
  const url = isReviewed ? file : window.URL.createObjectURL(file);
  return (
    <StyledPhoto className="add-height">
      {!isUploaded && !isReviewed && (
        <FileDelete
          // src={cancle_green}
          onClick={() => {
            setAnswerPhotos((files) => {
              files.splice(index, 1);
              return [...files];
            });
          }}
        >
          <img src={icon_reading_cancle} className="icon_reading_cancle"></img>
        </FileDelete>
      )}
      {isOpenImgDialog && (
        <ImgDialog
          photo_url={url}
          handleImgDialog={() => setOpenImgDialog((pre) => !pre)}
        />
      )}
      <FileWrapper onClick={() => setOpenImgDialog((pre) => !pre)}>
        <img src={url} alt="" className="file" />
      </FileWrapper>
    </StyledPhoto>
  );
};

export default (props) => {
  console.log(props, "commit");
  const {
    generate_token_url: generateTokenUrl,
    day_study_record_url: dayStudyRecordUrl,
  } = props;
  const [answerArr, setAnswerArr] = useState([]);
  const [isUploaded, setUploaded] = useState(
    props.reading_comment_state == "pending_upload" ||
      props.reading_comment_state == "overtime"
      ? false
      : true
  );
  const inputOnChange = (e) => {
    const newFiles = e.target.files;
    console.log(newFiles);
    setAnswerArr((files) => [...files, ...newFiles]);
  };

  const submitAnswers = useCallback(async () => {
    // setUploaded(true);

    if (answerArr.length === 0) {
      alert("您还没有上传图片噢！");
      return;
    }
    // return;
    const toast = ToastManager.showLoading("图片上传中...");
    console.log("图片上传");
    try {
      const ids = await Promise.all(
        answerArr
          .filter((p) => !!p)
          .map((f) =>
            uploadFile({
              file: f,
              generateTokenUrl: generateTokenUrl,
            })
          )
      );
      console.log("ids", ids);
      const res = await request({
        url: dayStudyRecordUrl,
        method: "POST",
        headers: csrfHeaders,
        data: {
          day_study_answers: ids.map((r) => ({ answer_photo_id: r.id })),
        },
      });
      toast.cancel();
      setUploaded(true);
      if (!res.data.data.show_finish_whole_day_study) {
        window.location.reload();
      } else {
        props.handleShowReward();
      }
    } catch (e) {
      toast.cancel();
      console.log(e);
      const dialogOptions = {
        text: "上传出错，请重试",
        mainLabel: "确定",
        subLabel: "取消",
        mainCallback: () => {
          submitAnswers();
        },
      };
      showDialog.call(this, dialogOptions);
      Raven.captureException(e);
    }
  }, [answerArr]);
  const uploadToQiNiu = (file, token) =>
    new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("name", "");
      fd.append("token", token);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://upload.qiniup.com", true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            const obj = JSON.parse(xhr.responseText);
            console.log(obj);
            resolve(obj);
          } else {
            reject(JSON.parse(xhr.responseText).error);
          }
        }
      };
      xhr.onerror = (e) => {
        console.error(e);
        // if (UserAgent.isNativeClient()) {
        //   alert("上传成功！");
        // } else {
        // }
        reject(e);
      };

      xhr.send(fd);
    });

  const uploadFile = async ({ file, generateTokenUrl }) => {
    const res = await request({
      url: generateTokenUrl,
      method: "GET",
      headers: csrfHeaders,
      params: {
        type: "day_study_answer",
      },
    });
    const token = res.data.token;
    const photo = await uploadToQiNiu(file, token);
    console.log(photo);
    return { id: photo.id };
  };
  useEffect(() => {
    if (UserAgent.isIOS() && XinYaNativeBridge) {
      XinYaNativeBridge.requestPermission("camera", (granted) => {});
    }
  }, []);

  const timeInspect = (time) => {
    return (
      <span style={{ color: "#FF973C" }}>
        {time?.replace?.(/-/g, ".")} 23:59
      </span>
    )
  }

  return (
    <div className="examination-exercise">
      {(props.reading_comment_state === "pending_upload" || !isUploaded) && (
        <div className="examination-exercise-item answer-area">
          <div className="answer-area-title">完成纸质版练习，上传作业</div>
          {props.submit_reward_end_date && props.has_point_reward && !props.reward_expired ? (
            <div className="answer-area-tips" style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              <div>
                {timeInspect(props.submit_reward_end_date)}前上传，可额外获得5
              </div>
              <img src={Crystal} style={{width: '20px'}} />
              <div>奖励。</div>
            </div>
          ) : (
            ""
          )}
          <div className="answer-area-mian">
            {answerArr.map((answer_item, answer_index) => {
              return !answer_item ? (
                ""
              ) : (
                // <div key={"anser" + answer_index}>
                <Photo
                  className="answer-area-mian-item"
                  key={"anser" + answer_index}
                  file={answer_item}
                  index={answer_index}
                  setAnswerPhotos={setAnswerArr}
                  // isReviewed
                  isUploaded={false}
                ></Photo>

                // </div>
              );
            })}
            {answerArr.length < 1 && !isUploaded ? (
              <label
                className="answer-area-mian-item"
                key={"anser3"}
                id={`file_label_3`}
                // as="label"
                htmlFor={"reading_answer_photos"}
              >
                <div className="answer-area-mian-item-add">
                  <img src={add_icon}></img>
                </div>
                <div>添加作业照片</div>
              </label>
            ) : (
              ""
            )}
            {answerArr.length < 2 && !isUploaded ? (
              <label
                className="answer-area-mian-item"
                key={"anser0"}
                id={`file_label_0`}
                // as="label"
                htmlFor={"reading_answer_photos"}
              >
                <div className="answer-area-mian-item-add">
                  <img src={add_icon}></img>
                </div>
                <div>添加作业照片</div>
              </label>
            ) : (
              ""
            )}
            {isUploaded ? (
              ""
            ) : (
              <label
                className="answer-area-mian-item"
                key={"anser1"}
                id={`file_label_1`}
                // as="label"
                htmlFor={"reading_answer_photos"}
              >
                <div className="answer-area-mian-item-add">
                  <img src={add_icon}></img>
                </div>
                <div>添加作业照片</div>
              </label>
            )}
          </div>

          <input
            type="file"
            id="reading_answer_photos"
            accept="image/jpeg,image/jpg,image/png"
            style={{ display: "none" }}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={inputOnChange}
            multiple
          />
          {isUploaded ? (
            ""
          ) : (
            <div
              className={`uploader-item ${
                answerArr.length == 0 ? "uploader-item-mengceng" : ""
              } `}
              onClick={debounce(submitAnswers)}
              // onClick={() => {
              //   if (answerArr.length == 0) {
              //     return;
              //   }
              // }}
            >
              <div>确认上传</div>
              <img src={icon_uploader}></img>
            </div>
          )}
        </div>
      )}
      {!isUploaded ||
      props.reading_comment_state == "pending_upload" ||
      props.reading_comment_state == "overtime" ? (
        <div className="examination-exercise-item">
          <div className="tips-area-title">
            <div className="tips-area-title-icon">
              <img src={icon_tips}></img>
            </div>
            <div className="tips-area-title-font">温馨提示</div>
          </div>
          <div className="tips-area-content">
          {timeInspect(props.upload_end_date)}前，都可以获得老师的点评。超出上传时间，仅能查看答案解析哦。
          </div>
        </div>
      ) : (
        ""
      )}
      {isUploaded && props.answers && props.answers.length > 0 ? (
        <MyAnswer
          answerArr={props.answers}
          reading_comment_state={props.reading_comment_state}
          score_level={props.score_level}
          setUploaded={(bool) => {
            setUploaded(bool);
          }}
        ></MyAnswer>
      ) : (
        ""
      )}
      {props.reading_comment_state !== "pending_upload" &&
      props.reading_comment_state !== "overtime" &&
      props.reading_comment_state !== "uploaded" &&
      // props.course_consultant &&
      isUploaded ? (
        <TeacherComments
          titles={"老师评语"}
          reading_comment_state={props.reading_comment_state}
          course_consultant={props.course_consultant}
          comment_text={props.comment_text}
          comment_audio_url={props.comment_audio_url}
          comment_audio_info_url={props.comment_audio_info_url}
        ></TeacherComments>
      ) : (
        ""
      )}
      {props.reading_comment_state !== "pending_upload" &&
      // props.reading_comment_state !== "pending_comment" &&
      props.reading_comment_state !== "overtime" &&
      isUploaded &&
      props.rehearsal_analysis ? (
        <ExamRoom content={props.rehearsal_analysis}></ExamRoom>
      ) : (
        ""
      )}

      {props.reading_comment_state !== "pending_upload" &&
      // props.reading_comment_state !== "pending_comment" &&
      props.reading_comment_state !== "overtime" &&
      props.sub_subjects && props.sub_subjects.length > 0 &&
      isUploaded ? (
        <AnswerAnalysis
          content={props.material_analysis}
          sub_subjects={props.sub_subjects}
        ></AnswerAnalysis>
      ) : (
        ""
      )}
    </div>
  );
};
