import React, { Component } from "react";
import "./ReportHeaderDay.scss";

export default class ReportHeaderDay extends Component {
  render() {
    const { prevDayUrl, day, nextDayUrl, isUserStudyPackage } = this.props;
    return (
      <div className="date_head">
        {prevDayUrl ? (
          <a href={prevDayUrl} target="_self">
            前一天
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            style={{ background: "#fff", color: "#fff" }}
          >
            hide
          </a>
        )}
        <a href="javascript:void(0);">
          第 <i>{day}</i> {isUserStudyPackage ? "次" : "天"}
        </a>
        {nextDayUrl ? (
          <a href={nextDayUrl} target="_self">
            后一天
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            style={{ background: "#fff", color: "#fff" }}
          >
            hide
          </a>
        )}
      </div>
    );
  }
}
