import React from "react";
import PropTypes from "prop-types";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";

import "./LessonDetailsItem.scss";

const ICON_STARS = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

const LessonRightStatus = React.memo(({ lessonRank, isLessonLocked }) => {
  if (isLessonLocked) {
    return (
      <img className="poetry_star lazyload" data-src={icon_lock_gray} alt="" />
    );
  }
  if (typeof lessonRank === "number") {
    return (
      <img
        className="poetry_star lazyload"
        data-src={ICON_STARS[lessonRank]}
        alt=""
      />
    );
  }

  return <div className="poetry_start">开始</div>;
});

LessonRightStatus.propTypes = {
  lessonRank: PropTypes.number,
  isLessonLocked: PropTypes.bool,
};

const LessonDetailsItem = React.memo(
  ({
    url,
    name,
    rank,
    lock,
    isReviewLesson,
    usePoetryDetailInstallApp,
    setShowDown,
  }) => {
    const {
      onSetRemindInstallApp,
      reminderInstallAppModal,
    } = usePoetryDetailInstallApp();
    const onDetailClick = (e) => {
      // inner modal 的继续学习按钮事件冒泡
      let event = window.event;
      if (setShowDown) {
        setShowDown(true);
        e && e.preventDefault();
        event && event.preventDefault();
        return false;
      } else {
        window.location.href = url;
        return false;
      }

      // if (e.target.id === "modal_continue_study") {
      //   onSetRemindInstallApp && onSetRemindInstallApp(false);
      //   window.location.href = url;
      //   return false;
      // }
      // onSetRemindInstallApp return true,表明需要弹框
      // if (lock || (onSetRemindInstallApp && onSetRemindInstallApp(true))) {
      //   e.preventDefault();
      //   return false;
      // }
    };
    return (
      <div
        className={
          !isReviewLesson
            ? "comp_poetry_normal_lesson"
            : "comp_poetry_review_lesson"
        }
        // href={""}
        onClick={onDetailClick}
      >
        {reminderInstallAppModal && reminderInstallAppModal()}
        <p className="lesson_name">{name}</p>
        <LessonRightStatus lessonRank={rank} isLessonLocked={lock} />
      </div>
    );
  }
);

LessonDetailsItem.propTypes = {
  name: PropTypes.string,
  rank: PropTypes.number,
  url: PropTypes.string,
  lock: PropTypes.bool,
  isReviewLesson: PropTypes.bool,
  usePoetryDetailInstallApp: PropTypes.func,
};

export default LessonDetailsItem;
