import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";
import icon_teacher_photo from "R2/icon_teacher_photo.png";
import icon_teacher_voice from "reading/icon_teacher_voice.png";
import icon_teacher_voice_gif from "reading/icon_voice_play.gif";
import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";
import "./TeacherComments.scss";
import { request, csrfHeaders } from "../../utils/request";
import { formatAudioTime } from "../../utils/formatAudioTime";

export default (props) => {
  const [playVoice, setPlayVoice] = useState(false);
  const [times, setTimes] = useState("");

  useEffect(() => {
    if (props.comment_audio_info_url) {
      request({
        url: props.comment_audio_info_url,
        method: "GET",
        headers: csrfHeaders,
      }).then((res) => {
        console.log(res, "-------");
        if (res.data.format.duration) {
          // 01'05''
          // formatAudioTime
          setTimes(
            formatAudioTime(Number(res.data.format.duration)).replace(
              /\:/g,
              "'"
            ) + "''"
          );
        }
      });
    }
  }, []);
  const playAudio = () => {
    console.log("-======", playVoice);
    let audio = document.getElementById("teacher_comment");
    if (audio) {
      setPlayVoice(true);
      audio.play();
    }
  };
  return (
    <InfoBroadTitle isImg={false} titles={props.titles || "老师点评"}>
      <div className="teacher-comment">
        {props.reading_comment_state == "pending_comment" ? (
          ""
        ) : (
          <div className="teacher-comment-record">
            <div className="icon_teacher_photo">
              {props.course_consultant.photo_url ? (
                <img
                  src={props.course_consultant.photo_url}
                  style={{ borderRadius: "50%" }}
                ></img>
              ) : (
                ""
              )}
              {props.course_consultant.name ? (
                <div className="icon_teacher_photo_title">
                  {props.course_consultant.name}
                </div>
              ) : (
                ""
              )}
            </div>
            {props.comment_audio_url ? (
              <div
                className="teacher-comment-play"
                onClick={() => {
                  playAudio();
                }}
              >
                <div className="teacher-comment-play-time">{times}</div>
                {/* {console.log(playVoice, "playVoice")} */}
                <img
                  className="teacher-comment-play-icon"
                  src={playVoice ? icon_teacher_voice_gif : icon_teacher_voice}
                ></img>
                <audio
                  id="teacher_comment"
                  src={props.comment_audio_url}
                  onEnded={() => {
                    setPlayVoice(false);
                  }}
                ></audio>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        <div
          className="teacher-comment-font"
          style={{ marginTop: props.comment_audio_url ? "" : 0 }}
        >
          {props.comment_text
            ? <div className="markdown_content"><ReactMarkdown source={props.comment_text} /></div>
            : "老师将在作业上传后2个工作日内点评作业，并公布正确答案。"}
        </div>
      </div>
    </InfoBroadTitle>
  );
};
