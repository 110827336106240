import React, { memo, useState } from "react"
import PropTypes from "prop-types"
import ExcellentExerciseSingle from "./ExcellentExerciseSingle"
import LikeComponent from "../../shared/LikeComponent"
import Magnifier from "./Magnifier"
import "./UploadExerciseList.scss"
import NoExcellentExerciseIcon from "no-excellent-exercise.png"
import "../../shared/useful_css.scss"

const ExcellentExerciseList = ({excellentExercises, SidebarLabel, readingCommentState}) => {
  console.log("excellentExercises props are: ", readingCommentState)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [zoomInImages, setZoomInImages] = useState([])
  const List = () => {
    return excellentExercises.map((record, index) => {
      return (
        <ExcellentExerciseSingle
          photoUrl={record.day_study_answers[0]}
          index={index}
          key={index}
          liked={record.liked}
          likesCount={record.likes_count}
          likesUrl={record.likes_url}
          handleZoomIn={handleZoomIn}
          baby={record.baby}
        />
      )
    })
  }

  const neverUploaded = () => {
    return ['pending_upload', 'overtime'].indexOf(readingCommentState) >= 0
  }

  const handleZoomIn = (index) => {
    console.log("zoom in index is: ", index)
    setCurrentIndex(index)
    setZoomInImages(excellentExercises[index].day_study_answers)
  }

  const handleZoomOut = () => {
    setCurrentIndex(null)
    setZoomInImages([])
  }

  const praiseSlot = () => {
    const record = excellentExercises[currentIndex]
    return (
      <LikeComponent
        likesUrl={record.likes_url}
        liked={record.liked}
        likesCount={record.likes_count}
        customStyle={{position: 'absolute', top: '15px', right: '15px'}}
      />
    )
  }

  if (!excellentExercises || excellentExercises.length === 0 || readingCommentState === 'pending_upload') {
    const text = neverUploaded() ? '上传后，才能查看被老师选取的“优秀作业”哦' : '老师尚未指定优秀作业哦'
    return (
      <div className="list-block">
        {SidebarLabel("优秀作业")}
        <div className="flex flex-col justify-center items-center text-center" style={{marginTop: '30px', padding: 'auto 10px'}}>
          <img src={NoExcellentExerciseIcon} style={{width: '102px', height: '102px'}} />
          <div style={{fontSize: '16px', color: '#666666', marginTop: '15px'}}>{text}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="list-block" style={{position: 'relative'}}>
      {SidebarLabel("优秀作业")}
      <div className="list" style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
        <List />
      </div>
      { currentIndex !== null && <Magnifier handleZoomOut={handleZoomOut} images={zoomInImages} index={currentIndex} slot={praiseSlot} />}
    </div>
  )
}

export default memo(ExcellentExerciseList)

ExcellentExerciseList.propTypes = {
  excellentExercises: PropTypes.array.isRequired,
  readingCommentState: PropTypes.string.isRequired,
}