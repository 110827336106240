/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import icon_default_boy from "icon_default_boy.png";
import icon_default_girl from "icon_default_girl.png";
import starWhite from "study_package/icon_star_border.png";
import starYellow from "study_package/icon_star_yellow.png";
import { request, csrfHeaders } from "../../../../utils/request.js";
import { SmallAudioPlayer } from "../../../../utils/audio_player/AudioPlayer.jsx";
import { DateCountDown } from "../../../../utils/formatAudioTime.js";
import "./UserAudioInfoList.scss";
// 音频列表模块
export default class UserAudioInfoList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isStared: props.liked,
      starNumed: props.starNumed,
    };
  }

  staredClick = () => {
    request({
      url: this.props.likeUrl,
      method: "POST",
      headers: csrfHeaders,
    }).catch((err) => {
      console.log(err);
    });
    // 点赞 +1
    if (!this.state.isStared) {
      console.log("collect ++ zan");
      this.setState({
        isStared: true,
        starNumed: parseInt(this.state.starNumed) + 1,
      });
    } else {
      // 点赞 -1
      console.log("cancel -- zan");
      this.setState({
        isStared: false,
        starNumed: parseInt(this.state.starNumed) - 1,
      });
    }
  };

  render() {
    const { recorder, index, systemTime } = this.props;
    const { isStared, starNumed } = this.state;
    let audioUserGender;
    if (recorder.baby.photo_url == null) {
      if (recorder.baby.gender === "男") {
        audioUserGender = (
          <img data-src={icon_default_boy} alt="" className="lazyload" />
        );
      } else {
        audioUserGender = (
          <img data-src={icon_default_girl} alt="" className="lazyload" />
        );
      }
    } else {
      audioUserGender = (
        <img data-src={recorder.baby.photo_url} alt="" className="lazyload" />
      );
    }
    return (
      <li className="comp_user_info_container">
        <div className="user_info_container">
          <div className="user_info_wrapper">
            <div className="user_avatar">{audioUserGender}</div>
            <div className="text_info">
              <p>
                {recorder.baby.nickname}
                {recorder.baby.grade && <span>（{recorder.baby.grade}）</span>}
              </p>
              <div className="baby_info">
                <span>{DateCountDown(recorder.update_time, systemTime)}</span>
              </div>
            </div>
          </div>

          <div className="user_setting">
            <div className="stared">
              <div
                className={
                  isStared
                    ? "button_wrapper_active_lesson_one"
                    : "button_wrapper_lesson_one"
                }
                onClick={this.staredClick}
              >
                <img
                  style={{
                    marginLeft: isStared ? null : "5px",
                    paddingTop: isStared ? null : "3px",
                  }}
                  src={isStared ? starYellow : starWhite}
                  alt=""
                />
                {starNumed === 0 ? null : (
                  <div className="start_num">{starNumed}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="user_audio_recorder">
          <SmallAudioPlayer
            audioSrc={recorder.entire_record_url}
            audioInfoUrl={recorder.audio_info_url}
            index={index}
          />
        </div>
      </li>
    );
  }
}
