import { expr } from "jquery";
import React from "react";
import star_light from "c4/star_light.png";
import star_gery from "c4/star_gery.png";
export default ({ pass, rank }) => {
  if (pass) {
    let starArr = [];
    for (let index = 0; index < 3; index++) {
      if (index < rank) {
        starArr.push(true);
      } else {
        starArr.push(false);
      }
    }
    return (
      <>
        {starArr.map((star_item, star_index) => {
          return (
            <img
              src={star_item ? star_light : star_gery}
              key={star_index + "s"}
            ></img>
          );
        })}
      </>
    );
  } else {
    return "";
  }
};
