import React from "react";
import PropTypes from "prop-types";
import poetryAppInstall from "new_poetry/tip_download.png";
import "./RemindInstallAppModal.scss";

const RemindInstallAppModal = ({ onModalCloseClick }) => {
  return (
    <div className="comp_install_app_modal_container">
      <div className="modal_close" onClick={onModalCloseClick}></div>
      <div className="modal_content">
        <img data-src={poetryAppInstall} className="lazyload" />
        <div className="continue_study" id="modal_continue_study">
          继续学习，以后再安装
        </div>
      </div>
    </div>
  );
};

RemindInstallAppModal.propTypes = {
  onModalCloseClick: PropTypes.func,
};

export default RemindInstallAppModal;
