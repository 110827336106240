/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogManager } from "../../../../utils/Dialog";
import { formatDotDate } from "../../../../utils/formatAudioTime";
import RightFloatIcons from "../../../../study_package_activities/RightFloatIcons.jsx";
import { StudyPackageCommentModule } from "../../../../study_packages/UnitModeStudyPackage.jsx";
import { SharePosterProxy } from "../../../../promotion_activities/SharePoster";
import WithPlatformEnv from "../../../../utils/WithPlatformEnv.jsx";
import { onNavigate } from "../../../../utils/helpers";
import { GA, GA_new } from "../../../../utils/Env";
import InstallAppReminder from "../components/InstallAppReminder.jsx";
import NewPoetryBuyDialog from "../components/NewPoetryBuyDialog.jsx";
import UnlockedAllCourseReminder from "../components/UnlockedAllCourseReminder.jsx";
import useCoursePayment from "../../../sync_practice/components/CoursePayment.jsx";
import CourseAuditionCoupon from "../../../CourseAuditionCoupon.jsx";

import * as Components from "../components";
import icon_package_placeholder from "study_package/icon_package_placeholder.png";
import icon_lock from "study_package/icon_lock_white.png";
import icon_warning from "study_package/icon_warning.png";
import "./SimplePoetryList.scss";

const SimplePoetryList = memo((props) => {
  console.log("SimplePoetryList props: ", props);
  const {
    unitData: {
      study_package_modules: studyPackageModules,
      upgrade_package: upgradePackage,
      batch_ecoupon: batchEcoupon,
      handbook,
      ecoupons,
      ecoupons_study_required: ecouponsStudyRequired,
      description,
    },
  } = props;
  const {
    create_recommend_request_url,
    comment_state,
    unitName,
    study_report_url,
    lock,
    upgrade_package_required,
    upgrade_package,
    study_package_activity_id,
    bonus,
    is_spell,
    study_package_system_time,
    sub_course_type,
  } = props;

  /**
   * 注：上面
   * unitData中的upgradePackage 和 props中的upgrade_package是不同模式的解锁字段
   * */

  const [writeEvaluateDialogState, setWriteEvaluateDialogState] = useState(
    false
  );
  const [createRecommendRequestUrl, setCreateRecommendRequestUrl] = useState(
    create_recommend_request_url
  );
  const [isScrolledAnchor, setIsScrolledAnchor] = useState(false);
  const [isShowReminderModal, setIsShowReminderModal] = useState(false);
  const [subSlideAnchor, setSubSlideAnchor] = useState(-1);

  const onStudyReportIconClick = (e) => {
    GA(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      "study_reports",
      props.study_package_activity_id
    );
    GA_new(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      "study_reports",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    // window.location.href = study_report_url;
    onNavigate(e, `${study_report_url}&ref=shortcut_course`);
  };

  const onInvitationIconClick = useCallback(() => {
    const {
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
    } = props;
    GA(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      "package_poster",
      props.study_package_activity_id
    );
    GA_new(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      "package_poster",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    const free = "none_shopping_group";
    const sharePosterProxy = new SharePosterProxy({
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: free,
      bonus: bonus,
    });
    sharePosterProxy.open();
  }, [bonus, props]);

  const getCreateRecommendRequestUrl = () => {
    setCreateRecommendRequestUrl("");
  };

  // 点击“写评价” 控制显示
  const getWriteEvaluateStatus = () => {
    setWriteEvaluateDialogState(true);
    GA(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      "report_comment",
      props.study_package_activity_id
    );
    GA_new(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      "report_comment",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
  };

  // 关闭评价弹框
  const hideWriteEvaluateState = () => {
    setWriteEvaluateDialogState(false);
  };

  // const handleBackToTop = useCallback(() => {
  //   $("#_scroll_list").scrollTop(0);
  // }, []);

  useEffect(() => {
    if (!isScrolledAnchor) {
      const anchorCurrentProgress = document.getElementById("current_progress");

      // 二级导航
      const subSlideElement = document.getElementsByClassName(
        "sub_slide_theme_guide"
      );

      // 试听单元
      const trialPoetryUnit = document.getElementsByClassName(
        "trial_poetry_unit"
      );

      // 优惠券
      const courseAuditionCouppon = document.getElementsByClassName(
        "course_audition_couppon"
      );

      if (anchorCurrentProgress) {
        let scrollVal = 0;
        let currentAnchorFirstVal = 0;
        const subThemeGuide = subSlideElement[0]
          ? subSlideElement[0].offsetHeight
          : 0;

        const trialPoetryUnitVal = trialPoetryUnit[0]
          ? trialPoetryUnit[0].offsetHeight
          : 0;

        const courseAuditionCoupponVal = courseAuditionCouppon[0]
          ? courseAuditionCouppon[0]
          : 0;

        const poetryUnitContainer = document.getElementsByClassName(
          "poetry_unit"
        );
        const poetryUnitContainerArr = [].slice.call(poetryUnitContainer);
        // console.log("poetryUnitContainerArr: ", poetryUnitContainerArr);

        poetryUnitContainerArr.some((container, index) => {
          const dataId = poetryUnitContainerArr[index].getAttribute("data-id");

          // 因为第一个单元的元素有180的高度
          // 所以如果所学习的进度正好落在了第一个元素上, 高度要额外的加上去
          const dataFirstUnit = poetryUnitContainerArr[index].getAttribute(
            "data-first"
          );
          if (dataId && dataFirstUnit) {
            console.info(
              "第一个元素和dataId同时存在: ",
              dataId && dataFirstUnit,
              ", ",
              dataId && dataFirstUnit && "true"
            );
            currentAnchorFirstVal = 200;
          } else {
            currentAnchorFirstVal = 0;
          }
          if (container.getAttribute("data-id") === "current_progress") {
            return true;
          }
          scrollVal += container.offsetHeight + 10;
          // console.log("container: ", container);
        });

        console.log("currentAnchorFirstVal: ", currentAnchorFirstVal);

        // 计算向上滑动总高度
        // 注: scrollVal等于0代表是第一天学习
        const totalScrollHeight =
          scrollVal === 0
            ? 0
            : Number(scrollVal) +
              Number(subThemeGuide) +
              Number(currentAnchorFirstVal) +
              Number(trialPoetryUnitVal) +
              Number(courseAuditionCoupponVal) +
              40;
        console.log(
          "scrollVal: ",
          scrollVal,
          "totalScrollHeight: ",
          totalScrollHeight
        );

        $("#_scroll_list").animate(
          {
            scrollTop: totalScrollHeight,
          },
          500
        );
        setIsScrolledAnchor(true);
      }
    }
  }, [isScrolledAnchor]);

  // 是体验课的判断
  const isTrialCourse = handbook === true;

  // 非客户端判断
  const isNotNativeClient = !props.platformEnv.isNativeClient;

  // 正价课 + 购买成功
  const isBoughtSuccess = !isTrialCourse && props.bought ? "buy_success" : "";

  // 正价课 + 未购买
  const isNotBoughtSuccess =
    !isTrialCourse && !props.bought ? "is_not_buy_success" : "";

  // 体验课
  const isTrialSuccess = isTrialCourse ? "is_trial_course" : "";

  // 正价课 + 非客户端 + 购买成功
  const isVipBuySuccess =
    !isTrialCourse && isNotNativeClient && props.bought
      ? "is_vip_buy_success"
      : "";

  // 购买课程链接
  let buyUrl;
  if (!is_spell) {
    buyUrl = upgrade_package && upgrade_package.study_package_activity_buy_url;
  }

  const onModalOpenClick = (boolTrue) => {
    setIsShowReminderModal(boolTrue);
    GA("studyPackages", "Unlock", study_package_activity_id);
  };
  const getCloseModalStatus = (boolFalse) => {
    setIsShowReminderModal(boolFalse);
  };

  // subSlideGuide 定位锚点
  const getSubSlideGuideAnchor = (anchorIdx) => {
    setSubSlideAnchor(anchorIdx);
  };

  const getMatchAnchorStatus = useCallback(() => {
    let anchor = 0;
    if (subSlideAnchor < 0) return;
    studyPackageModules.forEach((_element, index) => {
      if (Number(subSlideAnchor) === Number(index)) {
        anchor = index;
      }
    });
    return {
      index: anchor,
      item: studyPackageModules[anchor],
    };
  }, [studyPackageModules, subSlideAnchor]);

  useEffect(() => {
    const poetryListBox = document.getElementsByClassName("poetry_list_box");
    const poetryListBoxArr = [].slice.call(poetryListBox);
    console.log("getMatchAnchorStatus: ", getMatchAnchorStatus());

    if (poetryListBox && getMatchAnchorStatus() !== undefined) {
      const { index } = getMatchAnchorStatus();
      if (poetryListBox && index >= 0) {
        poetryListBoxArr[index].scrollIntoView(true);
        window.scrollBy({
          top: 0,
        });
        setSubSlideAnchor(-1);
      }
    }
  }, [getMatchAnchorStatus]);

  return (
    <div
      className={`simple_poetry_list_box ${isBoughtSuccess} ${isTrialSuccess}${isNotBoughtSuccess}${isVipBuySuccess}`}
    >
      {
        // 注: 只有一个模块内容时，二级模块导航不显示
        studyPackageModules &&
          studyPackageModules.length > 1 &&
          !studyPackageModules[0]?.poetry_articles && (
            <SubSlideGuide
              studyPackageModules={studyPackageModules}
              getSubSlideGuideAnchor={getSubSlideGuideAnchor}
            />
          )
      }
      <PoetryListBox
        lock={lock}
        isTrialCourse={isTrialCourse}
        upgradePackageRequired={upgrade_package_required}
        studyPackageModules={studyPackageModules}
        onModalOpenClick={onModalOpenClick}
        upgradePackageExpand={upgradePackage}
        isSpell={is_spell}
        isSanZiJing={sub_course_type === "sanzijing"}
        ecoupons={ecoupons}
        study_package_activity_id={props.study_package_activity_id}
        target_id={props.target_id}
        target_type={props.target_type}
        ecouponsStudyRequired={ecouponsStudyRequired}
        description={description}
        systemTime={study_package_system_time}
      />
      {/* {isTrialCourse &&
        // (batchEcoupon ? (
        //   // 显示优惠券
        //   <Components.SendCoupons batchEcoupon={batchEcoupon} />
        // ) : (
        // 体验课的体验单元模块 显示列表项购买入口
        // )
        // )
        upgrade_package_required && (
          <Components.UnlockAllPoetryGuide
            url={`${buyUrl}&ref=unlock_poetry_trial`}
          />
        )} */}
      <Components.CurrentUnitEnd text={`${unitName}结束`} />
      {
        // upgrade_package_required: false 正价课
        isNotNativeClient && (isTrialCourse || !buyUrl) && (
          <InstallAppReminder />
        )
      }
      {buyUrl && (
        <UnlockedAllCourseReminder
          url={
            upgrade_package_required
              ? `${buyUrl}&ref=unlock_poetry_footer`
              : buyUrl
          }
        />
      )}
      <RightFloatIcons
        isShowMakeComment={
          comment_state === "uncommented" && !!createRecommendRequestUrl
        }
        onMakeCommentClick={getWriteEvaluateStatus}
        isShowRedMoney={!!props.bonus}
        onRedMoneyClick={onInvitationIconClick}
        isShowReport={!!study_report_url}
        onReportClick={onStudyReportIconClick}
        bottomPosition={
          buyUrl || (isNotNativeClient && (isTrialCourse || !buyUrl)) ? 80 : 20
        }
        // isShowScrollTop
        // onScrollTop={handleBackToTop}
      />
      {!!writeEvaluateDialogState && (
        <StudyPackageCommentModule
          create_recommend_request_url={createRecommendRequestUrl}
          comment_target={props.comment_target}
          current_user_comment={props.current_user_comment}
          bought_phase={props.bought_phase}
          promotion={props.promotion}
          rate={props.rate}
          name={props.study_package_name}
          hideDialog={hideWriteEvaluateState}
          getCreateRecommendRequestUrl={getCreateRecommendRequestUrl}
        />
      )}
      {isShowReminderModal && (
        <NewPoetryBuyDialog
          getCloseModalStatus={getCloseModalStatus}
          url={`${buyUrl}&ref=unlock_poetry_pop`}
        />
      )}
    </div>
  );
});
const PoetryListBox = memo(({ ...props }) => {
  console.log("PoetryListBox_props----sim: ", props);
  const {
    lock,
    isTrialCourse,
    upgradePackageRequired,
    studyPackageModules,
    onModalOpenClick,
    upgradePackageExpand,
    isSpell,
    ecoupons,
    ecouponsStudyRequired,
    description,
    systemTime,
    isSanZiJing,
  } = props;

  console.log("PoetryListBox studyPackageModules: ", studyPackageModules);

  if (!studyPackageModules) return "";
  return (
    <div className="poetry_list_container">
      {studyPackageModules.map((study, index) => (
        <PoetryUnit
          key={index}
          systemTime={systemTime}
          ecoupons={ecoupons}
          ecouponsStudyRequired={ecouponsStudyRequired}
          description={description}
          isTrialCourse={isTrialCourse}
          backgroundUrl={study.photo_url}
          renderSimplePoetryUnit={() => (
            <SimplePoetryUnit
              lock={lock}
              isTrialCourse={isTrialCourse}
              upgradePackageRequired={upgradePackageRequired}
              dayStudies={study.day_studies}
              currentStudyDate={study.current_study_date}
              onModalOpenClick={onModalOpenClick}
              upgradePackageExpand={upgradePackageExpand}
              sub_course_type={props.sub_course_type}
              study_package_activity_id={props.study_package_activity_id}
              target_id={props.target_id}
              target_type={props.target_type}
              isSpell={isSpell}
              isSanZiJing={isSanZiJing}
            />
          )}
        />
      ))}
    </div>
  );
});

const PoetryUnit = memo(({ ...props }) => {
  const {
    systemTime,
    ecoupons,
    ecouponsStudyRequired,
    description,
    isTrialCourse,
    backgroundUrl,
    renderSimplePoetryUnit,
  } = props;
  return (
    <div
      className={`poetry_list_box ${isTrialCourse ? "is_trial_list_box" : ""}`}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      {isTrialCourse && description && (
        <div className="trial_poetry_unit">
          <div className="trial_course_describe">
            <img src={icon_warning} alt="" />
            {description}
          </div>
        </div>
      )}
      {isTrialCourse && ecoupons && ecoupons.length > 0 && (
        <CourseAuditionCoupon
          ecoupons={ecoupons}
          ecouponsStudyRequired={ecouponsStudyRequired}
          systemTime={systemTime}
        />
      )}
      {renderSimplePoetryUnit()}
    </div>
  );
});

const ButtonReviewOrBegainStudy = memo(
  ({ studyRelateClassStatus, progressRelateText }) => {
    return (
      <div className={`button_begain_study ${studyRelateClassStatus}`}>
        <div className="progress_icon_study"></div>
        <p>{progressRelateText}</p>
      </div>
    );
  }
);
const useFixUnExpectedUnicode = () => {
  const ref = React.useRef();
  useEffect(() => {
    const text = ref.current.innerHTML;
    try {
      if (text.charCodeAt(0) === 8) {
        const arr = text.split("");
        arr.shift();
        ref.current.innerHTML = arr.join("");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  return ref;
};
const ArticleDescribe = ({ text }) => {
  const tempRef = useFixUnExpectedUnicode();
  return (
    <div className="article_describe" ref={tempRef}>
      {text}
    </div>
  );
};
const UnitCourseContent = memo(
  ({ isSpell, day_study, renderButtonStudy, isSanZiJing }) => {
    // study_type: 0(精讲)、1(拓展)、2(单元练习)、3(复习)、4(期末考)
    const unitPoetryName = useCallback(
      (day_study, text) => {
        const isNotMark =
          day_study.study_type === 1 ||
          day_study.study_type === 2 ||
          day_study.study_type === 4;
        const isReviewSubName =
          day_study.study_type === 2 || day_study.study_type === 4;
        const expandClassMark =
          isNotMark || isSanZiJing ? "is_expand_class_mark" : "";
        const reviewClassMark = isReviewSubName ? "is_review_class_mark" : "";
        return (
          <div className="unit_articles">
            {isReviewSubName || !text ? "" : <ArticleDescribe text={text} />}
            <div className={`title ${expandClassMark} ${reviewClassMark}`}>
              {isNotMark || isSpell || isSanZiJing
                ? `${day_study.name}`
                : `${`《${day_study.name}》`}`}
            </div>
          </div>
        );
      },
      [isSanZiJing, isSpell]
    );
    const unitPoetryArticles = useCallback(
      (day_study, text) => {
        return (
          <div className="unit_articles">
            <ArticleDescribe text={text} />
            <div className={`title`}>
              {isSanZiJing ? day_study.name : `《${day_study.name}》`}
            </div>
          </div>
        );
      },
      [isSanZiJing]
    );

    const reviewBrief = useCallback((reviewMediaLessons = []) => {
      return (
        <div className="review_brief">
          {reviewMediaLessons.map((lesson, index) => (
            <div className="brief" key={index}>
              {lesson.name}
            </div>
          ))}
        </div>
      );
    }, []);

    const awakenReviewBrief = useCallback(
      (day_study) => {
        return day_study.review_media_lessons.length > 0 ? (
          day_study.review_media_lessons.map((media_lesson, index) => (
            <div className="awaken_review_brief" key={index}>
              <ArticleDescribe text={day_study.sub_name} />
              <div className="review_brief">
                {isSanZiJing
                  ? `${media_lesson.name}`
                  : `《${media_lesson.name}》`}
              </div>
            </div>
          ))
        ) : (
          <div className="awaken_review_brief">
            {/* <div className="article_describe">{day_study.sub_name}</div> */}
            <div className="review_study_name" style={{ paddingLeft: 0 }}>
              {day_study.name}
            </div>
          </div>
        );
      },
      [isSanZiJing]
    );

    // 单元复习（有复习朗诵）
    const hasReviewMediaLessons = useMemo(() => {
      const lessons = [
        {
          name: "复习朗诵",
        },
        {
          name: `${isSanZiJing ? "" : "诗词"}小擂台`,
        },
      ];
      if (isSanZiJing) {
        return lessons;
      } else {
        lessons.push({
          name: "泛读诗",
        });
      }
      return lessons;
    }, [isSanZiJing]);

    // 单元复习（无复习朗诵）
    const noReviewMediaLessons = [
      {
        name: `${isSanZiJing ? "" : "诗词"}小擂台`,
      },
      {
        name: "泛读诗",
      },
    ];

    return (
      <div className="unit_content">
        <img
          data-src={`${day_study.photo_url}?imageView2/1/w/360/h/200`}
          className="lazyload"
          alt=""
        />
        <div className="right_unit_content">
          {day_study.study_type === 3 ? (
            awakenReviewBrief(day_study)
          ) : (
            <div className="article_section">
              {day_study?.articles?.length > 0 && (
                <div className="extra_text">{day_study.articles[0].name}</div>
              )}
              {unitPoetryName(day_study, day_study.sub_name)}
              {
                // 非拼音课
                !isSpell &&
                  day_study?.poetry_articles?.length > 0 &&
                  unitPoetryArticles(day_study.poetry_articles[0], `泛读`)
              }
              {day_study.study_type === 2
                ? reviewBrief(
                    day_study.has_review_media_lessons
                      ? hasReviewMediaLessons
                      : noReviewMediaLessons
                  )
                : reviewBrief(day_study.review_media_lessons)}
            </div>
          )}
          {
            // 非锁定状态时，才显示”开始学习“按钮
            !day_study.day_lock && !day_study.lock && renderButtonStudy()
          }
        </div>
      </div>
    );
  }
);

const SubSlideGuide = memo(
  ({ studyPackageModules, getSubSlideGuideAnchor }) => {
    const handleAnchorFocus = (e) => {
      const anchorIndex = e.currentTarget.getAttribute("data-index");
      console.log("anchorIndex: ", anchorIndex);
      getSubSlideGuideAnchor(anchorIndex);
    };

    if (!studyPackageModules) return "";
    return (
      <div className="sub_slide_theme_guide clear_scroll_bar">
        {studyPackageModules.map((theme, index) => (
          <div
            className="poetry_theme"
            key={index}
            data-index={index}
            onClick={handleAnchorFocus}
          >
            <div className="img_container">
              <img data-src={theme.photo_url} className="lazyload" alt="" />
            </div>
            <div className="theme_name">{theme.name}</div>
          </div>
        ))}
      </div>
    );
  }
);

const SimplePoetryUnit = memo(({ ...props }) => {
  const {
    lock,
    isTrialCourse,
    upgradePackageRequired,
    dayStudies,
    currentStudyDate,
    onModalOpenClick,
    upgradePackageExpand,
    isSpell,
    isSanZiJing,
  } = props;

  // console.warn("SimplePoetryUnit_dayStudies数据处理: ", dayStudies);

  // 增加拓展版解锁
  const onPayModalOpen = useCoursePayment(upgradePackageExpand);

  const handleCoursePay = useCallback(() => {
    onPayModalOpen();
  }, [onPayModalOpen]);

  const iconLockSection = useCallback(() => {
    return (
      <div className="lock_status">
        <img src={icon_lock} alt="" />
      </div>
    );
  }, []);

  const alreadyFinishStudy = useCallback(() => {
    return <div className="already_finish_study"></div>;
  }, []);

  const currentProgressGuideText = useCallback((currentProgressText) => {
    return (
      <div className="current_progress" id="current_progress">
        {currentProgressText}
      </div>
    );
  }, []);

  const DayAndDate = memo(({ dayStudy, isFinishSymbolClass }) => {
    const {
      day,
      date,
      handbook,
      week,
      week_start,
      week_end,
      customize_name,
    } = dayStudy;
    const isSameDay =
      formatDotDate(dayStudy.start_rest_day) ===
      formatDotDate(dayStudy.end_rest_day);
    const holidayDate = isSameDay
      ? formatDotDate(dayStudy.start_rest_day)
      : `${formatDotDate(dayStudy.start_rest_day)} - ${formatDotDate(
          dayStudy.end_rest_day
        )}`;
    const dayDate = (
      <div className="date" style={isSpell ? { fontSize: "15px" } : {}}>
        {!day ? holidayDate : `（${formatDotDate(date)}）`}
      </div>
    );
    const weekDate = `（${formatDotDate(week_start)} - ${formatDotDate(
      week_end
    )}）`;

    const datePattern = week ? weekDate : dayDate;

    return (
      <div className={`day_and_date ${isFinishSymbolClass}`}>
        {
          // 休息日隐藏
          day && (
            <div className="day">
              {customize_name || (week ? `第${week}周` : `第${day}天`)}
            </div>
          )
        }
        {!upgradePackageRequired && !handbook && datePattern}
      </div>
    );
  });

  const includeFirstDay = useCallback((handbook) => {
    return handbook ? "请先从这里开始" : "开始第1天学习";
  }, []);

  const start_study = (day_study, linkRedirect) => {
    GA(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      day_study.state === "pass" ? "review" : "start_day_study",
      props.study_package_activity_id
    );
    GA_new(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : "study_package_details",
      day_study.state === "pass" ? "review" : "start_day_study",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    window.location.href = linkRedirect;
    // handleCoursePay();
  };

  // 锁定的课时，点击时弹提示
  const handleLockedDialog = (day_study) => {
    let text;
    if (day_study.week) {
      text = `课程将于${formatDotDate(
        day_study.week_start
      )}日解锁，请到时再来学习`;
    } else {
      if (lock === "unlock_by_day") {
        text = `课程将于${formatDotDate(day_study.date)}解锁，请到时再来学习`;
      } else {
        text = "请先学完前一天的内容，或等日期到了解锁";
      }
    }
    const buttons = [
      {
        label: "我知道了",
        onClick: () => confirmDialog.close(),
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>{text}</Dialog>
    );
  };
  if (!dayStudies) return "";
  return (dayStudies || []).map((day_study, index) => {
    const activeCurrentProgress = day_study.current_progress_date === true;
    const isActiveCurrentStudyDate = currentStudyDate === day_study.date;
    const isExerciseMark =
      day_study.study_type === 2 || day_study.study_type === 4;

    let studyRelateClassStatus;
    if (isActiveCurrentStudyDate) {
      studyRelateClassStatus = "active_current_study_progress";
    }
    if (!isActiveCurrentStudyDate && day_study.state === "pass") {
      studyRelateClassStatus = "button_review";
    }
    if (isExerciseMark) {
      studyRelateClassStatus = "button_exercise_mark";
    }

    // 日期处理
    const currentProgressText =
      Number(day_study.day) === 1
        ? includeFirstDay(day_study.handbook)
        : day_study.start_rest_day
        ? "今日休息"
        : "上次学到这里，继续";
    const progressRelateText =
      day_study.state === "pass"
        ? "复习"
        : isExerciseMark
        ? "开始"
        : "开始学习";
    const isFinishSymbolClass =
      day_study.state === "pass" ? "has_finish_symbol" : "";
    const isSimpleUnitLockedClass = day_study.day_lock
      ? "simple_poetry_unit_locked"
      : "";

    // 增加拓展版解锁样式
    const spellSimpleUnitLockedClass = day_study.lock
      ? "spell_simple_poetry_unit_locked"
      : `${isSimpleUnitLockedClass}`;
    const isActiveBoxShadowClass = isActiveCurrentStudyDate
      ? "active_box_shadow"
      : "";

    let linkRedirect;
    if (day_study.lock) {
      linkRedirect = "javascript:void(0)";
    } else {
      if (day_study.day_lock) {
        linkRedirect = "javascript:void(0)";
      } else {
        linkRedirect = day_study.url;
      }
    }

    return day_study.day ? (
      <div
        onClick={
          () => {
            if (day_study.lock) {
              handleCoursePay();
              // if(day_study.day_lock){

              // }
            } else {
              if (day_study.day_lock) {
                if (upgradePackageRequired) {
                  onModalOpenClick(true);
                } else {
                  handleLockedDialog(day_study);
                }
              } else {
                start_study(day_study, linkRedirect);
                // return ""
              }
            }
            // !day_study.lock
            //   ? !day_study.day_lock
            //     ? start_study(day_study, linkRedirect)
            //     : handleLockedDialog(day_study)
            //   : !day_study.day_lock
            //   ?
            //   : upgradePackageRequired
            //   ? onModalOpenClick(true)
            //   : handleLockedDialog(day_study);
          }
          // day_study.lock
          //   ? start_study(day_study)
          //   : day_study.day_lock
          //   ? upgradePackageRequired
          //     ? onModalOpenClick(true)
          //     : handleLockedDialog(day_study)
          //   : null
        }
        className="poetry_unit"
        key={index}
        // href={linkRedirect}
        data-id={`${isActiveCurrentStudyDate ? "current_progress" : ""}`}
        data-first={`${Number(index) === 0 ? "first_unit" : ""}`}
      >
        {!isTrialCourse && upgradePackageRequired
          ? ""
          : isActiveCurrentStudyDate &&
            currentProgressGuideText(currentProgressText)}
        <div
          className={`simple_poetry_unit ${spellSimpleUnitLockedClass} ${isActiveBoxShadowClass}`}
        >
          {day_study.state === "pass" && alreadyFinishStudy()}
          {(day_study.day_lock || day_study.lock) && iconLockSection()}

          <DayAndDate
            dayStudy={day_study}
            isFinishSymbolClass={isFinishSymbolClass}
          />
          <UnitCourseContent
            isSanZiJing={isSanZiJing}
            isSpell={isSpell}
            day_study={day_study}
            renderButtonStudy={() => (
              <ButtonReviewOrBegainStudy
                studyRelateClassStatus={studyRelateClassStatus}
                progressRelateText={progressRelateText}
              />
            )}
          />
        </div>
      </div>
    ) : (
      // 增加节假日
      <HolidayRestPrompt
        key={index}
        dayStudy={day_study}
        currentProgressText={
          !isTrialCourse && upgradePackageRequired
            ? ""
            : activeCurrentProgress &&
              currentProgressGuideText(currentProgressText)
        }
        activeCurrentProgress={activeCurrentProgress}
        renderDayAndDate={() => (
          <DayAndDate
            dayStudy={day_study}
            isFinishSymbolClass={isFinishSymbolClass}
          />
        )}
      />
    );
  });
});

// 增加语文类课表节假日
const HolidayRestPrompt = ({
  dayStudy,
  currentProgressText,
  activeCurrentProgress,
  renderDayAndDate,
}) => {
  return (
    <a
      className="poetry_unit holiday_poetry_unit"
      data-id={activeCurrentProgress ? "current_progress" : ""}
      href="javascript: void(0)"
    >
      {currentProgressText}
      <div
        className={`simple_poetry_unit ${
          dayStudy.day_lock ? "simple_poetry_unit_locked" : ""
        } ${activeCurrentProgress ? "active_box_shadow" : ""}`}
      >
        {renderDayAndDate()}
        <div className="unit_content holiday_unit_content">
          <img
            data-src={`${icon_package_placeholder}?imageView2/1/w/360/h/200`}
            className="lazyload"
            alt=""
          />
          <div className="right_unit_content">
            <p className="holiday_text">休息日</p>
            <p>补课或复习之前的内容吧</p>
          </div>
        </div>
      </div>
    </a>
  );
};

SimplePoetryList.propTypes = {
  studyPackageModules: PropTypes.array,
};

export default WithPlatformEnv(SimplePoetryList);
