import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./ImageDialog.scss";
import ComponentProxy from "../core/ComponentProxy";

class ImageDialog extends React.Component {
  handleImgTransition = () => {
    let imgTransionElement = document.getElementById("_img_transition");
    if (imgTransionElement) {
      this.setState({
        imgTransionHeight: imgTransionElement.offsetHeight,
      });
    }
  };
  render() {
    return (
      <div className="comp-image-dialog">
        <div className="image_absolute">
          <div className="image_relative">
            <img
              onLoad={this.handleImgTransition}
              src={this.props.imgSrc}
              id={this.props.transition ? "_img_transition" : null}
            />
            <div
              className="btn-close"
              id="_btn_close"
              onClick={this.props.onClose}
            />
          </div>
        </div>
      </div>
    );
  }
}

ImageDialog.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  transition: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default class ImageDialogProxy {
  open() {
    if (!this.node) {
      this.node = document.createElement("div");
      document.body.appendChild(this.node);
    }
    ReactDOM.render(this.createComponent(), this.node);
  }

  constructor(props) {
    this.imgSrc = props.imgSrc;
    this.transition = props.transition ? props.transition : false;
    if (!(typeof props.onClose === "function")) {
      this.onClose = () => this.cancel();
    } else {
      this.onClose = props.onClose;
    }
  }

  cancel() {
    ReactDOM.unmountComponentAtNode(this.node);
    this.node = null;
  }

  createComponent() {
    return (
      <ImageDialog
        imgSrc={this.imgSrc}
        transition={this.transition}
        onClose={() => this.cancel()}
      />
    );
  }
}
