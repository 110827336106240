/* eslint-disable react/prop-types */
import React, { useCallback } from "react";

import InnerModal from "../../../../utils/Modal/InnerModal.jsx";
import ModalMask from "../../../../utils/Modal/ModalMask.jsx";
import poetryTipsUnlock from "new_poetry/tips_unlock.png";

import "./NewPoetryBuyDialog.scss";

const NewPoetryBuyDialog = ({ getCloseModalStatus, url }) => {
  const onModalCloseClick = useCallback(() => {
    getCloseModalStatus(false);
  }, [getCloseModalStatus]);
  return (
    <InnerModal>
      <ModalMask>
        <div className="modal_container_buy_dialog">
          <div
            className="modal_close"
            onClick={() => onModalCloseClick()}
          ></div>
          <div className="modal_content">
            <img data-src={poetryTipsUnlock} className="lazyload" />
            <a className="continue_study" href={url}>
              立即报名
            </a>
          </div>
        </div>
      </ModalMask>
    </InnerModal>
  );
};
export default NewPoetryBuyDialog;
