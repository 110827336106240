import React, { memo } from "react";
import "./InstallAppReminder.scss";

const InstallAppReminder = memo(() => {
  return (
    <div className="comp_install_app_reminder">
      <div className="flex_wrapper">
        <dl>
          <dt>当前环境暂不支持跟读录音功能</dt>
          <dd>请安装【心芽学堂】App，以获得更好的学习效果</dd>
        </dl>
        <a
          className="install_btn"
          href="https://a.app.qq.com/dom/micro/open.jsp?pkgname=cn.fireflykids.app"
        >
          安装
        </a>
      </div>
    </div>
  );
});

export default InstallAppReminder;
