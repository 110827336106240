import React, { Component } from "react";
import DayExamSubject from "../../utils/study_report_info/DayExamSubject";
import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";

import "./DayStudyExam.scss";

export default class DayStudyExam extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const list = this.props.list;
    const day_study_exam = this.props.dayStudyExam;
    const poetry_report_rank = [
      dialog_star_0,
      dialog_star_1,
      dialog_star_2,
      dialog_star_3,
    ];

    return (
      <div key={day_study_exam.id} className="test_subject">
        <div className="subject_head">
          {this.props.courseType && this.props.courseType === "poetry" ? (
            <img
              src={poetry_report_rank[day_study_exam.score]}
              alt=""
              style={{
                width: "120px",
                margin: "0px auto 10px",
                display: "block",
              }}
            />
          ) : (
            day_study_exam.score && (
              <h3>
                {day_study_exam.score}
                <i>分</i>
              </h3>
            )
          )}
          <p>{day_study_exam.name}</p>
        </div>
        {!day_study_exam.media_contents ? (
          day_study_exam.url && <a href={day_study_exam.url}>去测试 »</a>
        ) : (
          <div>
            {day_study_exam.media_contents.map((media_content, index) => (
              <DayExamSubject
                key={media_content.id}
                mediaContent={media_content}
                index={index}
                list={list}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}
