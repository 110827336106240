import React, { Component } from "react";
import Hammer from "react-hammerjs";
import "./ImageEdit.scss";
/** 
 * USEAGE:
 * Example: <ImageEdit key={photo.src} photo={photo} edited={this.editedPhoto} />
 * 三个参数说明
 * key: 原始图片地址。变化后重新挂载编辑组件
 * 
 * photo: 原始图片信息。格式为：
    {
    "src": "blob:http://localhost:3000/f32b7f3b-1536-49c1-a8bf-94cd00b463bd",
    "width": 540,
    "height": 258
    }
 * edited: 组件处理结果输出。返回给父级组件。格式：
 {
  "src": "blob:http://localhost:3000/f32b7f3b-1536-49c1-a8bf-94cd00b463bd",
  "width": 540, //  图片尺寸
  "height": 258,
  "left": -79.36102236421726, //  图片偏移位置
  "top": 0,
}
*/

// HammerJs组件配置信息
const HamerOpts = {
  touchAction: "compute",
  recognizers: {
    pan: {
      threshold: 10,
      direction: 30,
      velocity: 0.25,
    },
    pinch: {
      enable: true,
      velocity: 0.25,
    },
  },
};

class ImageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // photo: this.props.photo,
      output: {
        left: 0,
        top: 0,
        scale: 1,
      },
      init: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        ratio: 1,
      },
      relitiveScale: 1,
    };
  }
  // 缩放图片
  handlePinch = (e) => {
    document.body.style.overflow = "hidden";
    // TODO:缩放后offset优化
    let img = document.getElementById("_IMAGE_");
    let { width, height, top, left } = this.state.init;
    let scale = this.state.relitiveScale * Math.sqrt(e.scale);
    if (scale < 1) {
      scale = 1;
      img.style.top = top;
      img.style.left = left;
    }
    if (scale > 3) scale = 3;

    img.style.width = width * scale + "px";
    img.style.height = height * scale + "px";

    this.setState({
      relitiveScale: scale,
    });
    console.log(
      "pinch... w/h =>",
      img.width,
      img.height,
      "RelitiveScale=>",
      scale,
      e.scale
    );
  };
  // 移动图片
  handlePan = (e) => {
    let img = document.getElementById("_IMAGE_");
    let wrap = img.parentElement;
    let { width, height, offsetLeft, offsetTop } = img;
    let finTop, finLeft;
    console.log("Pan Event", e);
    let panRatio = (0.25 * e.distance) / e.deltaTime;
    e.deltaX = e.deltaX * panRatio;
    e.deltaY = e.deltaY * panRatio;
    // e.velocity = e.velocity * panRatio

    // 拖动范围
    // offsetTop/Left 不能出界，即始终是负值
    // 右移上限是当前offsetX值，左移是图宽-盒宽-左offsetX值
    // 下移上限是offsetY值，上移是图高-盒高-上offsetY
    if (e.deltaX > 0) {
      finLeft = offsetLeft + Math.min(Math.abs(offsetLeft), e.deltaX);
    } else {
      finLeft =
        offsetLeft -
        Math.min(Math.abs(e.deltaX), width - wrap.clientWidth + offsetLeft);
    }

    if (e.deltaY > 0) {
      finTop = offsetTop + Math.min(Math.abs(offsetTop), e.deltaY);
    } else {
      finTop =
        offsetTop -
        Math.min(Math.abs(e.deltaY), height - wrap.clientHeight + offsetTop);
    }
    // TODO: 偏移误差优化
    if (offsetLeft > 0 || offsetTop > 0) {
      return;
    }

    img.style.top = finTop + "px";
    img.style.left = finLeft + "px";
    this.setState({
      image: {
        left: finLeft,
        top: finTop,
      },
    });

    // console.log('panEnd... offset(x,y)=>', offsetLeft, offsetTop, 'delta=>(x,y)', e.deltaX, e.deltaY, 'FIN->' ,finLeft, finTop, e)
  };

  handlePanEnd = (e) => {
    // document.body.style.overflow = "auto";
    // OUTPUT
    this.outputImageData();
  };
  handlePinchEnd = (e) => {
    // OUTPUT
    this.outputImageData();
  };
  // 初始化图片尺寸和位移
  initImage = () => {
    let { width, height } = this.props.photo;
    let img = document.querySelector("#_IMAGE_");
    let wrap = img.parentElement;

    let iRatio = width / height;
    let wRatio = wrap.clientWidth / wrap.clientHeight;

    if (iRatio > wRatio) {
      img.height = wrap.clientHeight;
      img.width = wrap.clientHeight * iRatio;
      // 水平位移
      img.style.left = -(img.width - wrap.clientWidth) / 2 + "px";
    } else {
      img.width = wrap.clientWidth;
      img.height = wrap.clientWidth / iRatio;
      // 垂直位移
      img.style.top = -(img.height - wrap.clientHeight) / 2 + "px";
    }

    this.setState({
      init: {
        width: img.width,
        height: img.height,
        left: img.offsetLeft,
        top: img.offsetTop,
        ratio: img.width / width,
      },
    });
    this.outputImageData();
  };
  outputImageData = () => {
    let img = document.querySelector("#_IMAGE_");
    let wrap = img.parentElement;
    // TOTO: 裁切原始图片
    // 原始图片
    let oimg = this.props.photo;
    let oRatio = img.width / oimg.width;
    let eRatio = wrap.clientWidth / img.width;
    //  输出最终图片尺寸信息
    let oData = {
      src: oimg.src,
      width: img.width,
      height: img.height,
      left: img.offsetLeft,
      top: img.offsetTop,
    };
    this.props.edited(oData);
  };

  componentDidMount() {
    this.initImage();
    // document.documentElement.style.overflow = 'hidden'
    // document.body.style.overflow = 'hidden'
    // document.documentElement.style.position = 'fixed'
    // document.body.style.position = 'fixed'
    $("html, body").addClass("no_scroll");
    window.addEventListener("touchmove", (e) => {
      e.preventDefault;
      e.stopPropagation();
    });
  }
  componentWillUnmount() {
    $("html, body").removeClass("no_scroll");
    // document.documentElement.className = this.cacheClass;
    // document.body.className = this.cacheClass;
  }

  render() {
    return (
      <Hammer
        onPan={this.handlePan}
        onPanEnd={this.handlePanEnd}
        onPinch={this.handlePinch}
        onPinchEnd={this.handlePinchEnd}
        options={HamerOpts}
      >
        <img id="_IMAGE_" src={this.props.photo.src} />
      </Hammer>
    );
  }
}

export default ImageEdit;
