import React from "react";
import PropTypes from "prop-types";
import "./PoetryReading.scss";

const UNIT_READING_ITEM_STYLE = {
  marginTop: 0,
  borderBottom: "1px dashed lightgrey",
};

const UNIT_READING_ITEM_CONTAINER_STYLE = {
  borderRadius: 0,
  boxShadow: "none",
};

const PoetryReading = ({
  isUnitReview,
  poetry_article_url,
  author,
  name,
  photo_url,
}) => {
  return (
    <div
      className="comp_poetry_readings"
      style={isUnitReview ? UNIT_READING_ITEM_CONTAINER_STYLE : {}}
    >
      <a
        className="external_poetry_item"
        href={poetry_article_url}
        style={isUnitReview ? UNIT_READING_ITEM_STYLE : {}}
      >
        <div className="external_poetry_wrapper">
          <div
            className="img_wrapper"
            style={{ background: photo_url ? "none" : "#f5f5f5" }}
          >
            <img data-src={photo_url} className="lazyload" />
          </div>
          <div className="peotry_info">
            <div className="peotry_title">{name}</div>
            <div className="author_dynasty">{author}</div>
          </div>
        </div>
        <div className="arrow_left"></div>
      </a>
    </div>
  );
};

PoetryReading.propTypes = {
  isUnitReview: PropTypes.bool,
  poetry_article_url: PropTypes.string,
  author: PropTypes.string,
  name: PropTypes.string,
  photo_url: PropTypes.string,
};

export default PoetryReading;
