import React from "react";
import PropTypes from "prop-types";
import LazyloadContent from "../../utils/lazyload/LazyloadContent";
import "./ReportQuizItem.scss";

export const ReportPracticeLearnItem = ({ subquestion, iddx, totalCount }) => {
  return (
    <div className="practice_new_report_box">
      <div className="practice_report_info practice_report_subquestion">
        <div className="option_title option_subquestion">
          {totalCount && <span>{`${iddx + 1}/${totalCount}`}</span>}
          <p>{subquestion.question}</p>
        </div>
        {subquestion.question_photo_url != null && (
          <div className="question_pic" style={{ padding: "5px 0 5px 35px" }}>
            <img src={subquestion.question_photo_url} />
          </div>
        )}
        {subquestion.question_type === 1 ? (
          <OptionQuestionType subquestion={subquestion} />
        ) : (
          <FillQuestionType correctAnswer={subquestion.correct_answer} />
        )}
        {subquestion.hint && (
          <div className="problem_solving">
            <div className="solving_text">解题思路：</div>
            <LazyloadContent content={subquestion.hint} />
          </div>
        )}
      </div>
    </div>
  );
};

const OptionQuestionType = ({ subquestion }) => {
  const correct_option = subquestion.correct_option.correct;
  return subquestion.options.length > 0 ? (
    <div className="options_wrapper">
      {subquestion.options.map((option, ix) => (
        <div
          className={`options ${
            option.correct === correct_option ? "highlight" : ""
          }`}
          key={ix}
        >
          {option.photo ? <img src={option.photo.url} /> : option.content}
        </div>
      ))}
    </div>
  ) : null;
};

const FillQuestionType = ({ correctAnswer }) => {
  return (
    <div className="report_answer">
      <div className="right_answer">
        <p className="title">正确答案：</p>
        <p>{correctAnswer}</p>
      </div>
    </div>
  );
};
ReportPracticeLearnItem.propTypes = {
  subquestions: PropTypes.array,
};
