import React from "react";
import PropTypes from "prop-types";

const ListContainer = React.memo(function ListContainer({
  list,
  listItem,

  fallBack,
}) {
  return list && list.length > 0
    ? list.map((item, itemIndex) => listItem({ ...item, itemIndex }))
    : typeof fallBack === "function"
    ? fallBack()
    : "";
});

ListContainer.propTypes = {
  list: PropTypes.array,
  listItem: PropTypes.func,
  // baby: PropTypes.object,
  fallBack: PropTypes.func,
};

export default ListContainer;
