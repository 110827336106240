import React from "react";
import ReactDOM from "react-dom";

class InnerModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRoot = document.getElementById("portal_root");
    if (!this.modalRoot) {
      this.modalRoot = document.createElement("div");
      document.body.appendChild(this.modalRoot);
    }
    this.el = document.createElement("div");
  }

  componentCleanup = () => {
    document.querySelector("html, body").classList.remove("no_scroll");
    try {
      this.modalRoot.removeChild(this.el);
    } catch (e) {}
  };

  componentDidMount() {
    document.querySelector("html, body").classList.add("no_scroll");
    this.modalRoot.appendChild(this.el);
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentWillUnmount() {
    document.querySelector("html, body").classList.remove("no_scroll");
    try {
      this.modalRoot.removeChild(this.el);
    } catch (e) {}
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
export default InnerModal;
