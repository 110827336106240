import React from "react";
import UserAgent from "./UserAgent.js";
import { isDevelopment, isProduction } from "./Env.js";

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

export const useEnv = () => {
  const envParams = React.useMemo(() => ({
    platformEnv: {
      isIOS: UserAgent.isIOS(),
      isAndroid: UserAgent.isAndroid(),
      isNativeClient: UserAgent.isNativeClient(),
      isWechat: UserAgent.isWechat(),
      isWebBrowser: UserAgent.isWebBrowser(),
      isMiniProgram: UserAgent.isMiniProgram(),
      isUnknown: UserAgent.isUnknown(),
    },
    projectEnv: {
      isDevelopment: isDevelopment(),
      isProduction: isProduction(),
    },
  }));

  return envParams;
};

const WithEnv = function (WrappedComponent) {
  return class WithEnv extends React.Component {
    static displayName = `WithEnv(${getDisplayName(WrappedComponent)})`;

    constructor(props) {
      super(props);
      this.state = {
        platformEnv: {
          isIOS: UserAgent.isIOS(),
          isAndroid: UserAgent.isAndroid(),
          isNativeClient: UserAgent.isNativeClient(),
          isWechat: UserAgent.isWechat(),
          isWebBrowser: UserAgent.isWebBrowser(),
          isMiniProgram: UserAgent.isMiniProgram(),
          isUnknown: UserAgent.isUnknown(),
        },
        projectEnv: {
          isDevelopment: isDevelopment(),
          isProduction: isProduction(),
        },
      };
      this.checkNamespace();
    }

    checkNamespace = () => {
      if (this.state.projectEnv.isDevelopment) {
        if ("platformEnv" in this.props || "projectEnv" in this.props) {
          if (this.state.projectEnv["isDevelopment"]) {
            throw new Error(
              "The name 'platformEnv' or 'projectEnv' is used in [WithEnv HOC], please use others instead"
            );
          } else {
            console.warn(
              "The name 'platformEnv' or 'projectEnv' is used in [WithEnv HOC], please use others instead"
            );
          }
        }
      }
      try {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.state["platformEnv"] = {
              ...this.state["platformEnv"],
              isMiniProgram: true,
            };
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
};

export default WithEnv;
