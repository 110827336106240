/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import UserAgent from "../../../../utils/UserAgent";
import XinYaNativeBridge from "../../../../../packs/native_bridges/native_bridge";

import { Dialog, DialogManager } from "../../../../utils/Dialog";
import { formatDotDate } from "../../../../utils/formatAudioTime";
import RightFloatIcons from "../../../../study_package_activities/RightFloatIcons.jsx";
import { StudyPackageCommentModule } from "../../../../study_packages/UnitModeStudyPackage.jsx";
import { SharePosterProxy } from "../../../../promotion_activities/SharePoster";
import WithPlatformEnv from "../../../../utils/WithPlatformEnv.jsx";
import { onNavigate, clipboard } from "../../../../utils/helpers";
import { GA, GA_new } from "../../../../utils/Env";
import InstallAppReminder from "../components/InstallAppReminder.jsx";
import NewPoetryBuyDialog from "../components/NewPoetryBuyDialog.jsx";
import UnlockedAllCourseReminder from "../components/UnlockedAllCourseReminder.jsx";
import useCoursePayment from "../../../sync_practice/components/CoursePayment.jsx";
import CourseAuditionCoupon from "../../../CourseAuditionCoupon.jsx";
import back_tari_course from "../../../../../../assets/images/back_tari_course.png";
import back_tari_course_title from "../../../../../../assets/images/back_tari_course_title.png";
import * as Components from "../components";
import icon_package_placeholder from "study_package/icon_package_placeholder.png";
import icon_lock from "study_package/icon_lock_white.png";
import icon_warning from "study_package/icon_warning.png";
import icon_children_back_read from "../../../../../../assets/images/icon_children_back_read.png";

import icon_back_test_shop from "../../../../../../assets/images/icon_back_test_shop.png";
// import "../../../../../components/day_study_reports/StudyReport.scss";
import "./SimplePoetryList.scss";
import "./ReadingList.scss";

export const PdfPracticeNoteCopy = ({
  // media_lessons,
  handlePdfPractice,
  copyUrl,
  unitPdfUrl,
  unitName,
  // activityCode,
  // isPoetry,
}) => {
  // const CopyPdfUrlList = ({ title }) => {
  //   return (
  //     <div className="pdf_quiz_lists">
  //       <div className="left_title">{title}</div>
  //       {media_lessons.map((media_lesson, index) => {
  //         const {
  //           media_course_name,
  //           name,
  //           media_lesson_pdf_url,
  //         } = media_lesson;
  //         return !media_lesson.media_lesson_pdf_url ? (
  //           ""
  //         ) : (
  //           <div className="quiz_list" key={index}>
  //             <div className="left_section">
  //               <div className="practice_title">
  //                 {isPoetry
  //                   ? media_course_name
  //                   : !!media_course_name
  //                   ? `${activityCode}-${media_course_name}-${name}`
  //                   : `${activityCode}-${name}`}
  //               </div>
  //               {/* <div className="practice_url">{media_lesson_pdf_url}</div> */}
  //             </div>
  //             <div
  //               className="right_section"
  //               onClick={copyUrl}
  //               data-index={index}
  //             >
  //               复制网址
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const UnitPdfUrlList = ({ title }) => {
    return (
      <div className="pdf_quiz_lists">
        <div className="left_title">{title}</div>
        <div className="quiz_list">
          <div className="left_section">
            <div className="practice_title">
              {unitName ? unitName : "课件"}
              {/* {isPoetry
                ? `${unitName}单元全部讲义`
                : `${activityCode}-${unitName}单元全部练习题`} */}
            </div>
            {/* <div className="practice_url">{unitPdfUrl}</div> */}
          </div>
          <div className="right_section" onClick={copyUrl} data-index={`-1`}>
            复制网址
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="pdf_practice_copy">
      <div className="overlay" />
      <div className="dialog_container">
        <div className="title">建议使用电脑浏览器访问以下网址打印</div>
        <div
          className="close_button_right"
          onClick={handlePdfPractice.bind(this, false)}
        >
          &times;
        </div>
        <div className="pdf_quiz_lists_container">
          {/* <CopyPdfUrlList title={`附件`} /> */}
          {unitPdfUrl && <UnitPdfUrlList title={`附件`} />}
        </div>
      </div>
    </div>
  );
};
const SimplePoetryList = memo((props) => {
  console.log("SimplePoetryList props: -read", props);
  const {
    unitData: {
      study_package_modules: studyPackageModules,
      upgrade_package: upgradePackage,
      batch_ecoupon: batchEcoupon,
      handbook,
      ecoupons,
      trial_period_notice,
      is_trial_course,

      ecoupons_study_required: ecouponsStudyRequired,
      description,
    },
  } = props;
  const {
    create_recommend_request_url,
    comment_state,
    unitName,
    study_report_url,
    lock,
    upgrade_package_required,
    upgrade_package,
    study_package_activity_id,
    bonus,
    is_spell,
    study_package_system_time,
    sub_course_type,
  } = props;

  /**
   * 注：上面
   * unitData中的upgradePackage 和 props中的upgrade_package是不同模式的解锁字段
   * */

  const [writeEvaluateDialogState, setWriteEvaluateDialogState] = useState(
    false
  );
  const [pdfPracticeUrlCopy, setPdfPracticeUrlCopy] = useState(false);
  const [createRecommendRequestUrl, setCreateRecommendRequestUrl] = useState(
    create_recommend_request_url
  );
  const [isScrolledAnchor, setIsScrolledAnchor] = useState(false);
  const [isShowReminderModal, setIsShowReminderModal] = useState(false);
  const [subSlideAnchor, setSubSlideAnchor] = useState(-1);

  const onStudyReportIconClick = (e) => {
    GA(
      "study_package_details",
      "study_reports",
      props.study_package_activity_id
    );
    GA_new(
      "study_package_details",
      "study_reports",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    // window.location.href = study_report_url;
    onNavigate(e, `${study_report_url}&ref=shortcut_course`);
  };

  const onInvitationIconClick = useCallback(() => {
    const {
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
    } = props;
    GA(
      "study_package_details",
      "report_poster",
      props.study_package_activity_id
    );
    GA_new(
      "study_package_details",
      "report_poster",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    const free = "none_shopping_group";
    const sharePosterProxy = new SharePosterProxy({
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: free,
      bonus: bonus,
    });
    sharePosterProxy.open();
  }, [bonus, props]);

  const getCreateRecommendRequestUrl = () => {
    setCreateRecommendRequestUrl("");
  };

  // 点击“写评价” 控制显示
  const getWriteEvaluateStatus = () => {
    // console.log("-------------", props);
    GA(
      "study_package_details",
      "packageComment",
      props.study_package_activity_id
    );
    GA_new(
      "study_package_details",
      "package_comment",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    setWriteEvaluateDialogState(true);
  };

  // 关闭评价弹框
  const hideWriteEvaluateState = () => {
    setWriteEvaluateDialogState(false);
  };
  const handleWrongBookClick = () => {
    $(`.head_menu_li:last`).trigger("click");
  };
  // const handleBackToTop = useCallback(() => {
  //   $("#_scroll_list").scrollTop(0);
  // }, []);

  useEffect(() => {
    if (!isScrolledAnchor) {
      const anchorCurrentProgress = document.getElementById("current_progress");
      if (anchorCurrentProgress) {
        let progressMarkTop = anchorCurrentProgress.getBoundingClientRect().top;
        // 45: top tab height
        // 124 second tab height
        // 160: list box padding top
        let isFirstDayStudy = false;
        try {
          isFirstDayStudy =
            studyPackageModules[0].current_study_date ===
            studyPackageModules[0].day_studies[0][0].date;
        } catch (e) {}

        if (isFirstDayStudy) {
          progressMarkTop = 20;
        }
        $("#_scroll_list").animate(
          {
            scrollTop: progressMarkTop - 20,
          },
          500
        );
        setIsScrolledAnchor(true);
      }

      // // 二级导航
      // const subSlideElement = document.getElementsByClassName(
      //   "sub_slide_theme_guide"
      // );

      // // 试听单元
      // const trialPoetryUnit = document.getElementsByClassName(
      //   "trial_poetry_unit"
      // );

      // // 优惠券
      // const courseAuditionCouppon = document.getElementsByClassName(
      //   "course_audition_couppon"
      // );

      // if (anchorCurrentProgress) {
      //   let scrollVal = 0;
      //   let currentAnchorFirstVal = 0;
      //   const subThemeGuide = subSlideElement[0]
      //     ? subSlideElement[0].offsetHeight
      //     : 0;

      //   const trialPoetryUnitVal = trialPoetryUnit[0]
      //     ? trialPoetryUnit[0].offsetHeight
      //     : 0;

      //   const courseAuditionCoupponVal = courseAuditionCouppon[0]
      //     ? courseAuditionCouppon[0]
      //     : 0;

      //   const poetryUnitContainer = document.getElementsByClassName(
      //     "poetry_unit"
      //   );
      //   const poetryUnitContainerArr = [].slice.call(poetryUnitContainer);
      //   // console.log("poetryUnitContainerArr: ", poetryUnitContainerArr);

      //   poetryUnitContainerArr.some((container, index) => {
      //     const dataId = poetryUnitContainerArr[index].getAttribute("data-id");

      //     // 因为第一个单元的元素有180的高度
      //     // 所以如果所学习的进度正好落在了第一个元素上, 高度要额外的加上去
      //     const dataFirstUnit = poetryUnitContainerArr[index].getAttribute(
      //       "data-first"
      //     );
      //     if (dataId && dataFirstUnit) {
      //       console.info(
      //         "第一个元素和dataId同时存在: ",
      //         dataId && dataFirstUnit,
      //         ", ",
      //         dataId && dataFirstUnit && "true"
      //       );
      //       currentAnchorFirstVal = 200;
      //     } else {
      //       currentAnchorFirstVal = 0;
      //     }
      //     if (container.getAttribute("data-id") === "current_progress") {
      //       return true;
      //     }
      //     scrollVal += container.offsetHeight + 10;
      //     // console.log("container: ", container);
      //   });

      //   console.log("currentAnchorFirstVal: ", currentAnchorFirstVal);

      //   // 计算向上滑动总高度
      //   // 注: scrollVal等于0代表是第一天学习
      //   const totalScrollHeight =
      //     scrollVal === 0
      //       ? 0
      //       : Number(scrollVal) +
      //         Number(subThemeGuide) +
      //         Number(currentAnchorFirstVal) +
      //         Number(trialPoetryUnitVal) +
      //         Number(courseAuditionCoupponVal) +
      //         40;
      //   console.log(
      //     "scrollVal: ",
      //     scrollVal,
      //     "totalScrollHeight: ",
      //     totalScrollHeight
      //   );
    }
  }, [isScrolledAnchor, studyPackageModules]);

  // 是体验课的判断
  const isTrialCourse = handbook === true;

  // 非客户端判断
  const isNotNativeClient = !props.platformEnv.isNativeClient;

  // 正价课 + 购买成功
  const isBoughtSuccess = !isTrialCourse && props.bought ? "buy_success" : "";

  // 正价课 + 未购买
  const isNotBoughtSuccess =
    !isTrialCourse && !props.bought ? "is_not_buy_success" : "";

  // 体验课
  const isTrialSuccess =
    isTrialCourse && !is_trial_course ? "is_trial_course" : "";

  // 正价课 + 非客户端 + 购买成功
  const isVipBuySuccess =
    !isTrialCourse && isNotNativeClient && props.bought
      ? "is_vip_buy_success"
      : "";
  const outTime = new Date(ecoupons && ecoupons[0].end_time) > new Date();
  // 购买课程链接
  let buyUrl;
  if (!is_spell) {
    buyUrl = upgrade_package && upgrade_package.study_package_activity_buy_url;
  }
  const handlePdfPractice = (boolState) => {
    setPdfPracticeUrlCopy(boolState);
  };
  const handleCopyUrl = (e) => {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    // console.log('parentIndex: ', parentIndex);

    let currentClickUrl = trial_period_notice.document.download_url;
    // parentIndex == -1
    //   ? this.props.unit_pdf_url
    //   : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  };
  const onModalOpenClick = (boolTrue) => {
    setIsShowReminderModal(boolTrue);
    GA("studyPackages", "Unlock", study_package_activity_id);
  };
  const getCloseModalStatus = (boolFalse) => {
    setIsShowReminderModal(boolFalse);
  };

  // subSlideGuide 定位锚点
  const getSubSlideGuideAnchor = (anchorIdx) => {
    setSubSlideAnchor(anchorIdx);
  };

  const getMatchAnchorStatus = useCallback(() => {
    let anchor = 0;
    if (subSlideAnchor < 0) return;
    studyPackageModules.forEach((_element, index) => {
      if (Number(subSlideAnchor) === Number(index)) {
        anchor = index;
      }
    });
    return {
      index: anchor,
      item: studyPackageModules[anchor],
    };
  }, [studyPackageModules, subSlideAnchor]);

  useEffect(() => {
    const poetryListBox = document.getElementsByClassName("poetry_list_box");
    const poetryListBoxArr = [].slice.call(poetryListBox);
    console.log("getMatchAnchorStatus: ", getMatchAnchorStatus());

    if (poetryListBox && getMatchAnchorStatus() !== undefined) {
      const { index } = getMatchAnchorStatus();
      if (poetryListBox && index >= 0) {
        poetryListBoxArr[index].scrollIntoView(true);
        window.scrollBy({
          top: 0,
        });
        setSubSlideAnchor(-1);
      }
    }
  }, [getMatchAnchorStatus]);

  return (
    <div
      className={`simple_poetry_list_box simple_reading_list_box ${isBoughtSuccess} ${isTrialSuccess}${isNotBoughtSuccess}${isVipBuySuccess}`}
    >
      {
        // 注: 只有一个模块内容时，二级模块导航不显示
        studyPackageModules &&
          studyPackageModules.length > 1 &&
          !studyPackageModules[0]?.poetry_articles && (
            <SubSlideGuide
              studyPackageModules={studyPackageModules}
              getSubSlideGuideAnchor={getSubSlideGuideAnchor}
            />
          )
      }
      {is_trial_course && ecoupons && trial_period_notice && (
        <div className="back_tari_course">
          {/* <img src={back_tari_course}></img> */}
          <div className="back_tari_course_connect_top">
            <div className="back_tari_course_connect_top_1">
              <div className="back_tari_course_connect_top_hrader">
                <img src={back_tari_course_title}></img>
                <div>{trial_period_notice.title}</div>
                <img src={back_tari_course_title}></img>
              </div>
              <div className="back_tari_course_connect_top_body">
                {trial_period_notice.contents.map((item, index) => {
                  return (
                    <div
                      className="back_tari_course_title_item"
                      key={"a" + item}
                    >
                      <div className="back_tari_course_title_li"></div>
                      <div style={{ wordBreak: "break-all", width: "98%" }}>
                        {item}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="back_tari_course_connect_top_2">
              <div className="back_tari_course_connect_top_2_title">
                {trial_period_notice.document.name}
              </div>
              <div style={{ minWidth: !props.is_android_os ? 145 : 71 }}>
                {!props.is_android_os && (
                  // {props.platformEnv.isIOS && (
                  <div
                    className="back_tari_course_connect_top_2_look"
                    onClick={() => {
                      window.location.href =
                        trial_period_notice.document.download_url;
                      // "https://upload.fireflybaby.cn/file/656feb418549068c280d7bff6cfd0a10.pdf";
                    }}
                  >
                    预览
                  </div>
                )}
                <div
                  className="back_tari_course_connect_top_2_down"
                  onClick={() => {
                    handlePdfPractice(true);
                  }}
                >
                  下载
                </div>
              </div>
            </div>
          </div>
          {(!ecoupons[0].owned || ecoupons[0].state == 0) &&
            (!ecoupons[0].owned ||
              new Date(ecoupons[0].end_time) > new Date()) && (
              <div
                className="back_tari_course_connect_bottom"
                onClick={(e) => {
                  if (UserAgent.isMiniProgram()) {
                    onMiniProgram(e, ecoupons[0].url, {});
                  } else if (UserAgent.isNativeClient()) {
                    XinYaNativeBridge.navigate({ href: ecoupons[0].url });
                  } else {
                    window.location.href = ecoupons[0].url;
                  }
                }}
              >
                <div
                  className="style_line"
                  // style={{ height: 38, marginTop: -38, backgroundColor: "#fff" }}
                >
                  <div
                    style={{ height: 13, borderBottom: "1px dashed #FAE2CC" }}
                  ></div>
                </div>
                <div className="back_tari_course_connect_top_2  back_tari_course_connect_bottom_2">
                  <div className="back_tari_course_connect_top_2_title">
                    <div style={{ textIndent: -6 }}>
                      {ecoupons[0].can_buy_names.map((can_buy_name, index) => (
                        <span key={"ecoupons" + index}>{can_buy_name}</span>
                      ))}
                      <span style={{ color: "#FF8635" }}>
                        {"￥" + ecoupons[0].price}
                      </span>
                      优惠券
                    </div>
                    <div className="back_tari_course_connect_bottom_2_desc">
                      {"领取后" + ecoupons[0].period_interval + "小时内有效"}
                    </div>
                  </div>

                  <div
                    className="back_tari_course_connect_top_2_down"
                    style={{ marginRight: 9 }}
                  >
                    {ecoupons[0].owned ? "使用" : "领取"}
                  </div>
                </div>
              </div>
            )}
        </div>
      )}
      {pdfPracticeUrlCopy && (
        <PdfPracticeNoteCopy
          // media_lessons={media_lessons}
          handlePdfPractice={handlePdfPractice}
          copyUrl={handleCopyUrl}
          unitPdfUrl={trial_period_notice.document.download_url}
          unitName={trial_period_notice.document.name}
          // activityCode={study_package_activity_code}
        />
      )}
      <PoetryListBox
        lock={lock}
        buyUrl={buyUrl}
        sub_course_type={sub_course_type}
        isTrialCourse={isTrialCourse}
        upgradePackageRequired={upgrade_package_required}
        studyPackageModules={studyPackageModules}
        onModalOpenClick={onModalOpenClick}
        upgradePackageExpand={upgradePackage}
        isSpell={is_spell}
        target_id={props.target_id}
        target_type={props.target_type}
        isSanZiJing={sub_course_type === "sanzijing"}
        isReading={sub_course_type === "reading"}
        ecoupons={ecoupons}
        ecouponsStudyRequired={ecouponsStudyRequired}
        description={description}
        is_trial_course={is_trial_course}
        study_package_activity_id={props.study_package_activity_id}
        systemTime={study_package_system_time}
      />
      {/* {isTrialCourse &&
        // (batchEcoupon ? (
        //   // 显示优惠券
        //   <Components.SendCoupons batchEcoupon={batchEcoupon} />
        // ) : (
        // 体验课的体验单元模块 显示列表项购买入口
        // )
        // )
        upgrade_package_required && (
          <Components.UnlockAllPoetryGuide
            url={`${buyUrl}&ref=unlock_poetry_trial`}
          />
        )} */}
      <Components.CurrentUnitEnd text={`${unitName}结束`} />
      {
        // upgrade_package_required: false 正价课
        isNotNativeClient && (isTrialCourse || !buyUrl) && (
          <InstallAppReminder />
        )
      }
      {buyUrl && (
        <UnlockedAllCourseReminder
          // style={{ bottom: isNotNativeClient ? 45 : 0 }}
          bottom={isNotNativeClient && (isTrialCourse || !buyUrl) ? 60 : 0}
          url={
            upgrade_package_required
              ? `${buyUrl}&ref=unlock_poetry_footer`
              : buyUrl
          }
        />
      )}
      <RightFloatIcons
        isShowWrongBook
        onWongBookClick={handleWrongBookClick}
        isShowMakeComment={
          comment_state === "uncommented" && !!createRecommendRequestUrl
        }
        onMakeCommentClick={getWriteEvaluateStatus}
        isShowRedMoney={!!props.bonus}
        onRedMoneyClick={onInvitationIconClick}
        isShowReport={!!study_report_url}
        onReportClick={onStudyReportIconClick}
        bottomPosition={
          buyUrl || (isNotNativeClient && (isTrialCourse || !buyUrl)) ? 80 : 50
        }
        // isShowScrollTop
        // onScrollTop={handleBackToTop}
      />
      {!!writeEvaluateDialogState && (
        <StudyPackageCommentModule
          create_recommend_request_url={createRecommendRequestUrl}
          comment_target={props.comment_target}
          current_user_comment={props.current_user_comment}
          bought_phase={props.bought_phase}
          promotion={props.promotion}
          rate={props.rate}
          name={props.study_package_name}
          hideDialog={hideWriteEvaluateState}
          getCreateRecommendRequestUrl={getCreateRecommendRequestUrl}
        />
      )}
      {isShowReminderModal && (
        <NewPoetryBuyDialog
          getCloseModalStatus={getCloseModalStatus}
          url={`${buyUrl}&ref=unlock_poetry_pop`}
        />
      )}
      {/* {is_trial_course && (
        <div
          className="unlock_one_week_button"
          style={{
            bottom: isNotNativeClient && (isTrialCourse || !buyUrl) ? 45 : 30,
          }}
          onClick={() => {
            // window.location.href = `${this.props.unitData?.upgrade_package?.study_package_activity_buy_url}&ref=note`;
          }}
        >
          <img src={icon_back_test_shop}></img>
          <div className="unlock_one_week_button_inner">
            <div>
              <p>解锁全部课程，让孩子思维拓展不断档</p>
              <p style={{ fontSize: "12px", fontWeight: "400" }}>
                考试提分 + 阅读能力培养一站解决
              </p>
            </div>
            <div className="now_unlock">解锁</div>
          </div>
        </div>
      )} */}
    </div>
  );
});
const PoetryListBox = memo(({ ...props }) => {
  console.log("PoetryListBox_props:--rea ", props);
  const {
    lock,
    buyUrl,
    isTrialCourse,
    upgradePackageRequired,
    studyPackageModules,
    onModalOpenClick,
    upgradePackageExpand,
    isSpell,
    ecoupons,
    ecouponsStudyRequired,
    description,
    systemTime,
    isSanZiJing,
    isReading,
    is_trial_course,
  } = props;

  console.log("PoetryListBox studyPackageModules: ", studyPackageModules);

  if (!studyPackageModules) return "";
  return (
    <div
      className={`poetry_list_container ${
        isReading ? "reading_list_container" : ""
      }`}
    >
      {studyPackageModules.map((module, index) => (
        <div key={index + "c"}>
          <PoetryUnit
            systemTime={systemTime}
            ecoupons={ecoupons}
            ecouponsStudyRequired={ecouponsStudyRequired}
            description={description}
            isTrialCourse={isTrialCourse}
            backgroundUrl={module.photo_url}
            renderSimplePoetryUnit={() => (
              <SimplePoetryUnit
                buyUrl={buyUrl}
                sub_course_type={props.sub_course_type}
                lock={lock}
                isTrialCourse={isTrialCourse}
                upgradePackageRequired={upgradePackageRequired}
                dayStudies={module.day_studies}
                currentStudyDate={module.current_study_date}
                onModalOpenClick={onModalOpenClick}
                upgradePackageExpand={upgradePackageExpand}
                isSpell={isSpell}
                target_id={props.target_id}
                target_type={props.target_type}
                study_package_activity_id={props.study_package_activity_id}
                isSanZiJing={isSanZiJing}
                isReading={isReading}
                studyPackageModules={studyPackageModules}
              />
            )}
          />
          {is_trial_course && !index && (
            <img
              src={icon_children_back_read}
              className="customized_questionnaire_entry11"
              // style={flag ? { margin: "25px 0px  15px" } : {}}
              onClick={() => {
                window.location.href = upgradePackageRequired
                  ? `${buyUrl}&ref=unlock_poetry_footer`
                  : buyUrl;
              }}
            ></img>
          )}
        </div>
      ))}
    </div>
  );
});

const PoetryUnit = memo(({ ...props }) => {
  const {
    systemTime,
    ecoupons,
    ecouponsStudyRequired,
    description,
    isTrialCourse,
    backgroundUrl,
    renderSimplePoetryUnit,
  } = props;
  return (
    <div
      className={`poetry_list_box ${isTrialCourse ? "is_trial_list_box" : ""}`}
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 10%, #fae2cc 25%, #fae2cc),url(${backgroundUrl})`,
      }}
    >
      {isTrialCourse && description && (
        <div className="trial_poetry_unit">
          <div className="trial_course_describe">
            <img src={icon_warning} alt="" />
            {description}
          </div>
        </div>
      )}
      {/* {isTrialCourse && ecoupons && ecoupons.length > 0 && (
        <CourseAuditionCoupon
          ecoupons={ecoupons}
          ecouponsStudyRequired={ecouponsStudyRequired}
          systemTime={systemTime}
        />
      )} */}
      {renderSimplePoetryUnit()}
    </div>
  );
});

const ButtonReviewOrBegainStudy = memo(
  ({ studyRelateClassStatus, progressRelateText, isReadingExercise }) => {
    return (
      <div className={`button_begain_study ${studyRelateClassStatus}`}>
        <div
          className={`${
            isReadingExercise ? "reading_exercise_icon" : "progress_icon_study"
          }`}
        ></div>
        <p>{progressRelateText}</p>
      </div>
    );
  }
);
const useFixUnExpectedUnicode = () => {
  const ref = React.useRef();
  useEffect(() => {
    const text = ref.current.innerHTML;
    try {
      if (text.charCodeAt(0) === 8) {
        const arr = text.split("");
        arr.shift();
        ref.current.innerHTML = arr.join("");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  return ref;
};
const ArticleDescribe = ({ text }) => {
  const tempRef = useFixUnExpectedUnicode();
  return (
    <div className="article_describe" ref={tempRef}>
      {text}
    </div>
  );
};
const UnitCourseContent = memo(
  ({ isSpell, day_study, renderButtonStudy, isSanZiJing }) => {
    // study_type: 0(精讲)、1(拓展)、2(单元练习)、3(复习)、4(期末考)
    const unitPoetryName = useCallback(
      (day_study, text) => {
        const isNotMark =
          day_study.study_type === 1 ||
          day_study.study_type === 2 ||
          day_study.study_type === 4;
        const isReviewSubName =
          day_study.study_type === 2 || day_study.study_type === 4;
        const expandClassMark =
          isNotMark || isSanZiJing ? "is_expand_class_mark" : "";
        const reviewClassMark = isReviewSubName ? "is_review_class_mark" : "";
        return (
          <div className="unit_articles">
            {isReviewSubName || !text ? "" : <ArticleDescribe text={text} />}
            <div className={`title ${expandClassMark} ${reviewClassMark}`}>
              {isNotMark || isSpell || isSanZiJing
                ? `${day_study.name}`
                : `${`《${day_study.name}》`}`}
            </div>
          </div>
        );
      },
      [isSanZiJing, isSpell]
    );
    const unitPoetryArticles = useCallback(
      (day_study, text) => {
        return (
          <div className="unit_articles">
            <ArticleDescribe text={text} />
            <div className={`title`}>
              {isSanZiJing ? day_study.name : `《${day_study.name}》`}
            </div>
          </div>
        );
      },
      [isSanZiJing]
    );

    const reviewBrief = useCallback((reviewMediaLessons = []) => {
      return (
        <div className="review_brief">
          {reviewMediaLessons.map((lesson, index) => (
            <div className="brief" key={index}>
              {lesson.name}
            </div>
          ))}
        </div>
      );
    }, []);

    const awakenReviewBrief = useCallback(
      (day_study) => {
        return day_study.review_media_lessons.length > 0 ? (
          day_study.review_media_lessons.map((media_lesson, index) => (
            <div className="awaken_review_brief" key={index}>
              <ArticleDescribe text={day_study.sub_name} />
              <div className="review_brief">
                {isSanZiJing
                  ? `${media_lesson.name}`
                  : `《${media_lesson.name}》`}
              </div>
            </div>
          ))
        ) : (
          <div className="awaken_review_brief">
            {/* <div className="article_describe">{day_study.sub_name}</div> */}
            <div className="review_study_name" style={{ paddingLeft: 0 }}>
              {day_study.name}
            </div>
          </div>
        );
      },
      [isSanZiJing]
    );

    // 单元复习（有复习朗诵）
    const hasReviewMediaLessons = useMemo(() => {
      const lessons = [
        {
          name: "复习朗诵",
        },
        {
          name: `${isSanZiJing ? "" : "诗词"}小擂台`,
        },
      ];
      if (isSanZiJing) {
        return lessons;
      } else {
        lessons.push({
          name: "泛读诗",
        });
      }
      return lessons;
    }, [isSanZiJing]);

    // 单元复习（无复习朗诵）
    const noReviewMediaLessons = [
      {
        name: `${isSanZiJing ? "" : "诗词"}小擂台`,
      },
      {
        name: "泛读诗",
      },
    ];

    return (
      <div className="unit_content">
        <img
          data-src={`${day_study.photo_url}?imageView2/1/w/360/h/200`}
          className="lazyload"
          alt=""
        />
        <div className="right_unit_content right_reading_unit_content">
          <span className="reading_topic">{day_study.topic}</span>
          <div className="reading_sub_topic">{day_study.sub_topic}</div>
          {day_study?.poetry_articles?.map((ar, index) => (
            <p key={index} className="article">{`《${ar.name}》`}</p>
          ))}
          {/* {day_study.study_type === 3 ? (
            awakenReviewBrief(day_study)
          ) : (
            <div className="article_section">
              {day_study?.articles?.length > 0 && (
                <div className="extra_text">{day_study.articles[0].name}</div>
              )}
              {unitPoetryName(day_study, day_study.sub_name)}
              {
                // 非拼音课
                !isSpell &&
                  day_study?.poetry_articles?.length > 0 &&
                  unitPoetryArticles(day_study.poetry_articles[0], `泛读`)
              }
              {day_study.study_type === 2
                ? reviewBrief(
                    day_study.has_review_media_lessons
                      ? hasReviewMediaLessons
                      : noReviewMediaLessons
                  )
                : reviewBrief(day_study.review_media_lessons)}
            </div>
          )} */}
          {
            // 非锁定状态时，才显示”开始学习“按钮
            !day_study.day_lock && !day_study.lock && renderButtonStudy()
          }
        </div>
      </div>
    );
  }
);

const SubSlideGuide = memo(
  ({ studyPackageModules, getSubSlideGuideAnchor }) => {
    const handleAnchorFocus = (e) => {
      const anchorIndex = e.currentTarget.getAttribute("data-index");
      console.log("anchorIndex: ", anchorIndex);
      getSubSlideGuideAnchor(anchorIndex);
    };

    if (!studyPackageModules) return "";
    return (
      <div className="sub_slide_theme_guide clear_scroll_bar reading">
        {studyPackageModules.map((theme, index) => (
          <div
            className="poetry_theme "
            key={index}
            data-index={index}
            onClick={handleAnchorFocus}
          >
            <div className="img_container">
              <img
                data-src={theme.navigate_photo_url}
                className="lazyload"
                alt=""
              />
            </div>
            <div className="theme_name">{theme.name}</div>
          </div>
        ))}
      </div>
    );
  }
);

const SimplePoetryUnit = memo(({ ...props }) => {
  const {
    buyUrl,
    sub_course_type,
    lock,
    isTrialCourse,
    upgradePackageRequired,
    dayStudies,
    currentStudyDate,
    onModalOpenClick,
    upgradePackageExpand,
    isSpell,
    isSanZiJing,
    studyPackageModules,
    isReading,
  } = props;
  console.log(buyUrl, "SimplePoetryUnit=============", sub_course_type);
  // console.warn("SimplePoetryUnit_dayStudies数据处理: ", dayStudies);

  // 增加拓展版解锁
  const onPayModalOpen = useCoursePayment(upgradePackageExpand);
  const handleCoursePay = useCallback(() => {
    onPayModalOpen();
  }, [onPayModalOpen]);

  const iconLockSection = useCallback(({ isFirstBlock }) => {
    return (
      <div className="lock_status" style={!isFirstBlock ? { top: -11 } : {}}>
        <img src={icon_lock} alt="" />
      </div>
    );
  }, []);

  const alreadyFinishStudy = useCallback(() => {
    return <div className="already_finish_study"></div>;
  }, []);

  const currentProgressGuideText = useCallback((currentProgressText) => {
    return (
      <div
        className="current_progress current_reading_progress"
        id="current_progress"
      >
        {currentProgressText}
      </div>
    );
  }, []);

  const DayAndDate = memo(({ dayStudy, isFinishSymbolClass }) => {
    const {
      day,
      date,
      handbook,
      week,
      week_start,
      week_end,
      customize_name,
    } = dayStudy;
    const isSameDay =
      formatDotDate(dayStudy.start_rest_day) ===
      formatDotDate(dayStudy.end_rest_day);
    const holidayDate = isSameDay
      ? formatDotDate(dayStudy.start_rest_day)
      : `${formatDotDate(dayStudy.start_rest_day)} - ${formatDotDate(
          dayStudy.end_rest_day
        )}`;
    const dayDate = (
      <div className="date" style={isSpell ? { fontSize: "15px" } : {}}>
        {!day ? holidayDate : `（${formatDotDate(date)}）`}
      </div>
    );
    const weekDate = `（${formatDotDate(week_start)} - ${formatDotDate(
      week_end
    )}）`;

    const datePattern = week ? weekDate : dayDate;

    return (
      <div className={`day_and_date ${isFinishSymbolClass}`}>
        {
          // 休息日隐藏
          day && (
            <div className="day">
              {customize_name || (week ? `第${week}周` : `第${day}天`)}
            </div>
          )
        }
        {!upgradePackageRequired && !handbook && datePattern}
      </div>
    );
  });

  const includeFirstDay = useCallback((handbook) => {
    return handbook ? "请先从这里开始" : "开始第1天学习";
  }, []);

  const start_study = (day_study, linkRedirect) => {
    console.log("start_study_ReadingLIst", props);
    GA(
      "study_package_details",
      day_study.state === "pass" ? "review" : "start_day_study",
      props.study_package_activity_id
    );
    GA_new(
      "study_package_details",
      day_study.state === "pass" ? "review" : "start_day_study",
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    // debugger;
    // handleCoursePay();
    window.location.href = linkRedirect;
  };

  // 锁定的课时，点击时弹提示
  const handleLockedDialog = (day_study) => {
    let text;
    if (day_study.week) {
      text = `课程将于${formatDotDate(
        day_study.week_start
      )}日解锁，请到时再来学习`;
    } else {
      if (lock === "unlock_by_day") {
        text = `课程将于${formatDotDate(day_study.date)}解锁，请到时再来学习`;
      } else {
        text = "请先学完前一天的内容，或等日期到了解锁";
      }
    }
    const buttons = [
      {
        label: "我知道了",
        onClick: () => confirmDialog.close(),
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>{text}</Dialog>
    );
  };
  if (!dayStudies) return "";

  const singleSameWeekDayStudy = ({
    sub_course_type,
    buyUrl,
    day_study,
    index,
    needMarkCurrentStudyDate,
    needMarkDayIndex,
  }) => {
    // console.log("needMarkDayIndex - 1 === index ", needMarkDayIndex, index);
    const activeCurrentProgress = day_study.current_progress_date === true;
    const isActiveCurrentStudyDate = currentStudyDate === day_study.date;
    const isExerciseMark =
      day_study.study_type === 2 || day_study.study_type === 4;

    let studyRelateClassStatus;
    if (isActiveCurrentStudyDate) {
      studyRelateClassStatus = "active_current_study_progress";
    }
    if (!isActiveCurrentStudyDate && day_study.state === "pass") {
      studyRelateClassStatus = "button_review";
    }
    if (isExerciseMark) {
      studyRelateClassStatus = "button_exercise_mark";
    }

    // 日期处理
    const currentProgressText =
      Number(day_study.day) === 1
        ? includeFirstDay(day_study.handbook)
        : day_study.start_rest_day
        ? "今日休息"
        : "上次学到这里，继续";
    const progressRelateText =
      day_study.state === "pass"
        ? "复习"
        : isExerciseMark
        ? "开始学习"
        : "开始学习";
    const isFinishSymbolClass =
      day_study.state === "pass" ? "has_finish_symbol" : "";
    const isSimpleUnitLockedClass = day_study.day_lock
      ? "reading_unit_locked"
      : "";

    // 增加拓展版解锁样式
    const spellSimpleUnitLockedClass = day_study.lock
      ? "reading_unit_locked"
      : `${isSimpleUnitLockedClass}`;
    // const isActiveBoxShadowClass = isActiveCurrentStudyDate
    //   ? "active_box_shadow"
    //   : "";

    let linkRedirect;
    if (day_study.lock) {
      linkRedirect = "javascript:void(0)";
    } else {
      if (day_study.day_lock) {
        linkRedirect = "javascript:void(0)";
      } else {
        linkRedirect = day_study.url;
      }
    }

    return day_study.day ? (
      <div
        onClick={() => {
          // console.log("222222222222222", day_study);
          if (!day_study.lock) {
            start_study(day_study, linkRedirect);
          } else {
            if (sub_course_type == "reading") {
              window.location.href = buyUrl;
            } else {
              if (upgradePackageRequired) {
                onModalOpenClick(true);
              } else {
                handleLockedDialog(day_study);
              }
            }
            // if (day_study.day_lock) {
            // if (upgradePackageRequired) {
            //   onModalOpenClick(true);
            // } else {
            //   handleLockedDialog(day_study);
            // }
            // }
          }
          // !day_study.lock
          //   ? start_study(day_study, linkRedirect)
          //   : day_study.day_lock
          //   ? upgradePackageRequired
          //     ? onModalOpenClick(true)
          //     : handleLockedDialog(day_study)
          //   : "";
        }}
        className={`poetry_unit reading ${
          day_study.state === "pass" ? "has_padding" : ""
        }`}
        key={index}
        // href={linkRedirect}
        data-id={`${isActiveCurrentStudyDate ? "current_progress" : ""}`}
        data-first={`${Number(index) === 0 ? "first_unit" : ""}`}
      >
        {!isTrialCourse && upgradePackageRequired
          ? ""
          : isActiveCurrentStudyDate &&
            needMarkCurrentStudyDate && (
              <div className="reading_progress">
                {currentProgressGuideText(currentProgressText)}
              </div>
            )}
        <div
          className={`simple_poetry_unit ${spellSimpleUnitLockedClass} reading_unit ${
            isActiveCurrentStudyDate ? "need_mark_current_study_date" : ""
          } ${needMarkDayIndex - 1 === index ? "already_studied_unit" : ""}`}
        >
          {day_study.state === "pass" && alreadyFinishStudy()}
          {(day_study.day_lock || day_study.lock) &&
            iconLockSection({ isFirstBlock: index === 0 })}

          <DayAndDate
            dayStudy={day_study}
            isFinishSymbolClass={isFinishSymbolClass}
          />
          <UnitCourseContent
            isSanZiJing={isSanZiJing}
            isSpell={isSpell}
            day_study={day_study}
            renderButtonStudy={() => (
              <ButtonReviewOrBegainStudy
                studyRelateClassStatus={studyRelateClassStatus}
                progressRelateText={progressRelateText}
                isReadingExercise={
                  day_study.study_type === 2 || day_study.study_type === 1
                }
              />
            )}
          />
        </div>
      </div>
    ) : (
      // 增加节假日
      <HolidayRestPrompt
        key={index}
        dayStudy={day_study}
        currentProgressText={
          !isTrialCourse && upgradePackageRequired
            ? ""
            : activeCurrentProgress &&
              currentProgressGuideText(currentProgressText)
        }
        activeCurrentProgress={activeCurrentProgress}
        renderDayAndDate={() => (
          <DayAndDate
            dayStudy={day_study}
            isFinishSymbolClass={isFinishSymbolClass}
          />
        )}
      />
    );
  };

  const singleDayStudy = ({ sub_course_type, buyUrl, day_study, index }) => {
    const activeCurrentProgress = day_study.current_progress_date === true;
    const isActiveCurrentStudyDate = currentStudyDate === day_study.date;
    const isExerciseMark =
      day_study.study_type === 2 || day_study.study_type === 4;

    let studyRelateClassStatus;
    if (isActiveCurrentStudyDate) {
      studyRelateClassStatus = "active_current_study_progress";
    }
    if (!isActiveCurrentStudyDate && day_study.state === "pass") {
      studyRelateClassStatus = "button_review";
    }
    if (isExerciseMark) {
      studyRelateClassStatus = "button_exercise_mark";
    }

    // 日期处理
    const currentProgressText =
      Number(day_study.day) === 1
        ? includeFirstDay(day_study.handbook)
        : day_study.start_rest_day
        ? "今日休息"
        : "上次学到这里，继续";
    const progressRelateText =
      day_study.state === "pass"
        ? "复习"
        : isExerciseMark
        ? "开始"
        : "开始学习";
    const isFinishSymbolClass =
      day_study.state === "pass" ? "has_finish_symbol" : "";
    const isSimpleUnitLockedClass = day_study.day_lock
      ? "simple_poetry_unit_locked"
      : "";

    // 增加拓展版解锁样式
    const spellSimpleUnitLockedClass = day_study.lock
      ? "spell_simple_poetry_unit_locked"
      : `${isSimpleUnitLockedClass}`;
    const isActiveBoxShadowClass = isActiveCurrentStudyDate
      ? "active_box_shadow"
      : "";

    let linkRedirect;
    if (day_study.lock) {
      linkRedirect = "javascript:void(0)";
    } else {
      if (day_study.day_lock) {
        linkRedirect = "javascript:void(0)";
      } else {
        linkRedirect = day_study.url;
      }
    }

    return day_study.day ? (
      <div
        onClick={() => {
          // console.log("000000000000000000");
          if (day_study.lock) {
            start_study(day_study, linkRedirect);
          } else {
            if (sub_course_type == "reading") {
              window.location.href = buyUrl;
            } else {
              if (upgradePackageRequired) {
                onModalOpenClick(true);
              } else {
                handleLockedDialog(day_study);
              }
            }
          }
        }}
        className="poetry_unit"
        key={index}
        // href={linkRedirect}
        data-id={`${isActiveCurrentStudyDate ? "current_progress" : ""}`}
        data-first={`${Number(index) === 0 ? "first_unit" : ""}`}
      >
        {!isTrialCourse && upgradePackageRequired
          ? ""
          : isActiveCurrentStudyDate &&
            currentProgressGuideText(currentProgressText)}
        <div
          className={`simple_poetry_unit ${spellSimpleUnitLockedClass} ${isActiveBoxShadowClass}`}
        >
          {day_study.state === "pass" && alreadyFinishStudy()}
          {(day_study.day_lock || day_study.lock) && iconLockSection()}

          <DayAndDate
            dayStudy={day_study}
            isFinishSymbolClass={isFinishSymbolClass}
          />
          <UnitCourseContent
            isSanZiJing={isSanZiJing}
            isSpell={isSpell}
            day_study={day_study}
            renderButtonStudy={() => (
              <ButtonReviewOrBegainStudy
                studyRelateClassStatus={studyRelateClassStatus}
                progressRelateText={progressRelateText}
              />
            )}
          />
        </div>
      </div>
    ) : (
      // 增加节假日
      <HolidayRestPrompt
        key={index}
        dayStudy={day_study}
        currentProgressText={
          !isTrialCourse && upgradePackageRequired
            ? ""
            : activeCurrentProgress &&
              currentProgressGuideText(currentProgressText)
        }
        activeCurrentProgress={activeCurrentProgress}
        renderDayAndDate={() => (
          <DayAndDate
            dayStudy={day_study}
            isFinishSymbolClass={isFinishSymbolClass}
          />
        )}
      />
    );
  };

  let isFirstDayStudy = false;
  try {
    isFirstDayStudy =
      studyPackageModules[0].day_studies[0][0].date === currentStudyDate;
  } catch (e) {}

  return (dayStudies || []).map((day_study, index) => {
    // 前端已经把同一周的day study group好了 -> withUnitStudySubscription:: getChineseUnitStudy
    let needMarkCurrentStudyDate = true;

    let needMarkDayIndex = day_study.findIndex(
      (day) => day.date === currentStudyDate
    );

    if (day_study[0].date === currentStudyDate) {
      needMarkCurrentStudyDate = false;
      needMarkDayIndex = 0;
    }
    if (Array.isArray(day_study)) {
      return (
        <React.Fragment key={index}>
          {day_study[0].date === currentStudyDate && (
            <div className="first_day_reading_progress">
              {currentProgressGuideText(
                isFirstDayStudy ? "开始第1天学习" : "上次学到这里，继续"
              )}
            </div>
          )}
          <div className="same_week_container">
            {day_study.map((day, idx) =>
              singleSameWeekDayStudy({
                sub_course_type: props.sub_course_type,
                buyUrl: buyUrl,
                day_study: day,
                index: idx,
                needMarkCurrentStudyDate: needMarkCurrentStudyDate && idx != 0,
                needMarkDayIndex,
              })
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return singleDayStudy({
        sub_course_type: props.sub_course_type,
        buyUrl: buyUrl,
        day_study,
        index,
        buyUrl,
      });
    }
  });
});

// 增加语文类课表节假日
const HolidayRestPrompt = ({
  dayStudy,
  currentProgressText,
  activeCurrentProgress,
  renderDayAndDate,
}) => {
  return (
    <a
      className="poetry_unit holiday_poetry_unit"
      data-id={activeCurrentProgress ? "current_progress" : ""}
      href="javascript: void(0)"
    >
      {currentProgressText}
      <div
        className={`simple_poetry_unit ${
          dayStudy.day_lock ? "simple_poetry_unit_locked" : ""
        } ${activeCurrentProgress ? "active_box_shadow" : ""}`}
      >
        {renderDayAndDate()}
        <div className="unit_content holiday_unit_content">
          <img
            data-src={`${icon_package_placeholder}?imageView2/1/w/360/h/200`}
            className="lazyload"
            alt=""
          />
          <div className="right_unit_content">
            <p className="holiday_text">休息日</p>
            <p>补课或复习之前的内容吧</p>
          </div>
        </div>
      </div>
    </a>
  );
};

SimplePoetryList.propTypes = {
  studyPackageModules: PropTypes.array,
};

export default WithPlatformEnv(SimplePoetryList);
