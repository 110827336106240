/* eslint-disable react/prop-types */
import React, { memo } from "react";
import WithErrorBoundary from "../../../../utils/WithErrorBoundary.jsx";
import BabyInfo from "./BabyInfo.jsx";
import LearningProcessing from "./LearningProcessing.jsx";
import LearningReminder from "./LearningReminder.jsx";
import PoetryRewards from "./PoetryRewards.jsx";
import PreSchoolPreparation from "./PreSchoolPreparation.jsx";
import { UserBabyInfo } from "../../../../study_packages/UnitModeStudyPackage.jsx";
import UnlockedFullVersion from "../components/UnlockedFullVersion.jsx";

import "./ParentGuide.scss";

const ParentGuideHeader = ({
  baby,
  reminderConfig,
  studyRecord,
  isSanZiJing,
}) => {
  return (
    <div className="parent_guide_header">
      <div className="header_top">
        {baby && (
          <BabyInfo
            babyName={baby?.nickname}
            babyNum={baby?.sno}
            babyGender={baby?.gender}
            babyPhoto={baby?.photo_url}
            editBabyPath={baby?.edit_baby_path}
          />
        )}
        {studyRecord && (
          <LearningProcessing
            // right part
            isHiddenMediaCoursesCount={isSanZiJing}
            studiedCourseDayCount={studyRecord.studied_course_day_count}
            courseDayCount={studyRecord.course_day_count}
            studiedMediaCorsesCount={studyRecord.studied_media_courses_count}
            mediaCoursesCount={studyRecord.media_courses_count}
          />
        )}
      </div>
      {reminderConfig && (
        <div className="header_bottom">
          <LearningReminder reminderConfig={reminderConfig} />
        </div>
      )}
    </div>
  );
};

ParentGuideHeader.displayName = "ParentGuideHeader";

const ParentGuide = memo(function ParentGuide(props) {
  const {
    unitData: {
      baby,
      reminder_config: reminderConfig,
      study_record: studyRecord,
      preparations,
      reward,
    },
    is_spell,
    upgrade_package,
    sub_course_type,
  } = props;
  console.log("ParentGuide", props);
  const isReading = sub_course_type === "reading";
  return (
    <div className="comp_parent_guide">
      {is_spell || isReading ? (
        <UserBabyInfo
          baby={baby}
          reminderTimeHour={reminderConfig.reminder_time_hour}
          configReminderTimeUrl={reminderConfig.config_reminder_time_url}
        />
      ) : (
        <ParentGuideHeader
          baby={baby}
          reminderConfig={reminderConfig}
          studyRecord={studyRecord}
          isSanZiJing={sub_course_type === "sanzijing"}
        />
      )}
      {upgrade_package && is_spell && (
        <UnlockedFullVersion upgradePackage={upgrade_package} />
      )}
      {preparations && <PreSchoolPreparation preparations={preparations} />}
      {reward && reward.operation_endtimes && (
        <PoetryRewards
          rewardsTimeList={reward.operation_endtimes}
          rewardPhoto={reward.photo_url}
          babyName={baby.nickname}
          isSpell={is_spell}
          isSanZiJing={sub_course_type === "sanzijing"}
        />
      )}
    </div>
  );
});

export default WithErrorBoundary(ParentGuide);
