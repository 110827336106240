import React, { useState, memo } from "react"
import PropTypes from "prop-types"
import { request, csrfHeaders } from "../utils/request"
import "../shared/useful_css.scss"
import PraiseIcon from "praise.png"
import PraiseFilledIcon from "praise-filled.png"

const LikeComponent = ({liked, likesCount, likesUrl, customStyle}) => {
  const [customLiked, setLiked] = useState(liked)
  const [customLikesCount, setLikesCount] = useState(likesCount)
  const handleClick = (e) => {
    e.stopPropagation()
    request({
      url: likesUrl,
      method: "POST",
      headers: csrfHeaders,
    }).then((res) => {
      setLikesCount(res.data.count)
      setLiked(res.data.result)
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <div
      className={`flex items-center ${customLikesCount && customLikesCount > 0 ? 'justify-between' : 'justify-center'}`}
      style={{backgroundColor: "rgba(51, 51, 51, 0.6)", minWidth: "46px", borderRadius: '11px', padding: "5px",...customStyle}}
      onClick={handleClick}
    >
      <img src={customLiked ? PraiseFilledIcon : PraiseIcon} style={{width: "12px", hegith: "12px"}} />
      {customLikesCount && customLikesCount > 0 ? <div style={{color: "white", fontSize: "11px", lineHeight: '12px'}}>{customLikesCount}</div> : null}
    </div>
  )
}

export default memo(LikeComponent)


LikeComponent.propTypes = {
  liked: PropTypes.bool.isRequired,
  likesCount: PropTypes.number,
  likesUrl: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
}