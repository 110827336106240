import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";
import WithErrorBoundary from "../../../../utils/WithErrorBoundary.jsx";
import ModalManager from "../../../../utils/Modal/ModalManage.jsx";
import ModalMask from "../../../../utils/Modal/ModalMask.jsx";
import ListContainer from "../../../../utils/ListContainer/ListContainer.jsx";
import { onNavigate } from "../../../../utils/helpers";
import ParentGuideVideo from "./ParentGuideVideo.jsx";
import bgVideo from "new_poetry/bg_video.jpg";

import "./PreSchoolPreparation.scss";

const PreSchoolPreparation = memo(({ preparations }) => {
  const videoRef = React.createRef();
  const modal = useMemo(
    () =>
      new ModalManager({
        render: (_, modalClose, fakeClose, extra) => {
          document.querySelector("html, body").classList.add("no_scroll");
          const onClose = () => {
            document.querySelector("html, body").classList.remove("no_scroll");
            modalClose();
          };
          return (
            <ModalMask>
              <div className="pre_school_video">
                <ParentGuideVideo videoSrc={extra.video_src} ref={videoRef} />
                <span className="pre_school_video_close" onClick={onClose}>
                  &times;
                </span>
              </div>
            </ModalMask>
          );
        },
      }),
    [videoRef]
  );
  const showVideo = (e, poster, video_src) => {
    if (video_src.includes("mp4")) {
      modal.open({ poster: poster, video_src: video_src });
      videoRef.current.play();
    } else {
      onNavigate(e, video_src);
    }
  };
  if (!preparations) {
    return "";
  }
  return (
    <div className="comp_pre_school_preparation">
      <div className="lesson_banner">
        <img className="banner_bg" src={bgVideo} alt="" />
      </div>
      {preparations?.articles && (
        <div className="pre_school_box">
          <ListContainer
            list={preparations.articles}
            listItem={({ itemIndex: idx, ...content }) => (
              <div
                className="knowledge_unscramble"
                key={idx}
                onClick={(e) => showVideo(e, content.photo_url, content.url)}
              >
                <div className="left_box">
                  <img src={content.photo_url} alt="" />
                </div>
                <div className="right_box">
                  <div className="text_title">{content.name}</div>
                  <div className="text_describe">{content.brief}</div>
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
});

PreSchoolPreparation.displayName = "PreSchoolPreparation";

PreSchoolPreparation.propTypes = {
  preparations: PropTypes.object,
};

export default WithErrorBoundary(PreSchoolPreparation);
