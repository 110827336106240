import React from "react";
import AnswerAnalysis from "../components/AnswerAnalysis";
import GoodWords from "../components/GoodWords";
import MyAnswer from "../components/MyAnswer";
import Handout from "../components/Handout"; //讲义
import PracticeClass from "../components/PracticeClass"; //随堂练习
import TeacherComments from "../components/TeacherComments";
import ExamRoom from "../components/ExamRoom";
import BreakThrough from "../components/BreakThrough";
import icon_special from "reading/icon_special.png";
import StudyReportInfo from "../../utils/study_report_info/StudyReportBar";
import ReportHeaderDay from "../../utils/study_report_info/ReportHeaderDay";
import { days } from "../reading_index/ReadingIndex";
import "./ReadingReport.scss";
import RewardExchangeProgress from "../../point_reward/RewardExchangeProgress";
import UploadExerciseList from "../reading_details/UploadExerciseList"
import ExcellentExerciseList from "../reading_details/ExcellentExerciseList"
export default class ReadingReport extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "props,report----------");
    this.state = {};
  }

  isThirdDayOfReadingV3 = () => {
    return this.props.study_mode === 'reading_v2' && this.props.study_mode_version === 3 && this.props.study_topic_day === 3
  }

  SidebarLabel = (text) => {
    return (
      <div style={{marginLeft: '-20px', width: '112px', textAlign: 'center'}}>
        <div style={{backgroundColor: '#FFBA44', height: '32px', lineHeight: '32px', color: 'white', fontSize: '18px', fontWeight: 'bold', borderRadius: '10px 50px 50px 0'}}>{text}</div>
        <div style={{width: '5px', height: '5px', backgroundColor: '#F7831D', borderRadius: '0 0 0 4px'}}></div>
      </div>
    )
  }

  R3ThirdDayComponents = () => {
    const {
      answers,
      upload_end_date: uploadEndDate,
      upload_expired: uploadExpired,
      generate_token_url: generateTokenUrl,
      day_study_record_url: dayStudyRecordUrl,
      submit_reward_end_date: submitRewardEndDate,
      ...others
    } = this.props
    return (
      <>
        <UploadExerciseList
          SidebarLabel={this.SidebarLabel}
          isThirdDayOfReadingV3={this.isThirdDayOfReadingV3}
          neverUploaded={false}
          answers={answers}
          templates={this.props.offline_exercises}
          hasPointReward={false}
          submitRewardEndDate={submitRewardEndDate ?? ''}
          uploadEndDate={uploadEndDate ?? ''}
          uploadExpired={uploadExpired ?? true}
          generateTokenUrl={generateTokenUrl}
          dayStudyRecordUrl={dayStudyRecordUrl}
          others={others}
          hideOverdueNote={true}
        />
        <ExcellentExerciseList
          SidebarLabel={this.SidebarLabel}
          excellentExercises={others.excellent_exercises ?? []}
          readingCommentState={others.reading_comment_state}
        />
      </>
    )
  }

  // 非 R3 第三天的组件
  OtherComponents = () => {
    return (
      <>
        {this.props.answers && (
          <MyAnswer
            answerArr={this.props.answers}
            reading_comment_state={this.props.reading_comment_state}
            score_level={this.props.score_level}
            hideReuploadButton={true}
          ></MyAnswer>
        )}
        {this.props.reading_comment_state &&
          this.props.reading_comment_state !== "pending_upload" &&
          this.props.reading_comment_state !== "overtime" &&
          this.props.reading_comment_state !== "uploaded" &&
          this.props.course_consultant && (
            <TeacherComments
              // titles={"老师评语"}
              course_consultant={this.props.course_consultant}
              comment_text={this.props.comment_text}
              comment_audio_url={this.props.comment_audio_url}
              comment_audio_info_url={this.props.comment_audio_info_url}
            ></TeacherComments>
          )}
        {this.props.kb_lessons && (
          <PracticeClass
            title={"随堂练习"}
            kb_lessons={this.props.kb_lessons}
          ></PracticeClass>
        )}
        {this.props.poetry_articles && (
          <BreakThrough
            poetry_articles={this.props.poetry_articles}
          ></BreakThrough>
        )}
        {this.props.rehearsal_analysis && (
          <ExamRoom content={this.props.rehearsal_analysis}></ExamRoom>
        )}
        {this.props.sub_subjects && this.props.study_topic_day == 2 ? (
          <AnswerAnalysis
            content={this.props.material_analysis}
            sub_subjects={this.props.sub_subjects}
          ></AnswerAnalysis>
        ) : (
          <GoodWords sub_subjects={this.props.sub_subjects}></GoodWords>
        )}

        {/* <GoodWords isReport={true}></GoodWords> */}

        {this.props.media_lessons && (
          <Handout media_lessons={this.props.media_lessons}></Handout>
        )}

        {/* <TeacherComments></TeacherComments> */}

        {this.props.study_topic_report_url && (
          <div
            className="report-bottom"
            onClick={() => {
              window.location.href = this.props.study_topic_report_url;
            }}
          >
            <div className="report-bottom-center">
              <img src={icon_special} className="icon_special"></img>
              查看专题掌握情况
            </div>
          </div>
        )}
      </>
    )
  }

  render() {
    const {
      isSanZiJing = this.props.sub_course_type === "sanzijing",
      isReading = this.props.sub_course_type === "reading",
    } = this.props;
    return (
      <div className="reading-report reading-report-type-1">
        <StudyReportInfo
          type={true}
          studiedCourseDayCount={this.props.studied_course_day_count}
          courseDayCount={this.props.course_day_count}
          leftSectionName={"已学天数"}
          totalStudiedMediaLessonsCount={
            this.props.is_spell || isSanZiJing
              ? this.props.total_studied_media_lessons_count
              : this.props.studied_media_courses_count
          }
          mediaLessonsCount={
            this.props.is_spell || isSanZiJing
              ? this.props.media_lessons_count
              : this.props.media_courses_count
          }
          rightSectionName={
            this.props.is_spell || isSanZiJing ? "已学课时" : "已学古诗"
          }
          reward={this.props.reward}
          course_type={this.props.course_type}
          isReading={isReading}
          studied_tags_count={this.props.studied_tags_count || 0}
          currentStudyModule={this.props.current_study_module || 0}
          currentStudyModule={this.props.current_study_module}
          readingArticlesCount={this.props.read_articles_count}
          readingWordsCount={this.props.read_words_count}
        ></StudyReportInfo>

        {this.props.reward_commodity && <div style={{marginBottom: '20px', marginTop: '20px'}}>
          <RewardExchangeProgress
            commodity={this.props.reward_commodity}
            totalPoint={this.props.total_point}
            create_reward_exchange_url={this.props.create_reward_exchange_url}
            study_package_activity_id={this.props.study_package_activity_id}
            className="without-border"
            list={this.props.exchange_commodities.commodities}
            selected={this.props.exchange_commodities.selected}
            selectRewardPath={this.props.select_reward_commodity_path}
            commoditiesPath={this.props.commodities_path}
            maxRewardPoints={this.props.max_reward_points}
          />
        </div>}

        <div className="term-review-info-name">
          <div>
            {this.props.name}
            {/* {this.props.study_topic_day == 2
              ? "《" + this.props.name + "》"
              : this.props.name} */}
          </div>
          <div className="sub-name">{days[this.props.study_topic_day - 1]}</div>
        </div>

        {this.isThirdDayOfReadingV3() ? this.R3ThirdDayComponents() : this.OtherComponents()}

        
      </div>
    );
  }
}
