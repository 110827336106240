import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";

import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";

export default (props) => {
  return (
    <InfoBroadTitle isImg={false} titles={"考场演练"}>
      <div>
        <Titles text={"答案解析"}></Titles>
        <ReactMarkdown
          source={props.content}
          className="markdown_content markdown_content_back_eee"
          escapeHtml={false}
        ></ReactMarkdown>
      </div>
    </InfoBroadTitle>
  );
};
