import React from "react";
import PropTypes from "prop-types";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import "./BabyInfo.scss";

const BabyInfo = ({
  babyGender,
  babyName,
  babyNum,
  babyPhoto,
  editBabyPath,
}) => {
  return (
    <div className="comp_baby_info">
      <a href={editBabyPath}>
        <img
          src={babyPhoto || (babyGender === "男" ? iconBoyUser : iconGirlUser)}
          alt=""
        />
        {!babyPhoto && <div className="to_add_avatar"></div>}
      </a>

      <dl>
        <dt>{babyName}</dt>
        <dd>{`学号：${babyNum}`}</dd>
      </dl>
    </div>
  );
};

BabyInfo.propTypes = {
  babyGender: PropTypes.string,
  babyName: PropTypes.string,
  babyNum: PropTypes.string,
  babyPhoto: PropTypes.string,
  editBabyPath: PropTypes.string,
};

export default BabyInfo;
