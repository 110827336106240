import React from "react";
import crystal from "point_rewards/crystal.png";
import './RewardCommodity.scss';
import "../shared/useful_css.scss";
import ArrowRight from "new_arrow_right";

const rewardCommodity  = (props) => {
  let chosen = '';
  if (props.commodity.selected) {
    chosen = 'chosen';
  }
  return (
    <div className={`reward-commodity bg-transparent flex-shrink-0 ${chosen} ${props.isActive ? 'active' : ''}`}>
      <div className="commodity-cover" style={{backgroundImage: `url(${props.commodity.photo})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
        <a href={`/commodities/${props.commodity.id}`} className="commodity-detail">
          <div>查看详情</div>
          <img src={ArrowRight} style={{width: '12px'}} />
        </a>
      </div>
      {/* <img src={props.commodity.photo} className="commodity-photo" /> */}
      <div className="info bg-white">
        <div className="commodity-name">{props.commodity.name}</div>
        <div className="flex flex-row justify-between choose-row">
          <div className="flex flex-row items-center">
            <img src={crystal} />
            <div className="point-cost">{props.commodity.point_cost}</div>
          </div>
          <button className="choose-commodity" onClick={props.onClick}>{props.textWord}</button>
        </div>
      </div>
    </div>
  )
}
export default rewardCommodity;