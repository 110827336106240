import React from "react";
import raven from "raven-js";
import "./QuizVoice.scss";
import AudioIcon from "study_package/icon_play_white_normal.png";
import AudioPlayingIcon from "study_package/icon_play_white_selected.gif";
import { debounce } from "../helpers";
import PropTypes from "prop-types";

class QuizVoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
    };
  }
  playAudio = () => {
    const { isPlaying } = this.state;
    if (!isPlaying) {
      this.audioEl.play();
    }
  };
  // stopAudio = () => {
  //   const { isPlaying } = this.state
  //   if (isPlaying) {
  //     this.audioEl.stop()
  //     this.setState({
  //       isPlaying: false
  //     })
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quizId != this.props.quizId) {
      if (this.audioEl) {
        this.setState({
          isPlaying: false,
        });
        this.audioEl.pause();
        this.audioEl.currentTime = 0;
      }
    }
  }
  onPlay = () => {
    this.setState({
      isPlaying: true,
    });
    this.props.getAudioPlayStatus(true);
  };

  onEnded = () => {
    this.setState({
      isPlaying: false,
    });
    this.props.getAudioPlayStatus(false);
  };

  onError = (e) => {
    raven.captureException(
      `load quiz voice error. code: ${e.currentTarget?.error?.code}, message: ${e.currentTarget?.error?.message}`
    );
  };

  render() {
    const { audioUrl } = this.props;
    const { isPlaying } = this.state;
    return (
      <div
        className={`comp_quiz_question ${
          audioUrl ? "comp_quiz_question_text_intend" : ""
        }`}
        onClick={debounce(this.playAudio, 500)}
      >
        {audioUrl && (
          <audio
            src={audioUrl}
            ref={(audioEl) => (this.audioEl = audioEl)}
            onPlay={this.onPlay}
            onEnded={this.onEnded}
            onError={this.onError}
          ></audio>
        )}
        {isPlaying ? (
          <img src={AudioPlayingIcon} />
        ) : (
          audioUrl && <img src={AudioIcon} />
        )}
        <p>读题</p>
      </div>
    );
  }
}

QuizVoice.defaultProps = {
  getAudioPlayStatus: () => {},
};

export default QuizVoice;
