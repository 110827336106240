import React, { useEffect, useState } from "react";

import RewardCommodity from "./RewardCommodity";
import './RewardCommodityList.scss';
import "../shared/useful_css.scss";
import Capsule from "../shared/Capsule";
import ChooseRewardText from "point_rewards/choose-reward-text.png";
import Crystal from "point_rewards/crystal.png";
// import Hammer from "hammerjs";
import { request, csrfHeaders } from "../utils/request";


const rewardCommodityList = (props) => {
  const [selectedSlide, setSelectedSlide] = useState(props.activeIndex ?? -1); // 已选择的奖励
  const [activeSlide, setActiveSlide] = useState(selectedSlide < 0 ? 0 : selectedSlide); // 处于屏幕中间的奖励
  const [originalList, setOriginalList] = useState(props.list)
  let activeVariable = activeSlide || 0
  let startXPosition = 0

  let totalWidth
  let listContainer
  let listItem
  let listItemWidth
  let positionLeft
  const listComponent =  originalList.map((item, i) => {
    return (
      <RewardCommodity
        commodity={item.commodity}
        textWord={selectedSlide === -1 ? '选择它' : i === selectedSlide ? '已选择' : '换成它'}
        onClick={() => {chooseReward(i)}}
        key={item.commodity.id}
        isActive={activeSlide === i}
      />
    )
  })

  const handleTouchStart = (e) => {
    e = e || window.event; 
    e.stopPropagation();
    if (e.touches[0]) {
      startXPosition = e.touches[0].clientX
    }
    
  }

  const handleTouchEnd = (e) => {
    e = e || window.event;
    e.stopPropagation();
    if (!e.changedTouches[0]) {
      return
    }
    const endXPosition = e.changedTouches[0].clientX
    if (endXPosition - startXPosition > 5)  {
      swipe('right')
    } else if (endXPosition - startXPosition < -5) {
      swipe('left')
    }
  }

  const swipe = (direction) => {
    listContainer = document.getElementById('list-container')
    totalWidth = document.querySelector('.reward-commodity-list').offsetWidth
    listContainer = document.getElementById('list-container')
    listItem = document.querySelector('.reward-commodity')
    listItemWidth = listItem.offsetWidth
    positionLeft = listContainer.offsetLeft
    const maxLeft = listContainer.offsetWidth * -1 + totalWidth / 2 + listItemWidth / 2
    const minLeft = totalWidth / 2 - listItemWidth / 2
    
    if (direction === 'left') { // 向左滑动
      positionLeft -= 194
      positionLeft = Math.max(maxLeft, positionLeft)
      changeAcitveSlide('left')
    } else if (direction === 'right') { // 向右滑动
      console.log(minLeft, positionLeft)
      positionLeft += 194
      positionLeft = Math.min(minLeft, positionLeft)
      changeAcitveSlide('right')
    }
    
    positionLeft += 'px'
    
    // 安卓使用动画会报错，暂时的解决方案
    try {
      listContainer.animate({left: positionLeft}, {duration: 500, fill: 'forwards'})
    } catch {
      listContainer.style.left = positionLeft
    }
  }
  const navDot = () => {
    let dom = []
    for (let i = 0; i < originalList.length; i++) {
      dom.push(<div className={`dot ${activeSlide === i ? 'active' : ''}`} key={i}></div>)
    }
    return dom
  }

  let manager = null

  useEffect(() => {
    const newList = [...originalList]
    for (let i = 0; i < newList.length; i++) {
      newList[i].commodity.selected = i === selectedSlide
    }
    setOriginalList(newList)
  }, [selectedSlide])

  useEffect(() => {
    let manager

    totalWidth = document.querySelector('.reward-commodity-list').offsetWidth
    listContainer = document.getElementById('list-container')
    listItem = document.querySelector('.reward-commodity')
    listItemWidth = listItem.offsetWidth
    positionLeft = totalWidth / 2 - listItemWidth / 2
    const offset = Math.max(selectedSlide, 0)
    positionLeft -= 194 * offset
    listContainer.style.left =  positionLeft + 'px'
    // if (!manager) {
      
    //   manager = new Hammer.Manager(listContainer)
    //   const swipe = new Hammer.Swipe()
    //   swipe.set({threshold: 1})
    //   console.log(swipe)
    //   manager.add(swipe)
    // }
    // manager.on('swipeleft swiperight', function(e) {
    //   let direction = e.offsetDirection;
    //   console.log(direction, e.deltaX)
    //   let positionLeft = listContainer.offsetLeft
    //   const maxLeft = listContainer.offsetWidth * -1 + totalWidth / 2 + listItemWidth / 2
    //   const minLeft = totalWidth / 2 - listItemWidth / 2
    //   console.log(listContainer.offsetWidth, totalWidth, maxLeft, minLeft)
      
    //   if (direction === 2) { // 向左滑动
    //     positionLeft -= 194
    //     positionLeft = Math.max(maxLeft, positionLeft)
    //     changeAcitveSlide('left')
    //   } else if (direction === 4) { // 向右滑动
    //     console.log(minLeft, positionLeft)
    //     positionLeft += 194
    //     positionLeft = Math.min(minLeft, positionLeft)
    //     changeAcitveSlide('right')
    //   }
     
    //   positionLeft += 'px'
    //   listContainer.animate({left: positionLeft}, {duration: 500, fill: 'forwards'})
    //   console.log(listContainer.offsetLeft, totalWidth, '==============')
    // });
  }, [])

  const changeAcitveSlide = (direction) => {
    if (activeVariable > 0 && direction === 'right') {
      activeVariable -= 1
      setActiveSlide(activeVariable)
    } else if (activeVariable < originalList.length - 1 && direction === 'left') {
      activeVariable += 1
      setActiveSlide(activeVariable)
    }
  }

  const handleClick = () => {
    if (selectedSlide < 0 ) {
      return
    }
    const commodity = originalList[selectedSlide].commodity
    request({
      url: props.selectRewardPath,
      method: 'POST',
      headers: csrfHeaders,
      data: {
        commodity_id: commodity.id,
        study_package_activity_id: props.studyPackageActivityId,
      }
    }).then((res) => {
      console.log(res)
      props.confirmExchangeOption(res)
    })
  }

  const chooseReward = (index) => {
    setSelectedSlide(index)
  }

  const adsContent = props.isDemoClass ? '报名系统课，学完所有课时，大约可收获' : '学完所有新课，大约可收获'

  return (
    <div className="reward-commodity-list-container">
      <div className="background-cover"></div>
      <div className="reward-commodity-list flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center w-100">
          
          <div className="flex items-center justify-center flex-wrap text-white">
            <div>{adsContent}</div>
            <div className="text-yellow">{props.maxRewardPoints}</div>
            <img src={Crystal} style={{width: '22px', height: '22px', margin: '0 4px'}} />
            <div>，</div>
            <div>可用于兑换奖品哦~</div>
          </div>
          <img src={ChooseRewardText} style={{height: '23px', marginTop: '20px'}} />
          <div className="list-outer-container flex items-center mt-px-30">
            <div className="flex flex-row items-center" id="list-container" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
              {listComponent}
              {/* <div className="swiper-pagination"></div> */}
            </div>
          </div>

          <div className="nav flex justify-center mt-px-20">
            {navDot()}
          </div>
          
          { selectedSlide >= 0 && (<div className="report-info flex flex-col items-center">
            <div className="flex items-center justity-center info-text">
              <div className="dot"></div>
              <div className="text">后续可在</div>
              <div className="study-report-link">【学习报告】</div>
              <div className="text">中查看兑换进度、更换奖品哦。</div>
            </div>
            
            <Capsule text="确认"
              onClick={handleClick}
              className="confirm-exchange"
              style={{backgroundColor: '#FFC000', color: 'white', padding: '10px 50px', marginTop: '21px', fontSize: '16px'}}
            />
          </div>)}
          { selectedSlide < 0 && <Capsule text="稍后再选"
              onClick={props.chooseLater}
              // className="confirm-exchange"
              style={{color: 'white', padding: '10px 32px', marginTop: '21px', fontSize: '16px', border: '1px solid white', borderRadius: '22px'}}
            /> }
        </div>
      </div>
    </div>
  );
};



export default rewardCommodityList;


