import React from "react";
import ErrorBoundary from "./ErrorBoundary.jsx";

const withErrorBoundary = (Comp) => {
  const WithErrorBoundary = (props) => (
    <ErrorBoundary>
      <Comp {...props} />
    </ErrorBoundary>
  );
  WithErrorBoundary.displayName = "WithErrorBoundary";
  return WithErrorBoundary;
};

export default withErrorBoundary;
