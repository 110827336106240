import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastManager } from "../../utils/Toast"
import "../../shared/useful_css.scss"

const Magnifier = (props) => {
  console.log("magnifier props is: ", props)
  const [currentIndex, setCurrentIndex] = useState(props.index)
  let startXPosition = 0

  const handleTouchStart = (e) => {
    e = e || window.event; 
    e.stopPropagation();
    if (e.touches[0]) {
      startXPosition = e.touches[0].clientX
    }
    
  }

  const handleTouchEnd = (e) => {
    e = e || window.event;
    e.stopPropagation();
    if (!e.changedTouches[0]) {
      return
    }
    const endXPosition = e.changedTouches[0].clientX
    if (endXPosition - startXPosition > 5)  {
      handleSwipeRight()
    } else if (endXPosition - startXPosition < -5) {
      handleSwipeLeft()
    }
  }

  const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }

  const handleSwipeLeft = () => {
    console.log("current index is: ", currentIndex)
    if (currentIndex == props.images.length - 1) {
      ToastManager.showToastText("已浏览到最后一张", 2000, 'center')
      console.log('已经是最后一张啦！')
      return
    }
    setCurrentIndex(currentIndex + 1)
  }

  const handleSwipeRight = () => {
    console.log("current index is: ", currentIndex)
    if (currentIndex === 0) {
      ToastManager.showToastText("已浏览到第一张", 2000, 'center')
      console.log('已经是第一张啦！')
      return
    }
    setCurrentIndex(currentIndex - 1)
  }

  const dotStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'white',
    opacity: 0.4,
    margin: '0 8px',
  }

  const isLandspace = (img) => { 
    const windowRatio = window.innerWidth / window.innerHeight
    const immageRatio = img.clientWidth / img.clientHeight
    if (immageRatio > windowRatio) {
      return true
    }
    return false
  }

  const handleImageStyle = () => {
    const img = document.getElementById('image')
    if (isLandspace(img)) {
      img.style.width = '100%'
      img.style.height = 'auto'
    } else {
      img.style.width = 'auto'
      img.style.height = '100%'
    }
  }

  const Dots = () => {
    return (
      props.images.map((item, index) => {
        const style = {...dotStyle}
        if (index === currentIndex) {
          style.opacity = 1
        } else {
          style.opacity = 0.4
        }
        return (
          <div style={style} key={index}></div>
        )
      })
    )
  }

  return (
    <div className="magnifier" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100, backgroundColor: 'black'}}>
      <img src={props.images[currentIndex]} style={imageStyle} onLoad={handleImageStyle} id="image" onClick={props.handleZoomOut} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} />
      <div className="flex justify-center" style={{position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)', padding: '23px 0', zIndex: 101}}>
        {Dots()}
      </div>
      {props.slot && props.slot()}
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  if(prevProps.images === nextProps.images && prevProps.index === nextProps.index) {
    return true
  }
  return false
}

export default React.memo(Magnifier, areEqual)

Magnifier.propTypes = {
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
  slot: PropTypes.func,
}