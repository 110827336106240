import React, { useState } from "react";
import Capsule from "../shared/Capsule";
import Gift from "point_rewards/present.png";
import PropTypes from "prop-types";
import "./DispatchNotify.scss";


const DispatchNotify = ({ notifyText, shouldShowWindow }) => {
  const [showWindow, setShowWindow] = useState(shouldShowWindow)
  const buttonStyle = {
    backgroundColor: "#FFC000",
    color: "#FFF",
    fontSize: '16px',
    padding: '9px 30px',
    display: 'inline',
    width: '180px',
    textAlign: 'center',
  }
  const handleConfirm = () => {
    setShowWindow(false)
  }
  return (
    <>
      { 
      showWindow
      ?
      <>
        <div className="background-mask"></div>
        <div className="dispatch-notify-container">
          <img src={Gift} className="gift" />
          <div className="dispatch-text">{notifyText}</div>
          <Capsule text="确认" style={buttonStyle} onClick={handleConfirm} />
        </div>
      </>
      :
      null 
      }
    </>
  )
}

DispatchNotify.propTypes = {
  notifyText: PropTypes.string,
  shouldShowWindow: PropTypes.bool.isRequired,
}

DispatchNotify.defaultProps = {
  notifyText: '发货成功',
}

export default DispatchNotify