// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/new_poetry/bg_gift.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../assets/images/new_poetry/bg_san_zi_jing.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".comp_poetry_rewards{margin-top:200px;padding:80px 12px 12px;background-color:#fff;box-shadow:1px 1px 4px 0 rgba(0,1,0,.03);border-radius:8px;position:relative;z-index:10}.comp_poetry_rewards:before{content:\"\";position:absolute;top:-185px;left:-10px;width:calc(100% + 20px);height:248px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;background-position:center 100%}.comp_poetry_rewards .title{margin-bottom:9px;color:#756855;font-size:16px;font-weight:700;text-align:center}.comp_poetry_rewards .reward{color:#756855;padding-left:22px;text-align:left;position:relative;line-height:1.5}.comp_poetry_rewards .reward:before{content:\"\";position:absolute;left:3px}.comp_poetry_rewards .reward_one{margin-bottom:9px}.comp_poetry_rewards .reward_one:before{content:\"1.\"}.comp_poetry_rewards .reward_two:before{content:\"2.\"}.comp_poetry_rewards .rewards_img{margin-top:24px;border-radius:6px;max-width:100%}.comp_spell_poetry_rewards{margin-top:10px;padding-top:15px}.comp_spell_poetry_rewards:before{display:none}.comp_san_zi_jing_poetry_rewards:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@media screen and (max-width:320px){.comp_poetry_rewards{margin-top:172px}}", ""]);
// Exports
module.exports = exports;
