/* eslint-disable react/prop-types */
import React, { memo } from "react";
import "./SendCoupons.scss";

export default memo(({ ...props }) => {
  const { price, period_days, url } = props.batchEcoupon;
  return (
    <div className="comp_send_coupons">
      <a className="send_coupons_box" href={url}>
        <div className="left_coupon_price">
          <div className="price">
            <span>￥</span>
            {price}
          </div>
          <div className="text">
            <p>哇！一张大额券砸中了你！</p>
            <p>可用于《分级学古诗》课程报名！</p>
            <p>仅限{period_days}天有效！</p>
          </div>
        </div>
        <div className="right_coupon_button">
          <div className="button_get_coupon">领券报名</div>
        </div>
      </a>
    </div>
  );
});
