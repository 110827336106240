/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import icon_default_boy from "icon_default_boy.png";
import icon_default_girl from "icon_default_girl.png";
import starWhite from "study_package/icon_star_border.png";
import starYellow from "study_package/icon_star_yellow.png";
import { debounce } from "../../../../utils/helpers.js";
import { ToastManager } from "../../../../utils/Toast.jsx";
import { Dialog, DialogManager } from "../../../../utils/Dialog.jsx";
import { request, csrfHeaders } from "../../../../utils/request.js";
import AudioPlayer, {
  SmallAudioPlayer,
} from "../../../../utils/audio_player/AudioPlayer.jsx";
import { DateCountDown } from "../../../../utils/formatAudioTime.js";
import UserAudioInfoList from "./UserAudioInfoList.jsx";
import "./AudioCommunity.scss";
import "./UserAudioInfoList.scss";
// review read、release module
export default class AudioCommunity extends PureComponent {
  constructor(props) {
    super(props);
    const community = this.props.community;
    const current_baby_audio = community.current_baby_audio;
    this.state = {
      more_baby_recorders: community.baby_audio_recorders,
      page: 2,
      current_every_request_recorder: { length: 10 },
      isStared: current_baby_audio && current_baby_audio.liked,
      likeUrl: current_baby_audio && current_baby_audio.like_url,
      starNumed: current_baby_audio && current_baby_audio.rank,
    };
  }

  // 取消发布之前的弹窗提醒
  PreCancelRelease = () => {
    const buttons = [
      {
        label: "确定取消",
        onClick: () => {
          this.confirmDialog.close();
          this.cancalReleaseAudio();
        },
      },
      {
        label: "不取消",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        取消发布，其他人就听不到
        {this.props.community.current_baby_audio.baby.nickname}读的诗了
      </Dialog>
    );
  };

  // click release audio
  releaseAudio = () => {
    let url = "";
    if (this.state.update_state_url) {
      url = this.state.update_state_url;
    }
    if (this.state.draft_update_state_url) {
      url = this.state.draft_update_state_url;
    }
    const params = {
      state: 1,
    };
    request({
      url: url,
      method: "PUT",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        const data = res.data;
        this.setState({
          entire_record_url: data.entire_record_url,
          update_time: data.update_time,
          audio_info_url: data.audio_info_url,
          update_state_url: data.update_state_url,
          update_state: data.state,
        });
        location.reload();
        console.log(
          "发布语音: ",
          data,
          "update_state",
          this.state.update_state
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // cancel issue audio
  cancalReleaseAudio = () => {
    let url = "";
    if (this.state.update_state_url) {
      url = this.state.update_state_url;
    }
    if (this.state.draft_update_state_url) {
      url = this.state.draft_update_state_url;
    }
    const params = {
      state: 0,
    };
    request({
      url: url,
      method: "PUT",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        const data = res.data;
        this.setState({
          draft_entire_record_url: data.entire_record_url,
          draft_update_time: data.update_time,
          draft_audio_info_url: data.audio_info_url,
          draft_update_state_url: data.update_state_url,
          draft_update_state: data.state,
        });
        location.reload();
        console.log(
          "取消发布: ",
          data,
          "draft_update_state ",
          this.state.draft_update_state
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadingMoreAudio = () => {
    this.toast = ToastManager.showLoading("拼命加载中...");
    const url = this.props.community.request_baby_audio_recorder_url;
    const params = {
      input: {
        page: this.state.page,
        count: 10,
      },
    };
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        this.toast.cancel();
        const data = res.data;
        this.setState({
          more_baby_recorders: this.state.more_baby_recorders.concat(
            data.baby_audio_recorders
          ),
          page: this.state.page + 1,
          current_every_request_recorder: res.data.baby_audio_recorders,
        });
      })
      .catch((err) => {
        this.toast.cancel();
        console.log(err);
      });
  };

  // click collect zan and cancel_zan
  staredOneselfClick = () => {
    request({
      url: this.state.likeUrl,
      method: "POST",
      headers: csrfHeaders,
    }).catch((err) => {
      console.log(err);
    });
    if (!this.state.isStared) {
      // 点赞 +1
      console.log("点赞");
      this.setState({
        isStared: true,
        starNumed: parseInt(this.state.starNumed) + 1,
      });
    } else {
      // 点赞 -1
      console.log("取消赞");
      this.setState({
        isStared: false,
        starNumed: parseInt(this.state.starNumed) - 1,
      });
    }
  };

  componentWillMount() {
    const community = this.props.community;
    const current_baby_audio = community.current_baby_audio;
    if (current_baby_audio) {
      const published = current_baby_audio.published;
      const draft = current_baby_audio.draft;
      if (published) {
        this.setState({
          entire_record_url: published.entire_record_url,
          update_time: published.update_time,
          audio_info_url: published.audio_info_url,
          update_state_url: published.update_state_url,
          update_state: published.state,
        });
      }
      if (draft) {
        this.setState({
          draft_entire_record_url: draft.entire_record_url,
          draft_update_time: draft.update_time,
          draft_audio_info_url: draft.audio_info_url,
          draft_update_state_url: draft.update_state_url,
          draft_update_state: draft.state,
        });
      }
    }
  }

  // before issue audio
  reReadIssueAudio(current_baby_audio) {
    let entireRecordUrl;
    if (
      current_baby_audio &&
      current_baby_audio.published &&
      this.state.update_state === 0
    ) {
      entireRecordUrl = this.state.entire_record_url;
    }
    if (
      current_baby_audio &&
      current_baby_audio.draft &&
      this.state.draft_update_state === 0
    ) {
      entireRecordUrl = this.state.draft_entire_record_url;
    }
    return (
      <div className="audio_operate">
        <AudioPlayer
          name={this.props.poetryName}
          babyName={current_baby_audio.baby.nickname}
          entireRecordUrl={entireRecordUrl}
        />
        <div className="operate_section">
          <a
            href="javascript:void(0)"
            onClick={debounce(this.props.poetryTap, 1000)}
            data-url={current_baby_audio.re_record_url}
          >
            重读
          </a>
          <a href="javascript:void(0)" onClick={this.releaseAudio}>
            发布
          </a>
        </div>
      </div>
    );
  }

  // no audio list , need show text describe
  createTextDescribe(text) {
    return (
      <div className="pre_release_remind">
        <p>{text}</p>
      </div>
    );
  }

  // after issue audio (zan and cancel_zan)
  userAudioShow(current_baby_audio, isStared, starNumed, systemTime) {
    let userGender;
    if (current_baby_audio.baby.photo_url == null) {
      if (current_baby_audio.baby.gender == "男") {
        userGender = (
          <React.Fragment>
            <img data-src={icon_default_boy} alt="" className="lazyload" />
            <div className="to_add_avatar"></div>
          </React.Fragment>
        );
      } else {
        userGender = (
          <React.Fragment>
            <img data-src={icon_default_girl} alt="" className="lazyload" />
            <div className="to_add_avatar"></div>
          </React.Fragment>
        );
      }
    } else {
      userGender = (
        <img
          data-src={current_baby_audio.baby.photo_url}
          alt=""
          className="lazyload"
        />
      );
    }
    let userDateCountDown, entireRecordUrl, audioInfoUrl;
    // current_baby_audio is published mode
    if (
      current_baby_audio &&
      current_baby_audio.published &&
      this.state.update_state === 1
    ) {
      userDateCountDown = (
        <span>{DateCountDown(this.state.update_time, systemTime)}</span>
      );
      entireRecordUrl = this.state.entire_record_url;
      audioInfoUrl = this.state.audio_info_url;
    }
    // current_baby_audio is draft mode
    if (
      current_baby_audio &&
      current_baby_audio.draft &&
      this.state.draft_update_state === 1
    ) {
      userDateCountDown = (
        <span>{DateCountDown(this.state.draft_update_time, systemTime)}</span>
      );
      entireRecordUrl = this.state.draft_entire_record_url;
      audioInfoUrl = this.state.draft_audio_info_url;
    }
    return (
      <li className="comp_user_info_container">
        <div className="user_info_container">
          <div className="user_info_wrapper">
            <a
              className="user_avatar"
              href={current_baby_audio.baby.edit_baby_path}
            >
              {userGender}
            </a>
            <div className="text_info">
              <p>
                {current_baby_audio.baby.nickname}
                {current_baby_audio.baby.grade && (
                  <span>（{current_baby_audio.baby.grade}）</span>
                )}
              </p>
              <div className="baby_info">{userDateCountDown}</div>
            </div>
          </div>

          <div className="user_setting">
            <div className="stared">
              <div
                className={
                  isStared
                    ? "button_wrapper_active_lesson_one"
                    : "button_wrapper_lesson_one"
                }
                onClick={this.staredOneselfClick}
              >
                <img
                  style={{
                    marginLeft: isStared ? null : "5px",
                    paddingTop: isStared ? null : "3px",
                  }}
                  src={isStared ? starYellow : starWhite}
                  alt=""
                />
                {starNumed === 0 ? null : (
                  <div className="start_num">{starNumed}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="user_audio_recorder">
          <SmallAudioPlayer
            audioSrc={entireRecordUrl}
            audioInfoUrl={audioInfoUrl}
            index="0"
          />
          <div className="cancel_release_audio">
            <span onClick={this.PreCancelRelease}>取消发布</span>
          </div>
        </div>
      </li>
    );
  }

  render() {
    const { more_baby_recorders, isStared, starNumed } = this.state;
    // eslint-disable-next-line react/prop-types
    const {
      systemTime,
      community,
      courseFinishCondition,
      isSanZiJing,
    } = this.props;
    console.log("render - update_state: ", this.state.update_state);
    console.log("render - draft_update_state: ", this.state.draft_update_state);
    const current_baby_audio = community.current_baby_audio;
    let textDescribe;
    if (community && !current_baby_audio) {
      if (courseFinishCondition === true) {
        textDescribe = this.createTextDescribe(
          `快完成${community.media_lesson_name}中的跟读录音`
        );
      } else {
        if (more_baby_recorders.length <= 0) {
          textDescribe = this.createTextDescribe(
            `快完成今日学习，成为第一个${isSanZiJing ? "朗读" : "读诗"}的人吧`
          );
        } else {
          textDescribe = this.createTextDescribe(
            `快完成今日学习，一起来${isSanZiJing ? "朗读" : "读诗"}吧`
          );
        }
      }
    }
    return (
      <div className="comp_audios_together">
        {textDescribe}
        <div className="user_audio_list">
          {
            // user person audio published mode
            current_baby_audio &&
              current_baby_audio.published &&
              this.state.update_state === 0 &&
              this.reReadIssueAudio(current_baby_audio)
          }
          {
            // user person audio draft mode
            current_baby_audio &&
              current_baby_audio.draft &&
              this.state.draft_update_state === 0 &&
              this.reReadIssueAudio(current_baby_audio)
          }
          <ul>
            {
              // user person audio list published mode
              current_baby_audio &&
                current_baby_audio.published &&
                this.state.update_state === 1 &&
                this.userAudioShow(
                  current_baby_audio,
                  isStared,
                  starNumed,
                  systemTime
                )
            }
            {
              // user person audio list draft mode
              current_baby_audio &&
                current_baby_audio.draft &&
                this.state.draft_update_state === 1 &&
                this.userAudioShow(
                  current_baby_audio,
                  isStared,
                  starNumed,
                  systemTime
                )
            }

            {more_baby_recorders.map((recorder, index) => (
              <UserAudioInfoList
                key={index}
                recorder={recorder}
                index={index + 1}
                starNumed={recorder.rank}
                liked={recorder.liked}
                likeUrl={recorder.like_url}
                systemTime={systemTime}
              />
            ))}
          </ul>
          {this.state.more_baby_recorders.length >= 10 && (
            <div onClick={this.loadingMoreAudio}>
              {/* eslint-disable-next-line eqeqeq */}
              {this.state.current_every_request_recorder.length == 10 && (
                <div className="loading_comment">加载更多</div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
