import React from "react";
import { SmallAudioPlayer } from "../../../../utils/audio_player/AudioPlayer.jsx";
import "./PoetryBabyAudio.scss";

const PoetryBabyAudio = (props) => {
  return (
    <div className="comp_poetry_baby_audio">
      <SmallAudioPlayer {...props} />
    </div>
  );
};

export default PoetryBabyAudio;
