import React from "react";
import PropTypes from "prop-types";
import StudyProgressCircle from "../../../../utils/study_progress_circle/StudyProgressCircle.jsx";
import "./LearningProcessing.scss";

const LearningProcessing = ({
  studiedCourseDayCount,
  courseDayCount,
  studiedMediaCorsesCount,
  mediaCoursesCount,
  isHiddenMediaCoursesCount,
}) => {
  return (
    <div className="comp_study_progress_circle">
      <StudyProgressCircle
        isHiddenRightCircle={isHiddenMediaCoursesCount}
        studiedCourseDayCount={studiedCourseDayCount} // 已学天数
        courseDayCount={courseDayCount} // 总天数
        totalStudiedMediaLessonsCount={studiedMediaCorsesCount} // 已学课程数
        mediaLessonsCount={mediaCoursesCount} // 总课程数
        leftCircleName={"已学天数"} // 左边环title
        rightCircleName={"已学古诗"} // 右边环title
      />
    </div>
  );
};

LearningProcessing.displayName = "LearningProcessing";

LearningProcessing.propTypes = {
  studiedCourseDayCount: PropTypes.number,
  courseDayCount: PropTypes.number,
  studiedMediaCorsesCount: PropTypes.number,
  mediaCoursesCount: PropTypes.number,
  isHiddenMediaCoursesCount: PropTypes.bool,
};

export default LearningProcessing;
