import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Picker from "better-picker";
import { DateHourData } from "../../../UnitModeStudyPackage.jsx";
import UserAgent from "../../../../utils/UserAgent";
import { request, csrfHeaders } from "../../../../utils/request";
import "./LearningReminder.scss";

const LearningReminder = (props) => {
  const {
    reminderConfig: {
      reminder_time_hour: reminderTimeHour = -1,
      config_reminder_time_url: configReminderTimeUrl,
    },
  } = props;
  const setRemainderPicker = useCallback(
    (dom) => {
      const picker = new Picker({
        data: [DateHourData],
        selectedIndex: [reminderTimeHour + 1],
        title: "",
      });
      picker.on("picker.select", function (selectedVal, selectedIndex) {
        dom.innerText = `当前上课提醒${DateHourData[
          selectedIndex[0]
        ].text.replace("点", "")}:00`;

        // eslint-disable-next-line eqeqeq
        if (selectedIndex[0] - 1 != reminderTimeHour) {
          const params = {
            reminder_hour: selectedIndex[0] - 1,
          };
          request({
            url: configReminderTimeUrl,
            method: "POST",
            headers: csrfHeaders,
            data: params,
          })
            .then((resp) => {
              console.log("正确返回");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });

      if (reminderTimeHour >= -1) {
        const time_setup_click = document.getElementById("time_setup_click");

        if (time_setup_click) {
          time_setup_click.addEventListener("click", function () {
            picker.show();
            if (UserAgent.isNativeClient()) {
              // eslint-disable-next-line no-undef
              XinYaNativeBridge.requestAuthorization({
                authType: "notification",
              });
            }
          });
        }
      }
    },
    [configReminderTimeUrl, reminderTimeHour]
  );

  useEffect(() => {
    const pickerDom = document.getElementById("reminder_picker");
    setRemainderPicker(pickerDom);
  }, [setRemainderPicker]);

  return (
    <div className="comp_learning_reminder">
      <div className="current_remind_time" id="reminder_picker">
        {reminderTimeHour === -1
          ? "不提醒"
          : `当前上课提醒${reminderTimeHour}:00`}
      </div>
      <div className="change_reminder_time" id="time_setup_click">
        更改时间
      </div>
    </div>
  );
};

LearningReminder.displayName = "LearningReminder";

LearningReminder.propTypes = {
  reminderConfig: PropTypes.shape({
    reminder_time_hour: PropTypes.number,
    config_reminder_time_url: PropTypes.string,
  }),
};

export default LearningReminder;
