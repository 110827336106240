import React from "react";
import styled from "styled-components";
import icon_grade from "reading/icon_grade.png";
import icon_upload_once from "reading/icon_upload_once.png";
import { InfoBroadTitle, Photo } from "../reading_details/ExaminationCommit";
const GradeResulte = styled.div`
  position: absolute;
  top: -3px;
  right: 15px;
  width: 71px;
  height: 71px;
  background: url(${icon_grade}) 0 0/100% 100% no-repeat;
  &::before {
    content: "${({ content }) => content}";
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
`;
export default (props) => {
  console.log(props, "myanswer");
  return (
    <InfoBroadTitle isImg={true} titles={"我的回答"}>
      {props.reading_comment_state == "commented" && props.score_level ? (
        <GradeResulte content={props.score_level}></GradeResulte>
      ) : (
        ""
      )}
      <div
        className="answer-area-mian"
        style={{ padding: 0, margin: "0 -4px" }}
      >
        {props.answerArr &&
          props.answerArr.map((answer_item, answer_index) => {
            return !answer_item ? (
              ""
            ) : (
              // <div key={"anser" + answer_index}>
              <Photo
                className="answer-area-mian-item"
                key={"anser" + answer_index}
                file={
                  props.reading_comment_state == "commented"
                    ? answer_item.comment_photo_url
                    : answer_item.answer_photo_url
                }
                index={answer_index}
                setAnswerPhotos={() => {}}
                isReviewed={true}
                isUploaded={true}
              ></Photo>

              // </div>
            );
          })}
      </div>
      {props.reading_comment_state == "pending_comment" && !props.hideReuploadButton ? (
        <div
          className="upload"
          onClick={() => {
            props.setUploaded && props.setUploaded(false);
          }}
        >
          重新上传<img src={icon_upload_once}></img>
        </div>
      ) : (
        ""
      )}
    </InfoBroadTitle>
  );
};
