/* eslint-disable react/prop-types */
import React, { memo } from "react";
import "./CourseSignInReminder.scss";

const CourseSignInReminder = memo(({ ...props }) => {
  const { url, text } = props;
  return (
    <div className="comp_course_signin_reminder">
      <div className="flex_wrapper">
        <dl>
          <dt>万千家长好评的课程，连报更优惠</dt>
          <dd>{text}</dd>
        </dl>
        <a className="install_btn" href={url}>
          立即报名
        </a>
      </div>
    </div>
  );
});

export default CourseSignInReminder;
