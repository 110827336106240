import { expr } from "jquery";
import React, { useState } from "react";
import star_light from "c4/star_light.png";
import star_gery from "c4/star_gery.png";
import UserAgent from "../../../utils/UserAgent";
import "./UpdateApp.scss";
import XinYaNativeBridge from "../../../../packs/native_bridges/native_bridge";
export default (props) => {
  console.log("upgrade_app_url", props);
  const [showUpper, setShowUpper] = useState(true);
  return showUpper && props.upgrade_app_url ? (
    <div className="upper-icon">
      <div className="upper-icon-back-img">
        {/* 更新内容 */}
        <div className="upper-icon-back-text">
          <div style={{ color: "#66d167" }}>新版APP中新增：语音题型</div>
          <div style={{ color: "#66d167" }}>
            请升级APP，否则将无法进行练习哦~
          </div>
        </div>
        {/* 更新按钮 ============ */}
        <div
          className="upper-icon-back-text-item-button"
          onClick={() => {
            if (UserAgent.isAndroid()) {
              XinYaNativeBridge.navigate({
                href: props.upgrade_app_url,
                type: "update",
              });
            } else {
              XinYaNativeBridge.navigate({
                href: props.upgrade_app_url,
              });
            }
          }}
        >
          立即升级
        </div>
        {/* 更新按钮 ============*/}
      </div>
      {/* 取消按钮 */}
      <div
        className="upper-icon-back-text-item-cancle"
        onClick={() => {
          console.log("..........cancle");
          setShowUpper(false);
        }}
      ></div>
      {/* 取消按钮 ===============*/}
      {/* 更新提示====================== */}
    </div>
  ) : (
    ""
  );
};
