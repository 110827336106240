import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  memo,
} from "react";
import Raven from "raven-js";
import QuizVoice from "../quiz_question/QuizVoice";
import { TabPane, Tabs } from "../../utils/Tabs/Tabs.js";
import LazyloadContent from "../../utils/lazyload/LazyloadContent";
import { debounce } from "../helpers.js";
import { BasicQuizGeneration } from "../../day_studies/BasicQuizGeneration";
import ImageEdit from "../../study_packages/Certificate/ImageEdit";
import CanvasBoard from "../../utils/canvasBoard.js";
import icon_quiz_pass from "icon_quiz_pass.png";
import iconVoice from "icon_play_normal.png";
import iconVoicePlaying from "study_package/icon_play_white_selected.gif";
import InnerModal from "../Modal/InnerModal";
import eraser from "icon_rubber.png";

export const ConfirmButton = ({
  isForSubQuestions,
  confirmSelect,
  isSelectedOrFilled,
  text,
  styles,
}) => (
  <div
    className="confirm_button_box"
    style={isForSubQuestions ? { width: "100%" } : {}}
  >
    <div
      className={styles ? styles : "confirm_button fill-btn-submit"}
      onClick={debounce(confirmSelect)}
      style={{ background: isSelectedOrFilled ? "#49c114" : "lightgrey" }}
    >
      {text ? text : "确定"}
    </div>
  </div>
);

export const WriterBoardArea = memo(({ letterImage, confirmWrite, quizId }) => {
  const canvasRef = useRef();
  // const [imgSrc, setImgSrc] = useState(null);
  // const [isEnableEraser, setEnableEraser] = useState(false);

  const canvasBoard = useMemo(() => new CanvasBoard(), []);

  useEffect(() => {
    canvasBoard.setCanvasElement(canvasRef.current);
    const removeEvents = canvasBoard.bindEvents({
      mouseout: (myCanvasBoard, e) => {
        myCanvasBoard.painting = false;
      },
    });
    return () => removeEvents();
  }, [canvasBoard]);

  // const onEraserClick = useCallback(
  //   bool => {
  //     setEnableEraser(isEnableEraser => {
  //       const toSet = typeof bool === "boolean" ? bool : !isEnableEraser;
  //       canvasBoard.enableEraser(toSet);
  //       return toSet;
  //     });
  //   },
  //   [canvasBoard]
  // );

  const onClearBoard = useCallback(() => {
    canvasBoard.clearBoard();
  }, [canvasBoard]);

  const onSave = useCallback(
    async (letterPic) => {
      const fakeCanvas = document.createElement("canvas");
      const {
        width: canvasWith,
        height: canvasHeight,
      } = canvasBoard.canvasElement;

      fakeCanvas.width = canvasWith;
      fakeCanvas.height = canvasHeight;

      const fakeCanvasBoard = new CanvasBoard(fakeCanvas);
      console.log("letterPic", letterPic);
      try {
        await fakeCanvasBoard.drawImg({
          isHTMLImage: true,
          src: letterPic,
          left: 0,
          top: 0,
          width: canvasWith,
          height: canvasHeight,
          // height: 100
        });

        await fakeCanvasBoard.drawImg({
          isHTMLImage: false,
          src: canvasBoard.canvasElement,
          top: 0,
          left: 0,
          width: canvasWith,
          height: canvasHeight,
        });

        const img = await fakeCanvasBoard.toImg();
        return img;
        // console.log(img);
        // setImgSrc(img)
      } catch (e) {
        Raven.captureException(e);
        // alert("保存图片出错，请重新尝试");
      }
    },
    [canvasBoard.canvasElement]
  );

  const confirmSelect = useCallback(async () => {
    const img = await onSave(letterImage);
    confirmWrite(quizId, img, onClearBoard);
  }, [confirmWrite, letterImage]);

  return (
    <div className="answer-eara big_area">
      <div className="eraser" onClick={onClearBoard}>
        <img src={eraser} alt="" />
      </div>

      <div className="canvas_wrapper">
        <canvas
          ref={canvasRef}
          width="260"
          height="260"
          style={{
            backgroundImage: `url(${letterImage})`,
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></canvas>
        <ConfirmButton
          isSelectedOrFilled={true}
          confirmSelect={confirmSelect}
        />
        {/* <img src={imgSrc} /> */}
      </div>
    </div>
  );
});

const VoiceOption = memo(({ audioUrl, isStopVoice }) => {
  const audioRef = useRef();

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const resetAudiosPlay = useCallback(() => {
    const voiceOptionAudios = Array.from(
      document.getElementsByClassName("_audio_voice_audio")
    );
    if (voiceOptionAudios.length) {
      voiceOptionAudios.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });
    }
  }, []);

  useEffect(() => {
    if (isStopVoice) {
      resetAudiosPlay();
    }
  }, [isStopVoice]);

  const onVoiceOptionClick = useCallback(() => {
    if (audioRef.current && !isAudioPlaying) {
      resetAudiosPlay();
      audioRef.current.play();
      setIsAudioPlaying(true);
    } else if (audioRef.current && isAudioPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsAudioPlaying(false);
    }
  }, [isAudioPlaying, resetAudiosPlay]);

  const onAudioStop = useCallback(() => setIsAudioPlaying(false), []);

  return (
    <div
      className="audio_voice"
      style={{ width: "100%", height: 70 }}
      onClick={onVoiceOptionClick}
    >
      <audio
        src={audioUrl}
        ref={audioRef}
        className="_audio_voice_audio"
        onEnded={onAudioStop}
        onPause={onAudioStop}
      />
      <img src={isAudioPlaying ? iconVoicePlaying : iconVoice} alt="" />
    </div>
  );
});

export const SetCanScrollIcon = () => {
  return <div className="can_scroll_icon"></div>;
};

export const EffectAudioGroup = () => [
  <audio
    src="https://upload.fireflybaby.cn/failure1.mp3"
    id="effect_fail_sound"
    preload="auto"
    key="effect_fail_sound"
  />,
  <audio
    src="https://upload.fireflybaby.cn/pass.mp3"
    id="effect_pass_sound"
    preload="auto"
    key="effect_pass_sound"
  />,
];

export const ShowQuestionPhotoUrl = ({
  isSuccDisplay,
  questionPhotoUrl,
  reloadQuizImages,
  handleImgDialog,
}) => {
  return (
    <div className="quiz-question-image">
      {isSuccDisplay ? (
        !!questionPhotoUrl && (
          <img src={questionPhotoUrl} onClick={handleImgDialog} />
        )
      ) : (
        <div className="refreshButton" onClick={reloadQuizImages}>
          重新加载图片
        </div>
      )}
    </div>
  );
};

export const ImgDialog = ({ photo_url, handleImgDialog }) => {
  const photo = {
    src: photo_url,
    width: 540,
    height: 258,
  };
  return (
    <div className="img_dialog_container">
      <div className="close_img_dialog" onClick={handleImgDialog}></div>
      <div className="img_dialog_box">
        <ImageEdit key={photo_url} photo={photo} edited={() => {}} />
        {/* <img src={photo_url} alt=""/> */}
      </div>
    </div>
  );
};

export const ShowInputArea = memo(
  ({
    isSuccDisplay,
    answerText,
    isSelectedOrFilled,
    handleInputChange,
    btnSubmitFillAnwer,
    confirmButton,
    quizCurrentIdxStatus,
  }) => {
    return (
      isSuccDisplay && (
        <div className="fill_subject_confirm_box common_subject_confirm_box">
          <div className="exam-fill-subject">
            <input
              type="tel"
              placeholder="请输入答案"
              value={answerText}
              onChange={!quizCurrentIdxStatus ? handleInputChange : () => {}}
              // disabled={`${!quizCurrentIdxStatus ? null : "disabled"}`}
            />
            {!quizCurrentIdxStatus &&
              (!confirmButton ? (
                <div className="confirm_button_box fill_button_box">
                  <button
                    className="fill-btn-submit"
                    onClick={btnSubmitFillAnwer}
                    style={{
                      background: isSelectedOrFilled ? "#49c114" : "lightgrey",
                    }}
                  >
                    确定
                  </button>
                </div>
              ) : (
                confirmButton()
              ))}
          </div>
        </div>
      )
    );
  }
);

export const ShowOptionsArea = memo(
  ({
    isSuccDisplay,
    options,
    isSelectedOrFilled,
    selectedIndex,
    handleSelect,
    confirmButton,
    quizCurrentIdxStatus,
    singleOptionTrueValue,
    isOptionsImageExit,
    withoutWrapper,
  }) => {
    const [isStopVoice, setIsStopVoice] = useState(false);
    const answerOptions = useMemo(
      () => (
        <React.Fragment>
          <div className="answer-eara">
            <ul
              className={`${
                isOptionsImageExit ? "is_image_option" : "is_font_option"
              }`}
            >
              {options &&
                options.map((option, index) => {
                  return (
                    <li
                      key={option.id}
                      id={option.id}
                      data-index={index}
                      onClick={!quizCurrentIdxStatus ? handleSelect : null}
                      className={
                        // 查看答过的题目争取的选项和当前选项正确的值做对比
                        singleOptionTrueValue == option.correct
                          ? "selected"
                          : isSelectedOrFilled && selectedIndex == index
                          ? "selected"
                          : ""
                      }
                      style={{ padding: option.audio ? 0 : "" }}
                      data-option={option.correct}
                    >
                      {option.audio ? (
                        <VoiceOption
                          audioUrl={option.audio.url}
                          isStopVoice={isStopVoice}
                        />
                      ) : option.photo ? (
                        <img src={option.photo.url} key={option.photo.id} />
                      ) : (
                        option.content
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
          {!quizCurrentIdxStatus && confirmButton(setIsStopVoice)}
        </React.Fragment>
      ),
      [
        confirmButton,
        handleSelect,
        isOptionsImageExit,
        isSelectedOrFilled,
        options,
        quizCurrentIdxStatus,
        selectedIndex,
        singleOptionTrueValue,
      ]
    );
    if (withoutWrapper) {
      return answerOptions;
    }
    return (
      isSuccDisplay && (
        <div className="option_area_confirm_box common_subject_confirm_box">
          {answerOptions}
        </div>
      )
    );
  }
);

export const ShowCountDownTime = ({ countDownTime, handleCountdownEnd }) =>
  countDownTime > 0 ? (
    <CountDownTime
      endTime={new Date(countDownTime)}
      overText="结束考试"
      onEnd={handleCountdownEnd}
    >
      {({ d, h, m, s }) => {
        return (
          <span>
            {h}:{m}:{s}
          </span>
        );
      }}
    </CountDownTime>
  ) : (
    ""
  );

export const QuizProgressBar = ({
  quiz_length_width,
  quizzes,
  quiz_current_idx,
  quizProgressClick,
  selectedQuizIndex = 0,
  quizCurrentIdxStatus,
}) => {
  return (
    <div
      className="quiz_progress_bar_box"
      style={{ paddingLeft: quizzes.length > 6 ? "10px" : null }}
    >
      <div className="quiz_progress_bar" style={{ width: quiz_length_width }}>
        <div
          className="progress_bar_line"
          style={{ width: quiz_length_width }}
        ></div>
        <div className="box_progress_bar">
          {quizzes.length > 0 &&
            quizzes.map((quiz, index) => (
              <div
                key={index}
                className={`progress_bar ${
                  quiz_current_idx >= index ? "highlight" : ""
                }`}
                data-index={index}
                onClick={quizProgressClick}
              >
                {quizCurrentIdxStatus
                  ? selectedQuizIndex == index &&
                    selectedQuizIndex <= quiz_current_idx && (
                      <div className="active"></div>
                    )
                  : quiz_current_idx === index && (
                      <div className="active"></div>
                    )}
                <span>{index + 1}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

class SubQuestionsItems extends React.Component {
  constructor(props) {
    super(props);
    let quiz = this.initRecoverRecord.call(this, props.quizzes);

    this.state = {
      quiz,
      answerText: "",
      isSuccDisplayQuizImages: true,
      quizCurrentIdxStatus: false, // 点击相应的进度条索引的状态值
      imgDialogStatus: false,
    };
    this.handleAnswers = this.SubQuestionsAnswerStorage();
  }
  SubQuestionsAnswerStorage() {
    let storage = [];
    return {
      push: (data) => {
        storage.push(data);
      },
      get: () => storage,
      clear: () => (storage = null),
    };
  }

  handleUserAnswerData = (...args) => {
    const questionType = args[0];
    const { quiz, answerText } = this.state;
    const { handleLastSubQuestion } = this.props;
    const isLastQuiz = this.isLastQuiz();
    console.warn("subq cost,", this.endAnswerTime - this.startAnswerTime);
    const basicData = {
      id: quiz.id,
      start_answer_time: this.startAnswerTime,
      end_answer_time: this.endAnswerTime,
      question_type: questionType,
    };
    if (questionType === 1) {
      const correctOption = args[1];
      const option = args[2];
      this.handleAnswers.push({
        ...basicData,
        correct: option.correct,
        choose_option: option,
        correct_option: correctOption,
      });
    }
    if (questionType === 0) {
      const isAnswerCorrect = args[1];
      this.handleAnswers.push({
        ...basicData,
        correct: isAnswerCorrect,
        input_answer: answerText.trim(),
        correct_answer: quiz.answer,
      });
    }
    if (questionType === 3) {
      const isAnswerCorrect = true;
      this.handleAnswers.push({
        ...basicData,
        correct: isAnswerCorrect,
        input_answer: "",
        correct_answer: quiz.answer,
      });
    }
    if (isLastQuiz) {
      handleLastSubQuestion(this.handleAnswers.get());
      this.handleAnswers.clear();
    }
  };

  handleSelectedAnswerData = (correctOption, option) => {
    this.handleUserAnswerData(1, correctOption, option);
  };
  handleFilledAnswerData = (isAnswerCorrect) => {
    this.handleUserAnswerData(0, isAnswerCorrect);
  };

  handleWrithenAnswerData = (isAnswerCorrect) => {
    this.handleUserAnswerData(3, isAnswerCorrect);
  };

  isLastQuiz = () => {
    const { quizzes } = this.props;
    const { quiz } = this.state;
    const index = quizzes.findIndex((element) => {
      return element.id == quiz.id;
    });
    return index + 1 === quizzes.length;
  };

  tabClick = (e, activeTabId) => {};

  quizProgressClick = (e) => {
    const { quizzes } = this.props;
    let data_index = e.currentTarget.getAttribute("data-index");
    const current_index = quizzes.findIndex((element) => {
      return element.id == this.state.quiz.id;
    });

    // 针对当前点击的索引下的题目是选择题的情况, 找到选项为true的情况
    if (quizzes[data_index].options) {
      let current_option_correct = quizzes[data_index].options.filter(
        (item) => {
          if (item.correct == true) {
            return item;
          }
        }
      );
      this.setState({
        singleOptionTrueValue: current_option_correct[0].correct,
      });
    }

    // 下一题 “current_index(当前的索引)”, 和用户 ”data_indexx(点击的索引)“
    if (data_index < current_index) {
      this.setState(() => ({
        selectedQuizIndex: data_index,
        quizCurrentIdxStatus: true,
        currentClickQuiz: quizzes[data_index],
        fillButtonDialog: false,
        optionButtonDialog: false,
        scrollLeftStatus: false,
      }));
    } else {
      this.setState(() => ({
        quizCurrentIdxStatus: false,
      }));
    }
  };

  handleDialogNextQuestion = () => {
    this.deliverNextQuestionStatus.call(this);
  };

  render() {
    /**
     * totalQuizzes: 组件 PracticeLearning 传过来的quizzes数据
     * totalCurrentIndex: 组件 PracticeLearning 传过来的quizzes当前的索引
     */
    const {
      quizzes,
      dayExam,
      totalCurrentIndex,
      totalQuizzes,
      quizAudioEnable,
    } = this.props;
    const {
      isSelectedOrFilled,
      scrollLeftStatus,
      quiz,
      selectedIndex,
      isSuccDisplayQuizImages,
      answerText,
      selectedQuizIndex,
      quizCurrentIdxStatus,
      singleOptionTrueValue,
      currentClickQuiz,
      optionButtonDialog,
      fillButtonDialog,
      imgDialogStatus,
    } = this.state;

    const dayExamName = dayExam?.name ?? "随堂测试";

    /**
     * optionButtonDialog: 选择选项后, 点击确定, 生成为true的状态
     * fillButtonDialog: 填写填空题后, 点击确定, 生成为true的状态
     */

    // console.log("optionButtonDialog", optionButtonDialog)
    // console.log("fillButtonDialog", fillButtonDialog)

    const options = quiz.options;
    const index = quizzes.findIndex((element) => {
      return element.id == quiz.id;
    });
    let quiz_length_width;
    if (quizzes.length > 0) {
      // 这里的 “62” = (答题tab的圆的宽度：32px) + (margin-right: 30px)
      // 这里的 “32” = (答题tab的圆的宽度：32px)
      quiz_length_width = (quizzes.length - 1) * 62 + 32 + "px";
    }

    const isLastQuiz = this.isLastQuiz();
    const buttonDialogStatus =
      (optionButtonDialog || fillButtonDialog) && isLastQuiz;
    const isTotalLastQuizzesLength = totalQuizzes.length;

    if (scrollLeftStatus) {
      document.getElementsByClassName("quiz_progress_bar_box")[0].scrollLeft =
        62 * index;
    }

    const AnswerPaneSection = () =>
      quizzes.length > 0 &&
      (quizCurrentIdxStatus ? (
        <PreAndNextSubQuestionsContainer
          handleWrittenConfirm={this.confirmWrite}
          selectedQuizIndex={selectedQuizIndex}
          quiz={quizzes[selectedQuizIndex]}
          isSuccDisplayQuizImages={isSuccDisplayQuizImages}
          reloadQuizImages={this.reloadQuizImages}
          answerText={quizzes[selectedQuizIndex].answer}
          options={quizzes[selectedQuizIndex].options}
          quizCurrentIdxStatus={quizCurrentIdxStatus}
          singleOptionTrueValue={singleOptionTrueValue}
          imgDialogStatus={imgDialogStatus}
          quizAudioEnable={quizAudioEnable}
          handleImgDialog={() => this.handleImgDialog(true)}
          handleImgCloseDialog={() => this.handleImgDialog(false)}
        />
      ) : (
        <SubQuestionsContainer
          handleWrittenConfirm={this.confirmWrite}
          quiz={quiz}
          isSuccDisplayQuizImages={isSuccDisplayQuizImages}
          reloadQuizImages={this.reloadQuizImages}
          options={options}
          handleSelect={this.handleSelect}
          isSelectedOrFilled={isSelectedOrFilled}
          selectedIndex={selectedIndex}
          answerText={answerText}
          handleInputChange={this.handleInputChange}
          // isLastQuiz={isLastQuiz}
          confirmSelect={this.confirmSelect}
          btnSubmitFillAnwer={this.btnSubmitFillAnwer}
          imgDialogStatus={imgDialogStatus}
          quizAudioEnable={quizAudioEnable}
          handleImgDialog={() => this.handleImgDialog(true)}
          handleImgCloseDialog={() => this.handleImgDialog(false)}
        />
      ));

    const displayCurrentQuiz = (quiz) => {
      console.log("quiz----", quiz);
      return !!!quiz.hint && !!!quiz.hint_photo_url ? (
        AnswerPaneSection()
      ) : (
        <Tabs defaultAction={this.tabClick} styles="tabs">
          <TabPane tab="答题" styles="tab" activeStyle="active">
            {AnswerPaneSection()}
          </TabPane>
          <TabPane tab="解题思路" styles="tab" activeStyle="active">
            <LazyloadContent
              content={quiz.hint || ""}
              hint_photo_url={quiz.hint_photo_url || ""}
            />
          </TabPane>
        </Tabs>
      );
    };

    return (
      <div className="comp_sub_questions">
        <QuizProgressBar
          quiz_length_width={quiz_length_width}
          quizzes={quizzes}
          quiz_current_idx={index}
          quizProgressClick={this.quizProgressClick}
          selectedQuizIndex={selectedQuizIndex}
          quizCurrentIdxStatus={quizCurrentIdxStatus}
        />
        <div className="quiz_knowledge_switch">
          <div className="knowledge_switch_scroll">
            <div className="quiz_knowledge_content">
              {quizCurrentIdxStatus
                ? displayCurrentQuiz(currentClickQuiz)
                : displayCurrentQuiz(quiz)}
            </div>
          </div>
        </div>
        {
          /**
           * DialogNextQuestion: 弹框, 真棒 继续下一题
           * DialogResult: 弹框, 最后一题 确定完成
           */
          buttonDialogStatus &&
            (totalCurrentIndex + 1 != isTotalLastQuizzesLength ? (
              <InnerModal>
                <DialogNextQuestion
                  handleNextQuestion={this.handleDialogNextQuestion}
                />
              </InnerModal>
            ) : (
              <DialogResult
                dayExamName={dayExamName}
                handleNextQuestion={this.handleDialogNextQuestion}
              />
            ))
        }
      </div>
    );
  }
}

export const SubQuestions = (props) => {
  const WrappedComponent = BasicQuizGeneration(
    SubQuestionsItems,
    "SUBQUESTIONS"
  );
  return (
    <WrappedComponent
      quizzes={props.questions}
      totalCurrentIndex={props.totalCurrentIndex}
      totalQuizzes={props.totalQuizzes}
      dayExam={props.dayExam}
      quizAudioEnable={props.quizAudioEnable}
      handleLastSubQuestion={props.handleLastSubQuestion}
    />
  );
};

const SubQuestionsContainer = ({
  quiz,
  isSuccDisplayQuizImages,
  reloadQuizImages,
  options,
  handleSelect,
  isSelectedOrFilled,
  selectedIndex,
  answerText,
  handleInputChange,
  isLastQuiz,
  confirmSelect,
  btnSubmitFillAnwer,
  handleImgDialog,
  imgDialogStatus,
  handleImgCloseDialog,
  quizAudioEnable,
  handleWrittenConfirm,
}) => {
  let isOptionsImageExit;
  if (options) {
    isOptionsImageExit = options.some((option) => option.photo);
  }
  // console.log("PracticeTemplates: ", isOptionsImageExit);
  return (
    <div className="sub_questions_container">
      <div className="questions_area">
        {quiz.question_audio_url && quizAudioEnable && (
          <QuizVoice audioUrl={quiz.question_audio_url} quizId={quiz.id} />
        )}
        <div className="question_and_photo">
          {quiz.question && (
            <div className="quiz_content_box">{quiz.question}</div>
          )}
          {quiz.question_type !== 3 && (
            <ShowQuestionPhotoUrl
              isSuccDisplay={isSuccDisplayQuizImages}
              questionPhotoUrl={quiz.question_photo_url}
              reloadQuizImages={reloadQuizImages}
              handleImgDialog={handleImgDialog}
            />
          )}
        </div>
        {imgDialogStatus && (
          <ImgDialog
            photo_url={quiz.question_photo_url}
            handleImgDialog={handleImgCloseDialog}
          />
        )}
        {quiz.question_type === 1 && (
          <ShowOptionsArea
            isSuccDisplay={isSuccDisplayQuizImages}
            options={options}
            isSelectedOrFilled={isSelectedOrFilled}
            selectedIndex={selectedIndex}
            handleSelect={handleSelect}
            isOptionsImageExit={isOptionsImageExit}
            confirmButton={() => (
              <ConfirmButton
                isForSubQuestions
                confirmSelect={confirmSelect}
                isSelectedOrFilled={isSelectedOrFilled}
                key="button"
                text={"确定"}
              />
            )}
          />
        )}
        {quiz.question_type === 0 && (
          <ShowInputArea
            isSuccDisplay={isSuccDisplayQuizImages}
            answerText={answerText}
            isSelectedOrFilled={isSelectedOrFilled}
            handleInputChange={handleInputChange}
            confirmButton={() => (
              <ConfirmButton
                isForSubQuestions
                confirmSelect={btnSubmitFillAnwer}
                isSelectedOrFilled={isSelectedOrFilled}
                key="fill_button"
                text={"确定"}
              />
            )}
          />
        )}

        {quiz.question_type === 3 && (
          <WriterBoardArea
            letterImage={quiz.question_photo_url}
            confirmWrite={handleWrittenConfirm}
          />
        )}
      </div>
    </div>
  );
};

const DialogNextQuestion = ({ handleNextQuestion }) => {
  return (
    <div className="dialog_question_section">
      <div className="dialog_wrapper">
        <div className="text">真棒，继续下一题</div>
        <div className="next_question" onClick={handleNextQuestion}>
          下一题
        </div>
      </div>
    </div>
  );
};

const DialogResult = ({ dayExamName, handleNextQuestion }) => {
  return (
    <div className="dialog_question_section">
      <div className="dialog_wrapper result_wrapper">
        <img className="rank" src={icon_quiz_pass} alt="" />
        <p>{dayExamName}</p>
        <strong>完成</strong>
        <div className="finish_question" onClick={handleNextQuestion}>
          确定
        </div>
      </div>
    </div>
  );
};

const PreAndNextSubQuestionsContainer = ({
  selectedQuizIndex,
  quiz,
  isSuccDisplayQuizImages,
  reloadQuizImages,
  isSelectedOrFilled,
  answerText,
  options,
  quizCurrentIdxStatus,
  singleOptionTrueValue,
  imgDialogStatus,
  handleImgDialog,
  handleImgCloseDialog,
  quizAudioEnable,
  handleWrittenConfirm,
}) => {
  return (
    <div className="sub_questions_container">
      <div className="questions_area">
        {quiz.question_audio_url && quizAudioEnable && (
          <QuizVoice audioUrl={quiz.question_audio_url} quizId={quiz.id} />
        )}
        <div className="question_and_photo">
          <div className="quiz_content_box">{quiz.question}</div>
          {quiz.question_type !== 3 && (
            <ShowQuestionPhotoUrl
              isSuccDisplay={isSuccDisplayQuizImages}
              questionPhotoUrl={quiz.question_photo_url}
              reloadQuizImages={reloadQuizImages}
              handleImgDialog={handleImgDialog}
            />
          )}
        </div>
        {imgDialogStatus && (
          <ImgDialog
            photo_url={quiz.question_photo_url}
            handleImgDialog={handleImgCloseDialog}
          />
        )}

        {quiz.question_type === 1 && (
          <ShowOptionsArea
            isSuccDisplay={isSuccDisplayQuizImages}
            options={options}
            isSelectedOrFilled={isSelectedOrFilled}
            selectedIndex={selectedQuizIndex}
            quizCurrentIdxStatus={quizCurrentIdxStatus}
            singleOptionTrueValue={singleOptionTrueValue}
          />
        )}
        {quiz.question_type === 0 && (
          <ShowInputArea
            isSuccDisplay={isSuccDisplayQuizImages}
            answerText={answerText}
            isSelectedOrFilled={isSelectedOrFilled}
            quizCurrentIdxStatus={quizCurrentIdxStatus}
          />
        )}
        {quiz.question_type === 3 && (
          <WriterBoardArea
            letterImage={quiz.question_photo_url}
            confirmWrite={handleWrittenConfirm}
          />
        )}
      </div>
    </div>
  );
};
