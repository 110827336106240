import React from "react";

const ProgressBar = (props) => {
  const defaultOutterStyle = {
    width: '100%',
    height: '10px',
    backgroundColor: '#EEEEEE',
  }
  const defaultInnerStyle = {
    backgroundColor: '#FFC000',
    height: '100%',
    backgroundSize: 'cover',
  }
  const outterStyle = Object.assign({}, defaultOutterStyle, props.outterStyle)
  let innerStyle = Object.assign({}, defaultInnerStyle)
  // if (props.innerStyle.background || props.innerStyle.backgroundImage) {
  //   innerStyle.backgroundRepeat = 'repeat-x';
  // }
  innerStyle = Object.assign(innerStyle, props.innerStyle);
  if (props.totalLength <= props.currentLength) {
    innerStyle.width = '100%';
  } else {
    innerStyle.width = `${props.currentLength / props.totalLength * 100}%`;
  }
  if (outterStyle.borderRadius) {
    innerStyle.borderRadius = outterStyle.borderRadius;
  }
  return (
    <div className="progress-bar" style={outterStyle}>
      <div className="progress-bar-inner" style={innerStyle}></div>
    </div>
  )
}

export default ProgressBar;