import React from "react";
import "./ContinuousLearningFlag.scss";

const ContinuousLearningFlag = ({ ...props }) => {
  const { course_type, baby_name, study_day, url } = props;
  return (
    <div className="comp_continuous_learning_flag">
      <div className="continuous_learning_text">
        {baby_name}
        {`${course_type == "math" ? "按课表" : ""}`}已连续学习
        <strong>{study_day}</strong>天
      </div>
      <a className="learn_activity_rule" href={url}>
        活动规则 »
      </a>
    </div>
  );
};

export default ContinuousLearningFlag;
