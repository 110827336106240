/* eslint-disable react/prop-types */
import React from "react";
import unlock_tips from "new_poetry/unlock_tips.png";

import "./UnlockAllPoetryGuide.scss";

const UnlockAllPoetryGuide = ({ url }) => {
  return (
    <div className="comp_unlock_all_poetry_course_guide">
      <img src={unlock_tips} alt="" />
      <a className="continue_study" href={url}>
        立即报名
      </a>
    </div>
  );
};

export default UnlockAllPoetryGuide;
