import React, { useState, useEffect, memo } from "react"
import PropTypes from "prop-types"
import "../../shared/useful_css.scss"
import LikeComponent from "../../shared/LikeComponent"

const ExcellentExerciseSingle = ({photoUrl, likesCount, liked, baby, likesUrl, index, handleZoomIn}) => {
  useEffect(() => {
    const width = $('.single-block').parent().parent().width()
    const margin = ((width - 3 * 100) / 6) + 'px'
    $('.excellent-exercise-single').css({marginLeft: margin, marginRight: margin})
    console.log("margin is: ", margin)
  }, [])
  const photoStyle = {
    backgroundImage: `url(${photoUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: "100%",
    height: "205px",
    position: "relative",
    borderRadius: '10px 10px 0 0',
  }

  const avatarStyle = {
    backgroundImage: `url(${baby.avatar})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: '8px',
  }
  
  return (
    <div className="excellent-exercise-single" style={{width: "152px", height: "245px", borderRadius: '10px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'}}>
      <div className="photo" style={photoStyle} onClick={() => { handleZoomIn(index) }}>
        <LikeComponent
          customStyle={{position: 'absolute', bottom: '10px', right: '10px', zIndex: 10}}
          liked={liked}
          likesCount={likesCount}
          likesUrl={likesUrl}
        />
      </div>
      <div className="flex justify-between items-center flex-grow" style={{fontSize: "14px", color: "#333333", padding: "5px 10px"}}>
        <div className="flex items-center">
          <div style={avatarStyle}></div>
          <div style={{maxWidth: '44px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{baby.nickname}</div>
        </div>
        <div style={{marginLeft: "8px"}}>{baby.grade}</div>
      </div>
    </div>
  )
}

export default memo(ExcellentExerciseSingle)

ExcellentExerciseSingle.propTypes = {
  liked: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  likesCount: PropTypes.number,
  likesUrl: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
  baby: PropTypes.object.isRequired,
}