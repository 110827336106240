import React, { Component } from "react";
import SolveIdea from "./SolveIdea.jsx";

import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";
import passImg from "icon_quiz_pass.png";
import failImg from "icon_quiz_fail.png";
import { UserStudyReportQuizList } from "../../day_study_reports/StudyReport";
import "../../day_studies/GenerateQuiz.scss";
import "../../day_study_reports/UserStudyReportQuizList.scss";

import "./DayExamSubject.scss";

export default class DayExamSubject extends Component {
  constructor(props) {
    super(props);
    this.handleUpDown = this.handleUpDown.bind(this);
  }

  handleUpDown = (e) => {
    e.preventDefault();
    let ind = e.currentTarget.getAttribute("data-idx");
    this.props.list[ind] = !this.props.list[ind];
    this.setState({
      list: this.props.list,
    });
  };

  render() {
    const { list } = this.props;
    const { index } = this.props;
    const media_content = this.props.mediaContent;

    return (
      <div className="subject_lists">
        <div
          className="subject_content"
          data-idx={index}
          onClick={this.handleUpDown}
        >
          <img src={!list[index] ? arrowDown : arrowUp} />
          <div className="quiz_title">
            {media_content.passed === true ? (
              <img src={passImg} />
            ) : (
              <img src={failImg} />
            )}
            <h2>{media_content.name}</h2>
          </div>
        </div>
        {media_content.quizzes.map((quiz) => (
          <div key={quiz.id}>
            {!list[index] ? (
              ""
            ) : (
              <UserStudyReportQuizList quiz={quiz} />
              // <div className="practice_report_box">
              //   {quiz.correct_answer == quiz.error_answer &&
              //   quiz.correct_url == quiz.error_url ? (
              //     <div className="answer_correct">回答正确</div>
              //   ) : (
              //     <div className="answer_error">回答错误</div>
              //   )}
              //   <div className="practice_report_info">
              //     <div className="option_title">
              //       <p>{quiz.question}</p>
              //     </div>
              //     {quiz.question_photo_url != null ? (
              //       <div className="question_pic">
              //         <img src={quiz.question_photo_url} />
              //       </div>
              //     ) : (
              //       ""
              //     )}
              //   </div>
              //   <div className="report_answer">
              //     <div
              //       className={
              //         quiz.correct_answer == quiz.error_answer &&
              //         quiz.correct_url == quiz.error_url
              //           ? "respond_answer"
              //           : "answer_fail"
              //       }
              //     >
              //       <p>回答：</p>
              //       {quiz.error_url != "" && quiz.error_url != null ? (
              //         <img src={quiz.error_url} />
              //       ) : (
              //         quiz.error_answer
              //       )}
              //     </div>
              //     <div className="right_answer">
              //       <p>正确答案:</p>
              //       {quiz.correct_url != "" && quiz.correct_url != null ? (
              //         <img src={quiz.correct_url} />
              //       ) : (
              //         quiz.correct_answer
              //       )}
              //     </div>
              //   </div>
              //   {quiz.hint && <SolveIdea hint={quiz.hint} />}
              // </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
