/* istanbul ignore file */
import React, { Component, PureComponent } from "react";
import { request, csrfHeaders } from "../../utils/request";
import { formatAudioTime } from "../../utils/formatAudioTime";
import "./AudioPlayer.scss";
class AudioPlay extends Component {
  constructor(props) {
    super(props);
    this.resource = this.props.entireRecordUrl;

    this.state = {
      playPauseClassName: "btn-play",
      playOtherPauseClassName: "btn-play",
      totalTime: 0.0,
      width: "0%",
      showTime: "00:00",
      handleSelected: false,
      firstPlay: false,
      btnPlayPause: true,
    };

    this.getAudioInfo();
    this.btnPlayClick = this.btnPlayClick.bind(this);
    this.containerProgressClick = this.containerProgressClick.bind(this);
  }

  btnPlayClick = (e) => {
    if (!this.state.firstPlay) {
      this.setState({
        firstPlay: true,
      });
    }
    this.setState(
      (preState) => {
        return { btnPlayPause: !preState.btnPlayPause };
      },
      () => {
        if (!this.state.btnPlayPause) {
          this.audioEl.play();
          // console.log("语音播放")
        } else {
          this.audioEl.pause();
          // console.log("语音暂停")
        }
      }
    );
  };

  onEnded = (e) => {
    this.setState({
      btnPlayPause: true,
    });
  };

  onPause = (e) => {
    // console.log('on Pause')
    this.setState({
      playPauseClassName: "btn-play",
      playOtherPauseClassName: this.state.isInMiniProgram
        ? "btn_play_other_mini_pro"
        : "btn-play-other",
    });
  };

  onPlay = (e) => {
    // console.log('on Play')
    this.setState({
      playPauseClassName: this.state.isInMiniProgram
        ? "btn_play_loading_mini_pro"
        : "btn-play-loading",
      playOtherPauseClassName: this.state.isInMiniProgram
        ? "btn_play_loading_mini_pro"
        : "btn-play-loading",
    });
  };

  onTimeUpdate = (e) => {
    // console.log("on Time Update")
    if (isNaN(this.audioEl.duration)) {
      return;
    }
    const current = this.audioEl.currentTime;
    const duration = this.state.totalTime;
    const percent = current / duration;

    // console.log("current:" + current + "，duration:" + duration + "，percent:" + percent)

    this.updateProgress(percent);
    if (current > 1.0) {
      this.setState({
        playPauseClassName: "btn-play-pause",
        playOtherPauseClassName: "btn-play-pause",
      });
    }
  };

  updateProgress(percent) {
    const duration = this.state.totalTime;
    var showTime = duration * (1 - percent) <= 0 ? 0 : duration * (1 - percent);
    if (isNaN(showTime)) {
      showTime = 0.0;
    }

    this.setState({
      width: Math.round(100 * percent) + "%",
      showTime: formatAudioTime(showTime),
    });
  }

  containerProgressClick = (e) => {
    var relativeLeft =
      e.clientX - this.containerProgress.getBoundingClientRect().left;
    this.seek(relativeLeft);
  };

  handleStart = (e) => {
    this.setState({
      handleSelected: true,
    });
  };

  handleEnd = (e) => {
    this.setState({
      handleSelected: false,
    });
  };

  handleMove = (e) => {
    if (!this.state.handleSelected) {
      return;
    }
    console.log(e);
    var relativeLeft =
      e.clientX - this.containerProgress.getBoundingClientRect().left;
    console.log("relativeLeft:" + relativeLeft);
    this.seek(relativeLeft);
  };

  seek(relativeLeft) {
    if (isNaN(relativeLeft)) {
      return;
    }
    var percent = 0.0;
    percent = relativeLeft / this.containerProgress.offsetWidth;
    if (percent > 1) {
      percent = 1;
    }
    this.skipTo(percent);
    this.updateProgress(percent);
    if (percent < 1 && percent > 0) {
      // 拖动后自动播放
      this.audioEl.play();
    }
  }

  skipTo(percent) {
    this.audioEl.currentTime = this.audioEl.duration * percent;
  }

  // 获取语音的播放时长
  getAudioInfo() {
    const url = this.resource + "?avinfo";
    request({
      url: url,
      method: "get",
    })
      .then((resp) => {
        // console.log(resp.data)
        const totalTime = parseInt(resp.data.format.duration);
        this.setState({
          totalTime: totalTime,
          showTime: formatAudioTime(totalTime),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { babyName, name, type, errAudioStatus } = this.props;
    let textName;
    if (name.includes("儿歌")) {
      textName = `《${name.split("儿歌").join("")}》儿歌`;
    } else {
      textName = `《${name}》`;
    }
    let headName = "";
    if (babyName) {
      headName = `${textName} - ${babyName}`;
    } else {
      headName = `${name}`;
    }
    return (
      <div
        className={`comp_audio_play ${
          errAudioStatus == "err_audio_status" ? "pinyin_audio_style" : ""
        }`}
      >
        <div
          id="container-course"
          className="container_course"
          data-turbolinks-permanent
          data-created="true"
        >
          <div className="wrapper">
            <div className="part-left" data-turbolinks-permanent>
              <div className="play-pause">
                <div
                  className={this.state.playPauseClassName}
                  onClick={this.btnPlayClick}
                ></div>
              </div>
            </div>

            <div className="part-right">
              <div
                className={`title ${type == "pinyin" ? "pinyin_title" : ""}`}
              >
                {headName}
              </div>
              {type != "pinyin" && (
                <div className="container-time">
                  <div
                    className="container-progress"
                    ref={(containerProgress) =>
                      (this.containerProgress = containerProgress)
                    }
                    onTouchMove={this.handleMove}
                    onMouseMove={this.handleMove}
                    onClick={this.containerProgressClick}
                  >
                    <div
                      className="progress"
                      style={{ maxWidth: this.state.width }}
                    >
                      <span
                        className="handle"
                        onMouseDown={this.handleStart}
                        onTouchStart={this.handleStart}
                        onMouseUp={this.handleEnd}
                        onMouseOut={this.handleEnd}
                        onTouchEnd={this.handleEnd}
                      ></span>
                    </div>
                  </div>
                  <div className="time">{this.state.showTime}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <audio
          src={this.resource}
          ref={(audioEl) => (this.audioEl = audioEl)}
          onTimeUpdate={this.onTimeUpdate}
          onPause={this.onPause}
          onPlay={this.onPlay}
          onEnded={this.onEnded}
        ></audio>
      </div>
    );
  }
}
export class SmallAudioPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      playedPercent: 0,
      currentState: "play",
      formattedTime: "00:00",
    };
    if (this.props.audioInfoUrl) this.getAudioInfo();
  }
  // 获取语音的播放时长
  getAudioInfo() {
    const url = this.props.audioInfoUrl;
    // console.log(url)
    request({
      url: url,
      method: "get",
    })
      .then((resp) => {
        // console.log(resp.data)s
        const totalTime = parseInt(resp.data.format.duration);
        this.formatTime(totalTime);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  formatTime(time) {
    if (!time) {
      return "00:00";
    }
    let min = `${Math.floor(time / 60)}`;
    let sec = `${Math.floor(time - min * 60)}`;
    let formattedTime = `${min ? min.padStart(2, "0") : "00"}:${
      sec ? sec.padStart(2, "0") : "00"
    }`;
    this.setState({
      formattedTime: formattedTime,
    });
  }

  componentDidMount() {
    let setComponentState = (obj) => {
      this.setState(obj);
    };

    // 音频播放
    let PlayAudioState = function (audio, scop) {
      this.audio = audio;
      this.scop = scop;
      this.currentState = "play";
    };
    PlayAudioState.prototype.buttonWasPressed = function () {
      this.audio.play();
      console.log("音频播放");
      this.scop.setState(this.scop.pauseAudioState);
    };

    // 音频暂停
    let PauseAudioState = function (audio, scop) {
      this.audio = audio;
      this.scop = scop;
      this.currentState = "pause";
    };
    PauseAudioState.prototype.buttonWasPressed = function () {
      this.audio.pause();
      console.log("音频暂停");
      this.scop.setState(this.scop.playAudioState);
    };

    // 音频构造函数
    let Audio = function (audio) {
      this.playAudioState = new PlayAudioState(audio, this);
      this.pauseAudioState = new PauseAudioState(audio, this);
      this.button = null;
      this.audio = audio;
    };
    let self = this;
    // 音频初始化
    Audio.prototype.init = function (button) {
      this.audio.ontimeupdate = () => {
        let currentTime = audioId.currentTime;
        let duration = audioId.duration;
        self.formatTime(duration - currentTime);
        let playedPercent = currentTime / audioId.duration;
        if (playedPercent >= 1) {
          this.setState(this.playAudioState);
        }
        setComponentState({
          playedPercent: playedPercent * 100,
        });
      };

      // 音频初始化状态
      this.currState = this.playAudioState;

      this.button = button;
      this.button.onclick = () => {
        console.log("clicked");
        this.currState.buttonWasPressed();
      };
    };

    // 更新音频状态
    Audio.prototype.setState = function (newState) {
      setComponentState({
        currentState: newState.currentState,
      });
      this.currState = newState;
    };
    let audioId = document.getElementById(`audioSrc${this.props.index}`);
    let buttonId = document.getElementById(`play_stop${this.props.index}`);
    var audio = new Audio(audioId);
    audio.init(buttonId);
  }
  render() {
    const { poetryName, baby, audioSrc, poetryTitle } = this.props;
    const { formattedTime, currentState, playedPercent } = this.state;
    return [
      <audio
        src={audioSrc}
        preload="auto"
        id={`audioSrc${this.props.index}`}
        key="audio"
      ></audio>,
      <div className="audio_player_wrapper" key="audio_player_wrapper">
        <div className="audio_play">
          <div className="audio_player_container">
            <div
              className={`play_stop_common ${currentState}`}
              id={`play_stop${this.props.index}`}
            ></div>
            <div className="audio_info">
              {poetryName && baby && (
                <div className="title_info">
                  {poetryName} - {baby}
                </div>
              )}
              {poetryTitle && <div className="title_info">{poetryTitle}</div>}
              <div
                className="audio_progress"
                style={{
                  background:
                    "linear-gradient( 90deg,#6ad33b 0,#6ad33b " +
                    playedPercent +
                    "%, transparent " +
                    playedPercent +
                    "%,transparent 100%)",
                }}
              ></div>
            </div>
            <div className="time_progress">{formattedTime}</div>
          </div>
        </div>
      </div>,
    ];
  }
}
export default AudioPlay;

SmallAudioPlayer.defaultProps = {
  index: 0,
};
