/* eslint-disable react/prop-types */
import React, { memo, useState, useEffect, useCallback } from "react";

import { Dialog, DialogManager } from "../../../../utils/Dialog";
import { formatDate } from "../../../../utils/formatAudioTime";
import NewPoetryBuyDialog from "../components/NewPoetryBuyDialog.jsx";
import useCoursePayment from "../../../sync_practice/components/CoursePayment.jsx";
import "../../../../utils/ReactMarkDown.scss";
import icon_lock from "study_package/icon_lock_white.png";

import "./ExtensiveReading.scss";
import "../../v2/components/SimplePoetryList.scss";

// import PoetryReading from '../../../../poetry_readings/PoetryReading'

const ExternalPoetryItem = ({
  article,
  isLocked,
  render,
  endTime,
  systemTime,
  startTime,
  isSpell,
  isSanZiJing,
}) => {
  return (
    <a href={article.poetry_article_url}>
      <div className="external_poetry_wrapper">
        <div className={`${isSpell ? "spell_img_wrapper" : "img_wrapper"}`}>
          <img data-src={article.photo_url} className="lazyload" />
        </div>
        {isSanZiJing ? (
          <div className="peotry_info">
            <div className="peotry_title">{article.name}</div>
            <div className="author_dynasty" style={{ whiteSpace: "initial" }}>
              {article.brief}
            </div>
          </div>
        ) : (
          !isSpell && (
            <div className="peotry_info">
              <div className="peotry_title">{article.name}</div>
              <div className="author_dynasty">{article.author}</div>
            </div>
          )
        )}
      </div>
      {formatDate(systemTime) < formatDate(startTime) ? (
        render()
      ) : (
        <div className="arrow_left"></div>
      )}
    </a>
  );
};

const ExtensivePoetriesList = ({
  name,
  isLocked,
  buyUrl,
  articles,
  onModalOpenClick,
  endTime,
  startTime,
  systemTime,
  isSpell,
  handleCoursePay,
  studyPackageModules,
  isSanZiJing,
}) => {
  const ExternalPoetryState = () => {
    return (
      <div className="locked">
        <div className="lock"></div>
      </div>
    );
  };

  const onExternalPoetryClick = (e) => {
    const buttons = [
      {
        label: "关闭",
        onClick: () => confirmDialog.close(),
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        <p>等待课程开课后解锁</p>
      </Dialog>
    );
  };

  // 拼音课·解锁基础版泛读
  const unlockedBasicCourse =
    studyPackageModules[0]?.upgrade_package && isSpell;

  console.log("拼音课·解锁基础版泛读: ", unlockedBasicCourse);
  return (
    // 判断是否基础版，再判断是否到了解锁时间
    <div
      onClick={
        unlockedBasicCourse && isLocked
          ? () => handleCoursePay()
          : formatDate(systemTime) < formatDate(startTime)
          ? () => onExternalPoetryClick()
          : () => onModalOpenClick(true)
      }
      className={
        isLocked
          ? "extensive_poetries_list is_locked"
          : "extensive_poetries_list"
      }
    >
      {isLocked && (
        <div className="lock_status">
          <img src={icon_lock} alt="" />
        </div>
      )}
      <div className="external_poetry_item">
        {!isSanZiJing && name && (
          <div className="external_poetry_item_head">
            <h3>{name}</h3>
          </div>
        )}
        {(articles || []).map((article, index) => (
          <ExternalPoetryItem
            key={index}
            article={article}
            isLocked={isLocked}
            render={() => <ExternalPoetryState />}
            endTime={endTime}
            systemTime={systemTime}
            startTime={startTime}
            isSpell={isSpell}
            isSanZiJing={isSanZiJing}
          />
        ))}
      </div>
    </div>
  );
};

const PoetryReading = ({ ...props }) => {
  const [isShowReminderModal, setIsShowReminderModal] = useState(false);
  const {
    study_package_modules,
    study_package_system_time,
    study_package_end_time,
    study_package_start_time,
    isSpell,
    isSanZiJing,
  } = props;
  console.log("PoetryReading: ", props);

  const upgradePackageExpand =
    study_package_modules && study_package_modules[0]?.upgrade_package;
  console.log("upgradePackageExpand_upgrade_package: ", upgradePackageExpand);

  if (isSpell && upgradePackageExpand) {
    upgradePackageExpand.isSpell = true;
  }

  const onPayModalOpen = useCoursePayment(upgradePackageExpand);
  const handleCoursePay = useCallback(() => {
    onPayModalOpen();
  }, [onPayModalOpen]);
  // 购买课程链接
  let buyUrl;
  if (!isSpell) {
    buyUrl =
      study_package_modules &&
      study_package_modules[0]?.upgrade_package &&
      study_package_modules[0]?.upgrade_package?.study_package_activity_buy_url;
  }

  const onModalOpenClick = useCallback((boolTrue) => {
    setIsShowReminderModal(boolTrue);
  }, []);

  const getCloseModalStatus = useCallback((boolFalse) => {
    setIsShowReminderModal(boolFalse);
  }, []);

  return (
    <div className="external_poetry_wrap">
      <div className="external_poetry_cover">
        {isSpell ? (
          <img src="https://upload.fireflybaby.cn/study_package_activity/20fbcb13a176b6ed1428891c8199562a.jpg" />
        ) : (
          <img src="https://upload.fireflybaby.cn/article/807200bf249bb3f56fafa7282af90053.jpg" />
        )}
      </div>
      <div className="comp_extensive_poetrirs">
        {study_package_modules &&
          study_package_modules.map((m, idx) => {
            return (
              m?.poetry_articles?.length > 0 && (
                <ExtensivePoetriesList
                  key={idx}
                  name={m.module_name}
                  isLocked={m.upgrade_package_required || m.datetime_diff > 0}
                  buyUrl={m.study_package_activity_buy_url}
                  articles={m.poetry_articles}
                  onModalOpenClick={buyUrl ? onModalOpenClick : () => {}}
                  endTime={study_package_end_time}
                  startTime={study_package_start_time}
                  systemTime={study_package_system_time}
                  handleCoursePay={handleCoursePay}
                  studyPackageModules={study_package_modules}
                  isSpell={isSpell}
                  isSanZiJing={isSanZiJing}
                />
              )
            );
          })}
      </div>
      {isShowReminderModal && (
        <NewPoetryBuyDialog
          getCloseModalStatus={getCloseModalStatus}
          url={buyUrl}
        />
      )}
    </div>
  );
};

const ExtensiveReading = memo(({ ...props }) => {
  console.log("ExtensiveReading_props: ", props);
  const { unitData, is_spell: isSpell, sub_course_type } = props;
  return (
    <div className="extensive_reading">
      {unitData && (
        <PoetryReading
          study_package_modules={unitData.study_package_modules}
          {...props}
          isSpell={isSpell}
          isSanZiJing={sub_course_type === "sanzijing"}
        />
      )}
    </div>
  );
});

export default ExtensiveReading;
