/* eslint-disable react/prop-types */
import React, { useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { DateCountDown } from "../../../../utils/formatAudioTime.js";
import PoetryBabyAudio from "./PoetryBabyAudio.jsx";
import { ToastManager } from "../../../../utils/Toast";
import { request, csrfHeaders } from "../../../../utils/request.js";
import ListContainer from "../../../../utils/ListContainer/ListContainer.jsx";
import "./MyAudios.scss";

const AudioItem = (props) => {
  return (
    <div className="audio_item">
      <div className="from_now">{props.fromNow}</div>
      <PoetryBabyAudio {...props} />
    </div>
  );
};

AudioItem.propTypes = {
  fromNow: PropTypes.string,
};

const LoadMore = ({ requestMore }) => (
  <span className="load_more" onClick={requestMore}>
    加载更多
  </span>
);

LoadMore.propTypes = {
  requestMore: PropTypes.func,
};

const NoneContent = ({ isSpell, isSanZiJing }) => {
  return (
    <div className="no_content">
      <p className="main">还没有音频哦~</p>
      <p className="desc">
        {isSpell
          ? `音频来自于上课的“唱儿歌”环节`
          : isSanZiJing
          ? "音频来自于上课的“巧记忆”和“配乐读”环节"
          : "音频来自于上课的“节奏品读”和“配乐朗诵”环节"}
      </p>
      <p className="desc_two">请先完成相应课程学习</p>
    </div>
  );
};

const MyAudios = (props) => {
  console.log(
    "MyAudios",
    props
    // distanceInWordsToNow(new Date(1575632208143), {
    //   locale: zh_cn
    // })
  );
  const [babyAudioRecords, setBabyAudioRecords] = useState(
    props.unitData.baby_audio_recorders || []
  );
  const [isShowLoadMore, setIsShowLoadMore] = useState(true);
  const page = useRef(2);
  const requestMore = useCallback(() => {
    const toast = ToastManager.showLoading("数据加载中...");
    request({
      url: props.requestUrl,
      method: "GET",
      headers: csrfHeaders,
      params: {
        count: 10,
        page: page.current,
      },
    })
      .then((res) => {
        toast.cancel();
        const records = res.data.baby_audio_recorders;
        if (records.length) {
          setBabyAudioRecords((preRecords) => [...preRecords, ...records]);
          page.current++;
        } else {
          setIsShowLoadMore(false);
          ToastManager.showToastText("没有更多了", 2000);
        }
      })
      .catch((err) => {
        toast.cancel();
        console.log(err);
      });
  }, [props.requestUrl]);

  return (
    <div
      className={`comp_user_audios ${
        props.is_spell ? "comp_spell_user_audio" : ""
      }`}
    >
      <div className={babyAudioRecords.length ? "audio_list" : "no_audio_list"}>
        {
          <ListContainer
            list={babyAudioRecords}
            listItem={({ itemIndex, ...recorder }) => (
              <React.Fragment key={itemIndex}>
                <AudioItem
                  index={itemIndex}
                  key={itemIndex}
                  fromNow={DateCountDown(
                    recorder.update_time,
                    props.study_package_system_time
                  )}
                  poetryTitle={recorder.name}
                  audioSrc={recorder.entire_record_url}
                  audioInfoUrl={recorder.audio_info_url}
                />
                {itemIndex === babyAudioRecords.length - 1 &&
                  isShowLoadMore && <LoadMore requestMore={requestMore} />}
              </React.Fragment>
            )}
            fallBack={() => (
              <NoneContent
                isSpell={props.is_spell}
                isSanZiJing={props.sub_course_type === "sanzijing"}
              />
            )}
          />
        }
      </div>
    </div>
  );
};

MyAudios.propTypes = {
  study_package_system_time: PropTypes.string,
  unitData: PropTypes.object,
  requestUrl: PropTypes.string,
};
export default MyAudios;
