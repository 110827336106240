import Raven from "raven-js";

class CanvasBoard {
  constructor(canvasElement) {
    console.log("canvasElement: ", canvasElement);
    this.canvasElement = canvasElement;
    this.painting = false;
    this.lastPoint = { x: null, y: null };
    this.eraserEnabled = false;
    this.pencilWith = 7;
    this.setCanvasElement(canvasElement);

    this.radius = 10;
    this.strokeStyle = "rgba(104, 190, 237, 1)";
  }

  getFixedLastPoint = () => {
    const { left, top } = this.canvasElement.getBoundingClientRect();
    return {
      x: this.lastPoint.x + left,
      y: this.lastPoint.y + top,
    };
  };

  drawImg = ({ isHTMLImage, src, top, left, width, height }) => {
    let toPromise = Promise.resolve(src);
    if (isHTMLImage) {
      const img = new Image();
      img.setAttribute("crossOrigin", "Anonymous");
      toPromise = toPromise.then(
        (imgSrc) =>
          new Promise((resolve, reject) => {
            img.onload = () => {
              if (width === this.canvasElement.width) {
                height = (width / img.width) * img.height;
              }
              if (height === this.canvasElement.height) {
                width = (height / img.height) * img.width;
              }
              resolve(img);
            };
            img.onerror = () => {
              reject(img);
            };
            img.src = imgSrc;
          })
      );
    }

    return toPromise.then((imgSrc) => {
      const realTop =
        top == null ? (this.canvasElement.height - (height || 0)) / 2 : top;
      const realLeft =
        left == null ? (this.canvasElement.width - (width || 0)) / 2 : left;
      this.canvasCtx.drawImage(imgSrc, realLeft, realTop, width, height);
    });
  };

  toImg = (imageType) => {
    const dataUrlType = imageType || "image/png";
    return Promise.resolve(this.canvasElement.toDataURL(dataUrlType));
  };

  clearBoard = () => {
    // eslint-disable-next-line no-unused-expressions
    this.canvasCtx?.clearRect(
      0,
      0,
      this.canvasElement?.width,
      this.canvasElement?.height
    );
  };

  setCanvasElement = (canvasElement) => {
    this.canvasElement = canvasElement;
    if (canvasElement) {
      this.canvasCtx = canvasElement.getContext("2d");
    }
  };

  drawImageByScale = (img, scale) => {
    console.log("82===img===", img);
    console.warn("83===canvasCtx===", this.canvasCtx);
    console.warn("84===canvasElement===", this.canvasElement);
    console.log("scale: ", scale);
    var imageWidth = img.width * scale;
    var imageHeight = img.height * scale;
    var dx = (this.canvasElement?.width - imageWidth) / 2;
    var dy = (this.canvasElement?.height - imageHeight) / 2;
    this.canvasCtx.clearRect(
      0,
      0,
      this.canvasElement?.width,
      this.canvasElement?.height
    );
    this.canvasCtx.drawImage(img, dx, dy, imageWidth, imageHeight);
  };

  setPencilColor = (color) => {
    if (color) {
      this.strokeStyle = color;
    }
  };

  setPencilWith = (lineWidth) => {
    if (lineWidth) {
      this.pencilWith = lineWidth;
    }
  };

  enableEraser = (bool) => {
    this.eraserEnabled = bool;
  };

  eraserMoveHandler = (x1, y1, x2, y2) => {
    // 获取两个点之间的剪辑区域四个端点
    var asin = this.radius * Math.sin(Math.atan((y2 - y1) / (x2 - x1)));
    var acos = this.radius * Math.cos(Math.atan((y2 - y1) / (x2 - x1)));
    var x3 = x1 + asin;
    var y3 = y1 - acos;
    var x4 = x1 - asin;
    var y4 = y1 + acos;
    var x5 = x2 + asin;
    var y5 = y2 - acos;
    var x6 = x2 - asin;
    var y6 = y2 + acos; // 保证线条的连贯，所以在矩形一端画圆

    this.canvasCtx.save();
    this.canvasCtx.beginPath();
    this.canvasCtx.globalCompositeOperation = "destination-out";
    this.radius = this.pencilWith / 2 > 5 ? this.pencilWith / 2 : 5;
    this.canvasCtx.arc(x2, y2, this.radius, 0, 2 * Math.PI);
    this.canvasCtx.clip();
    this.canvasCtx.clearRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    this.canvasCtx.restore(); // 清除矩形剪辑区域里的像素

    this.canvasCtx.save();
    this.canvasCtx.beginPath();
    this.canvasCtx.globalCompositeOperation = "destination-out";
    this.canvasCtx.moveTo(x3, y3);
    this.canvasCtx.lineTo(x5, y5);
    this.canvasCtx.lineTo(x6, y6);
    this.canvasCtx.lineTo(x4, y4);
    this.canvasCtx.closePath();
    this.canvasCtx.clip();
    this.canvasCtx.clearRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    this.canvasCtx.restore();
  };

  drawLine = (x1, y1, x2, y2) => {
    try {
      const asin = this.pencilWith * Math.sin(Math.atan((y2 - y1) / (x2 - x1)));
      const acos = this.pencilWith * Math.cos(Math.atan((y2 - y1) / (x2 - x1)));
      // 分别获取矩形的四个点的xy轴位置
      const x3 = x1 + asin;
      const y3 = y1 - acos;
      const x4 = x1 - asin;
      const y4 = y1 + acos;
      const x5 = x2 + asin;
      const y5 = y2 - acos;
      const x6 = x2 - asin;
      const y6 = y2 + acos;

      this.canvasCtx.beginPath();
      this.canvasCtx.fillStyle = this.strokeStyle;
      this.canvasCtx.arc(x2, y2, this.pencilWith, 0, 2 * Math.PI);
      this.canvasCtx.fill();
      this.canvasCtx.closePath();
      this.canvasCtx.beginPath();
      this.canvasCtx.fillStyle = this.strokeStyle;
      this.canvasCtx.moveTo(x3, y3);
      this.canvasCtx.lineTo(x5, y5);
      this.canvasCtx.lineTo(x6, y6);
      this.canvasCtx.lineTo(x4, y4);
      this.canvasCtx.fill();
      this.canvasCtx.closePath();

      // this.canvasCtx.beginPath();
      // this.canvasCtx.lineWidth = this.pencilWith;
      // this.canvasCtx.strokeStyle = this.strokeStyle;
      // // context.globalAlpha = opacity;
      // // 设置线条末端样式。
      // this.canvasCtx.lineCap = "round";
      // // 设定线条与线条间接合处的样式
      // this.canvasCtx.lineJoin = "round";
      // this.canvasCtx.moveTo(x1, y1);
      // this.canvasCtx.lineTo(x2, y2);
      // this.canvasCtx.stroke();
      // this.canvasCtx.closePath();
    } catch (e) {
      console.log(e);
      Raven.captureException(e);
    }
  };

  /* update lastPoint {x, y} */
  handlePencilActionStart = (curX, curY) => {
    this.painting = true;
    if (this.eraserEnabled) {
      // 要使用eraser
      this.radius = this.pencilWith / 2 > 5 ? this.pencilWith / 2 : 5;
      this.canvasCtx.save();
      this.canvasCtx.globalCompositeOperation = "destination-out";
      this.canvasCtx.beginPath();

      this.canvasCtx.arc(curX, curY, this.radius, 0, 2 * Math.PI);
      this.canvasCtx.clip();
      this.canvasCtx.clearRect(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      this.canvasCtx.restore();
    }
    this.lastPoint.x = curX;
    this.lastPoint.y = curY;
  };

  /* update lastPoint {x, y} */
  handlePencilActionMove = (curX, curY) => {
    if (this.eraserEnabled) {
      this.eraserMoveHandler(this.lastPoint.x, this.lastPoint.y, curX, curY);
    } else {
      this.drawLine(this.lastPoint.x, this.lastPoint.y, curX, curY);
    }
    this.lastPoint.x = curX;
    this.lastPoint.y = curY;
  };

  handlePencilActionEnd = () => {
    this.painting = false;
    // canvasDraw();
  };

  bindEvents = (events) => {
    if (!this.canvasElement) {
      return;
    }

    const handleTouchStart = (e) => {
      const {
        left: preFixedX,
        top: preFixedY,
      } = this.canvasElement.getBoundingClientRect();
      const x = e.touches[0].clientX;
      const y = e.touches[0].clientY;
      this.handlePencilActionStart(x - preFixedX, y - preFixedY);
    };
    const handleTouchMove = (e) => {
      if (!this.painting) {
        return;
      }
      e.preventDefault();
      const {
        left: preFixedX,
        top: preFixedY,
      } = this.canvasElement.getBoundingClientRect();
      const x = e.touches[0].clientX;
      const y = e.touches[0].clientY;

      this.handlePencilActionMove(x - preFixedX, y - preFixedY);
    };
    const handleMouseDown = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      const {
        left: preFixedX,
        top: preFixedY,
      } = this.canvasElement.getBoundingClientRect();
      this.handlePencilActionStart(x - preFixedX, y - preFixedY);
    };
    const handleMouseMove = (e) => {
      if (!this.painting) {
        return;
      }
      e.preventDefault();
      const {
        left: preFixedX,
        top: preFixedY,
      } = this.canvasElement.getBoundingClientRect();
      const x = e.clientX;
      const y = e.clientY;
      this.handlePencilActionMove(x - preFixedX, y - preFixedY);
    };
    this.canvasElement.addEventListener("touchstart", handleTouchStart, false);
    this.canvasElement.addEventListener("touchmove", handleTouchMove, false);
    this.canvasElement.addEventListener(
      "touchend",
      this.handlePencilActionEnd,
      false
    );
    this.canvasElement.addEventListener("mousedown", handleMouseDown, false);
    this.canvasElement.addEventListener("mousemove", handleMouseMove, false);
    this.canvasElement.addEventListener(
      "mouseup",
      this.handlePencilActionEnd,
      false
    );

    const outEvents = [];

    if (events && typeof events === "object") {
      Object.keys(events).forEach((ev) => {
        const event = events[ev].bind(null, this);
        this.canvasElement.addEventListener(ev, event, false);
        outEvents.push(() => this.canvasElement.removeEventListener(ev, event));
      });
    }

    return () => {
      this.canvasElement.removeEventListener("touchstart", handleTouchStart);
      this.canvasElement.removeEventListener("touchmove", handleTouchMove);
      this.canvasElement.removeEventListener("mousedown", handleMouseDown);
      this.canvasElement.removeEventListener("mousemove", handleMouseMove);

      if (outEvents.length) {
        outEvents.forEach((removeEvent) => removeEvent());
      }
    };
  };
}

export default CanvasBoard;
